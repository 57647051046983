import React, { useEffect, useState } from 'react';
import { Descriptions, Row, Table } from 'antd';


const DailyOrderIncomeTabContents = ({dailyIncomeOrder = []}) => {
    const [numberTotal, setNumberTotal] = useState(0);
    const [cashTotal, setCashTotal] = useState(0);
    const [days, setDays] = useState([]);

    const orderColumns = [
        {title: 'día', dataIndex: 'day', align: 'center', width: 20},
        {title: 'órdenes', dataIndex: 'totalNumber', align: 'center', width: 20},
        {title: 'total', dataIndex: 'totalCash', align: 'center', width: 20, render: (e) => '$' + Number(e ?? 0).toLocaleString()}
    ];

    useEffect(() => {
        if (dailyIncomeOrder != null) {
            if (dailyIncomeOrder.OrdersNumberTotal != null) { setNumberTotal(dailyIncomeOrder.OrdersNumberTotal ?? []); }
            if (dailyIncomeOrder.OrdersCashTotal != null) { setCashTotal(dailyIncomeOrder.OrdersCashTotal ?? []); }
            if (dailyIncomeOrder.Orders != null) { setDays(dailyIncomeOrder.Orders ?? []); }
        }
    }, [dailyIncomeOrder]);


    return (
        <Row style={{display: 'flex', width: '100%'}}>
            <Row style={{width: '100%', marginTop: 20, marginBottom: 20}}>
                <Descriptions>
                    <Descriptions.Item label='Número de órdenes'>{numberTotal}</Descriptions.Item>
                    <Descriptions.Item label='Total'>${Number(cashTotal ?? 0).toLocaleString()}</Descriptions.Item>
                </Descriptions>
            </Row>
            <Table
                style={{width: '100%'}}
                size='small'
                scroll={{y: 500}}
                pagination={false}
                columns={orderColumns}
                dataSource={days}
                rowKey='day'
            />
        </Row>
    );
};

export default DailyOrderIncomeTabContents;