import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { Button, Card, Col, Table, Layout, Row, Select, Steps, Spin } from 'antd';


const { Header, Content } = Layout;
const ButtonGroup = Button.Group;
const Option = Select.Option;
const Step = Steps.Step;
const columns = [
    {
        title: 'Producto',
        dataIndex: 'product'
    },
    {
        title: 'Precio',
        dataIndex: 'price'
    },
    {
        title: 'Unidades vendidas',
        dataIndex: 'quantity'
    },
    {
        title: 'Total vendido',
        dataIndex: 'total'
    }
];


class DailyServiceReport extends Component {
    state = {
        loading: false,
        currentStep: 0,
        hotelsOptions: [],
        selectedHotel: [],
        products: new Map(),
        productPrices: new Map(),
        dateOptions: [],
        fromDate: null,
        shiftBalances: [],
        processedProducts: []
    }

    idToken = '';
    userHotels = [];


    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    loadData() {
        this.setState({loading: true});
        this.setHotelsMenu();
        this.getProducts();

        // DAYS
        const today = new Date();
        const upperLimit = this.getUpperDateLimit(today);
        
        var days = [];
        days.push(<Option key={upperLimit}>{today.toDateString()}</Option>);

        var dayDecrement = today;
        for (var i = 0; i < 60; i++) {
            dayDecrement.setDate(dayDecrement.getDate() - 1);
            days.push(<Option key={dayDecrement.getTime()}>{dayDecrement.toDateString()}</Option>);
        }
        this.setState({dateOptions: days, loading: false});
    }

    setHotelsMenu() {
        const enabledHotels = this.props.hotels;
        var hotels = [];

        for (var i in enabledHotels) {
            hotels.push(<Option key={i}>{enabledHotels[i]}</Option>);
        }

        this.setState({hotelsOptions: hotels});
    }

    getProducts() {
        API.get('benackoAPI', '/products?companyId=' + this.props.companyId, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( productsRes => {
                var products = new Map();
                productsRes.forEach( product => {
                    products.set(product.id, product.name);
                });

                this.setState({products: products});
            })
            .catch( errProducts => {
                console.log('Error loading products:', errProducts);
            });
    }

    getProductPrices() {
        API.get('benackoAPI', '/product-prices?hotelId=' + this.state.selectedHotel, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( pricesRes => {
                var prices = new Map();
                pricesRes.forEach( price => {
                    prices.set(price.productId, price.price);
                });

                this.setState({productPrices: prices});
            })
            .catch( errPrices => {
                console.log('Error loading prices:', errPrices);
            });
    }

    getUpperDateLimit(date) {
        var tomorrow = date;
        tomorrow.setHours(23);
        tomorrow.setMinutes(59);
        tomorrow.setSeconds(59);
        tomorrow.setMilliseconds(999);
        
        return tomorrow.getTime();
    }

    selectHotelHandler = (value) => {
        this.setState({selectedHotel: value}, () => {
            this.getProductPrices();
        });
    }

    selectFromDateHandler = (value) => {
        this.setState({fromDate: parseInt(value, 10)});
    }

    goToSecondStep = async () => {
        this.setState({ loading: true });

        const previousDay = new Date();
        previousDay.setTime(this.state.fromDate);
        previousDay.setDate(previousDay.getDate() - 1);
        const startTimestamp = previousDay.getTime();

        const endOfDay = new Date();
        endOfDay.setTime(this.state.fromDate);
        endOfDay.setDate(endOfDay.getDate() + 1);
        const endTimestamp = endOfDay.getTime();

        const pathString = '/shift-balances?hotelId=' + this.state.selectedHotel + '&startTimestamp=' + startTimestamp + '&endTimestamp=' + endTimestamp;
        await API.get('benackoAPI', pathString, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( async sbRes => {
                var shiftBalances = [];

                sbRes.forEach( sb => {
                    shiftBalances.push(sb.id);
                });

                return shiftBalances;
            })
            .then( async shiftBalances => {
                var products = [];

                await shiftBalances.forEach( sbId => {
                    const pathString = '/actions/rooms/service?companyId=' + this.props.companyId + '&hotelId=' + this.state.selectedHotel + '&shiftBalanceId=' + sbId;
                    API.get('benackoAPI', pathString, {
                            headers: {
                                Authorization: this.idToken
                            }
                        })
                        .then( serviceRes => {
                            serviceRes.Items.forEach( service => {
                                service.products.forEach( async product => {
                                    const price = this.state.productPrices.get(product.productId);

                                    if (products.find(pr => pr.key === product.productId)) {
                                        const storedQty = products.find(pr => pr.key === product.productId).quantity;
                                        const quantity = storedQty + product.quantity;
                                        const newTotal = price * quantity;
                                        
                                        products.find(pr => pr.key === product.productId).quantity = quantity;
                                        products.find(pr => pr.key === product.productId).total = '$' + newTotal;
                                    } else {
                                        const total = product.quantity * price;
                                        products.push({
                                            key: product.productId,
                                            product: this.state.products.get(product.productId),
                                            price: '$' + price,
                                            quantity: product.quantity,
                                            total: '$' + total
                                        });
                                    }
                                });
                            });
                        })
                        .catch( errService => {
                            console.log('Error loading services:', errService);
                        });
                });

                return products;
            })
            .then( products => {
                Promise.all([products])
                    .then( res => {
                        console.log('res', res[0]);
                        this.setState({processedProducts: res[0]});
                    });
            })
            .then( () => {
                this.setState({
                    loading: false,
                    currentStep: 1
                });
            })
            .catch( errSb => {
                console.log('Error loading report:', errSb);
                this.setState({loading: false});
            });
    }

    goPreviousStep = () => {
        const newStep = this.state.currentStep - 1;
        this.setState({
            currentStep: newStep,
            fromDate: null,
            processedProducts: []
        });
    }

    updateState = () => {
        this.setState({});
    }

    render() {
        const stepOne = (
            <Spin spinning={this.state.loading} size="large">
                <Row style={{width: 600, marginTop: 10}}>
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Selecciona el hotel"
                        onChange={this.selectHotelHandler}
                    >
                        {this.state.hotelsOptions}
                    </Select>
                </Row>
                <Row style={{width: 600, marginTop: 20}}>
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Selecciona la fecha"
                        onChange={this.selectFromDateHandler}
                    >
                        {this.state.dateOptions}
                    </Select>
                </Row>
                <Row style={{ marginTop: 50 }}>
                    <Button 
                        type="primary" 
                        onClick={this.goToSecondStep} 
                        disabled={(this.state.selectedHotel.length <= 0 || (this.state.toDate) ? true : false)}
                    >
                        Siguiente
                    </Button>
                </Row>
            </Spin>
        );

        const stepTwo = (
            <Spin spinning={this.state.loading} size="large">
                <Row>
                    <Card
                        title="Reporte"
                        bordered={false}
                        style={{ marginBottom: 30 }}
                    >
                        <Table
                            columns={columns} 
                            dataSource={this.state.processedProducts} 
                            size="small"
                            bordered={false}
                            locale={{ emptyText: 'No se encontraron servicios' }}
                            pagination={false}
                        />
                    </Card>
                </Row>
                <Row style={{ marginTop: 40 }}>
                    <ButtonGroup>
                        <Button onClick={this.updateState}>Cargar reporte</Button>
                        <Button type="primary" onClick={this.goPreviousStep}>Regresar</Button>
                    </ButtonGroup>
                </Row>
            </Spin>
        );

        let renderedStep = null;
        if (this.state.currentStep === 0) {
            renderedStep = stepOne;
        }

        if (this.state.currentStep === 1) {
            renderedStep = stepTwo;
        }

        return (
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#fff', paddingTop: 15 }}>
                    <Steps size="small" current={this.state.currentStep}>
                        <Step title="Seleccionar hotel y fecha" />
                        <Step title="Reporte diario de servicios" />
                    </Steps>
                    <Row style={{marginTop: 50}}>
                        <Spin spinning={this.state.loading} size="large">
                        <Col span={20} offset={2}>
                            {renderedStep}
                        </Col>
                        </Spin>
                    </Row>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.auth.companyId,
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(DailyServiceReport);