import React from 'react';
import { Route, Switch } from 'react-router-dom';

import RoomsList from './Rooms/RoomsList/RoomsList';
import RoomsCreate from './Rooms/RoomsCreate/RoomsCreate';
import RoomsDetails from './Rooms/RoomsDetails/RoomsDetails';

import RentTypesList from './RentTypes/RentTypesList/RentTypesList';
import RentTypesCreate from './RentTypes/RentTypesCreate/RentTypesCreate';
import RentTypesDetails from './RentTypes/RentTypesDetails/RentTypesDetails';


const Hotels = () => {
    return (
        <Switch>
            <Route path="/app/rooms"
                render={({ match: { url } }) => (
                    <div>
                        <Route exact path={`${url}`} component={RoomsList} />
                        <Route exact path={`${url}/create`} component={RoomsCreate} />
                        <Route exact path={`${url}/details/:id`} component={RoomsDetails} />
                    </div>
                )}
            />

            <Route path="/app/rent-types"
                render={({ match: { url } }) => (
                    <div>
                        <Route exact path={`${url}`} component={RentTypesList} />
                        <Route exact path={`${url}/create`} component={RentTypesCreate} />
                        <Route exact path={`${url}/details/:id`} component={RentTypesDetails} />
                    </div>
                )}
            />
        </Switch>
    );
}

export default Hotels;