import React from 'react';
import { Row } from 'antd';

const SectionTitle = ({title = '', marginLeft = 40, marginTop = 0}) => {

    return (
        <Row style={{marginTop: marginTop, display: 'flex', alignItems: 'center', width: '100%', height: 60}}>
            <span style={{marginLeft: marginLeft, fontSize: 28}}>{title}</span>
        </Row>
    );
}

export default SectionTitle;