import * as actionTypes from '../actions/actionTypes';


const initialState = {
    idToken: null,
    userId: null,
    user: null,
    error: null,
    loading: false,
    role: null,
    hotels: null,
    selectedHotel: null,
    companyId: null,
    isDataLoaded: false,
    hotelSelectorEnabled: false
};

const authStart = (state, action) => {
    return {
        ...state,
        error: null,
        loading: true
    };
};

const authSuccess = (state, action) => {
    return {
        ...state,
        idToken: action.idToken,
        userId: action.userId,
        error: null,
        loading: false
    };
};

const authLoad = (state, action) => {
    return {
        ...state,
        role: action.role,
        hotels: action.hotels,
        selectedHotel: action.selectedHotel,
        companyId: action.companyId,
        user: action.user,
        isDataLoaded: true
    };
};

const authFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        loading: false
    };
};

const authLogout = (state, action) => {
    return {
        ...state,
        idToken: null,
        userId: null,
        user: null,
        role: null,
        hotels: null,
        selectedHotel: null,
        companyId: null,
        isDataLoaded: false,
        hotelSelectorEnabled: false
    };
};


const reducer = ( state = initialState, action ) => {
    switch(action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_LOAD: return authLoad(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        default: return state;
    }
};

export default reducer;