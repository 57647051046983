import * as actions from '../actions/actionTypes';

const initialState = {
    currentUtilities: [],
    utilityEvents: [],
    error: null
};

const getCurrentUtility = (state, action) => {
    
    return {
        ...state,
        currentUtilities: action.currentUtilities,
        error: null
    };
};

const getCurrentUtilityFail = (state, action) => {
    return {
        ...state,
        currentUtilities: [],
        utilityEvents: [],
        error: action.error
    };
};

const createUtilityEvent = (state, _) => {
    
    return {
        ...state,
        error: null
    };
};

const createUtilityEventFail = (state, action) => {
    return {
        ...state,
        error: action.error
    };
};

const getUtilityEvents = (state, action) => {
    return {
        ...state,
        utilityEvents: action.events,
        error: null
    };
};

const getUtilityEventsFail = (state, action) => {
    return {
        ...state,
        utilityEvents: [],
        error: action.error
    };
};

const clearMaintenanceData = () => {
    return {
        currentUtilities: [],
        utilityEvents: [],
        error: null
    };
};


const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actions.GET_CURRENT_UTILITY: return getCurrentUtility(state, action);
        case actions.GET_CURRENT_UTILITY_FAIL: return getCurrentUtilityFail(state, action);
        case actions.CREATE_UTILITY_EVENT: return createUtilityEvent(state, action);
        case actions.CREATE_UTILITY_EVENT_FAIL: return createUtilityEventFail(state, action);
        case actions.GET_UTILITY_EVENTS: return getUtilityEvents(state, action);
        case actions.GET_UTILITY_EVENTS_FAIL: return getUtilityEventsFail(state, action);
        case actions.CLEAR_MAINTENANCE_DATA: return clearMaintenanceData();
        default: return state;
    }
};

export default reducer;