export const users = {
    'Users': 'Usuarios',
    'User details': 'Detalles de usuario',
    'Username': 'Nombre de usuario',
    'First name': 'Nombre',
    'Last name': 'Apellido(s)',
    'Role': 'Rol',
    'Created on': 'Creado',
    'Last updated': 'Última modificación',
    'Status': 'Estado',
    'enabled': 'habilitado',
    'disabled': 'disabled',
    'Enter the new value': 'Introduce el nuevo valor',

    // ROLES
    'sysadmin': 'sysadmin',
    'general management - administration': 'gerencia general - administración',
    'general management - operations': 'gerencia general - operaciones',
    'auditing': 'auditoría',
    'accounting': 'contabilidad',
    'management': 'gerencia',
    'front desk': 'recepción',
    'maintenance': 'mantenimiento'
};