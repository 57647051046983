import React from 'react';
import { Heading, useTheme } from '@aws-amplify/ui-react';

const LoginHeader = ({version = ''}) => {
    const { tokens } = useTheme();

    return (
        <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={5}
        >
            Benacko {version}
        </Heading>
    );
}

export default LoginHeader;