import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Breadcrumb, Col, Row, Statistic, Table } from 'antd';

import SectionTitle from '../../../../components/UI/Panels/SectionTitle/SectionTitle';
import MaintenanceService from '../../../../services/maintenance';


const UtilitiesReport = () => {
    const hid = useSelector(state => state.ui.selectedHotel ?? 0);

    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);

    const [title, setTitle] = useState('');
    const [unit, setUnit] = useState(null);
    const [count, setCount] = useState(0);
    const [sum, setSum] = useState(0.0);
    const [average, setAverage] = useState(0.0);

    const eventsColumns = [
        {title: 'Fecha', dataIndex: 'timestamp', defaultSortOrder: 'descend', render: (a) => new Date(a[0], (a[1] - 1), a[2]).toLocaleDateString()},
        {title: 'Medida', dataIndex: 'reading'},
        {title: 'Unidad', dataIndex: 'unit'},
        {title: 'Tomada por', dataIndex: 'username'}
    ];

    useEffect(async () => {
        if (hid != null && hid != 0) {
            setLoading(true);
            
            const queryParams = new URLSearchParams(location.search);
            const utility = queryParams.get('utility') ?? null;
            const fromDate = queryParams.get('initialDate') ?? null;
            const toDate = queryParams.get('finalDate') ?? null;
            await loadUtilityEvents(utility, fromDate, toDate);

            switch (utility) {
                case 'water':
                    setTitle('Agua')
                    setUnit('m^3');
                    break;
                case 'electricity':
                    setTitle('Electricidad');
                    setUnit('kWh');
                    break;
                case 'gas':
                    setTitle('Gas');
                    setUnit('%');
                    break;
                default:
                    setTitle('');
                    setUnit('');
                    break;
            }

            setLoading(false);
        }
    }, [hid]);

    useEffect(() => {
        if (events != null && events.length > 0) {
            let preCount = 0;
            let preSum = 0;
            events.forEach(event => {
                preSum += event.reading;
                preCount++;
            });

            setCount(preCount);
            setSum(preSum);
            setAverage(preSum / preCount);
        }
    }, [events]);

    const loadUtilityEvents = async (utility, fromDate, toDate) => {
        await MaintenanceService.getUtilitiesByDateRange(hid, utility, fromDate, toDate)
            .then(res => {
                if (res != null && res.log != null) {
                    setEvents(res.log);
                }
            })
            .catch(() => setEvents([]));
    };


    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 10}}>
                <Col span={22}>
                    <Row style={{marginLeft: 40, width: '100%', height: 40}}>
                        <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <NavLink to={'/app/maintenance/reports'}>{'Reportes'}</NavLink>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {'Reporte de servicios públicos'}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Col>
            </Row>
            
            <SectionTitle title={title} />

            <Row gutter={16} style={{width: '100%', height: 40, marginTop: 40, marginBottom: 80}}>
                <Col span={8} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Statistic title='Número de entradas' value={count} precision={0} suffix={unit}/>
                </Col>
                <Col span={8} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Statistic title='Total consumido' value={sum} precision={2} suffix={unit}/>
                </Col>
                <Col span={8} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Statistic title='Promedio' value={average} precision={2} suffix={unit}/>
                </Col>
            </Row>

            <Row style={{width: '100%', height: '100%', flexGrow: 1, marginBottom: 20}}>
                <Col offset={1} span={23}>
                    <Table
                        style={{cursor: 'pointer'}}
                        size={'small'}
                        bordered={true}
                        loading={loading}
                        columns={eventsColumns}
                        dataSource={events}
                        pagination={false}
                        rowKey={'timestamp'}
                        scroll={{y: '100%'}}
                        locale={{
                            emptyText: 'No se encontraron datos',
                            cancelSort: 'Haz clic para cancelar la ordenación',
                            triggerAsc: 'Haz clic para ordenar ascendentemente',
                            triggerDesc: 'Haz clic para ordenar descendentemente'
                        }}
                    />
                </Col>
            </Row>
        </Row>
    );
};

export default UtilitiesReport;
