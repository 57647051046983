import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Row, Col, Spin, Card, Select, Button } from 'antd';

const { Content } = Layout;
const Option = Select.Option;


class RoomStatesDashboard extends Component {
    state = {
        loading: false,
        hotelsOptions: [],
        selectedHotel: 0,
    }

    idToken = '';


    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState({loading: true});

        // HOTELS
        const enabledHotels = this.props.hotels;
        var hotels = [];

        for (var i in enabledHotels) {
            hotels.push(<Option key={i}>{enabledHotels[i]}</Option>);
        }

        this.setState({hotelsOptions: hotels, loading: false});
    }

    setSelectedHotel = (selectedHotel) => {
        const selHotel = parseInt(selectedHotel, 10);
        this.setState({selectedHotel: selHotel});
    };

    viewRoomStatesDashboardHandler = () => {
        this.props.history.push('/app/reports/roomstatesdashboard/' + this.state.selectedHotel);
    }

    render() {

        return(
            <Layout>
                <Content style={{ margin: 0, padding: 0, background: '#fff' }}>
                    <Spin spinning={this.state.loading} size="large">
                        <Card loading={false} bordered={false} title="Estado de habitaciones">
                            <Row style={{marginTop: 30}}>
                                <Col span={20} offset={2}>
                                    <p>Hotel:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Select style={{width: '100%'}} onChange={this.setSelectedHotel} placeholder="Selecciona un hotel">
                                        {this.state.hotelsOptions}
                                    </Select>
                                </Col>
                                </Row>
                                <Row style={{marginTop: 10}}>
                                <Col span={4} offset={2}>
                                    <Button type="primary" 
                                        onClick={this.viewRoomStatesDashboardHandler}
                                        disabled={!this.state.selectedHotel}
                                    >
                                        Ver habitaciones
                                    </Button>
                                </Col>
                            </Row>
                        </Card>  
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(RoomStatesDashboard);