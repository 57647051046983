import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API, Auth } from 'aws-amplify';
import { Button, Layout, Row, Table } from 'antd'


const { Header, Content } = Layout;
const ButtonGroup = Button.Group;

const columns = [
    {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Estado',
        dataIndex: 'enabled',
        key: 'enabled'
    }
];


class Items extends Component {
    state = {
        loading: true,
        data: []
    }

    idToken = '';


    loadData() {
        this.setState({loading: true});

        this.setState({ loading: true });

        API.get('benackoAPI', '/items?companyId=' + this.props.companyId)
            .then(response => {
                var items = [];

                response.forEach(item => {
                    items.push({
                        key: item.id,
                        name: item.name,
                        enabled: (item.enabled == 1) ? 'Habilitado' : 'Deshabilitado'
                    });
                });

                this.setState({data: items});
            })
            .catch(errItems => console.log('Error loading items: ', errItems))
            .finally(() => this.setState({loading: false}));
    }

    createArticleHandler = () => {
        this.props.history.push('/app/items/create');
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.createArticleHandler}>Crear</Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#fff' }}>
                <Table 
                        columns={columns} 
                        dataSource={this.state.data}
                        onRow={ (record) => {
                            return {
                                onClick: () => {
                                    this.props.history.push('/app/items/details/' + record.key);
                                }
                            };
                        }}
                        size="middle" 
                        pagination={{
                            pageSize: 14,
                            showTotal: (total) => `Total: ${total} artículos`
                        }}
                        locale={{
                            emptyText: 'No se encontraron artículos'
                        }} 
                        loading={{
                            spinning: this.state.loading,
                            delay: 500,
                            size: 'large'
                        }}/>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.auth.companyId,
        cid: state.base.cid
    };
};


export default connect(mapStateToProps, null)(Items);