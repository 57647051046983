import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth, API } from 'aws-amplify';
import { Layout, Row, Table, Spin, Button, Tabs, Descriptions } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import PrintItemEventReport from '../../../components/UI/Modals/PrintItemEventReport/PrintItemEventReport';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;
const { TabPane } = Tabs;

const actionColumns = [{
    title: 'Articulo',
    dataIndex: 'item'
}, {
    title: 'Inicial',
    dataIndex: 'start'
}, {
    title: 'Ajustes',
    dataIndex: 'set'
}, {
    title: 'Salidas',
    dataIndex: 'out'
}, {
    title: 'Entradas',
    dataIndex: 'in'
}, {
    title: 'Final',
    dataIndex: 'end'
}];


class ItemActionsReportDetails extends Component {
    state = {
        loading: false,
        hotelId: 0,
        startDate: '',
        endDate: '',
        tabs: [],
        printableInventory: '',
        printableTable: [],
        printProducts: false
    }

    idToken = '';


    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    loadData = async () => {
        const hotelId = parseInt(this.props.match.params.hotelId, 10);

        const sDate = parseInt(this.props.match.params.startdate, 10);
        const startDate = new Date(sDate);
        const startDateLabel =  startDate.toDateString();
        const startDateForReport = startDate.getFullYear() + ('0' + (startDate.getMonth()+1)).slice(-2) + ('0' + startDate.getDate()).slice(-2);

        const eDate = parseInt(this.props.match.params.enddate, 10);
        const endDate = new Date(eDate);
        const endDateLabel = endDate.toDateString();
        const endDateForReport = endDate.getFullYear() + ('0' + (endDate.getMonth()+1)).slice(-2) + ('0' + (endDate.getDate()+1)).slice(-2);
        
        this.setState({loading: true, startDate: startDateLabel, endDate: endDateLabel});

        //First, get all inventories for the hotel
        const inventories = await API.get('benackoAPI', '/inventories?hotelId=' + hotelId, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then(step1res => {
                return step1res;
            })
            .catch(error => {
                console.log(error.response)
            })

        //Let's get the initial quantities
        let initialQuantities = await API.get('benackoAPI', '/v2/reports/inventories/balances?hotelId=' + hotelId + '&reportDate=' + startDateForReport).then(res => {
            if (res.Items === undefined || res.Items.length <= 0) {
                return null;
            } else {
                return res.Items[0].balances;
            }
        }).catch(err => {console.log('Error loading initial quantities', err);});
        let finalQuantities = await API.get('benackoAPI', '/v2/reports/inventories/balances?hotelId=' + hotelId + '&reportDate=' + endDateForReport).then(res => {
            if (res.Items === undefined || res.Items.length <= 0) {
                return null;
            } else {
                return res.Items[0].balances;
            }
        }).catch(err => {console.log('Error loading final quantities', err);});;

        //Then, get inventory actions for each inventory
        await inventories.forEach(async inventory => {
            let inventoryInitials = (initialQuantities !== null) ? initialQuantities[inventory.name] : null;
            let inventoryFinals = (finalQuantities !== null) ? finalQuantities[inventory.name] : null;

            //Get item zero counters
            await API.get('benackoAPI', '/v2/reports/item-actions/?inventoryId=' + inventory.id, {
                    headers: {
                        Authorization: this.idToken
                    }
                })
                .then(async itemActionsRes => {
                    var addedTabs = this.state.tabs;
                    const itemArray = itemActionsRes;
                    var processedItems = [];
                    if (itemActionsRes.length > 0) {
                        

                        await API.get('benackoAPI', '/v2/inventory-actions/?inventoryId=' + inventory.id + '&startTimestamp=' + sDate + '&endTimestamp=' + eDate, {
                                headers: {
                                    Authorization: this.idToken
                                }
                            })
                            .then(invActionsRes => {
                                if (invActionsRes.Items.length > 0) {
                                    invActionsRes.Items.forEach(event => {
                                        const itemId = event.item.id;
                                        
                                        if (event.action === 'salida de surtido' || event.action === 'venta') {
                                            const itemToUpdate = itemArray.find(it => { return it.itemID === itemId});
                                            const newQty = (itemToUpdate !== null && itemToUpdate !== undefined) ? itemToUpdate.out + event.quantity : '-';
                                            if (itemToUpdate !== null && itemToUpdate !== undefined) { itemToUpdate.out = newQty; }
                                        } else if (event.action === 'surtir artículo') {
                                            const itemToUpdate = itemArray.find(it => { return it.itemID === itemId});
                                            const newQty = (itemToUpdate !== null && itemToUpdate !== undefined) ? itemToUpdate.in + event.quantity : '-';
                                            if (itemToUpdate !== null && itemToUpdate !== undefined) { itemToUpdate.in = newQty; }
                                        } else if (event.action === 'sacar artículo' || event.action === 'mermar artículo') {
                                            const itemToUpdate = itemArray.find(it => { return it.itemID === itemId});
                                            const newQty = (itemToUpdate !== null && itemToUpdate !== undefined) ? itemToUpdate.set + event.quantity : '-';
                                            if (itemToUpdate !== null && itemToUpdate !== undefined) { itemToUpdate.set = newQty; }
                                        }
                                    });
                                }



                                itemActionsRes.forEach(item => {
                                    let initialQty = (inventoryInitials !== null) ? inventoryInitials.find(iQty => {return iQty.inventoryItemId === item.iiID}) : null;
                                    let finalQty = (inventoryFinals !== null) ? inventoryFinals.find(iQty => {return iQty.inventoryItemId === item.iiID}) : null;

                                    processedItems.push({
                                        key: item.iiID,
                                        item: item.itemName,
                                        start: (initialQty === null || initialQty === undefined) ? '-' : initialQty.quantity,
                                        set: item.set,
                                        out: item.out,
                                        in: item.in,
                                        end: (finalQty === null || finalQty === undefined) ? '-' : finalQty.quantity
                                    });
                                });
                            })
                            .catch(errInvActs => {
                                console.log('Error loading inventory actions', errInvActs);
                            });
                    }

                    const itemsTable = (
                        <Table
                            style={{width: '80%', paddingLeft: 20, marginBottom: 50}}
                            size="small"
                            columns={actionColumns}
                            dataSource={processedItems}
                            pagination={false}
                        />
                    );

                    addedTabs.push(
                        <TabPane tab={inventory.name} key={inventory.id}>
                                <Row style={{marginTop: 20, marginBottom: 10, paddingLeft: 20}}>
                                    <Descriptions title="Eventos" bordered>
                                        <Descriptions.Item label="Inicio del periodo">{this.state.startDate}</Descriptions.Item>
                                        <Descriptions.Item label="Fin del periodo">{this.state.endDate}</Descriptions.Item>
                                        <Descriptions.Item label="">
                                            {
                                                <Button
                                                    type="primary"
                                                    onClick={() => {
                                                        this.setState({
                                                            printableInventory: inventory.name,
                                                            printableTable: itemsTable
                                                        }, () => {
                                                            this.printProductsHandler();
                                                        });
                                                    }}
                                                >
                                                        Imprimir
                                                </Button>
                                            }
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Row>
                                <Row>
                                    {itemsTable}
                                </Row>
                            </TabPane>
                    );

                    this.setState({tabs: addedTabs});
                })
                .catch(itemActionsErr => console.log('funktioniert nicht', itemActionsErr));
        });

        this.setState({loading: false});
    }

    getSmallestTimestamp(datesArray) {
        if (datesArray.length > 0) {
            var smallestTimestamp = datesArray[0].SK;

            datesArray.forEach(date => {
                if (date.SK < smallestTimestamp) {
                    smallestTimestamp = date.SK;
                }
            });

            return smallestTimestamp;
        } else {
            return 0;
        }
    }

    getLargestTimestamp(datesArray) {
        if (datesArray.length > 0) {
            var largestTimestamp = datesArray[0].endTimestamp;

            datesArray.forEach(date => {
                if (date.endTimestamp > largestTimestamp) {
                    largestTimestamp = date.endTimestamp;
                }
            });

            return largestTimestamp;
        } else {
            return 0;
        }
    }

    printProductsHandler = () => {
        this.setState({
            printProducts: true
        });
    }

    closePrintProducts = () => {
        this.setState({
            printProducts: false
        });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#fff' }}>
                    <Spin spinning={this.state.loading} size="large">
                        <Tabs defaultActiveKey="1" tabPosition="right">
                            {this.state.tabs}
                        </Tabs>
                        <PrintItemEventReport
                            visible={this.state.printProducts}
                            onCancel={this.closePrintProducts}
                            addOnTitle={this.state.printableInventory}
                            itemTable={this.state.printableTable}
                            reportDate={this.state.startDate + ' - ' + this.state.endDate}
                        />
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId : state.auth.companyId,
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(ItemActionsReportDetails);
