import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, Col, Row, Space, Tabs } from 'antd';

import MaintenanceService from '../../../../services/maintenance';

import SectionTitle from '../../../../components/UI/Panels/SectionTitle/SectionTitle';
import CreateButton from '../../../../components/UI/Buttons/CreateButton/CreateButton';
import RoomFurnishingsTab from './RoomFurnishingsTab/RoomFurnishingsTab';
import RoomLogsTab from './RoomLogsTab/RoomLogsTab';

import { disableHotelSelector, enableHotelSelector } from '../../../../store/actions';

const TabPane = Tabs.TabPane;

const RoomDetails = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    
    const [loading, setLoading] = useState(false);
    const {roomId} = useParams();
    const [room, setRoom] = useState({});

    useEffect(() => {
        dispatch(enableHotelSelector());
        

        return () => {
            dispatch(disableHotelSelector());
        };
    }, []);

    useEffect(() => {
        if (roomId != null && roomId > 0) {
            loadData();
        }
    }, [roomId]);

    const loadData = async () => {
        setLoading(true);

        await loadRoom();

        setLoading(false);
    };

    const loadRoom = async () => {
        await MaintenanceService.getRoom(roomId)
            .then(res => {
                if (res != null) {
                    setRoom(res);
                }
            })
            .catch(err => errorNotification(err));
    };

    const goToAdd = () => {
        history.push('/app/maintenance/rooms/' + roomId + '/add/furnishing');
    }

    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 10}}>
                <Col span={22}>
                    <Row style={{marginLeft: 40, width: '100%', height: 40}}>
                        <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <NavLink to={'/app/maintenance/rooms'}>{'Habitaciones'}</NavLink>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {'Detalle de habitación'}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Col>
                <Col span={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Space>
                        <CreateButton onClick={goToAdd} loading={false} />
                    </Space>
                </Col>
            </Row>

            <SectionTitle title={room.name ?? ''} />

            <Tabs defaultActiveKey={1}>
                <TabPane tab="Mobiliario" key="1">
                    <RoomFurnishingsTab roomId={roomId ?? null}/>
                </TabPane>
            </Tabs>
        </Row>
    );
}

export default RoomDetails;