import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Table } from 'antd';

import { errorNotification } from '../../../../../components/UI/Notifications/Notifications';

import MaintenanceService from '../../../../../services/maintenance';
import { a } from 'aws-amplify';



const UtilityEvents = ({utility = ''}) => {   
    const cid = useSelector(state => state.base.cid ?? '');
    const hid = useSelector(state => state.ui.selectedHotel ?? 0);

    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);

    const dataColumns = [
        {title: 'Marca de tiempo', dataIndex: 'timestamp', defaultSortOrder: 'descend', sorter: (a,b) => a.timestamp > b.timestamp, render: (a) => new Date(a[0], (a[1] - 1), a[2]).toLocaleDateString()},
        {title: 'Datos', dataIndex: 'reading', sorter: (a,b) => a.reading - b.reading},
        {title: 'Unidades', dataIndex: 'unit', render: (a) => (a === 'kg') ? '%' : a}
    ];


    useEffect(() => {
        if (cid != null && hid != null && utility != null) {
            loadData();
        }
    }, [cid, hid, utility]);

    const loadData = () => {
        setLoading(true);

        MaintenanceService.getUtility(hid, utility)
            .then(res => {
                if (res != null) { setEvents(res.log); }
            })
            .catch(err => errorNotification(err))
            .finally(() => setLoading(false));
    };


    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%', marginTop: 30}}>
            <Col offset={1} span={22}>
                <Table
                    size="middle"
                    loading={loading}
                    columns={dataColumns}
                    rowKey={'timestamp'}
                    dataSource={events}
                    scroll={{y: 440}}
                    locale={{
                        emptyText: 'No se encontraron datos',
                        cancelSort: 'Haz clic para cancelar ordenar',
                        triggerAsc: 'Haz clic para ordenar - ascendente',
                        triggerDesc: 'Haz clic para ordenar - descendente'
                    }}
                />
            </Col>
        </Row>
    );
}

export default UtilityEvents;