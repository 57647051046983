import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { Breadcrumb, Button, Col, Input, InputNumber, Row, Skeleton, Table, Tabs, Tag } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

import SectionTitle from '../../../../components/UI/Panels/SectionTitle/SectionTitle';
import { errorNotification, successNotification } from '../../../../components/UI/Notifications/Notifications';

import RentTypeService from '../../../../services/rent-types';

// TEMPORARY TO CONNECT TO OLD BACKEND
import { API } from 'aws-amplify';

const { TabPane } = Tabs;


const RentTypesDetails = () => {
    const {id} = useParams();

    const hid = useSelector(state => state.ui.selectedHotel ?? 0);
    
    const [loading, setLoading] = useState(true);
    const [submitted, setSubmitted] = useState(false);

    const [rentType, setRentType] = useState(null);
    const [price, setPrice] = useState(0);
    const [breakfastNumber, setBreakfastNumber] = useState(null);
    const [extensionRoomPrice, setExtensionRoomPrice] = useState(null);
    const [extensionGuestPrice, setExtensionGuestPrice] = useState(null);
    const [rooms, setRooms] = useState([]);

    const roomColumns = [
        {title: 'Habitación', dataIndex: 'Name', key: 'Name'},
        {dataIndex: 'Enabled', key: 'Enabled', render: element => (element) ? <Tag color="#87D068">Habilitado</Tag> : <Tag color="#FF0500">No habilitado</Tag>}
    ];

    useEffect(async () => {
        await loadData();
    }, [hid]);

    const validatePrice = () => {
        if (submitted || (rentType != null && rentType.Settings != null && 
            parseFloat(rentType.Settings.Price) === price) || price < 0) {
            return true;
        } else {
            return false;
        }
    };

    const validateExtensionRoomPrice = () => {
        if (submitted || (rentType != null && rentType.Settings != null && 
            parseInt(rentType.Settings.RentExtensionHourlyRoomPrice) === extensionRoomPrice) || extensionRoomPrice < 0) {
            return true;
        } else {
            return false;
        }
    };

    const validateExtensionGuestPrice = () => {
        if (submitted || (rentType != null && rentType.Settings != null && 
            parseInt(rentType.Settings.RentExtensionHourlyGuestPrice) === extensionGuestPrice) || extensionGuestPrice < 0) {
            return true;
        } else {
            return false;
        }
    };

    const editRentTypeSetting = async (property, currentValue, newValue) => {
        setSubmitted(true);

        if (newValue != null && newValue.length != 0 && newValue != currentValue) {

            let body = {};
            if (property === 'Price') { body.price = newValue; }

            await API.put('benackoAPI', '/room-types/' + id, {body: body})
                    .then(() => successNotification('El parámetro ha sido editado exitosamente'))
                    .catch(err => errorNotification(err))
                    .finally(() => {
                        loadData();
                        setSubmitted(false);
                    });
        }
    };


    const loadData = async () => {
        setLoading(true);

        if (id != null) {
            RentTypeService.getRentTypeDetails(hid, id)
                .then(res => {
                    const settings = res.Settings ?? null;
                    
                    if (settings != null && settings.Price != null) {
                        setPrice(parseInt(settings.Price));
                    }

                    if (settings != null && settings.BreakfastNumber != null) {
                        setBreakfastNumber(parseInt(settings.BreakfastNumber));
                    }

                    if (settings != null && settings.RentExtensionHourlyRoomPrice != null) {
                        setExtensionRoomPrice(parseInt(settings.RentExtensionHourlyRoomPrice));
                    }

                    if (settings != null && settings.RentExtensionHourlyGuestPrice != null) {
                        setExtensionGuestPrice(parseInt(settings.RentExtensionHourlyGuestPrice));
                    }

                    const rooms = res.Rooms ?? null;
                    if (rooms != null) {
                        setRooms(rooms);
                    }

                    setRentType(res);
                    setLoading(false);
                })
                .catch(err => {
                    errorNotification(err);
                    setLoading(false);
                });
        }
    };


    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 10}}>
                <Col span={23}>
                    <Row style={{marginLeft: 40, width: '100%', height: 40}}>
                        <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <NavLink to={'/app/rent-types'}>{'Tipos de renta'}</NavLink>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {'Detalles de tipo de renta'}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Skeleton loading={loading} paragraph={{rows: 1, cols: 1}} active>
                <SectionTitle title={rentType?.Name ?? ''} />
            </Skeleton>
            <Tabs defaultActiveKey={1} style={{marginLeft: 40, marginTop: 30}}>
                <TabPane tab={'Información general'} key={1}>
                    <Skeleton loading={loading} paragraph={{rows: 9, cols: 1}} active>
                        <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                            <Row style={{width: '100%', height: 60}}>
                                <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>¿Temporal?</span></Col>
                                <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                                    <Input type="text" defaultValue={(rentType?.IsTemporary ?? 0 === 1) ? 'Si' : 'No'} disabled/></span>
                                </Col>
                            </Row>
                        </Row>
                    </Skeleton>
                </TabPane>
                <TabPane tab={'Ajustes'} key={2}>
                    <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                        <Row style={{width: '100%', height: 60}}>
                            <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Precio</span></Col>
                            <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                                <InputNumber min={0} step={0.1} defaultValue={rentType?.Settings?.Price ?? 0.0} onChange={(e) => setPrice(e)} disabled={submitted}/></span>
                                <span style={{marginLeft: 10, width: 20}}>
                                    <Button type="danger" shape="circle" size="small" style={{marginLeft: 10}} icon={<SaveOutlined />}
                                        onClick={() => editRentTypeSetting('Price', parseFloat(rentType.Settings.Price), price)} hidden={validatePrice()} disabled={validatePrice()}
                                    /></span>
                            </Col>
                        </Row>
                        {/* { extensionRoomPrice != null && */}
                        { false &&
                            <Row style={{width: '100%', height: 60}}>
                                <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Precio de extensión por hora</span></Col>
                                <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                                    <InputNumber min={0} step={1} defaultValue={rentType?.Settings?.RentExtensionHourlyRoomPrice ?? 0} onChange={(e) => setExtensionRoomPrice(e)} disabled={submitted}/></span>
                                    <span style={{marginLeft: 10, width: 20}}>
                                        <Button type="danger" shape="circle" size="small" style={{marginLeft: 10}} icon={<SaveOutlined />}
                                            onClick={() => editRentTypeSetting('RentExtensionHourlyRoomPrice', parseInt(rentType.Settings.RentExtensionHourlyRoomPrice), extensionRoomPrice)} hidden={validateExtensionRoomPrice()} disabled={validateExtensionRoomPrice()}
                                        /></span>
                                </Col>
                            </Row>
                        }
                        {/* { extensionGuestPrice != null && */}
                        { false &&
                            <Row style={{width: '100%', height: 60}}>
                                <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Precio de extensión por hora</span></Col>
                                <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                                    <InputNumber min={0} step={1} defaultValue={rentType?.Settings?.RentExtensionHourlyGuestPrice ?? 0} onChange={(e) => setExtensionGuestPrice(e)} disabled={submitted}/></span>
                                    <span style={{marginLeft: 10, width: 20}}>
                                        <Button type="danger" shape="circle" size="small" style={{marginLeft: 10}} icon={<SaveOutlined />}
                                            onClick={() => editRentTypeSetting('RentExtensionHourlyGuestPrice', parseInt(rentType.Settings.RentExtensionHourlyGuestPrice), extensionGuestPrice)} hidden={validateExtensionGuestPrice()} disabled={validateExtensionGuestPrice()}
                                        /></span>
                                </Col>
                            </Row>
                        }
                    </Row>
                </TabPane>
                { rooms?.length > 0 &&
                    <TabPane tab={'Habitaciones'} key={3}>
                        <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                            <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                                <Table
                                    style={{cursor: 'pointer'}}
                                    size={'small'}
                                    bordered={true}
                                    loading={loading}
                                    columns={roomColumns}
                                    dataSource={rooms}
                                    pagination={false}
                                    rowKey={'RoomKey'}
                                    scroll={{y: '100%'}}
                                    locale={{
                                        emptyText: 'No se encontraron datos'
                                    }}
                                />
                            </Row>
                        </Row>
                    </TabPane>
                }
            </Tabs>
        </Row>
    );
};

export default RentTypesDetails;