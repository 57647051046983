import XLSX from 'sheetjs-style';

export const processDailyOrderIncomeSheet = (dailyOrderIncome) => {
    const sheet = XLSX.utils.aoa_to_sheet([]);
    XLSX.utils.sheet_add_aoa(sheet, [['Número de órdenes', dailyOrderIncome?.OrdersNumberTotal ?? 0]], {origin:{r:1, c:1}});
    XLSX.utils.sheet_add_aoa(sheet, [['Total', dailyOrderIncome?.OrdersCashTotal ?? 0]], {origin:{r:2, c:1}});

    const processedData = dailyOrderIncome.Orders.map(order => {
        return {
            'Día': order.day,
            'Órdenes': order.totalNumber,
            'Total': order.totalCash
        };
    });
    XLSX.utils.sheet_add_json(sheet, processedData, {origin:{r:4, c:0}});

    return sheet;
};