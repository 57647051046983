import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import { API, Auth } from 'aws-amplify';
import { Layout, Row, Col, Spin, Card, Select, Button } from 'antd';

import { disableHotelSelector, enableHotelSelector } from '../../../store/actions/ui';

const { Content } = Layout;
const Option = Select.Option;


const ShiftBalances = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [dateOptions, setDateOptions] = useState([]);
    const [dateSelected, setDateSelected] = useState(null);
    const [timeOptions, setTimeOptions] = useState([]);
    const [selectedSbType, setSelectedSbType] = useState(3);
    const [selectedSbId, setSelectedSbId] = useState(0);
    const selectedHotel = useSelector(state => state.ui.selectedHotel);
    
    
    useEffect(() => {
        dispatch(enableHotelSelector());
        populateDateOptions();

        return () => {
            dispatch(disableHotelSelector());
        };
    }, []);

    useEffect(() => {
        setLoading(true);
        //set shift type - DEPRECATED
        // if (selectedHotel === 1) {
        //     setSelectedSbType(1);
        // } else {
        //     setSelectedSbType(3);
        // }

        const previousDayLimit = new Date();
        previousDayLimit.setTime(dateSelected);
        previousDayLimit.setDate(previousDayLimit.getDate() - 1);

        const endTimestamp = dateSelected;
        const startTimestamp = previousDayLimit.getTime();

        const pathString = '/v2/reports/shift-balances?hotelId=' + selectedHotel + '&startTimestamp=' + startTimestamp + '&endTimestamp=' + endTimestamp + '&type=' + selectedSbType;
        API.get('benackoAPI', pathString)
            .then(response => {
                var times = [];
                
                response.Items.forEach( t => {
                    times.push(<Option key={t.SK}>{new Date(t.SK).toLocaleString()}</Option>);
                });

                return times;
            })
            .then(timesRes => {
                setTimeOptions(timesRes);
            })
            .catch(errSbDate => console.log('Error getting hours for shift balances', errSbDate.response))
            .finally(() => setLoading(false))

    }, [selectedHotel, dateSelected]);


    const populateDateOptions = () => {
        setLoading(true);

        //set up date options
        const today = new Date();
        const upperLimit = getUpperDateLimit(today);
        
        var days = [];
        days.push(<Option key={upperLimit}>{today.toDateString()}</Option>);

        var dayDecrement = today;
        for (var i = 0; i < 60; i++) {
            dayDecrement.setDate(dayDecrement.getDate() - 1);
            days.push(<Option key={dayDecrement.getTime()}>{dayDecrement.toDateString()}</Option>);
        }

        setDateOptions(days);

        setLoading(false);
    }

    const getUpperDateLimit = (date) => {
        var tomorrow = date;
        tomorrow.setHours(23);
        tomorrow.setMinutes(59);
        tomorrow.setSeconds(59);
        tomorrow.setMilliseconds(999);
        
        return tomorrow.getTime();
    }

    const viewShiftBalanceHandler = () => {
        history.push('/app/reports/shiftbalances/' + selectedSbId + '/' + selectedHotel + '/' + selectedSbType);
    }

    return (
            <Layout>
                <Content style={{ margin: 0, padding: 0, background: '#fff' }}>
                    <Spin spinning={loading} size="large">
                        <Card loading={false} bordered={false} title="Cortes">
                            {/* {
                                selectedHotel === 1 && 
                                    <Row style={{marginTop: 10}}>
                                        <Col span={20} offset={2}>
                                            <p>Tipo de corte:</p>
                                        </Col>
                                        <Col span={20} offset={2}>
                                            <Select
                                                style={{width: '100%'}}
                                                onChange={setSelectedSbType}
                                                placeholder="Selecciona un tipo de corte"
                                                defaultValue={'Recepción'}
                                            >
                                                <Option key={1}>Recepción</Option>
                                                <Option key={2}>Restaurante</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                            } */}

                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Fecha:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Select style={{width: '100%'}} onChange={(e) => setDateSelected(e)} disabled={!selectedHotel && !selectedSbType} placeholder="Selecciona una fecha">
                                        {dateOptions}
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Horas:</p>
                                </Col>
                            </Row>
                                <Row>
                                    <Col span={20} offset={2}>
                                        <Select style={{width: '100%'}} placeholder="Selecciona una hora" onChange={(e) => setSelectedSbId(e)}>
                                            {timeOptions}
                                        </Select>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: 10}}>
                                <Col span={4} offset={2}>
                                    <Button type="primary" 
                                        onClick={viewShiftBalanceHandler}
                                        disabled={!selectedSbId}
                                    >
                                        Ver corte
                                    </Button>
                                </Col>
                            </Row>
                        </Card>  
                    </Spin>
                </Content>
            </Layout>
    );
}



export default ShiftBalances;