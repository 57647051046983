import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API, Auth } from 'aws-amplify';
import { Button, Layout, Row, Steps, InputNumber, Col, Spin, Select, Switch } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group
const Step = Steps.Step;
const Option = Select.Option;


class AddItem extends Component {
    state = {
        loadingStepOne: false,
        loadingStepTwo: false,
        loadingAddition: false,
        currentStep: 0,
        recipeId: 0,
        items: [],
        itemNames: new Map(),
        selectedItems: [],
        tableItems: [],
        productItemsQuantity: new Map(),
        productItemsEssential: new Map(),
        disableAddButton: true
    };

    idToken = '';

    loadData() {
        this.setState({loadingStepOne: true});

        API.get('benackoAPI', '/products/' + this.props.match.params.id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( productRes => {
                this.setState({recipeId: productRes[0].recipeId});

                return productRes[0].recipeId;
            })
            .then( recipeId => {
                let items = API.get('benackoAPI', '/recipe-items?recipeId=' + recipeId, {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then(recItemsRes => {
                        var items = [];

                        recItemsRes.forEach( item => {
                            items.push(item.itemId);
                        });

                        return items;
                    })
                    .catch( errRecItems => {
                        console.log('Error loading recipe items:', errRecItems);
        
                        this.setState({loadingStepOne: false});
                    });

                return items;
            })
            .then( recipeItems => {
                API.get('benackoAPI', '/items?companyId=' + this.props.companyId, {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then( itemsRes => {
                        var items = [];
                        var itemNames = new Map();

                        itemsRes.sort((a, b) => {return a.name > b.name});
        
                        itemsRes.forEach( item => {
                            if (!recipeItems.find( rt => rt === item.id)) {
                                itemNames[item.id] = item.name;
            
                                items.push(<Option key={item.id}>{item.name}</Option>);
                            }
                        });
        
                        this.setState({
                            loadingStepOne: false,
                            items: items,
                            itemNames: itemNames
                        });
                    })
                    .catch( errInventories => {
                        console.log('Error loading inventories:', errInventories);

                        this.setState({loadingStepOne: false});
                    });
            })
            .catch( errRecipe => {
                console.log('Error loading recipe:', errRecipe);

                this.setState({loadingStepOne: false});
            });
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    setProductQuantity = (quantity, itemKey) => {
        var productItems = this.state.productItemsQuantity;
        productItems[itemKey] = quantity;

        this.setState({
            productItemsQuantity: productItems,
            disableAddButton: true
        });
    }

    setEssential = (value, itemKey) => {
        var productItems = this.state.productItemsEssential;
        productItems[itemKey] = value;

        this.setState({
            productItemsEssential: productItems,
            disableAddButton: true
        });
    }

    selectItemHandler = (value) => {
        this.setState({selectedItems: value});
    }

    goToSecondStep = async () => {
        this.setState({loadingStepTwo: true, loadingCreate: true});
        var itemsQuantities = new Map();
        var itemsEssentials = new Map();

        const items = this.state.selectedItems.map( item => {
            itemsQuantities[item] = 1;
            itemsEssentials[item] = true;

            return (
                <Row key={item} style={{width: 600, marginTop: 10}}>
                    <Col span={6} offset={2}>{this.state.itemNames[item]}</Col>
                    <Col span={4}>
                        <InputNumber
                            key={item}
                            defaultValue="1"
                            step={1}
                            min={1}
                            onChange={ (quantity) => {
                                this.setProductQuantity(quantity, item);
                            }}
                        />
                    </Col>
                    <Col span={6}>
                        <Switch size="small" onChange={ (value) => {
                            this.setEssential(value, item);
                        }} defaultChecked />
                    </Col>
                </Row>
            );
        });
        
        this.setState({
            tableItems: items, 
            productItemsQuantity: itemsQuantities, 
            productItemsEssential: itemsEssentials, 
            currentStep: 1, 
            loadingStepTwo: false, 
            loadingCreate: false
        });
    }

    goPreviousStep = () => {
        const newStep = this.state.currentStep - 1;
        const quantities = new Map();
        const essentials = new Map();

        this.setState({
            currentStep: newStep, 
            selectedItems: [],
            productItemsQuantity: quantities,
            productItemsEssential: essentials
        });
    }

    validateProductItems = () => {
        const productItems = this.state.selectedItems;
        const itemQuantities = this.state.productItemsQuantity;

        var itemValidation = true;

        productItems.forEach( item => {
            if (itemQuantities[item] <= 0) {
                itemValidation = false;
            }
        });

        this.setState({disableAddButton: !itemValidation});
    }

    addItems = (event) => {
        event.preventDefault();
        
        this.setState({loadingAddition: true});

        const items = this.state.selectedItems;
        const quantities = this.state.productItemsQuantity;
        const essentials = this.state.productItemsEssential;
        items.forEach( async item => {
            await API.post('benackoAPI', '/recipe-items', {
                    body: {
                        recipeId: this.state.recipeId,
                        itemId: item,
                        quantity: quantities[item],
                        isEssential: essentials[item]
                    },
                    headers: {
                        Authorization: this.idToken
                    }
                })
                .catch( errAddItem => {
                    console.log('Error adding item:', errAddItem);
                });
        });

        this.setState({loadingAddition: false});
        
        this.props.history.goBack();
    }

    render() {

        const stepOne = (
            <Spin spinning={this.state.loadingStepOne} size="large">
                <Row style={{width: 600}}>
                    <Select
                        mode="multiple"
                        style={{ width: '100%', marginTop: 30 }}
                        placeholder="Selecciona los artículos a agregar"
                        onChange={this.selectItemHandler}
                    >
                        {this.state.items}
                    </Select>
                </Row>
                <Row style={{ marginTop: 50 }}>
                    <Button type="primary" onClick={this.goToSecondStep} disabled={(this.state.selectedItems.length <= 0) ? true : false}>Siguiente</Button>
                </Row>
            </Spin>
        );

        const stepTwo = (
            <Spin spinning={this.state.loadingStepTwo} size="large">
                <Row style={{width: 600, marginTop: 50, marginBottom: 10}}>
                    <Col span={6} offset={2}><strong>Artículo</strong></Col>
                    <Col span={4}><strong>Cantidad</strong></Col>
                    <Col span={6}><strong>¿Es esencial?</strong></Col>
                </Row>
                <Row style={{width: 600}}>
                    {this.state.tableItems}
                </Row>
                <Row style={{ marginTop: 40 }}>
                    <ButtonGroup>
                        <Button type="primary" onClick={this.goPreviousStep}>Regresar</Button>
                        <Button type="primary" onClick={this.validateProductItems}>Validar</Button>
                        <Button type="default" onClick={this.addItems} disabled={this.state.disableAddButton}>Agregar</Button>
                    </ButtonGroup>
                </Row>
            </Spin>
        );

        let renderedStep = null;
        if (this.state.currentStep === 0) {
            renderedStep = stepOne;
        }

        if (this.state.currentStep === 1) {
            renderedStep = stepTwo;
        }
        
        return (
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#ffff', paddingTop: 15 }}>
                    <Steps size="small" current={this.state.currentStep}>
                        <Step title="Nuevos ingredientes" />
                        <Step title="Detalles de nuevos artículos" />
                    </Steps>
                    <Row style={{marginTop: 50}}>
                        <Spin spinning={this.state.loadingAddition} size="large">
                            <Col span={20} offset={2}>
                                {renderedStep}
                            </Col>
                        </Spin>
                    </Row>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.auth.companyId
    };
};


export default connect(mapStateToProps, null)(AddItem);