import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Space, Table } from 'antd';

import { errorNotification } from '../../../components/UI/Notifications/Notifications';

import MaintenanceService from '../../../services/maintenance';
import { disableHotelSelector, enableHotelSelector } from '../../../store/actions';
import SectionTitle from '../../../components/UI/Panels/SectionTitle/SectionTitle';
import CreateButton from '../../../components/UI/Buttons/CreateButton/CreateButton';
import ReloadButton from '../../../components/UI/Buttons/ReloadButton/ReloadButton';


const WorkOrders = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    
    const hid = useSelector(state => state.ui.selectedHotel ?? 0);

    const [loading, setLoading] = useState(true);
    const [workOrders, setWorkOrders] = useState([]);

    const dataColumns = [
        {title: 'Apertura', dataIndex: 'openTimestamp', defaultSorter: 'ascend', sorter: (a,b) => a.openTimestamp > b.openTimestamp},
        {dataIndex: 'name'},
        {title: 'Estado', dataIndex: 'status', defaultSorter: 'ascend', sorter: (a,b) => a.status > b.status}
    ];


    useEffect(() => {
        dispatch(enableHotelSelector());

        return () => {
            dispatch(disableHotelSelector());
        };
    }, []);

    useEffect(() => {
        if (hid != null && hid > 0) {
            loadData();
        }
    }, [hid]);

    const loadData = async () => {
        setLoading(true);

        await loadWorkOrders();

        setLoading(false);
    };

    const loadWorkOrders = async () => {
        await MaintenanceService.getWorkOrders(hid)
            .then(res => {
                if (res != null) {
                    let processedRes = [];
                    res.forEach(order => {
                        let a = order?.openTimestamp ?? [-999999, 1, 1];
                        let openTimestamp = new Date(a[0], (a[1] - 1), a[2]).toLocaleDateString()
                        let state = calculateStatus(order.closeUserId, order.validationUserId);
                        processedRes.push({
                            id: order.id,
                            openTimestamp: openTimestamp,
                            name: order.name ?? '',
                            status: state
                        });
                    });
                    setWorkOrders(processedRes);
                }
            })
            .catch(err => errorNotification(err));
    };

    const calculateStatus = (closeUserId, validationUserId) => {
        if (validationUserId != null && validationUserId > 0) {
            return 'Cerrada';
        }

        if (closeUserId != null && closeUserId > 0) {
            return 'Lista para validar';
        }

        return 'Abierta';
    };

    const goToAdd = () => {
        history.push('/app/maintenance/work-orders-add');
    }

    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Col offset={22} span={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                <Space>
                    <CreateButton onClick={goToAdd} loading={loading} />
                    <ReloadButton onClick={loadData} loading={loading}/>
                </Space>
            </Col>
            <SectionTitle title='Órdenes de trabajo'/>

            <Col offset={1} span={22}>
                <Table
                    style={{cursor: 'pointer'}}
                    size="middle"
                    columns={dataColumns}
                    rowKey={'id'}
                    dataSource={workOrders}
                    scroll={{y: 440}}
                    locale={{
                        emptyText: 'No se encontraron datos',
                        cancelSort: 'Haz clic para cancelar ordenar',
                        triggerAsc: 'Haz clic para ordenar - ascendente',
                        triggerDesc: 'Haz clic para ordenar - descendente'
                    }}
                    onRow={ (record) => {
                        return {
                            onClick: () => {
                                history.push('/app/maintenance/work-orders/' + record.id);
                            }
                        };
                    }}
                    pagination={{
                        pageSize: 8,
                        showTotal: (total) => `Total: ${total}`
                    }}
                    loading={{
                        spinning: loading,
                        delay: 500,
                        size: 'large'
                    }}
                />
            </Col>
        </Row>
    );
}

export default WorkOrders;