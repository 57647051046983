import React, { useEffect, useState } from 'react';
import { Descriptions, Row, Table } from 'antd';

const DailyCardIncomeTabContents = ({dailyIncomeCard = []}) => {
    const [cashTotal, setCashTotal] = useState(0);
    const [days, setDays] = useState([]);

    const cardColumns = [
        {title: 'día', dataIndex: 'day', align: 'center', width: 20},
        {title: 'Rentas', dataIndex: 'totalRents', align: 'center', width: 20, render: (e) => '$' + Number(e ?? 0).toLocaleString()},
        {title: 'Extensiones de renta', dataIndex: 'totalExtensions', align: 'center', width: 20, render: (e) => '$' + Number(e ?? 0).toLocaleString()},
        {title: 'Huéspedes adicionales', dataIndex: 'totalGuests', align: 'center', width: 20, render: (e) => '$' + Number(e ?? 0).toLocaleString()},
        {title: 'Órdenes', dataIndex: 'totalOrders', align: 'center', width: 20, render: (e) => '$' + Number(e ?? 0).toLocaleString()},
        {title: 'Cargos', dataIndex: 'totalCharges', align: 'center', width: 20, render: (e) => '$' + Number(e ?? 0).toLocaleString()},
        {title: 'Reservaciones', dataIndex: 'totalReservations', align: 'center', width: 20, render: (e) => '$' + Number(e ?? 0).toLocaleString()},
        {title: 'total', dataIndex: 'dayTotal', align: 'center', width: 20, render: (e) => '$' + Number(e ?? 0).toLocaleString()}
    ];

    useEffect(() => {
        if (dailyIncomeCard != null && dailyIncomeCard.CardPaymentTotal != null) { setCashTotal(Number(dailyIncomeCard.CardPaymentTotal ?? 0).toLocaleString()); }
        if (dailyIncomeCard != null && dailyIncomeCard.CardPayments != null) { setDays(dailyIncomeCard.CardPayments ?? []); }
    }, [dailyIncomeCard]);


    return (
        <Row style={{display: 'flex', width: '100%'}}>
            <Row style={{width: '100%', marginTop: 20, marginBottom: 20}}>
                <Descriptions>
                    <Descriptions.Item label='Total'>${cashTotal}</Descriptions.Item>
                </Descriptions>
            </Row>
            <Table
                style={{width: '100%'}}
                size='small'
                scroll={{y: 500}}
                pagination={false}
                columns={cardColumns}
                dataSource={days}
                rowKey='day'
            />
        </Row>
    );
};

export default DailyCardIncomeTabContents;