import React from 'react';
import { I18n } from 'aws-amplify';
import { Button, useAuthenticator, View } from '@aws-amplify/ui-react';

const ResetPassword = () => {
    const { toResetPassword } = useAuthenticator();

    return (
        <View textAlign="center">
            <Button
                fontWeight="normal"
                onClick={toResetPassword}
                size="small"
                variation="link"
            >
              {I18n.get('Reset Password?')}
            </Button>
        </View>
    );
}

export default ResetPassword;