import * as actionTypes from '../actions/actionTypes';


const initialState = {
    uid: null,
    dbid: null,
    username: null,
    firstName: null,
    lastName: null,
    initials: null,
    roleKey: null,
    roleName: null,
    roleLevel: null,
    entitlements: null,
    hotels: null,
    cid: null,
    error: ''
};

const loadAuthUserData = (action) => {
    return {
        uid: action.uid,
        dbid: action.dbid,
        username: action.username,
        firstName: action.firstName,
        lastName: action.lastName,
        initials: action.initials,
        roleKey: action.roleKey,
        roleName: action.roleName,
        roleLevel: action.roleLevel,
        entitlements: action.entitlements,
        hotels: action.hotels,
        cid: action.cid,
        error: ''
    };
};

const loadAuthUserDataFail = (action) => {
    return {
        uid: null,
        dbid: null,
        username: null,
        firstName: null,
        lastName: null,
        initials: null,
        roleKey: null,
        roleName: null,
        roleLevel: null,
        entitlements: null,
        hotels: null,
        cid: null,
        error: action.error
    };
};

const clearAuthUserData = () => {
    return {
        uid: null,
        dbid: null,
        username: null,
        firstName: null,
        lastName: null,
        initials: null,
        roleKey: null,
        roleName: null,
        roleLevel: null,
        entitlements: null,
        hotels: null,
        cid: null,
        error: ''
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_AUTH_USER_DATA: return loadAuthUserData(action);
        case actionTypes.GET_AUTH_USER_DATA_FAIL: return loadAuthUserDataFail(action);
        case actionTypes.CLEAR_AUTH_USER_DATA: return clearAuthUserData();
        default: return state;
    }
};

export default reducer;
