import React from 'react';
import { Tooltip, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';


const CreateButton = ({loading = false, disabled = false, onClick = () => console.log('No click action set')}) => {

    return (
        <Tooltip placement="bottom" title={'Crear'} mouseEnterDelay={0.5} mouseLeaveDelay={0.1}>
            <Button
                type="primary"
                size="middle"
                icon={<PlusOutlined />}
                loading={loading}
                disabled={disabled}
                onClick={onClick}
            />
        </Tooltip>
    );
}

export default CreateButton;