import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Col, DatePicker, Row } from 'antd';

import SectionTitle from '../../../components/UI/Panels/SectionTitle/SectionTitle';

import { disableHotelSelector, enableHotelSelector } from '../../../store/actions';


const ReportDateSelector = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const queryString = useLocation();

    const [date, setDate] = useState(null);
    const [report, setReport] = useState(null);
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        dispatch(enableHotelSelector());

        return () => { dispatch(disableHotelSelector()) };
    }, []);

    useEffect(async () => {
        if (queryString != null && queryString.search != null && queryString.search.length > 6) {
            const search = queryString.search;
            const report = search.substring(8);

            setReport(report);
        }
    }, [queryString]);

    useEffect(() => {
        if (date != null) {
            setValidated(true);
        } else {
            setValidated(false);
        }
    }, [date]);

    const specifyDate = moment => {
        const formattedDate = moment.format('yyyy-MM');
        setDate(formattedDate);
    };

    return (
        <Row style={{display: 'flex', flexFlow: 'column', width: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 10}}>
                <Col span={23}>
                </Col>
            </Row>
            <SectionTitle title={'Reporte mensual' ?? ''} />
            
            <Row style={{width: '100%', marginTop: 50, marginLeft: 40}}>
                <Row style={{width: '100%'}}>
                    <Col span={4} style={{display: 'flex', alignItems: 'center', height: '100%'}}>Mes del reporte</Col>
                    <Col span={8}>
                        <DatePicker picker='month' style={{width: '100%'}} onChange={e => specifyDate(e)}/>
                    </Col>
                </Row>
                <Row style={{width: '100%', marginTop: 40}}>
                    <Button type='primary' onClick={() => history.push('/app/reports/monthlyincome?date=' + date)} disabled={!validated}>Generar reporte</Button>
                </Row>
            </Row>
        </Row>
    );
};

export default ReportDateSelector;