import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Row, Col, Input, Table, Space } from 'antd';
import { SearchOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import ReloadButton from '../../../../components/UI/Buttons/ReloadButton/ReloadButton';
import CreateButton from '../../../../components/UI/Buttons/CreateButton/CreateButton';
import { errorNotification } from '../../../../components/UI/Notifications/Notifications';

import RentTypeService from '../../../../services/rent-types';
import { disableHotelSelector, enableHotelSelector } from '../../../../store/actions';


const RentTypesList = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const cid = useSelector(state => state.base.cid ?? '');
    const hid = useSelector(state => state.ui.selectedHotel ?? 0);

    const [loading, setLoading] = useState(true);
    const [rentTypes, setRentTypes] = useState([]);
    const [filteredRentTypes, setFilteredRentTypes] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const rentTypeColumns = [
        {title: 'Nombre', dataIndex: 'Name', key: 'Name', defaultSortOrder: 'ascend', sorter: (a,b) => a.Name.localeCompare(b.Name)},
        {title: '¿Temporal?', dataIndex: 'IsTemporary', key: 'IsTemporary', render: (a) => (a) ? <CheckCircleOutlined/> : <CloseCircleOutlined/>}
    ];


    useEffect(() => {
        dispatch(enableHotelSelector());

        return () => { dispatch(disableHotelSelector()) };
    }, []);

    useEffect(() => {
        if (hid !== 0) {
            getRentTypes();
        }
    }, [hid]);

    useEffect(() => {
        if (rentTypes != null && rentTypes.length > 0) {
            clearCurrentRentTypes();
            setFilteredRentTypes(rentTypes);
        }
    }, [rentTypes]);

    useEffect(() => {
        if (inputValue !== null && inputValue !== undefined && inputValue !== '') {
            const newRentTypes = inputValue ? rentTypes.filter(i => (i.Name.toLowerCase().indexOf(inputValue) > -1)) : rentTypes;
            setFilteredRentTypes(newRentTypes);
        } else {
            setFilteredRentTypes(rentTypes);
        }
    }, [inputValue]);


    const getRentTypes = () => {
        setLoading(true);

        RentTypeService.getRentTypes(hid)
            .then(res => {
                setRentTypes(res);
                setLoading(false);
            })
            .catch(err => {
                errorNotification(err);
                setLoading(false);
            });
    };

    const clearCurrentRentTypes = () => {
        setFilteredRentTypes([]);
        setInputValue('');
    };

    const onChangeText = (text) => {
        setInputValue(text);
    };

    const goToCreateRentType = () => {
        history.push('/app/rent-types/create');
    };
    const goToRentTypeDetails = (id) => {
        history.push('/app/rent-types/details/' + id);
    };

    return (
        <Row style={{display: 'flex', flexFlow: 'column', width: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 40}}>
                <Col span={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Input
                        size="middle"
                        prefix={<SearchOutlined />}
                        placeholder={'Buscar'}
                        allowClear={true}
                        value={inputValue}
                        onChange={(ev) => onChangeText(ev.target.value)}
                    />
                </Col>
                <Col offset={10} span={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Space>
                        <CreateButton onClick={goToCreateRentType} loading={loading} />
                        <ReloadButton onClick={getRentTypes} loading={loading} />
                    </Space>
                </Col>
            </Row>

            <Row style={{width: '100%', height: '100%', flexGrow: 1, marginBottom: 20}}>
                <Col span={24}>
                    <Table
                        style={{cursor: 'pointer'}}
                        size={'small'}
                        bordered={true}
                        loading={loading}
                        columns={rentTypeColumns}
                        dataSource={filteredRentTypes}
                        pagination={false}
                        rowKey={'Key'}
                        scroll={{y: '100%'}}
                        locale={{
                            emptyText: 'No se encontraron datos',
                            cancelSort: 'Haz clic para cancelar la ordenación',
                            triggerAsc: 'Haz clic para ordenar ascendentemente',
                            triggerDesc: 'Haz clic para ordenar descendentemente'
                        }}
                        onRow={(row) => ({
                            onClick: () => goToRentTypeDetails(row.Key)
                        })}
                    />
                </Col>
            </Row>
        </Row>
    );
};

export default RentTypesList;