import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Breadcrumb, Button, Col, DatePicker, Row, Select } from 'antd';
import moment from 'moment';

import { disableHotelSelector, enableHotelSelector } from '../../../../store/actions';

const UtilitiesReportSelector = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [selectedUtility, setSelectedUtility] = useState(null);
    const [initialDate, setInitialDate] = useState(false);
    const [finalDate, setFinalDate] = useState(null);
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        dispatch(enableHotelSelector());

        return () => { dispatch(disableHotelSelector()) };
    }, []);

    const specifyRange = (moments) => {
        if (moments != null && moments.length === 2) {
            const current = moment();
            if (moments[0].diff(current, 'months') < -2 || moments[0].diff(current, 'days') > 0 ||
                moments[1].diff(current, 'months') < -2 || moments[1].diff(current, 'days') > 0) {

                setValidated(false);
            } else {
                const initial = moments[0].format('yyyy-MM-DD');
                setInitialDate(initial);
                const final = moments[1].format('yyyy-MM-DD');
                setFinalDate(final);

                setValidated(true);
            }
        } else {
            setInitialDate(null);
            setFinalDate(null);
            setValidated(false);
        }
    };

    return (
        <Row style={{width: '100%', marginTop: 50, marginLeft: 40}}>
            <Row style={{width: '100%', height: 40, marginBottom: 10}}>
                <Col span={22}>
                    <Row style={{marginLeft: 40, width: '100%', height: 40}}>
                        <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <NavLink to={'/app/maintenance/reports'}>{'Reportes'}</NavLink>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {'Reporte de servicios públicos'}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row style={{width: '100%', marginTop: 50}}>
                <Col offset={1} span={4} style={{display: 'flex', alignItems: 'center', height: '100%'}}>Servicio</Col>
                <Col span={8}>
                    <Select style={{width: '100%'}} onChange={e => setSelectedUtility(e)}
                        options={[
                            {label: 'Agua', value: 'water'},
                            {label: 'Electricidad', value: 'electricity'},
                            {label: 'Gas', value: 'gas'}
                        ]}
                    />
                </Col>
            </Row>
            <Row style={{width: '100%', marginTop: 40}}>
                <Col offset={1} span={4} style={{display: 'flex', alignItems: 'center', height: '100%'}}>Rango del reporte</Col>
                <Col span={8}>
                    <DatePicker.RangePicker style={{width: '100%'}} disabledDate={(current) => {return moment().add(0, 'days')  <= current || moment().add(-3, 'month')  >= current;}} onChange={e => specifyRange(e)}/>
                </Col>
            </Row>
            <Row style={{width: '100%', marginTop: 60, marginLeft: 45}}>
                <Button type='primary' onClick={() => history.push('/app/maintenance/reports/utilities?utility=' + selectedUtility + '&initialDate=' + initialDate + '&finalDate=' + finalDate)} disabled={!validated || selectedUtility == null}>Generar reporte</Button>
            </Row>
        </Row>
    );
};

export default UtilitiesReportSelector;