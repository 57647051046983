import XLSX from 'sheetjs-style';

export const processDailyTotalsSheet = (dailyTotals) => {
    const processedData = dailyTotals.Totals.map(total => {
        return {
            'Día': total.day,
            'Rentas': total.totalRents,
            'Extensiones de renta': total.totalExtensions,
            'Huéspedes adicionales': total.totalGuests,
            'Cambios': total.totalChanges,
            'Cancelaciones': total.totalCancellations,
            'Órdenes': total.totalOrders,
            'Cargos': total.totalCharges,
            'Reservaciones': total.totalReservations,
            'Total': total.dayTotal
        };
    });

    return XLSX.utils.json_to_sheet(processedData);
};