import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';

import { load, loadAuthUserData, switchHotel } from '../../store/actions';


const DataLoader = () => {
    const dispatch = useDispatch();
    const { route } = useAuthenticator(context => [context.route]);

    useEffect(() => {
        return route === 'authenticated' ?
                            dispatch(loadAuthUserData())
                                .then(hid => dispatch(switchHotel(hid)))
                                .then(dispatch(load()))
                                .catch(err => {
                                    console.error(err);
                                    Auth.signOut();
                                })
                            : <div/>;
    }, [dispatch]);

    return (
        <div style={{marginLeft: 20, marginTop: 20}}>Loading...</div>
    );
}

export default DataLoader;