import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API, Auth } from 'aws-amplify';
import { Layout, Row, Col, Spin, Card, Select, Button, DatePicker } from 'antd';

const { Content } = Layout;
const Option = Select.Option;
const ButtonGroup = Button.Group;


class TicketsEdit extends Component {
    state = {
        loading: false,
        hotelsOptions: [],
        selectedHotel: null,
        selectedDate: null,
        disableEditButton: true
    }

    idToken = '';


    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    loadData() {
        this.setState({loading: true});

        // HOTELS
        const enabledHotels = this.props.hotels;
        var hotels = [];

        for (var i in enabledHotels) {
            hotels.push(<Option key={i}>{enabledHotels[i]}</Option>);
        }

        this.setState({hotelsOptions: hotels, loading: false});
    }

    setSelectedHotel = (selectedHotel) => {
        this.setState({selectedHotel: selectedHotel});
    };

    setSelectedDate = (date, dateString) => {
        const beautifiedDate = dateString.split('-').join('');

        const companyId = this.props.companyId;
        const hotelId = this.state.selectedHotel;
        const yyyymmdd = beautifiedDate;
        const type = 'tk';

        const queryString = 'companyId=' + companyId + '&hotelId=' + hotelId + '&timestamp=' + yyyymmdd + '&type=' + type;
        
        API.get('benackoAPI', '/vouchers?' + queryString, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( vouchersRes => {
                var editButton = true;

                if (vouchersRes.Items.length > 0 && vouchersRes.Items[0].vouchers.length > 0) {
                    editButton = false;
                }

                this.setState({
                    selectedDate: beautifiedDate,
                    disableEditButton: editButton
                });
            })
            .catch( errVouchers => {
                console.log('Error loading vouchers:', errVouchers);
            });
    }

    goToVouchersEdit = () => {
        this.props.history.push('/app/vouchers/edit/' + this.state.selectedHotel + '/' + this.state.selectedDate);
    }

    render() {
        return(
            <Layout>
                <Content style={{ margin: 0, padding: 0, background: '#fff' }}>
                    <Spin spinning={this.state.loading} size="large">
                        <Card loading={false} bordered={false} title="Editar comprobantes">
                            <Row style={{marginTop: 30}}>
                                <Col span={4} offset={2}>
                                    <p>Hotel:</p>
                                </Col>
                                <Col span={10} offset={2}>
                                    <Select onChange={this.setSelectedHotel} style={{ width: '100%' }}>
                                        {this.state.hotelsOptions}
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={4} offset={2}>
                                    <p>Fecha:</p>
                                </Col>
                                <Col span={10} offset={2}>
                                    <DatePicker 
                                        onChange={(date, dateString) => this.setSelectedDate(date, dateString)}
                                        style={{ width: '100%' }}
                                        disabled={this.state.selectedHotel === null} 
                                        placeholder="Selecciona una fecha" 
                                        format="YYYY-MM-DD" 
                                    />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 40}}>
                                <Col span={20} offset={2}>
                                    <ButtonGroup>
                                        <Button 
                                            type="primary" 
                                            onClick={this.goToVouchersEdit} 
                                            disabled={this.state.disableEditButton}
                                        >
                                            Continuar
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </Card>  
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.auth.companyId,
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(TicketsEdit);