import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { Modal, TimePicker } from 'antd';


class EditCheckoutScheduleModal extends Component {
    state = {
        visible: false,
        confirmLoading: false,
        selectedDay: false,
        dayString: null,
        newTime: null
    };

    showEditModal = (dayId, dayString) => {
        this.setState({visible: true, selectedDay: dayId, dayString: dayString});
    }

    closeEditModal = () => {
        this.setState({visible: false, confirmLoading: false});
    }

    editDay = () => {
        const scheduleId = this.props.scheduleId;
        const scheduleType = this.props.scheduleType;
        const selectedDay = this.state.selectedDay;
        const checkoutTime = this.state.newTime;

        this.setState({confirmLoading: true});

        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;
            })
            .then( () => {
                API.put('benackoAPI', '/room-schedules/' + scheduleId + '/blocks', {
                        headers: {
                            Authorization: this.idToken
                        },
                        body: {
                            scheduleType: scheduleType,
                            day: selectedDay,
                            newValue: checkoutTime
                        }
                    })
                    .then( () => {
                        this.closeEditModal();
                    })
                    .catch( errEditSchedule => {
                        console.log('Error editing room schedule:', errEditSchedule);
                        this.closeEditModal();
                    });
            })
            .catch( errAuth => {
                console.log('Error authenticating request:', errAuth);
                this.closeEditModal();
            });
    }


    render() {
        return (
            <Modal
                closable={false}
                visible={this.state.visible}
                okText="Modificar"
                cancelText="Cancelar"
                okType="danger"
                confirmLoading={this.state.confirmLoading}
                onCancel={this.closeEditModal}
                onOk={this.editDay}
                title={"Modificar horario de salida: " + this.state.dayString}
            >
                <p>Selecciona la nueva hora de salida:</p>
                <TimePicker 
                    id="checkoutTime" 
                    use12Hours={false} 
                    placeholder="Hora de salida" 
                    minuteStep={10} 
                    secondStep={60} 
                    onChange={(time, timeString) => {
                        const beautifiedTime = timeString.substr(0, 5);
                        this.setState({newTime: beautifiedTime});
                    }}
                />
            </Modal>
        );
    }
}

export default EditCheckoutScheduleModal;