import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { Modal, InputNumber } from 'antd';


class EditHoursScheduleModal extends Component {
    state = {
        visible: false,
        confirmLoading: false,
        selectedDay: false,
        dayString: null,
        newHours: null
    };

    showEditModal = (dayId, dayString) => {
        this.setState({visible: true, selectedDay: dayId, dayString: dayString});
    }

    closeEditModal = () => {
        this.setState({visible: false, confirmLoading: false});
    }

    editDay = () => {
        const scheduleId = this.props.scheduleId;
        const scheduleType = this.props.scheduleType;
        const selectedDay = this.state.selectedDay;
        const hours = this.state.newHours;

        this.setState({confirmLoading: true});

        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;
            })
            .then( () => {
                API.put('benackoAPI', '/room-schedules/' + scheduleId + '/blocks', {
                        headers: {
                            Authorization: this.idToken
                        },
                        body: {
                            scheduleType: scheduleType,
                            day: selectedDay,
                            newValue: hours
                        }
                    })
                    .then( () => {
                        this.closeEditModal();
                    })
                    .catch( errEditSchedule => {
                        console.log('Error editing room schedule:', errEditSchedule);
                        this.closeEditModal();
                    });
            })
            .catch( errAuth => {
                console.log('Error authenticating request:', errAuth);
                this.closeEditModal();
            });
    }


    render() {
        return (
            <Modal
                closable={false}
                visible={this.state.visible}
                okText="Modificar"
                cancelText="Cancelar"
                okType="danger"
                confirmLoading={this.state.confirmLoading}
                onCancel={this.closeEditModal}
                onOk={this.editDay}
                title={"Modificar el número de horas: " + this.state.dayString}
            >
                <p>Selecciona el nuevo número de horas:</p>
                <InputNumber 
                    id="hours"
                    placeholder="Horas"
                    min={1}
                    max={23}
                    step={1}
                    onChange={(hours) => {
                        const beautifiedHours = Math.floor(hours);
                        this.setState({newHours: beautifiedHours});
                    }}
                />
            </Modal>
        );
    }
}

export default EditHoursScheduleModal;