import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { Layout, Row, Col, Spin, Card, Select, Button } from 'antd';

const { Content } = Layout;
const Option = Select.Option;
const ButtonGroup = Button.Group;


class DailyRentReport extends Component {
    state = {
        loading: false,
        hotelsOptions: [],
        selectedHotel: null,
        dateOptions: []
    }

    idToken = '';


    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    loadData() {
        this.setState({loading: true});

        // HOTELS
        const enabledHotels = this.props.hotels;
        var hotels = [];

        for (var i in enabledHotels) {
            hotels.push(<Option key={i}>{enabledHotels[i]}</Option>);
        }

        this.setState({hotelsOptions: hotels});

        // DAYS
        const today = new Date();
        const upperLimit = this.getUpperDateLimit(today);
        
        var days = [];
        days.push(<Option key={upperLimit}>{today.toDateString()}</Option>);

        var dayDecrement = today;
        for (i = 0; i < 60; i++) {
            dayDecrement.setDate(dayDecrement.getDate() - 1);
            days.push(<Option key={dayDecrement.getTime()}>{dayDecrement.toDateString()}</Option>);
        }
        this.setState({dateOptions: days});

        this.setState({loading: false});
    }

    getUpperDateLimit(date) {
        var tomorrow = date;
        tomorrow.setHours(23);
        tomorrow.setMinutes(59);
        tomorrow.setSeconds(59);
        tomorrow.setMilliseconds(999);
        
        return tomorrow.getTime();
    }

    setSelectedHotel = (selectedHotel) => {
        this.setState({selectedHotel: selectedHotel});
    };

    setSelectedDate = (dateSelected) => {
        this.setState({selectedDate: dateSelected});
    }

    goToCalculate = () => {
        this.props.history.push('/app/reports/dailyrentreport/' + this.state.selectedHotel + '/' + this.state.selectedDate + '/calculate');
    }

    goToView = () => {
        this.props.history.push('/app/reports/dailyrentreport/' + this.state.selectedHotel + '/' + this.state.selectedDate + '/view');
    }

    render() {
        return(
            <Layout>
                <Content style={{ margin: 0, padding: 0, background: '#fff' }}>
                    <Spin spinning={this.state.loading} size="large">
                        <Card loading={false} bordered={false} title="Reporte diario - Habitaciones">
                            <Row style={{marginTop: 30}}>
                                <Col span={4} offset={2}>
                                    <p>Hotel:</p>
                                </Col>
                                <Col span={10} offset={2}>
                                    <Select onChange={this.setSelectedHotel} style={{ width: '100%' }}>
                                        {this.state.hotelsOptions}
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={4} offset={2}>
                                    <p>Fecha:</p>
                                </Col>
                                <Col span={10} offset={2}>
                                    <Select onChange={this.setSelectedDate} style={{ width: '100%' }}>
                                        {this.state.dateOptions}
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 40}}>
                                <Col span={20} offset={2}>
                                    <ButtonGroup>
                                        <Button type="primary" onClick={this.goToView} disabled={!this.state.selectedHotel || !this.state.selectedDate}>Ver reporte</Button>
                                        <Button type="primary" onClick={this.goToCalculate} disabled={!this.state.selectedHotel || !this.state.selectedDate}>Calcular reporte</Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </Card>  
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(DailyRentReport);