import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { Button, Col, Descriptions, Table, Layout, Row, Spin } from 'antd';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;

const currencyTotalsColumns = [
    {
        title: 'Dinero',
        dataIndex: 'currency'
    }, {
        title: 'Pesos',
        dataIndex: 'mxn'
    }, {
        title: 'Dólares',
        dataIndex: 'usd'
    }, {
        title: 'Tarjeta',
        dataIndex: 'card'
    }, {
        title: 'Total',
        dataIndex: 'sbAbsTotal'
    }
];

const orderTotalsColumns = [
    {
        title: 'Órdenes',
        dataIndex: 'orders'
    }, {
        title: 'Cantidad',
        dataIndex: 'numberOfOrders'
    }, {
        title: 'Pesos',
        dataIndex: 'mxn'
    }, {
        title: 'Dólares',
        dataIndex: 'usd'
    }, {
        title: 'Tarjeta',
        dataIndex: 'card'
    }, {
        title: 'Total',
        dataIndex: 'orderAbsTotal'
}];

const roomTypeTotalsColumns = [
    {
        title: 'Tipo de habitación',
        dataIndex: 'roomType'
    }, {
        title: 'Número de rentas',
        dataIndex: 'number'
    }, {
        title: 'Monto ($)',
        dataIndex: 'amount'
    }
];

const absTotalsColumns = [
    {
        title: 'Totales',
        dataIndex: 'totals'
    }, {
        title: 'Habitaciones',
        dataIndex: 'roomAbsTotal'
    }, {
        title: 'Adicionales',
        dataIndex: 'addAbsTotal'
    }, {
        title: 'Órdenes',
        dataIndex: 'orderAbsTotal'
    }, {
        title: 'Cargos',
        dataIndex: 'chargeAbsTotal'
    }, {
        title: 'Reservaciones',
        dataIndex: 'reservationAbsTotal'
    }, {
        title: 'Cambios',
        dataIndex: 'changeAbsTotal'
    }, {
        title: 'Gran total',
        dataIndex: 'absTotal'
    }
];

class ShiftBalanceSummary extends Component {
    state = {
        loading: false,
        currentStep: 0,
        hotelsOptions: [],
        selectedHotel: [],
        dateOptions: [],
        fromDate: null,
        toDate: null,
        sbSummaries: []
    }

    idToken = '';
    userHotels = [];


    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    loadData() {
        this.setState({loading: true});

        const hotelId = parseInt(this.props.match.params.hotelid, 10);
        const startDate = parseInt(this.props.match.params.startdate, 10);
        const endDate = parseInt(this.props.match.params.enddate, 10);
        const sbType = parseInt(this.props.match.params.sbType, 10);

        const sbQueryString = 'hotelId=' + hotelId + '&type='+ sbType +'&startTimestamp=' + startDate + '&endTimestamp=' + endDate;
        API.get('benackoAPI', '/v2/reports/shift-balances?' + sbQueryString, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then(sbRes => {
                var processedEntries = [];

                if (sbRes.Items.length > 0) {
                    sbRes.Items.forEach(entry => {

                        if (entry.endTimestamp !== 0) {
                            const sbTypeLabel = (sbType === 1 || sbType === 3) ? 'Recepción' : 'Cocina';
                            const sbLabel = 'Corte de ' + new Date(entry.SK).toLocaleString() + ' (' + sbTypeLabel + ')';
                            const exchangeRate = entry.headers[0][0].rate;
                            const roomTypes = entry.headers[1];
                            const mainTotals = entry.totals[0];
                            const roomTotals = entry.totals[2];
                            const orderTotals = entry.totals[3];
                            const typeTotals = entry.totals[4];

                            var roomTypeTotals = [];
                            var roomTypeIds = new Set();
                            var roomTypeNames = {};
                            var roomTypeNumbers = {};
                            var roomTypeMonies = {};

                            for (var key in typeTotals) {
                                const roomTypeId = parseInt(key.split('D').pop(), 10);
                                const roomType = roomTypes.find( rt => rt.id === roomTypeId);

                                roomTypeIds.add(roomTypeId);
                                roomTypeNames[roomTypeId] = roomType.name;

                                if (key.includes('rtNumber')) {
                                    roomTypeNumbers[roomTypeId] = typeTotals[key];
                                }

                                if (key.includes('rtTotal')) {
                                    roomTypeMonies[roomTypeId] = typeTotals[key];
                                }
                            }

                            roomTypeIds.forEach( rtId => {
                                roomTypeTotals.push({ key: rtId, roomType: roomTypeNames[rtId], number: roomTypeNumbers[rtId], amount: '$' + roomTypeMonies[rtId] });
                            });

                            const currencyTotals = [{
                                key: entry.SK,
                                currency: '',
                                mxn: '$' + mainTotals.sbTotalMxn,
                                usd: '$' + mainTotals.sbTotalUsd,
                                card: '$' + mainTotals.sbTotalCard,
                                sbAbsTotal: '$' + mainTotals.sbAbsTotal
                            }];

                            const ordersTotals = [{
                                key: entry.SK,
                                orders: '',
                                numberOfOrders: orderTotals.numberOfOrders,
                                mxn: '$' + orderTotals.orderTotalMxn,
                                usd: '$' + orderTotals.orderTotalUsd,
                                card: '$' + orderTotals.orderTotalCard,
                                orderAbsTotal: '$' + orderTotals.orderAbsTotal
                            }];

                            const absTotals = [{
                                key: entry.SK,
                                totals: '',
                                roomAbsTotal: '$' + roomTotals.rentAbsTotal,
                                addAbsTotal: '$' + (roomTotals.addDaysAbsTotal + roomTotals.addGuestAbsTotal),
                                orderAbsTotal: '$' + orderTotals.orderAbsTotal,
                                chargeAbsTotal: '$' + roomTotals.chargeAbsTotal,
                                reservationAbsTotal: '$' + 0,
                                changeAbsTotal: '$' + roomTotals.changeAbsTotal,
                                absTotal: '$' + mainTotals.sbAbsTotal
                            }];

                            processedEntries.push(
                                <Row key={entry.SK} style={{ marginBottom: 50, width: '100%' }}>
                                    <Descriptions title={sbLabel} bordered={true} size="middle" style={{marginBottom: 30}}>
                                        <Descriptions.Item label="Apertura">{new Date(entry.SK).toLocaleString()}</Descriptions.Item>
                                        <Descriptions.Item label="Cierre">{new Date(entry.endTimestamp).toLocaleString()}</Descriptions.Item>
                                        <Descriptions.Item label="Tipo de cambio">${exchangeRate}</Descriptions.Item>
                                    </Descriptions>

                                    <Table
                                        columns={roomTypeTotalsColumns} 
                                        dataSource={roomTypeTotals} 
                                        size="small"
                                        bordered={false}
                                        pagination={false}
                                    />
                                    <Table
                                        columns={currencyTotalsColumns} 
                                        dataSource={currencyTotals} 
                                        size="small"
                                        bordered={false}
                                        pagination={false}
                                    />
                                    <Table
                                        columns={orderTotalsColumns} 
                                        dataSource={ordersTotals} 
                                        size="small"
                                        bordered={false}
                                        pagination={false}
                                    />
                                    <Table
                                        columns={absTotalsColumns} 
                                        dataSource={absTotals} 
                                        size="small"
                                        bordered={false}
                                        pagination={false}
                                    />
                                </Row>
                            );
                        }
                    });
                }

                return processedEntries;
            })
            .then(processedRes => {
                this.setState({loading: false, sbSummaries: processedRes});
            })
            .catch(errSb => {
                console.log('Error loading shift balances:', errSb);
                this.setState({loading: false});
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button onClick={this.goBackHandler}>Regresar</Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#fff', paddingTop: 15 }}>
                    <Row style={{ marginTop: 50 }}>
                        <Spin spinning={this.state.loading} size="large">
                        <Col span={20} offset={2}>
                            <Row>
                                {this.state.sbSummaries}
                            </Row>
                        </Col>
                        </Spin>
                    </Row>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.auth.companyId,
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(ShiftBalanceSummary);