import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { Button, Layout, Row, Card } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import HotelService from '../../../services/hotels';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group


class Detail extends Component {
    state = {
        loading: true,
        modalVisible: false,
        modalConfirmLoading: false,
        locationName: "",
        locationHotel: "",
        locationCreateDate: null,
        locationLastEditDate: null,
        locationEnabled: false
    }

    idToken = '';

    loadData() {
        this.setState({loading: true});
        const id = this.props.match.params.id;

        API.get('benackoAPI', '/product-locations/' + id)
            .then(async response => {
                if (response != null || response.length == 1) {
                    const name = response[0].name;
                    const hotel = await this.getHotelName(response[0].hotelId);
                    const createDate = new Date(response[0].created).toLocaleString();
                    const lastEditDate = new Date(response[0].updated).toLocaleString();
                    const enabled = response[0].enabled;

                    this.setState({
                        locationName: name,
                        locationHotel: hotel,
                        locationCreateDate: createDate,
                        locationLastEditDate: lastEditDate,
                        locationEnabled: enabled
                    });
                }
            })
            .catch(errItemLocation => console.log('Error loading item location:', errItemLocation))
            .finally(() => this.setState({loading: false}));
    }

    async getHotelName(hotelId) {
        const hotel = await HotelService.getHotel(hotelId);
        const hotelName = hotel?.name ?? '';

        return hotelName;
    }

    componentDidMount() {
        Auth.currentSession()
            .then(response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch(error => console.log(error));
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    goEditHandler = () => {
        this.props.history.push('/app/items/locations/details/' + this.props.match.params.id + '/edit')
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                            <Button type="primary" onClick={this.goEditHandler} disabled={!this.state.locationEnabled}>
                                Editar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#ffff', paddingTop: 15 }}>
                    <Card loading={this.state.loading} bordered={false} title={this.state.locationName}>
                        <p><strong>Hotel:</strong> {this.state.locationHotel}</p>
                        <p><strong>Fecha de creación:</strong> {this.state.locationCreateDate}</p>
                        <p><strong>Última actualización:</strong> {this.state.locationLastEditDate}</p>
                    </Card>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        cid: state.base.cid
    };
};


export default connect(mapStateToProps, null)(Detail);