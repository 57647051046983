import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Col, DatePicker, Input, Row, Select, Skeleton } from 'antd';

import { errorNotification, successNotification } from '../../../../components/UI/Notifications/Notifications';
import SectionTitle from '../../../../components/UI/Panels/SectionTitle/SectionTitle';

import MaintenanceService from '../../../../services/maintenance';


const AddPlanActivity = () => {
    const {planId} = useParams();
    const history = useHistory();

    const hid = useSelector(state => state.ui.selectedHotel ?? 0);
    const userId = useSelector(state => state.auth.user?.id ?? 0);
    const username = useSelector(state => state.auth.user?.username ?? '');

    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [newName, setNewName] = useState('');
    const [periodOptions, setPeriodOptions] = useState([]);
    const [baseMonthOptions, setBaseMonthOptions] = useState([]);
    const [monthDayOrderOptions, setMonthDayOrderOptions] = useState(null);
    const [monthDayNumberOptions, setMonthDayNumberOptions] = useState(null);
    const [dayOptions, setDayOptions] = useState([]);
    const [basePeriod, setBasePeriod] = useState(null);
    const [monthBasePeriod, setMonthBasePeriod] = useState(null);
    const [dayPeriod, setDayPeriod] = useState(null);
    const [monthDayOrder, setMonthDayOrder] = useState(null);
    const [monthDayNumber, setMonthDayNumber] = useState(null);
    const [yearPeriod, setYearPeriod] = useState(null);

    useEffect(() => {
        let processedOptions = [];
        processedOptions.push(<Select.Option key={'D'}>DIARIO</Select.Option>);
        processedOptions.push(<Select.Option key={'W'}>SEMANAL</Select.Option>);
        processedOptions.push(<Select.Option key={'M'}>MENSUAL</Select.Option>);
        processedOptions.push(<Select.Option key={'Y'}>ANUAL</Select.Option>);

        setPeriodOptions(processedOptions);


        let processedBaseMonthOptions = [];
        processedBaseMonthOptions.push(<Select.Option key={'_D'}>DÍA EXACTO</Select.Option>);
        processedBaseMonthOptions.push(<Select.Option key={'_P'}>DÍA DEL MES</Select.Option>);

        setBaseMonthOptions(processedBaseMonthOptions);


        let processedMonthDayOrderOptions = [];
        processedMonthDayOrderOptions.push(<Select.Option key={'_1'}>1º</Select.Option>);
        processedMonthDayOrderOptions.push(<Select.Option key={'_2'}>2º</Select.Option>);
        processedMonthDayOrderOptions.push(<Select.Option key={'_3'}>3º</Select.Option>);
        processedMonthDayOrderOptions.push(<Select.Option key={'_4'}>4º</Select.Option>);

        setMonthDayOrderOptions(processedMonthDayOrderOptions);


        let processedMonthDayNumberOptions = [];
        for (var i = 1; i < 32; i++) {
            processedMonthDayNumberOptions.push(<Select.Option key={'_' + i}>{i}</Select.Option>);
        }

        setMonthDayNumberOptions(processedMonthDayNumberOptions);


        let processedDayOptions = [];
        processedDayOptions.push(<Select.Option key={'_MON'}>LUNES</Select.Option>);
        processedDayOptions.push(<Select.Option key={'_TUE'}>MARTES</Select.Option>);
        processedDayOptions.push(<Select.Option key={'_WED'}>MIÉRCOLES</Select.Option>);
        processedDayOptions.push(<Select.Option key={'_THU'}>JUEVES</Select.Option>);
        processedDayOptions.push(<Select.Option key={'_FRI'}>VIERNES</Select.Option>);
        processedDayOptions.push(<Select.Option key={'_SAT'}>SÁBADO</Select.Option>);
        processedDayOptions.push(<Select.Option key={'_SUN'}>DOMINGO</Select.Option>);

        setDayOptions(processedDayOptions);
    }, []);

    const buildPeriodicity = () => {
        switch (basePeriod) {
            case 'D':
                return basePeriod;
            case 'W':
                return basePeriod + dayPeriod;
            case 'M':
                if (monthBasePeriod === '_D') {
                    return basePeriod + monthBasePeriod + monthDayNumber;
                } else {
                    return basePeriod + monthBasePeriod + monthDayOrder + dayPeriod;
                }
            case 'Y':
                return basePeriod + yearPeriod;
            default:
                return '';
        }
    };

    const create = () => {
        setLoading(true);
        
        if (!submitted) {
            setSubmitted(true);

            if (newName != null && newName.length != 0 && basePeriod != null) {
                const periodicity = buildPeriodicity();

                MaintenanceService.addPlanActivity(hid, planId, newName, periodicity, userId, username)
                    .then(() => successNotification('La actividad se ha agregado al plan de mantenimiento'))
                    .catch(err => errorNotification(err))
                    .finally(() => {
                        setSubmitted(false);
                        history.go(-1);
                    });
            }
        }
    };

    return (
        <Skeleton loading={loading} paragraph={{rows: 9, cols: 1}} active>
            <SectionTitle title='Agregar actividad a plan'/>

            <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                <Row style={{width: '100%', height: 60}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Descripción</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input type="text" maxLength={200} onChange={(e) => setNewName(e.target.value)} disabled={submitted}/></span>
                    </Col>
                </Row>
                <Row style={{width: '100%', height: 60, marginTop: 20}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Periodo base</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Select style={{width: 400}} onChange={r => setBasePeriod(r)} disabled={submitted}>{periodOptions}</Select></span>
                    </Col>
                </Row>

                {
                    (basePeriod === 'M') &&
                    <Row style={{width: '100%', height: 60, marginTop: 20}}>
                        <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Tipo de periodo mensual</span></Col>
                        <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                            <Select style={{width: 400}} onChange={r => setMonthBasePeriod(r)} disabled={submitted}>{baseMonthOptions}</Select></span>
                        </Col>
                    </Row>
                }

{
                    (basePeriod === 'M' && monthBasePeriod === '_D') &&
                    <Row style={{width: '100%', height: 60, marginTop: 20}}>
                        <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Día del mes</span></Col>
                        <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                            <Select style={{width: 400}} onChange={r => setMonthDayNumber(r)} disabled={submitted}>{monthDayNumberOptions}</Select></span>
                        </Col>
                    </Row>
                }

                {
                    (basePeriod === 'M' && monthBasePeriod === '_P') &&
                    <Row style={{width: '100%', height: 60, marginTop: 20}}>
                        <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Lugar del día en el mes</span></Col>
                        <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                            <Select style={{width: 400}} onChange={r => setMonthDayOrder(r)} disabled={submitted}>{monthDayOrderOptions}</Select></span>
                        </Col>
                    </Row>
                }

                {
                    (basePeriod === 'W' || (basePeriod === 'M' && monthBasePeriod === '_P')) &&
                    <Row style={{width: '100%', height: 60, marginTop: 20}}>
                        <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Día de la semana</span></Col>
                        <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                            <Select style={{width: 400}} onChange={r => setDayPeriod(r)} disabled={submitted}>{dayOptions}</Select></span>
                        </Col>
                    </Row>
                }

                {
                    (basePeriod === 'Y') &&
                    <Row style={{width: '100%', height: 60, marginTop: 20}}>
                        <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Día del año</span></Col>
                        <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                            <DatePicker format="MM-DD" style={{width: '100%'}} onChange={(_, ds) => setYearPeriod('_' + ds.replace('-', '_'))} /></span>
                        </Col>
                    </Row>
                }

                <Row style={{width: '100%', height: 60, marginTop: 40}}>
                    <Button type='primary' style={{marginLeft: 40}} onClick={() => create()} disabled={submitted || newName == null || newName.length <= 0 || basePeriod == null}>Agregar</Button>
                </Row>
            </Row>
        </Skeleton>
    );
};

export default AddPlanActivity;