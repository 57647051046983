import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, Button, Col, Row } from 'antd';

import SectionTitle from '../../../../components/UI/Panels/SectionTitle/SectionTitle';
import NumberInput from '../../../../components/UI/Panels/NumberInput/NumberInput';
import UtilityUnits from '../../../../components/UI/Panels/UtilityUnits/UtilityUnits';
import UtilitySelector from '../../../../components/UI/Panels/UtilitySelector/UtilitySelector';

import { enableHotelSelector, disableHotelSelector, createUtilityEvent } from '../../../../store/actions';
import MaintenanceService from '../../../../services/maintenance';
import { errorNotification } from '../../../../components/UI/Notifications/Notifications';


const CreateUtilityEvent = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const hid = useSelector(state => state.ui.selectedHotel ?? 0);
    const [utility, setUtility] = useState(null);
    const [dataPoint, setDataPoint] = useState(null);
    const userId = useSelector(state => state.auth.user?.id ?? 0);
    const username = useSelector(state => state.auth.user?.username ?? '');

    const [allowCreation, setAllowCreation] = useState(true);
    const [creating, setCreating] = useState(false);


    useEffect(() => {
        dispatch(enableHotelSelector());

        return () => {
            dispatch(disableHotelSelector());
        };
    }, []);

    useEffect(() => {
        if (hid != null && utility != null && dataPoint != null) {
            setAllowCreation(false);
        } else {
            setAllowCreation(true);
        }
    }, [hid, utility, dataPoint]);

    const create = () => {
        setCreating(true);

        MaintenanceService.createUtilityEvent(hid, utility, dataPoint, userId, username)
            .then(() => {
                history.goBack();
            })
            .catch(err => errorNotification(err))
            .finally(() => {
                setCreating(false);
            });
    };

    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 10}}>
                <Col span={23}>
                    <Row style={{marginLeft: 40, width: '100%', height: 40}}>
                        <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <NavLink to={'/app/maintenance/utilities'}>{'Servicios'}</NavLink>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {'Evento de servicio'}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <SectionTitle title={'Agregar evento de servicio'} />

            <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                <UtilitySelector minLength={5} onChange={(e) => setUtility(e)}/>
            </Row>
            <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                <NumberInput minLength={5} label={'Dato'} onChange={(e) => setDataPoint(e)}/>
            </Row>
            <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                <UtilityUnits utility={utility} />
            </Row>
            <Row style={{display: 'flex', alignItems: 'end', width: '100%', height: '60px'}}>
                <Button type="primary" size="large" style={{marginLeft: 40}} onClick={create} disabled={allowCreation} loading={creating}>
                    Guardar
                </Button>
            </Row>
        </Row>
    );
}

export default CreateUtilityEvent;