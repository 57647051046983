import { Auth, API } from 'aws-amplify';

class RoomService {

    static async getRooms(hid) {
        return API.get('benackoAPI', '/rooms?hotelId=' + hid)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    const processedResponse = [];
                    response.forEach(room => {
                        processedResponse.push({
                            Key: room.id,
                            Name: room.name
                        });
                    });

                    return processedResponse;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getRoomDetails(rid) {
        return API.get('benackoAPI', '/rooms/' + rid)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    const processedResponse = {
                        Key: response[0]?.id ?? 0,
                        Name: response[0]?.name ?? '',
                        Modified: response[0]?.updated ?? 0,
                        Created: response[0]?.created ?? 0,
                        Enabled: true
                    };

                    return processedResponse;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getRoomConfigurations(rid) {
        return API.get('benackoAPI', '/v2/rooms/configurations/' + rid)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }
};

export default RoomService;