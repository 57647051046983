import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { Modal, Col, Row, Input, InputNumber, Switch } from 'antd'; 


class EditableTicket extends Component{
    state = {
        tkQuantity: 0,
        tkConcept: '',
        tkAmount: '',
        tkInvoiced: false,
        confirmLoading: false
    };

    updateQuantity = (quantity) => {
        this.setState({tkQuantity: quantity});
    }

    updateConcept = (concept) => {
        this.setState({tkConcept: concept.target.value});
    }

    updateAmount = (amount) => {
        this.setState({tkAmount: amount});
    }

    updateInvoiced = (invoiced) => {
        this.setState({tkInvoiced: invoiced});
    }

    editTicket = () => {
        this.setState({confirmLoading: true});

        const pk = this.props.pk;
        const sk = this.props.sk;
        var storedVouchers = this.props.storedVouchers;

        storedVouchers.forEach(voucher => {
            if (voucher.folio === this.props.folio) {
                if (this.state.tkQuantity > 0) {
                    voucher.quantity = this.state.tkQuantity;
                }

                if (this.state.tkConcept.length > 0) {
                    voucher.concept = this.state.tkConcept;
                }

                if (this.state.tkAmount > 0) {
                    voucher.price = this.state.tkAmount;
                }

                voucher.invoiced = this.state.tkInvoiced;
                voucher.edited = true;
            }
        });

        API.put('benackoAPI', '/vouchers', {
                body: {
                    pk: pk,
                    sk: sk,
                    vouchers: storedVouchers
                },
                headers: {
                    Authorization: this.props.idToken
                }
            })
            .then(() => {
                this.setState({confirmLoading: false}, () => {
                    this.props.onOk();
                });
            })
            .catch(errEdit => {
                console.log('Error editing tickets:', errEdit);
            });
    }

    render() {
        return (
            <Modal
                title={'Editar comprobante ' + this.props.folio}
                cancelText="CERRAR"
                okText="EDITAR"
                onOk={this.editTicket}
                onCancel={this.props.onCancel}
                visible={this.props.visible}
                centered={true}
                confirmLoading={this.state.confirmLoading}
                destroyOnClose={true}
            >
                <Col>
                    <Row style={{ marginBottom: 20 }}>
                        <Col span={6}><strong>Cantidad: </strong></Col>
                        <Col span={12}><InputNumber defaultValue={this.props.quantity} placeholder="Cantidad" onChange={this.updateQuantity} /></Col>
                    </Row>
                    <Row style={{ marginBottom: 20 }}>
                        <Col span={6}><strong>Concepto: </strong></Col>
                        <Col span={12}><Input defaultValue={this.props.concept} placeholder="Concepto" onChange={this.updateConcept} /></Col>
                    </Row>
                    <Row style={{ marginBottom: 20 }}>
                        <Col span={6}><strong>Precio: </strong></Col>
                        <Col span={12}><InputNumber defaultValue={this.props.amount} placeholder="Precio" onChange={this.updateAmount} /></Col>
                    </Row>
                    <Row>
                        <Col span={6}><strong>Facturado: </strong></Col>
                        <Col span={12}><Switch onChange={this.updateInvoiced} /></Col>
                    </Row>
                </Col>
            </Modal>
        );
    }
};


export default EditableTicket;