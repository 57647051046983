import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { Button, Layout, Row, InputNumber, Table, Spin, Switch } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group
    
const pricesColumns = [{
        title: 'Hotel',
        dataIndex: 'hotel',
        key: 'hotel'
    }, {
        title: 'Precio',
        dataIndex: 'price',
        key: 'price'
    }, {
        title: 'Habilitado',
        dataIndex: 'enabled',
        key: 'enabled'
    }];


class Prices extends Component {
    state = {
        loading: true,
        productId: 0,
        productName: '',
        userHotels: [],
        productPrices: [],
        pricesMap: new Map(),
        enabledMap: new Map(),
        disableSaveButton: false
    }

    idToken = '';

    loadData() {
        this.setState({loading: true});
        const id = this.props.match.params.id;

        API.get('benackoAPI', '/products/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( productRes => {
                this.setState({
                    productId: id,
                    productName: productRes[0].name
                });
            })
            .then(async () => {
                var hotels = new Map();

                await API.get('benackoAPI', '/hotels', {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then(hotelsRes => {
                        hotelsRes.forEach(hotel => {
                            if (this.props.companyId === hotel.companyId) {
                                const hotelId = hotel.id;
                                hotels.set(hotelId, hotel.name);
                            }
                        });
                    })
                    .catch(errHotels => {
                        console.log('Error loading hotels:', errHotels);
                        this.setState({loading: false});
                    });

                return hotels;
            })
            .then( hotelsRes => {
                API.get('benackoAPI', '/product-prices?productId=' + id, {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then( pricesRes => {
                        var prices = [];
                        const userHotels = hotelsRes;
                        var pricesMap = this.state.pricesMap;
                        var enabledMap = this.state.enabledMap;

                        const usrHots = this.state.userHotels;

                        pricesRes.forEach( price => {

                            if (usrHots[price.hotelId] !== undefined) {
                                const key = price.productId + price.hotelId;

                                var switchState = (price.enabled === 1) ? true : false;

                                pricesMap[price.hotelId] = price.price;
                                enabledMap[price.hotelId] = switchState;

                                const priceIn = <InputNumber 
                                                    defaultValue={price.price}
                                                    min={0}
                                                    step={0.50}
                                                    onChange={ (e) => {
                                                        this.setPrice(price.hotelId, e);
                                                    }}
                                                />;

                                const checkedSwitch = <Switch 
                                                    defaultChecked 
                                                    onChange={ () => {
                                                        this.toggleEnabled(price.hotelId);
                                                    }}
                                                />;
                                                
                                const uncheckedSwitch = <Switch
                                                onChange={ () => {
                                                    this.toggleEnabled(price.hotelId);
                                                }}
                                            />;
                                            

                                prices.push({
                                    key: key,
                                    hotel: userHotels.get(price.hotelId),
                                    price: priceIn,
                                    enabled: (switchState) ? checkedSwitch : uncheckedSwitch
                                });
                            }
                        });

                        this.setState({
                            productPrices: prices,
                            pricesMap: pricesMap,
                            enabledMap: enabledMap
                        });
                    })
                    .catch( errPrices => {
                        console.log('Error loading prices:', errPrices);
                        this.setState({loading: false});
                    });
            })
            .then( () => {
                this.setState({loading: false});
            })
            .catch( error => {
                console.log(error);

                this.setState({loading: false});
            });
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.setUserHotels();
                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    setUserHotels() {
        const enabledHotels = this.props.hotels;
        var hotels = [];

        for (var i in enabledHotels) {
            hotels[i] = enabledHotels[i];
        }

        this.setState({userHotels: hotels});
    }

    setPrice = (hotel, price) => {
        var pricesMap = this.state.pricesMap;
        pricesMap[hotel] = price;

        this.setState({pricesMap: pricesMap});
    }

    toggleEnabled = (hotel) => {
        var enabledMap = this.state.enabledMap;
        enabledMap[hotel] = !enabledMap[hotel];

        this.setState({enabledMap: enabledMap});
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    savePriceChanges = () => {
        this.setState({loading: true});

        const prices = this.state.pricesMap;
        const enableds = this.state.enabledMap;

        for (var i in this.props.hotels) {
            API.put('benackoAPI', '/product-prices', {
                    body: {
                        productId: this.state.productId,
                        hotelId: i,
                        price: prices[i],
                        enabled: enableds[i]
                    },
                    headers: {
                        Authorization: this.idToken
                    }
                })
                .catch( errPrice => { console.log('Error editing price:', errPrice); });
        }

        this.setState({loading: false}, () => {
            this.props.history.goBack();
        });
    }

    render() {
        return(
            <Layout>
                <Spin spinning={this.state.loading} size="large">
                    <Header style={{ background: '#fff', padding: 0 }}>
                        <Row>
                            <ButtonGroup>
                                <Button type="primary" onClick={this.goBackHandler}>
                                    <LeftOutlined />Regresar
                                </Button>
                            </ButtonGroup>
                        </Row>
                    </Header>
                    <Content style={{ margin: 0, padding: 0, background: '#ffff', paddingTop: 15 }}>
                        <h3>Precios de {this.state.productName}</h3>
                        <Table 
                            columns={pricesColumns} 
                            dataSource={this.state.productPrices}
                            size="small"
                            bordered={false}
                            pagination={{ hideOnSinglePage: true }}
                            style={{ marginTop: 20 }}
                        />

                        <Button type="danger" onClick={this.savePriceChanges} disabled={this.state.disableSaveButton} style={{ marginTop: 50 }}>Guardar cambios</Button>
                    </Content>
                </Spin>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.auth.companyId,
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(Prices);