import XLSX from 'sheetjs-style';

export const processShiftDifferencesSheet = (shiftDifferences) => {
    const processedData = shiftDifferences.Shifts.map(shift => {
        return {
            'Turno': shift.timestamp,
            'Total': shift.cashTotal,
            'Depositado': shift.depositsTotal,
            'Diferencia': shift.difference
        };
    });

    return XLSX.utils.json_to_sheet(processedData);
};