import XLSX from 'xlsx-js-style';

const getHotelAcronym = (id) => {
    return ({
        1: 'MIM',
        2: 'MFM',
        3: 'MCM'
    }) [id] ?? 'HOTEL';
};

const formatReportDate = (date) => {
    const year = parseInt(date.substring(0, 4) ?? 1998);
    const month = parseInt(date.substring(5) ?? 10) - 1;
    
    const reportDate = new Date(year, month);
    const options = {year: 'numeric', month: 'long'};
    
    return reportDate?.toLocaleDateString('es-MX', options) ?? 'fecha';
};

export const processSummarySheet = (summary, hotelId, date) => {
    const sheet = XLSX.utils.aoa_to_sheet([]);

    XLSX.utils.sheet_add_aoa(sheet, [[getHotelAcronym(hotelId), '', '']], {origin: 'B4'});
    XLSX.utils.sheet_add_aoa(sheet, [['', '', '']], {origin: 'C4'});
    XLSX.utils.sheet_add_aoa(sheet, [['', '', '']], {origin: 'D4'});

    XLSX.utils.sheet_add_aoa(sheet, [[formatReportDate(date), '', '']], {origin: 'B8'});

    XLSX.utils.sheet_add_aoa(sheet, [['INGRESOS', '', '']], {origin: 'B11'});
    XLSX.utils.sheet_add_aoa(sheet, [['Pesos (MXN)', summary?.Income?.GrossIncome?.MxnIncome ?? 0, '']], {origin: 'B12'});
    XLSX.utils.sheet_add_aoa(sheet, [['Dólares (USD)', summary?.Income?.GrossIncome?.UsdIncome ?? 0, '']], {origin: 'B13'});
    XLSX.utils.sheet_add_aoa(sheet, [['Tarjetas', summary?.Income?.GrossIncome?.CardIncome ?? 0, '']], {origin: 'B14'});
    XLSX.utils.sheet_add_aoa(sheet, [['Total', summary?.Income?.GrossIncome?.TotalIncome ?? 0, '']], {origin: 'B15'});

    XLSX.utils.sheet_add_aoa(sheet, [['TIPO DE CAMBIO']], {origin: 'B18'});
    const processedData = summary.ExchangeRates.map(rate => {
        return {
            'Tasa': rate.Rate ?? '-',
            'Desde': rate.From?.substring(0, 10) ?? '-',
            'Hasta': rate.To?.substring(0, 10) ?? '-'
        };
    });
    XLSX.utils.sheet_add_json(sheet, processedData, {origin: 'B19'});

    sheet['!cols'] = [{wpx: 60}, {wpx:100}, {wpx:100}, {wpx:100}];
    sheet['!rows'] = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {hpt: 25}, {hpt: 20}, {hpt: 20}, {hpt: 20}, {hpt: 20}, {}, {hpt: 20}, {hpt: 20}];
    sheet['!merges'] = [{s: 'B4', e: 'D6'}, {s: 'B8', e: 'D8'}, {s: 'B11', e: 'D11'}, {s: 'B18', e: 'D18'}];

    // REPORT HEADER
    sheet['B4'].s = {alignment: {vertical: 'center',horizontal: 'center'}, font: {name: '', sz: 32, color: {rgb: '00000000'}, bold: true}};
    sheet['B8'].s = {alignment: {vertical: 'center',horizontal: 'center'}, font: {name: '', sz: 14, bold: false}};    


    // INCOME SECTION

    // TITLE
    sheet['B11'].s = {alignment: {vertical: 'center',horizontal: 'center'}, font: {name: '', sz: 16, color: {rgb: '000000FF'}, bold: false}, border: {top: {style: 'thin'}, bottom: {style: 'thin'}, left: {style: 'thin'}}};
    sheet['C11'].s = {border: {top: {style: 'thin'}, bottom: {style: 'thin'}}};
    sheet['D11'].s = {alignment: {vertical: 'center',horizontal: 'center'}, border: {top: {style: 'thin'}, right: {style: 'thin'}, bottom: {style: 'thin'}}};

    // TOTALS
    sheet['B12'].s = {border: {left: {style: 'thin'}}};
    sheet['C12'].s = {alignment: {vertical: 'center',horizontal: 'center'}, numFmt: '$#,##0.00'};
    sheet['D12'].s = {border: {right: {style: 'thin'}}};

    sheet['B13'].s = {border: {left: {style: 'thin'}}};
    sheet['C13'].s = {alignment: {vertical: 'center',horizontal: 'center'}, numFmt: '$#,##0.00'};
    sheet['D13'].s = {border: {right: {style: 'thin'}}};

    sheet['B14'].s = {border: {left: {style: 'thin'}}};
    sheet['C14'].s = {alignment: {vertical: 'center',horizontal: 'center'}, numFmt: '$#,##0.00'};
    sheet['D14'].s = {border: {right: {style: 'thin'}}};

    sheet['B15'].s = {border: {left: {style: 'thin'}, bottom: {style: 'thin'}}};
    sheet['C15'].s = {alignment: {vertical: 'center',horizontal: 'center'}, numFmt: '$#,##0.00', border: {bottom: {style: 'thin'}}};
    sheet['D15'].s = {border: {right: {style: 'thin'}, bottom: {style: 'thin'}}};



    // EXHCANGE RATES SECTION

    // TITLE
    sheet['B18'].s = {alignment: {vertical: 'center',horizontal: 'center'}, font: {sz: 10}};
    
    sheet['B19'].s = {alignment: {vertical: 'center',horizontal: 'center'}, font: {bold: true}};
    sheet['C19'].s = {alignment: {vertical: 'center',horizontal: 'center'}, font: {bold: true}};
    sheet['D19'].s = {alignment: {vertical: 'center',horizontal: 'center'}, font: {bold: true}};
    sheet['B20'].s = {alignment: {vertical: 'center',horizontal: 'center'}, numFmt: '$#,##0.00'};
    sheet['C20'].s = {alignment: {vertical: 'center',horizontal: 'center'}};
    sheet['D20'].s = {alignment: {vertical: 'center',horizontal: 'center'}};

    

    return sheet;
};