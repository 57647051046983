import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import packageJson from '../package.json';

import ResetPassword from './components/UI/Auth/ResetPassword/ResetPassword';
import LoginHeader from './components/UI/Auth/LoginHeader/LoginHeader';

//Styling
import '@aws-amplify/ui-react/styles.css';
import '@fontsource/inter/variable.css';
import './index.css';

//Redux Configuration
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

//AWS Configuration
import Amplify from 'aws-amplify';
import config from './aws.config';
import { I18n } from 'aws-amplify';
import { dict } from './localization/dictionary';
import { Authenticator, useTheme, View } from '@aws-amplify/ui-react';
import { translations } from '@aws-amplify/ui';
Amplify.configure(config);

//I18n Configuration
I18n.putVocabularies(translations);
I18n.putVocabularies(dict);
I18n.setLanguage('es');


//Reducers
import baseReducer from './store/reducers/base';
import uiReducer from './store/reducers/ui';
import maintenanceReducer from './store/reducers/maintenance';

import authReducer from './store/reducers/auth';

const rootReducer = combineReducers({
    base: baseReducer,
    ui: uiReducer,
    auth: authReducer,
    maintenance: maintenanceReducer
});

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk)
));


const loginComponents = {
    Header() {
        const { tokens } = useTheme();
    
        return (<View textAlign="center" padding={tokens.space.xxxl}/>);
    },
    SignIn: {
        Header() {    
          return (<LoginHeader version={'v' + packageJson.version}/>);
        },
        Footer() {
          return (<ResetPassword/>);
        },
      }
};

const app = (
    <Provider store={store}>
        <Authenticator
            loginMechanisms={['email']}
            components={loginComponents}
        >
            {() => <App/>}
        </Authenticator>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
