import React from 'react';
import { Modal } from 'antd';

const DisableModal = (props) => (
    <Modal
        closable={false}
        visible={props.visible}
        okText="Deshabilitar"
        okType="danger"
        cancelText="Cancelar"
        confirmLoading={props.confirmLoading}
        onCancel={props.onCancel}
        onOk={props.onOk}
        title="Deshabilitar"
    >
        <p>{props.modalText}</p>
    </Modal>
);

export default DisableModal;