import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Breadcrumb, Col, Row, Table } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

import SectionTitle from '../../../../components/UI/Panels/SectionTitle/SectionTitle';
import MaintenanceService from '../../../../services/maintenance';


const WorkOrdersReport = () => {
    const hid = useSelector(state => state.ui.selectedHotel ?? 0);

    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);

    const eventsColumns = [
        {title: 'Fecha', dataIndex: 'openTimestamp', defaultSortOrder: 'descend', render: (a) => new Date(a[0], (a[1] - 1), a[2]).toLocaleDateString()},
        {title: 'Nombre', dataIndex: 'name'},
        {title: 'Usuario', dataIndex: 'openUsername'},
        {title: 'Cerrada', dataIndex: 'closeUserId', render: (a) => (a !== 0) ? <CheckCircleTwoTone/> : <CloseCircleTwoTone/>},
        {title: 'Validada', dataIndex: 'validationUserId', render: (a) => (a !== 0) ? <CheckCircleTwoTone/> : <CloseCircleTwoTone/>}
    ];

    useEffect(async () => {
        if (hid != null && hid != 0) {
            setLoading(true);
            
            const queryParams = new URLSearchParams(location.search);
            const fromDate = queryParams.get('initialDate') ?? null;
            const toDate = queryParams.get('finalDate') ?? null;
            await loadWorkOrders(fromDate, toDate);

            setLoading(false);
        }
    }, [hid]);


    const loadWorkOrders = async (fromDate, toDate) => {
        await MaintenanceService.getWorkOrdersByDateRange(hid, fromDate, toDate)
            .then(res => {
                if (res != null) {
                    setEvents(res);
                }
            })
            .catch(() => setEvents([]));
    };


    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 10}}>
                <Col span={22}>
                    <Row style={{marginLeft: 40, width: '100%', height: 40}}>
                        <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <NavLink to={'/app/maintenance/reports'}>{'Reportes'}</NavLink>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {'Reporte de órdenes de trabajo'}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Col>
            </Row>
            
            <SectionTitle title={'Órdenes de trabajo'} />

            <Row style={{width: '100%', height: '100%', flexGrow: 1, marginBottom: 20}}>
                <Col offset={1} span={23}>
                    <Table
                        style={{cursor: 'pointer'}}
                        size={'small'}
                        bordered={true}
                        loading={loading}
                        columns={eventsColumns}
                        dataSource={events}
                        pagination={false}
                        rowKey={'id'}
                        scroll={{y: '100%'}}
                        locale={{
                            emptyText: 'No se encontraron datos',
                            cancelSort: 'Haz clic para cancelar la ordenación',
                            triggerAsc: 'Haz clic para ordenar ascendentemente',
                            triggerDesc: 'Haz clic para ordenar descendentemente'
                        }}
                    />
                </Col>
            </Row>
        </Row>
    );
};

export default WorkOrdersReport;
