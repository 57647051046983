import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { Button, Layout, Row, Tabs, Card, Table, Spin } from 'antd';
import { LeftOutlined, CheckOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group
const TabPane = Tabs.TabPane;

const itemsColumns = [{
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name'
    }, {
        title: 'Cantidad',
        dataIndex: 'quantity',
        key: 'quantity'
    }, {
        title: 'Esencial',
        dataIndex: 'isEssential',
        key: 'isEssential'
    }];
    
const pricesColumns = [{
        title: 'Hotel',
        dataIndex: 'hotel',
        key: 'hotel'
    }, {
        title: 'Precio',
        dataIndex: 'price',
        key: 'price'
    }, {
        title: 'Habilitado',
        dataIndex: 'enabled',
        key: 'enabled'
    }];


class Detail extends Component {
    state = {
        loading: true,
        productName: '',
        productCreateDate: null,
        productLastEditDate: null,
        recipeInstructions: null,
        recipeItems: [],
        userHotels: [],
        productPrices: [],
        canModifyPrices: true,
        canAddPrices: true
    }

    idToken = '';

    loadData() {
        this.setState({loading: true});
        const id = this.props.match.params.id;

        API.get('benackoAPI', '/products/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( productRes => {
                this.setState({
                    productName: productRes[0].name,
                    productCreateDate: new Date(productRes[0].created).toLocaleString(),
                    productLastEditDate: new Date(productRes[0].updated).toLocaleString()
                });

                return productRes[0].recipeId;
            })
            .then( recipeId => {
                API.get('benackoAPI', '/recipes/' + recipeId, {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then( recipeRes => {
                        this.setState({
                            recipeInstructions: recipeRes[0].instructions
                        });
                    })
                    .catch( errRecipe => {
                        console.log('Error loading recipe:', errRecipe);

                        this.setState({loading: false});
                    });

                return recipeId;
            })
            .then( recipeId => {
                var items = [];

                API.get('benackoAPI', '/recipe-items?recipeId=' + recipeId, {
                    headers: {
                        Authorization: this.idToken
                    }
                })
                .then( async itemsRes => {
                    itemsRes.forEach( async item => {
                        items.push({
                            key: item.itemId,
                            name: await this.getItemName(item.itemId),
                            quantity: item.quantity,
                            isEssential: (item.isEssential === 1) ? <CheckOutlined /> : ''
                        });
                    });

                    this.setState({
                        recipeItems: items
                    });
                })
                .catch( errRecipe => {
                    console.log('Error loading recipe:', errRecipe);

                    this.setState({loading: false});
                });
            })
            .then(async () => {
                var hotels = new Map();

                await API.get('benackoAPI', '/hotels', {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then(hotelsRes => {
                        hotelsRes.forEach(hotel => {
                            if (this.props.companyId === hotel.companyId) {
                                const hotelId = hotel.id;
                                hotels.set(hotelId, hotel.name);
                            }
                        });
                    })
                    .catch(errHotels => {
                        console.log('Error loading hotels:', errHotels);
                        this.setState({loading: false});
                    });

                return hotels;
            })
            .then(async hotelsRes => {
                var prices = [];

                await API.get('benackoAPI', '/product-prices?productId=' + id, {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then( pricesRes => {        
                        const userHotels = hotelsRes;

                        pricesRes.forEach( price => {
                            const key = price.productId + price.hotelId;

                            prices.push({
                                key: key,
                                hotel: userHotels.get(price.hotelId),
                                price: '$' + price.price,
                                enabled: (price.enabled === 1) ? <CheckOutlined /> : '',
                                hotelId: price.hotelId
                            });
                        });

                        this.setState({productPrices: prices});
                    })
                    .catch( errPrices => {
                        console.log('Error loading prices:', errPrices);
                        this.setState({loading: false});
                    });

                return prices;
            })
            .then(pricesRes => {
                //CHECK TO SEE IF PRICES CAN BE ADDED
                var canAdd = true;
                var canModify = true;
                const userHotels = this.props.hotels;

                for (var i in userHotels) {
                    const iInt = parseInt(i, 10);
                    if (pricesRes.find(pr => {return pr.hotelId === iInt}) === undefined) { canAdd = false; }

                    if (pricesRes.find(pr => {return pr.hotelId === iInt}) !== undefined) { canModify = false; }
                }

                this.setState({canAddPrices: canAdd, canModifyPrices: canModify});
            })
            .then( () => {
                this.setState({loading: false});
            })
            .catch( error => {
                console.log(error);

                this.setState({loading: false});
            });
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                //this.setUserHotels();
                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    /*setUserHotels() {
        const enabledHotels = this.props.hotels;
        var hotels = [];

        for (var i in enabledHotels) {
            hotels[i] = enabledHotels[i];
        }

        this.setState({userHotels: hotels});
    }*/

    async getItemName(itemId) {
        let item = await API.get('benackoAPI', '/items/' + itemId, { headers: { Authorization: this.idToken } });
        let itemName = item[0].name;

        return itemName;
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    goEditHandler = () => {
        this.props.history.push('/app/products/details/' + this.props.match.params.id + '/edit')
    }

    goAddArticle = () => {
        this.props.history.push('/app/products/details/' + this.props.match.params.id + '/add')
    }

    goRemoveArticle = () => {
        this.props.history.push('/app/products/details/' + this.props.match.params.id + '/remove')
    }

    goSetPrices = () => {
        this.props.history.push('/app/products/details/' + this.props.match.params.id + '/prices')
    }

    goAddPrice = () => {
        this.props.history.push('/app/products/details/' + this.props.match.params.id + '/prices/add')
    }

    render() {
        return(
            <Layout>
                <Spin spinning={this.state.loading} size="large">
                    <Header style={{ background: '#fff', padding: 0 }}>
                        <Row>
                            <ButtonGroup>
                                <Button type="primary" onClick={this.goBackHandler}>
                                    <LeftOutlined />Regresar
                                </Button>
                                <Button type="primary" onClick={this.goEditHandler} disabled={false}>
                                    Editar
                                </Button>
                                <Button type="danger" onClick={this.goRemoveArticle}>
                                    Quitar artículo
                                </Button>
                                <Button type="primary" onClick={this.goAddArticle}>
                                    Agregar artículo
                                </Button>
                                <Button type="danger" onClick={this.goSetPrices} disabled={this.state.canModifyPrices}>
                                    Modificar precios
                                </Button>
                                <Button type="danger" onClick={this.goAddPrice} disabled={this.state.canAddPrices}>
                                    Agregar precio
                                </Button>
                            </ButtonGroup>
                        </Row>
                    </Header>
                    <Content style={{ margin: 0, padding: 0, background: '#ffff', paddingTop: 15 }}>
                        <Tabs defaultActiveKey="1" tabPosition="right">
                            <TabPane tab="General" key="1">
                                <Card loading={this.state.loading} bordered={false} title={this.state.productName}>
                                    <p><strong>Creado en:</strong> {this.state.productCreateDate}</p>
                                    <p><strong>Última actualización:</strong> {this.state.productLastEditDate}</p>
                                </Card>
                            </TabPane>
                            <TabPane tab="Receta" key="2">
                                <h4>Ingredientes</h4>
                                <Table 
                                    columns={itemsColumns} 
                                    dataSource={this.state.recipeItems}
                                    size="small"
                                    bordered={false}
                                    pagination={{
                                        hideOnSinglePage: true,
                                        pageSize: 8,
                                        showTotal: (total) => `Total ${total} ingredientes`
                                    }}
                                    locale={{ emptyText: 'No se encontraron ingredientes' }}
                                />
                                <Card loading={this.state.loading} bordered={false} title="Instrucciones" style={{ marginTop: 30 }}>
                                    <p>{this.state.recipeInstructions}</p>
                                </Card>
                            </TabPane>
                            <TabPane tab="Precios" key="3">
                                <h4>Precios</h4>
                                <Table 
                                    columns={pricesColumns} 
                                    dataSource={this.state.productPrices}
                                    size="small"
                                    bordered={false}
                                    pagination={{ hideOnSinglePage: true }}
                                />
                            </TabPane>
                        </Tabs>
                    </Content>
                </Spin>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.auth.companyId,
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(Detail);