import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API, Auth } from 'aws-amplify';
import { Button, Layout, Row, InputNumber, Col, Spin, Select } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group
const Option = Select.Option;


class AddPrice extends Component {
    state = {
        loading: false,
        availableHotels: [],
        productId: 0,
        productHotel: null,
        productPrice: 0
    };

    idToken = '';

    loadData() {
        this.setState({loading: true});

        API.get('benackoAPI', '/product-prices?productId=' + this.props.match.params.id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( pricesRes => {
                var availableHotels = [];
                const userHotels = this.props.hotels;

                for (var i in userHotels) {
                    const iInt = parseInt(i, 10);
                    if (pricesRes.find(pr => {return pr.hotelId === iInt}) === undefined) {
                        availableHotels.push(<Option key={iInt} value={iInt}>{userHotels[iInt]}</Option>);
                    }
                }

                this.setState({loading: false, productId: this.props.match.params.id, availableHotels: availableHotels});
            })
            .catch( errPrices => {
                console.log('Error loading prices:', errPrices);
                this.setState({loading: false});
            });
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    

    selectHotelHandler = value => {
        this.setState({productHotel: value});
    }

    setPrice = value => {
        this.setState({productPrice: value});
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    addPrice = (event) => {
        event.preventDefault();
        this.setState({loading: true});

        const newPrice = {
            productId: this.state.productId,
            hotelId: this.state.productHotel,
            price: this.state.productPrice
        }

        API.post('benackoAPI', '/product-prices', {
                body: newPrice,
                headers: {
                    Authorization: this.idToken
                }
            })
            .then(() => {
                this.setState({loading: false});
                this.props.history.goBack();
            })
            .catch( errAddPrice => {
                console.log('Error adding price:', errAddPrice);
            });
    }

    render() {

        return (
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#ffff', paddingTop: 15 }}>
                    <Row style={{marginTop: 50}}>
                        <Spin spinning={this.state.loading} size="large">
                            <Col span={6} offset={2}>
                                <Row>
                                    <span><strong>Hotel: </strong></span>
                                    <Select
                                        style={{ width: '100%', marginTop: 30 }}
                                        placeholder="Selecciona el hotel"
                                        onChange={this.selectHotelHandler}
                                    >
                                        {this.state.availableHotels}
                                    </Select>
                                </Row>
                                <Row style={{marginTop: 30}}>
                                    <span><strong>Precio: </strong>$ </span>
                                    <InputNumber min={0} onChange={this.setPrice} />
                                </Row>
                                <Row style={{ marginTop: 50 }}>
                                    <Button type="primary" onClick={this.addPrice} disabled={!(this.state.productPrice > 0 && this.state.productHotel !== null)}>Agregar precio</Button>
                                </Row>
                            </Col>
                        </Spin>
                    </Row>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.auth.companyId,
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(AddPrice);