import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Space, Table } from 'antd';

import { errorNotification } from '../../../components/UI/Notifications/Notifications';

import MaintenanceService from '../../../services/maintenance';
import { disableHotelSelector, enableHotelSelector } from '../../../store/actions';
import SectionTitle from '../../../components/UI/Panels/SectionTitle/SectionTitle';
import CreateButton from '../../../components/UI/Buttons/CreateButton/CreateButton';
import ReloadButton from '../../../components/UI/Buttons/ReloadButton/ReloadButton';


const Plans = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    
    const hid = useSelector(state => state.ui.selectedHotel ?? 0);

    const [loading, setLoading] = useState(true);
    const [plans, setPlans] = useState([]);

    const dataColumns = [
        {dataIndex: 'name'}
    ];


    useEffect(() => {
        dispatch(enableHotelSelector());

        return () => {
            dispatch(disableHotelSelector());
        };
    }, []);

    useEffect(() => {
        if (hid != null && hid > 0) {
            loadData();
        }
    }, [hid]);

    const loadData = async () => {
        setLoading(true);

        await loadWorkOrders();

        setLoading(false);
    };

    const loadWorkOrders = async () => {
        await MaintenanceService.getPlans(hid)
            .then(res => {
                if (res != null) {
                    setPlans(res);
                }
            })
            .catch(err => errorNotification(err));
    };

    const goToAdd = () => {
        history.push('/app/maintenance/plans-add');
    }

    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Col offset={22} span={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                <Space>
                    <CreateButton onClick={goToAdd} loading={loading} />
                    <ReloadButton onClick={loadData} loading={loading}/>
                </Space>
            </Col>
            <SectionTitle title='Planes de mantenimiento'/>

            <Col offset={1} span={22}>
                <Table
                    style={{cursor: 'pointer'}}
                    size="middle"
                    columns={dataColumns}
                    rowKey={'id'}
                    dataSource={plans}
                    scroll={{y: 440}}
                    locale={{
                        emptyText: 'No se encontraron datos',
                        cancelSort: 'Haz clic para cancelar ordenar',
                        triggerAsc: 'Haz clic para ordenar - ascendente',
                        triggerDesc: 'Haz clic para ordenar - descendente'
                    }}
                    onRow={ (record) => {
                        return {
                            onClick: () => {
                                history.push('/app/maintenance/plans/' + record.id);
                            }
                        };
                    }}
                    pagination={{
                        pageSize: 8,
                        showTotal: (total) => `Total: ${total}`
                    }}
                    loading={{
                        spinning: loading,
                        delay: 500,
                        size: 'large'
                    }}
                />
            </Col>
        </Row>
    );
}

export default Plans;
