import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AppBase from './components/AppBase/AppBase';
import DataLoader from './components/DataLoader/DataLoader';

import './App.css';


const App = () => {
  //FOR LATER, AFTER ALL OPTIONS IN APPBASE HAVE BEEN MIGRATED TO NEW BACKEND
  // AND PERMISSIONS CAN BE RETRIEVED FROM NEW AUTHUSER REDUCER
  const roleKey = useSelector(state => state.base.roleKey ?? null);
  const role = useSelector(state => state.auth.role);

  return (
    <BrowserRouter>
      {(role == null || roleKey == null) ? <DataLoader /> : <AppBase/>}
    </BrowserRouter>
  );
}

export default App;
