import React, { useEffect, useState } from 'react';
import { Row, Table } from 'antd';

import './DailyRoomsTabContents.css';

const DailyRoomsTabContents = ({columns = [], dailyRooms = {}}) => {
    const [rentTypeColumns, setRentTypeColumns] = useState([]);
    const [shifts, setShifts] = useState([]);


    useEffect(() => {
        if (columns != null) {
            setRentTypeColumns([
                {title: '', dataIndex: 'Timestamp', width: 100},
                ...columns,
                {title: 'Cargos', dataIndex: 'ChargesTotal', align: 'center', render: (e) => '$' + Number(e ?? 0).toLocaleString()},
                {title: 'Bar', dataIndex: 'OrdersTotal', align: 'center', render: (e) => '$' + Number(e ?? 0).toLocaleString()},
                {title: 'Total', dataIndex: 'AbsTotal', align: 'center', render: (e) => '$' + Number(e ?? 0).toLocaleString()}
            ]);
        }

        if (dailyRooms != null) {
            if (dailyRooms.DailyRoomTotals != null) { setShifts(dailyRooms.DailyRoomTotals ?? []); }
        }
    }, [columns, dailyRooms]);


    return (
        <Row style={{display: 'flex', width: '1500px'}}>
            <Table
                className='daily-rooms-table'
                style={{width: '150%'}}
                size='small'
                scroll={{y: 500}}
                pagination={false}
                columns={rentTypeColumns}
                dataSource={shifts}
                rowKey='Timestamp'
            />
        </Row>
    );
};

export default DailyRoomsTabContents;