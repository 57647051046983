import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API, Auth } from 'aws-amplify';
import { Button, Layout, Row, Spin, Select } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;
const Option = Select.Option;


class RemoveItem extends Component {
    state = {
        loadingRemoval: false,
        recipeId: 0,
        items: [],
        itemNames: new Map(),
        selectedItems: []
    };

    idToken = '';

    loadData() {
        this.setState({loadingRemoval: true});

        API.get('benackoAPI', '/products/' + this.props.match.params.id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( productRes => {
                this.setState({recipeId: productRes[0].recipeId});

                return productRes[0].recipeId;
            })
            .then( async recipeId => {
                let items =await API.get('benackoAPI', '/recipe-items?recipeId=' + recipeId, {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then(recItemsRes => {
                        var items = [];

                        recItemsRes.forEach( item => {
                            if (item.isEssential === 0) {
                                items.push(item.itemId);
                            }
                        });

                        return items;
                    })
                    .catch( errRecItems => {
                        console.log('Error loading recipe items:', errRecItems);
        
                        this.setState({loadingRemoval: false});
                    });

                return items;
            })
            .then( async recipeItems => {
                await API.get('benackoAPI', '/items?companyId=' + this.props.companyId, {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then( itemsRes => {
                        var items = [];
                        var itemNames = new Map();
        
                        itemsRes.forEach( item => {
                            if (recipeItems.find( rt => rt === item.id)) {
                                itemNames[item.id] = item.name;
            
                                items.push(<Option key={item.id}>{item.name}</Option>);
                            }
                        });
        
                        this.setState({
                            loadingRemoval: false,
                            items: items,
                            itemNames: itemNames
                        });
                    })
                    .catch( errInventories => {
                        console.log('Error loading inventories:', errInventories);

                        this.setState({loadingRemoval: false});
                    });
            })
            .catch( errRecipe => {
                console.log('Error loading recipe:', errRecipe);

                this.setState({loadingRemoval: false});
            });
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    selectItemHandler = (value) => {
        this.setState({selectedItems: value});
    }

    removeItems = (event) => {
        event.preventDefault();
        
        this.setState({loadingRemoval: true});

        const items = this.state.selectedItems;

        items.forEach( item => {
            API.del('benackoAPI', '/recipe-items/' + item + '?recipeId=' + this.state.recipeId, {
                    headers: {
                        Authorization: this.idToken
                    }
                });
        });

        this.setState({loadingRemoval: false});
        
        this.props.history.goBack();
    }

    render() {
        return (
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#ffff', paddingTop: 15 }}>
                    <Spin spinning={this.state.loadingRemoval} size="large">
                        <Row style={{ marginTop: 50, marginBottom: 10 }}>
                            <h4>Selecciona los artículos a quitar del producto:</h4>
                        </Row>
                        <Row>
                            <Select
                                mode="multiple"
                                style={{ width: '100%', marginTop: 30 }}
                                placeholder="Selecciona los artículos a quitar del producto"
                                onChange={this.selectItemHandler}
                            >
                                {this.state.items}
                            </Select>
                        </Row>
                        <Row style={{ marginTop: 40 }}>
                            <ButtonGroup>
                                <Button type="primary" onClick={this.goPreviousStep}>Regresar</Button>
                                <Button type="danger" onClick={this.removeItems} disabled={this.state.selectedItems <= 0}>Quitar</Button>
                            </ButtonGroup>
                        </Row>
                        <Row style={{ marginTop: 10 }}>
                            <h6>SOLAMENTE SE PUEDEN QUITAR ARTÍCULOS NO ESENCIALES</h6>
                        </Row>
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.auth.companyId
    };
};


export default connect(mapStateToProps, null)(RemoveItem);