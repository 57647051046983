import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API, Auth } from 'aws-amplify';
import { Button, Form, InputNumber, Layout, Row, Col, Spin } from 'antd';
import { LeftOutlined, TableOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;


class Edit extends Component {
    state = {
        loading: false,
        newRate: 0.0
    }

    idToken = '';

    loadData() {
        this.setState({loading: true});
        const id = this.props.match.params.id;

        API.get('benackoAPI', '/exchange-rates/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( response => {
                const rate = response[0].rate;
                
                this.setState({newRate: rate, loading: false});
            })
            .catch( error => {
                console.log(error);
                this.setState({loading: false});
            });
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    editExchangeRateHandler = () => {
        this.setState({loading: true});
        const id = this.props.match.params.id;

        const newRate = this.state.newRate;
        const beautifiedRate = parseFloat(newRate).toFixed(2);
        
        API.put('benackoAPI', '/exchange-rates/' + id, {
                body: {
                    userId: this.props.userId,
                    rate: beautifiedRate
                },
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( () => {
                this.setState({loading: false});
                this.props.history.goBack();
            })
            .catch( error => {
                console.log(error);
                this.setState({loading: false});
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    render() {
        return(
            <Layout>
                <Header style={{background: '#fff', padding: 0, paddingLeft: 20}}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{margin: 0, padding: 0, paddingLeft: 20, background: '#ffff'}}>
                    <Spin spinning={this.state.loading} size="large">
                        <Form className="login-form">
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Tasa de cambio:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <InputNumber defaultValue={this.state.newRate} min={0.01} step={0.10} onChange={(e) => this.setState({newRate: e})} prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Tasa" />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 20}}>
                                <Col span={4} offset={2}>
                                    <Button type="primary" 
                                        onClick={this.editExchangeRateHandler}
                                        disabled={this.state.newRate == null} className="login-form-button"
                                    >
                                        Ajustar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        userId: state.auth.userId
    };
};


export default connect(mapStateToProps, null)(Edit);