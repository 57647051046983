import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Space } from 'antd';

import ReloadButton from '../../../components/UI/Buttons/ReloadButton/ReloadButton';
import CreateButton from '../../../components/UI/Buttons/CreateButton/CreateButton';
import UtilityPanel from '../../../components/UI/Panels/UtilityPanel/UtilityPanel';
import { errorNotification } from '../../../components/UI/Notifications/Notifications';

import MaintenanceService from '../../../services/maintenance';
import { disableHotelSelector, enableHotelSelector } from '../../../store/actions';


const Utilities = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    
    const cid = useSelector(state => state.base.cid ?? '');
    const hid = useSelector(state => state.ui.selectedHotel ?? 0);

    const [waterData, setWaterData] = useState(0);
    const [waterUnits, setWaterUnits] = useState('');
    const [waterTimestamp, setWaterTimestamp] = useState('');
    const [electricityData, setElectricityData] = useState(0);
    const [electricityUnits, setElectricityUnits] = useState('');
    const [electricityTimestamp, setElectricityTimestamp] = useState('');
    const [gasData, setGasData] = useState(0);
    const [gasUnits, setGasUnits] = useState('');
    const [gasTimestamp, setGasTimestamp] = useState('');


    useEffect(() => {
        dispatch(enableHotelSelector());

        return () => {
            dispatch(disableHotelSelector());
        };
    }, []);

    useEffect(() => {
        if (hid != null && hid > 0) {
            loadData();
        }
    }, [cid, hid]);

    const loadData = async () => {
        setLoading(true);

        await loadWater();
        await loadElectricity();
        await loadGas();

        setLoading(false);
    };

    const loadWater = async () => {
        await MaintenanceService.getUtility(hid, 'water')
            .then(res => {
                if (res != null) {
                    const waterReadings = res.log ?? [];
                    const water = waterReadings[0];
                    const waterTimestampArray = water?.timestamp ?? [1970, 1, 1];
                    const waterTimestamp = new Date(waterTimestampArray[0], waterTimestampArray[1] - 1, waterTimestampArray[2]).toISOString();
                    
                    setWaterData(water?.reading ?? '');
                    setWaterUnits(water?.unit ?? '');
                    setWaterTimestamp(waterTimestamp);
                }
            })
            .catch(err => errorNotification(err));
    };

    const loadElectricity = async () => {
        await MaintenanceService.getUtility(hid, 'electricity')
            .then(res => {
                if (res != null) {
                    const electricityReadings = res.log ?? [];
                    const electricity = electricityReadings[0];
                    const electricityTimestampArray = electricity?.timestamp ?? [1970, 1, 1];
                    const electricityTimestamp = new Date(electricityTimestampArray[0], electricityTimestampArray[1] - 1, electricityTimestampArray[2]).toISOString();
                    
                    setElectricityData(electricity?.reading ?? '');
                    setElectricityUnits(electricity?.unit ?? '');
                    setElectricityTimestamp(electricityTimestamp);
                }
            })
            .catch(err => errorNotification(err));
    };

    const loadGas = async () => {
        await MaintenanceService.getUtility(hid, 'gas')
            .then(res => {
                if (res != null) {
                    const gasReadings = res.log ?? [];
                    const gas = gasReadings[0];
                    const gasTimestampArray = gas?.timestamp ?? [1970, 1, 1];
                    const gasTimestamp = new Date(gasTimestampArray[0], gasTimestampArray[1] - 1, gasTimestampArray[2]).toISOString();
                    
                    setGasData(gas?.reading ?? '');
                    setGasUnits('%');
                    setGasTimestamp(gasTimestamp);
                }
            })
            .catch(err => errorNotification(err));
    };


    const goToAdd = () => {
        history.push('/app/maintenance/utilities/create');
    }

    const goToUtility = (utility) => {
        history.push('/app/maintenance/utilities/details?util=' + utility);
    }


    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 10}}>
                <Col offset={22} span={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Space>
                        <ReloadButton onClick={loadData} loading={loading} />
                        <CreateButton onClick={goToAdd} loading={loading} />
                    </Space>
                </Col>
            </Row>
            <Row style={{display: 'flex', width: '98%', marginTop: 50, marginLeft: 20}}>
                <UtilityPanel
                    loading={loading}
                    utility="Agua"
                    data={waterData}
                    unit={waterUnits}
                    timestamp={waterTimestamp}
                    onClick={() => goToUtility('water')}
                />
                <UtilityPanel
                    loading={loading}
                    utility="Electricidad"
                    data={electricityData}
                    unit={electricityUnits}
                    timestamp={electricityTimestamp}
                    onClick={() => goToUtility('electricity')}
                />
                <UtilityPanel
                    loading={loading}
                    utility="Gas"
                    data={gasData}
                    unit={gasUnits}
                    timestamp={gasTimestamp}
                    onClick={() => goToUtility('gas')}
                />
            </Row>
        </Row>
    );
}

export default Utilities;