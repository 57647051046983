import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth, API } from 'aws-amplify';
import { Layout, Row, Table, Spin, Card, Button, Tag } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import EditableTicket from '../../../components/UI/Modals/EditableTicket/EditableTicket';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;

const ticketsColumns = [{
    title: 'Fecha comprobante',
    dataIndex: 'date'
}, {
    title: 'Folio',
    dataIndex: 'folio'
}, {
    title: 'Cantidad',
    dataIndex: 'quantity'
}, {
    title: 'Concepto',
    dataIndex: 'concept'
}, {
    title: 'Precio',
    dataIndex: 'price'
}, {
    title: '',
    dataIndex: 'invoiced'
}, {
    title: '',
    dataIndex: 'edited'
}];


class TicketsEditDetails extends Component {
    state = {
        loading: false,
        tickets: [],
        PK: '',
        SK: '',
        storedVouchers: [],
        visibleTicket: false,
        visibleTicketQuantity: 0,
        visibleTicketAmount: 1,
        visibleTicketInvoiced: false,
        visibleTicketConcept: '',
        visibleTicketFolio: '',
    }

    idToken = '';


    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    loadData() {
        this.setState({loading: true});

        const companyId = this.props.companyId;
        const hotelId = this.props.match.params.hotelid;
        const date = this.props.match.params.date;
        const type = 'tk';

        const queryString = 'companyId=' + companyId + '&hotelId=' + hotelId + '&timestamp=' + date + '&type=' + type;

        API.get('benackoAPI', '/vouchers?' + queryString, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( vouchersRes => {
                var tickets = [];
                const vouchers = vouchersRes.Items[0].vouchers;

                const PK = vouchersRes.Items[0].PK;
                const SK = vouchersRes.Items[0].SK;
                const storedVouchers = vouchers;

                const vouchersYear = date.substring(0, 4);
                const vouchersMonth = date.substring(4, 6);
                const vouchersDay = date.substring(6, 8);
                const vouchersDatum = new Date(vouchersYear, (vouchersMonth - 1), vouchersDay);

                vouchers.forEach(voucher => {
                    tickets.push({
                        key: voucher.key,
                        date: vouchersDatum.toDateString(),
                        folio: voucher.folio,
                        quantity: voucher.quantity,
                        concept: voucher.concept,
                        price: voucher.price,
                        invoiced: (voucher.invoiced) ? <Tag color="geekblue">Facturado</Tag> : '',
                        edited: (voucher.edited) ? <Tag color="red">Editado</Tag> : '',
                        inv: (voucher.invoiced !== true) ? false : true,
                        sortKey: vouchersDatum.getTime()
                    });
                });

                tickets.sort((a, b) => { return b.sortKey - a.sortKey; });

                this.setState({
                    PK: PK,
                    SK: SK,
                    storedVouchers: storedVouchers,
                    tickets: tickets,
                    loading: false
                });
            })
            .catch( errVouchers => {
                console.log('Error loading vouchers:', errVouchers);
            });
    }

    viewTicket = (folio, quantity, concept, amount, invoiced) => {
        this.setState({
            visibleTicketFolio: folio,
            visibleTicketQuantity: quantity,
            visibleTicketConcept: concept,
            visibleTicketAmount: amount,
            visibleTicketInvoiced: invoiced,
            visibleTicket: true
        });
    }

    closeTicket = () => {
        this.setState({
            visibleTicket: false
        });
    }

    editTicket = () => {
        this.loadData();
        this.closeTicket();
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#fff' }}>
                    <Spin spinning={this.state.loading} size="large">
                        <Card loading={false} bordered={false}>
                            <Table
                                title={ ()  => 'Lista de comprobantes' }
                                loading={this.state.loading}
                                columns={ticketsColumns} 
                                dataSource={this.state.tickets} 
                                size="large"
                                bordered={false}
                                locale={{ emptyText: 'No se encontraron comprobantes' }}
                                pagination={false}
                                style={{ marginBottom: 40 }}
                                onRow={ (record) => {
                                    return {
                                        onClick: () => {
                                            this.viewTicket(record.folio, record.quantity, record.concept, record.price, record.inv);
                                        }
                                    };
                                }}
                            />
                        </Card>

                        <EditableTicket 
                            visible={this.state.visibleTicket} 
                            onCancel={this.closeTicket}
                            onOk={this.editTicket}
                            folio={this.state.visibleTicketFolio}
                            quantity={this.state.visibleTicketQuantity}
                            concept={this.state.visibleTicketConcept}
                            amount={this.state.visibleTicketAmount}
                            invoiced={this.state.visibleTicketInvoiced}
                            pk={this.state.PK}
                            sk={this.state.SK}
                            storedVouchers={this.state.storedVouchers}
                            idToken={this.idToken}
                        />
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId : state.auth.companyId,
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(TicketsEditDetails);