import XLSX from 'sheetjs-style';

export const processDailyCardIncomeSheet = (dailyCardIncome) => {
    const sheet = XLSX.utils.aoa_to_sheet([]);
    XLSX.utils.sheet_add_aoa(sheet, [['Total', dailyCardIncome?.CardPaymentTotal ?? 0]], {origin:{r:1, c:1}});

    const processedData = dailyCardIncome.CardPayments.map(payment => {
        return {
            'Día': payment.day,
            'Rentas': payment.totalRents,
            'Extensiones de renta': payment.totalExtensions,
            'Huéspedes adicionales': payment.totalGuests,
            'Órdenes': payment.totalOrders,
            'Cargos': payment.totalCharges,
            'Reservaciones': payment.totalReservations,
            'Total': payment.dayTotal
        };
    });
    XLSX.utils.sheet_add_json(sheet, processedData, {origin:{r:3, c:0}});

    return sheet;
};