export const amplify = {
    'Email': 'Correo electrónico',
    'Password': 'Contraseña',
    'Sign in': 'Ingresar',
    'Reset your password': 'Reiniciar tu contraseña',
    'Enter your email': 'Ingresa tu correo electrónico',
    'Send code': 'Enviar código',
    'New password': 'Nueva contraseña',
    'Submit': 'Enviar',
    'Reset Password?': '¿Reiniciar contraseña?',
    'Sending': 'Enviando',
    'Signing in': 'Ingresando',
    'User does not exist.': 'El usuario no existe',
    'Incorrect username or password.': 'Nombre de usuario o contraseña incorrectos',
    'Username/client id combination not found.': 'No se encontró combinación de nombre de usuario e identificador de cliente'
}
