import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth, API } from 'aws-amplify';
import { Layout, Row, Table, Spin, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;

const actionsColumns = [{
    title: 'Fecha',
    dataIndex: 'timestamp'
}, {
    title: 'Acción',
    dataIndex: 'action'
}, {
    title: 'Usuario',
    dataIndex: 'user'
}, {
    title: 'Artículo',
    dataIndex: 'item'
}, {
    title: 'Cantidad',
    dataIndex: 'quantity'
}, {
    title: '',
    dataIndex: 'description'
}];


class InventoryActionsDetails extends Component {
    state = {
        loading: false,
        actions: []
    }

    idToken = '';


    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    loadData() {
        this.setState({loading: true});

        const id = this.props.match.params.id;
        const startDate = parseInt(this.props.match.params.startdate, 10);
        const endDate = parseInt(this.props.match.params.enddate, 10);

        API.get('benackoAPI', '/inventory-items/' + id, {//TODO: SPECIAL PROCEDURE NEEDED TO MIGRATE TO NEW BACKEND
            headers: {
                Authorization: this.idToken
            }
        })
        .then( async invit => {
            const inventoryId = invit[0].inventoryId;
            const itemId = invit[0].itemId;

            const actionRes = {
                inventoryId: inventoryId,
                itemId: itemId
            };

            return actionRes;
        })
        .then(actionRes => {
            API.get('benackoAPI', '/v2/inventory-actions?inventoryId=' + actionRes.inventoryId + '&startTimestamp=' + startDate + '&endTimestamp=' + endDate, {//TODO: SPECIAL PROCEDURE NEEDED TO MIGRATE TO NEW BACKEND
                    headers: {
                        Authorization: this.idToken
                    }
                })
                .then(inventoryActionsRes => {
                    var actions = [];

                    inventoryActionsRes.Items.forEach(action => {
                        const itemId = parseInt(actionRes.itemId, 10);
                        if (action.item.id === itemId) {
                            actions.push({
                                key: action.SK,
                                timestamp: new Date(action.SK).toLocaleString(),
                                action: action.action,
                                user: action.user,
                                item: action.item.name,
                                quantity: action.quantity,
                                description: action.description
                            });
                        }
                    });

                    this.setState({actions: actions, loadingActionsTable: false});
                })
                .catch(errInventoryActions => {
                    console.log('Error loading inventory actions:', errInventoryActions);
                    this.setState({loadingActionsTable: false});
                });
        })
        .catch( errItems => console.log('Error loading items: ', errItems))
        .finally(() => this.setState({loading: false}));
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    goToInventoriesHandler = () => {
        this.props.history.push('/app/inventories');
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                            <Button type="primary" onClick={this.goToInventoriesHandler}>
                                Ir a inventarios
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#fff' }}>
                    <Spin spinning={this.state.loading} size="large">
                    <Table
                        columns={actionsColumns}
                        dataSource={this.state.actions}
                        size="middle" 
                        pagination={false}
                        locale={{
                            emptyText: 'No se encontraron acciones para ese periodo'
                        }} 
                        loading={{
                            spinning: this.state.loading,
                            delay: 500,
                            size: 'large'
                        }}
                    />
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId : state.auth.companyId,
        hotels: state.auth.hotels,
        cid: state.base.cid
    };
};


export default connect(mapStateToProps, null)(InventoryActionsDetails);