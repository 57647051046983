import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, Button, Col, Input, Row, Select, Skeleton } from 'antd';

import SectionTitle from '../../../../components/UI/Panels/SectionTitle/SectionTitle';
import { errorNotification, successNotification } from '../../../../components/UI/Notifications/Notifications';

import RoomService from '../../../../services/rooms';
import { disableHotelSelector, enableHotelSelector } from '../../../../store/actions';
// TEMP
import { API } from 'aws-amplify';


const RoomsCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    const [name, setName] = useState('');

    const [canSubmit, setCanSubmit] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const cid = useSelector(state => state.base.cid ?? null);
    const hid = useSelector(state => state.ui.selectedHotel ?? 0);

    useEffect(() => {
        dispatch(enableHotelSelector());

        return () => { dispatch(disableHotelSelector()) };
    }, []);

    useEffect(() => {
        clearAllFields();
        setLoading(false);
    }, [cid]);

    useEffect(() => {
        if (name != null && name.length >= 1 && hid != null && hid > 0) {
                setCanSubmit(true);
            } else {
                setCanSubmit(false);
            }

    }, [hid, name]);


    const clearAllFields = () => {
        setName('');
    };


    const create = () => {
        setSubmitted(true);
        const beautifiedName = name.trim();

        API.post('benackoAPI', '/rooms', {//TODO: REMOVE OLD REFERENCE
            body: {
                name: beautifiedName,
                roomTypeId: 1,
                hotelId: hid
            }
        })
        .catch(err => console.log('Error creating room in old backend:', err));
        
        RoomService.createRoom(cid, hid, beautifiedName)
            .then(() => {
                successNotification('La habitación fue creada exitosamente');
                history.goBack();
            })
            .catch(err => {
                errorNotification(err);
                setSubmitted(false);
            });
    };

    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 10}}>
                <Col span={23}>
                    <Row style={{marginLeft: 40, width: '100%', height: 40}}>
                        <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <NavLink to={'/app/rooms'}>{'Habitaciones'}</NavLink>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {'Crear habitación'}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <SectionTitle title={'Crear habitación'} />

            <Skeleton loading={loading} paragraph={{rows: 9, cols: 1}} active>
                <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                    <Row style={{width: '100%', height: 60}}>
                        <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Nombre de la habitación</span></Col>
                        <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                            <Input type="text" maxLength={200} onChange={(e) => setName(e.target.value)} disabled={submitted}/></span>
                        </Col>
                    </Row>

                    <Row style={{width: '100%', height: 120}}>
                        <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                            <Button type="primary" style={{marginLeft: 40, width: '50%'}} disabled={!canSubmit || submitted} onClick={create}>Crear</Button>
                        </Col>
                    </Row>
                </Row>
            </Skeleton>
        </Row>
    );
};

export default RoomsCreate;
