import * as actionTypes from './actionTypes';


export const switchHotel = (hotelId) => {
    return {
        type: actionTypes.SWITCH_HOTEL,
        selectedHotel: hotelId
    };
};

export const enableHotelSelector = () => {
    return {
        type: actionTypes.ENABLE_HOTEL_SELECTOR
    };
};

export const disableHotelSelector = () => {
    return {
        type: actionTypes.DISABLE_HOTEL_SELECTOR
    }
};

export const clearUiData = () => {
    return dispatch => {
        return new Promise((resolve) => {
            dispatch(clearUiDataComplete());
            resolve();
        });
    };
};

export const clearUiDataComplete = () => {
    return {
        type: actionTypes.CLEAR_UI_DATA
    }
};
