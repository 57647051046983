import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { Button, Col, Descriptions, Table, Layout, Row, Select, Steps, Spin } from 'antd';


const { Header, Content } = Layout;
const ButtonGroup = Button.Group;
const Option = Select.Option;
const Step = Steps.Step;

const columns = [
    {
        title: 'Fecha',
        dataIndex: 'timestamp'
    },
    {
        title: 'Tipo',
        dataIndex: 'sbType'
    },
    {
        title: 'MXN',
        dataIndex: 'mxn'
    },
    {
        title: 'USD',
        dataIndex: 'usd'
    },
    {
        title: 'Tarjeta',
        dataIndex: 'card'
    },
    {
        title: 'Total',
        dataIndex: 'total'
    },
    {
        title: 'Depósitos MXN',
        dataIndex: 'depositsMxn'
    },
    {
        title: 'Depósitos USD',
        dataIndex: 'depositsUsd'
    },
    {
        title: 'Diferencia',
        dataIndex: 'difference'
    }
];


class DepositList extends Component {
    state = {
        loading: false,
        currentStep: 0,
        hotelsOptions: [],
        selectedHotel: [],
        dateOptions: [],
        fromDate: null,
        toDate: null,
        sbTimestamps: new Map(),
        sbTypes: new Map(),
        depositsData: [],
        depositsTotals: [],
        mxnTotal: 0,
        usdTotal: 0,
        cardTotal: 0,
        amountTotal: 0,
        depositsMxnTotal: 0,
        depositsUsdTotal: 0,
        differenceTotal: 0
    }

    idToken = '';
    userHotels = [];


    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    loadData() {
        this.setState({loading: true});
        this.setHotelsMenu();

        // DAYS
        const today = new Date();
        const upperLimit = this.getUpperDateLimit(today);
        
        var days = [];
        days.push(<Option key={upperLimit}>{today.toDateString()}</Option>);

        var dayDecrement = today;
        for (var i = 0; i < 60; i++) {
            dayDecrement.setDate(dayDecrement.getDate() - 1);
            days.push(<Option key={dayDecrement.getTime()}>{dayDecrement.toDateString()}</Option>);
        }
        this.setState({dateOptions: days, loading: false});
    }

    setHotelsMenu() {
        const enabledHotels = this.props.hotels;
        var hotels = [];

        for (var i in enabledHotels) {
            hotels.push(<Option key={i}>{enabledHotels[i]}</Option>);
        }

        this.setState({hotelsOptions: hotels});
    }

    getUpperDateLimit(date) {
        var tomorrow = date;
        tomorrow.setHours(23);
        tomorrow.setMinutes(59);
        tomorrow.setSeconds(59);
        tomorrow.setMilliseconds(999);
        
        return tomorrow.getTime();
    }

    selectHotelHandler = (value) => {
        this.setState({selectedHotel: value});
    }

    selectFromDateHandler = (value) => {
        this.setState({fromDate: parseInt(value, 10)});
    }

    selectToDateHandler = (value) => {
        this.setState({toDate: parseInt(value, 10)});
    }

    goToSecondStep = async () => {
        this.setState({ loading: true });

        const previousDay = new Date();
        previousDay.setTime(this.state.fromDate);
        previousDay.setDate(previousDay.getDate() - 1);
        //const endTimestamp = this.state.toDate;
        const startTimestamp = previousDay.getTime();

        const endOfDay = new Date();
        endOfDay.setTime(this.state.fromDate);
        endOfDay.setDate(endOfDay.getDate() + 1);
        const endTimestamp = endOfDay.getTime();

        const pathString = '/shift-balances?hotelId=' + this.state.selectedHotel + '&startTimestamp=' + startTimestamp + '&endTimestamp=' + endTimestamp;
        await API.get('benackoAPI', pathString, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( async sbRes => {
                var ids = [];
                var sbTimestamps = {};
                var sbTypes = {};

                sbRes.forEach( sb => {
                    ids.push(sb.id);
                    sbTimestamps[sb.id] = sb.startTimestamp;
                    sbTypes[sb.id] = sb.type;
                });

                this.setState({
                    sbTimestamps: sbTimestamps,
                    sbTypes: sbTypes
                });

                return ids;
            })
            .then( ids => {

                var depositsData = [];
                var depositsTotals = [];

                ids.forEach( async sbId => {
                    await API.get('benackoAPI', '/reports/shift-balances/totals?companyId=' + this.props.companyId + '&hotelId=' + this.state.selectedHotel + '&shiftBalanceId=' + sbId, {
                            headers: {
                                Authorization: this.idToken
                            }
                        })
                        .then( totalsRes => {
                            var totals = totalsRes.Items[0];
                            
                            var sbType = this.state.sbTypes[sbId];
                            var sbTypeLabel = (sbType === 1 || sbType === 3) ? 'Recepción' : 'Cocina';

                            depositsData.push({
                                key: sbId,
                                timestamp: new Date(this.state.sbTimestamps[sbId]).toLocaleString(),
                                sbType: sbTypeLabel,
                                mxn: '$' + totals.shiftBalanceTotals.sbTotalMxn,
                                usd: '$' + totals.shiftBalanceTotals.sbTotalUsd,
                                card: '$' + totals.shiftBalanceTotals.sbTotalCard,
                                total: '$' + totals.shiftBalanceTotals.sbAbsTotal,
                                depositsMxn: '$' + (totals.depositTotals.depositTotalMxn + totals.depositTotals.depositTotalTickets),
                                depositsUsd: '$' + totals.depositTotals.depositTotalUsd,
                                difference: '$' + totals.shiftBalanceTotals.sbDifference
                            });

                            depositsTotals.push({
                                key: sbId,
                                mxn: totals.shiftBalanceTotals.sbTotalMxn,
                                usd: totals.shiftBalanceTotals.sbTotalUsd,
                                card: totals.shiftBalanceTotals.sbTotalCard,
                                total: totals.shiftBalanceTotals.sbAbsTotal,
                                depositsMxn: (totals.depositTotals.depositTotalMxn + totals.depositTotals.depositTotalTickets),
                                depositsUsd: totals.depositTotals.depositTotalUsd,
                                difference: totals.shiftBalanceTotals.sbDifference
                            });
                        })
                        .catch( errTotals => {
                            console.log('Error loading totals:', errTotals);
                            this.setState({loading: false});
                        });
                });

                this.setState({
                    depositsData: depositsData,
                    depositsTotals: depositsTotals,
                    currentStep: 1,
                    loading: false
                });
            })
            .catch( errSbDates => {
                console.log('Error loading shift balance dates:', errSbDates);
                this.setState({loading: false});
            });
    }

    goPreviousStep = () => {
        const newStep = this.state.currentStep - 1;
        this.setState({
            mxnTotal: 0,
            usdTotal: 0,
            cardTotal: 0,
            amountTotal: 0,
            depositsMxnTotal: 0,
            depositsUsdTotal: 0,
            differenceTotal: 0,
            currentStep: newStep,
            fromDate: null,
            toDate: null
        });
    }

    updateState = () => {
        var sbMxnTotal = 0;
        var sbUsdTotal = 0;
        var sbCardTotal = 0;
        var sbAbsTotal = 0;
        var depositsMxnTotal = 0;
        var depositsUsdTotal = 0;
        var sbDepositDifference = 0;

        this.state.depositsTotals.forEach( row => {
            sbMxnTotal += row.mxn;
            sbUsdTotal += row.usd;
            sbCardTotal += row.card;
            sbAbsTotal += row.total;
            depositsMxnTotal += row.depositsMxn;
            depositsUsdTotal += row.depositsUsd;
            sbDepositDifference += row.difference;
        });

        this.setState({
            mxnTotal: sbMxnTotal,
            usdTotal: sbUsdTotal,
            cardTotal: sbCardTotal,
            amountTotal: sbAbsTotal,
            depositsMxnTotal: depositsMxnTotal,
            depositsUsdTotal: depositsUsdTotal,
            differenceTotal: sbDepositDifference
        });
    }

    render() {
        const stepOne = (
            <Spin spinning={this.state.loading} size="large">
                <Row style={{width: 600, marginTop: 10}}>
                    <Select
                        style={{width: '100%'}}
                        placeholder="Selecciona el hotel"
                        onChange={this.selectHotelHandler}
                    >
                        {this.state.hotelsOptions}
                    </Select>
                </Row>
                <Row style={{width: 600, marginTop: 20}}>
                    <Select
                        style={{width: '100%'}}
                        placeholder="Selecciona la fecha"
                        onChange={this.selectFromDateHandler}
                    >
                        {this.state.dateOptions}
                    </Select>
                </Row>
                <Row style={{ marginTop: 50 }}>
                    <Button 
                        type="primary" 
                        onClick={this.goToSecondStep} 
                        disabled={(this.state.selectedHotel.length <= 0 || (this.state.fromDate === null)) ? true : false}
                    >
                        Siguiente
                    </Button>
                </Row>
            </Spin>
        );

        const stepTwo = (
            <Spin spinning={this.state.loading} size="large">
                <Row>
                    <Table
                        loading={this.state.loading}
                        columns={columns} 
                        dataSource={this.state.depositsData} 
                        size="small"
                        bordered={false}
                        locale={{ emptyText: 'No se encontraron depósitos' }}
                        pagination={false}
                    />
                </Row>
                <Row style={{ marginTop: 30 }}>
                    <Descriptions title="Totales">
                        <Descriptions.Item label="Total en pesos">${this.state.mxnTotal}</Descriptions.Item>
                        <Descriptions.Item label="Total en dólares">${this.state.usdTotal}</Descriptions.Item>
                        <Descriptions.Item label="Total en tarjeta">${this.state.cardTotal}</Descriptions.Item>
                        <Descriptions.Item label="Total vendido">${this.state.amountTotal}</Descriptions.Item>
                        <Descriptions.Item label="Total depósitos en pesos">$ {this.state.depositsMxnTotal}</Descriptions.Item>
                        <Descriptions.Item label="Total depósitos en dólares">$ {this.state.depositsUsdTotal}</Descriptions.Item>
                        <Descriptions.Item label="Diferencia">${this.state.differenceTotal}</Descriptions.Item>
                    </Descriptions>
                </Row>
                <Row style={{ marginTop: 40 }}>
                    <ButtonGroup>
                        <Button onClick={this.updateState}>Cargar reporte</Button>
                        <Button type="primary" onClick={this.goPreviousStep}>Regresar</Button>
                    </ButtonGroup>
                </Row>
            </Spin>
        );

        let renderedStep = null;
        if (this.state.currentStep === 0) {
            renderedStep = stepOne;
        }

        if (this.state.currentStep === 1) {
            renderedStep = stepTwo;
        }

        return (
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#fff', paddingTop: 15 }}>
                    <Steps size="small" current={this.state.currentStep}>
                        <Step title="Seleccionar hotel y fechas" />
                        <Step title="Lista de depósitos" />
                    </Steps>
                    <Row style={{marginTop: 50}}>
                        <Spin spinning={this.state.loading} size="large">
                        <Col span={20} offset={2}>
                            {renderedStep}
                        </Col>
                        </Spin>
                    </Row>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.auth.companyId,
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(DepositList);