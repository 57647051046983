import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Layout, List } from 'antd'


const { Content } = Layout;

const data = [
    /*{
        title: 'Ver comprobantes',
        path: '/app/vouchers/view',
        description: 'Lista de comprobantes'
    },*/
    {
        title: 'Resumen',
        path: '/app/vouchers/summary',
        description: 'Resumen de comprobantes'
    },
    {
        title: 'Generar comprobantes',
        path: '/app/vouchers/tk_generate',
        description: 'Generación de comprobantes'
    },
    {
        title: 'Generar notas de crédito',
        path: '/app/vouchers/nt_generate',
        description: 'Generación de notas de crédito'
    },
    {
        title: 'Editar comprobantes',
        path: '/app/vouchers/edit',
        description: 'Editar comprobantes por fecha'
    },
    {
        title: 'Folios',
        path: '/app/vouchers/folios',
        description: 'Folios de los comprobantes'
    }
];


class Vouchers extends Component {

    render() {
        return(
            <Layout>
                <Content style={{margin: 0, padding: 0, background: '#fff', paddingLeft: 30}}>
                    <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={item => (
                            <NavLink to={item.path}>
                                <List.Item>
                                    <List.Item.Meta
                                    title={item.title}
                                    description={item.description}
                                    />
                                </List.Item>
                            </NavLink>
                        )}
                    />
                </Content>
            </Layout>
        );
    }
}

export default Vouchers;