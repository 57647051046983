import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { Button, Layout, Row, Card } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group


class Detail extends Component {
    state = {
        loading: true,
        modalVisible: false,
        modalConfirmLoading: false,
        rateHotel: '',
        rateCurrency: '',
        rateRate: null,
        rateLastEditDate: null
    }

    idToken = '';

    loadData() {
        this.setState({loading: true});
        const id = this.props.match.params.id;

        API.get('benackoAPI', '/exchange-rates/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( async response => {
                const hotel = await this.getHotelName(response[0].hotelId);
                const currency = response[0].currency;
                const rate = response[0].rate.toFixed(2);
                const lastEditDate = new Date(response[0].updated).toLocaleString();

                this.setState({
                    loading: false,
                    rateHotel: hotel,
                    rateCurrency: 'MXN -> ' + currency,
                    rateRate: rate,
                    rateLastEditDate: lastEditDate
                });
            })
            .catch( errExchangeRate => {
                console.log('Error loading exchange rate:', errExchangeRate);

                this.setState({ loading: false });
            });
    }

    async getHotelName(hotelId) {
        let hotel = await API.get('benackoAPI', '/hotels/' + hotelId, { headers: { Authorization: this.idToken } });
        let hotelName = hotel[0].name;

        return hotelName;
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    goEditHandler = () => {
        this.props.history.push('/app/payments/currencies/details/' + this.props.match.params.id + '/edit')
    }

    render() {
        return(
            <Layout>
                <Header style={{background: '#fff', padding: 0, paddingLeft: 20}}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                            <Button type="primary" onClick={this.goEditHandler}>
                                Ajustar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{margin: 0, padding: 0, paddingLeft: 20, background: '#ffff', paddingTop: 15}}>
                    <Card loading={this.state.loading} bordered={false} title={this.state.rateHotel}>
                        <p><strong>Moneda:</strong> {this.state.rateCurrency}</p>
                        <p><strong>Tasa: $</strong> {this.state.rateRate}</p>
                        <p><strong>Última actualización:</strong> {this.state.rateLastEditDate}</p>
                    </Card>
                </Content>
            </Layout>
        );
    }
}


export default Detail;