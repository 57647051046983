import { Auth, API } from 'aws-amplify';

class HotelService {

    static async getHotels(cid) {
        const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});
        
        return API.get('benackoAPI', '/hotels', {headers: {Authorization: idToken}})
            .then(response => {
                if (response == null) {
                    throw Error(response ?? 'Invalid response from server');
                } else {
                    const processedResponse = response.filter(hotel => hotel.companyId === cid)
                    return processedResponse;
                }
            })
            .catch(err => {throw Error(err);});
    }

    static async getHotel(hid) {
        const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});
        
        return API.get('benackoAPI', '/hotels/' + hid, {headers: {Authorization: idToken}})
            .then(response => {
                if (response == null) {
                    throw Error(response ?? 'Invalid response from server');
                } else {
                    return response[0] ?? {};
                }
            })
            .catch(err => {throw Error(err);});
    }
};

export default HotelService;