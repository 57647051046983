import React from 'react';
import { useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import { Button, Tooltip } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

import { errorNotification } from '../../Notifications/Notifications';

import { clearAuthUserData, clearUiData } from '../../../../store/actions';


const Logout = () => {
    const dispatch = useDispatch();

    const triggerLogout = () => {
        Auth.signOut()
            .then(() => dispatch(clearAuthUserData()))
            .then(() => dispatch(clearUiData()))
            .catch(err => errorNotification(err));
    };

    return (
        <Tooltip placement="bottom" title={'Salir'} mouseEnterDelay={0.5}>
            <Button
                shape="round"
                icon={<LogoutOutlined/>}
                onClick={triggerLogout}
            />
        </Tooltip>
    );
}

export default Logout;