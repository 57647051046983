import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth, API } from 'aws-amplify';
import { Layout, Row, Table, Spin, Card, Button, Tag } from 'antd';
import { LeftOutlined, PrinterOutlined } from '@ant-design/icons';

import PrintableTicket from '../../../components/UI/Modals/PrintableTicket/PrintableTicket';
import PrintAllTickets from '../../../components/UI/Modals/PrintAllTickets/PrintAllTickets';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;

const ticketsColumns = [{
    title: 'Fecha comprobante',
    dataIndex: 'date'
}, {
    title: 'Folio',
    dataIndex: 'folio'
}, {
    title: 'Cantidad',
    dataIndex: 'quantity'
}, {
    title: 'Concepto',
    dataIndex: 'concept'
}, {
    title: 'Precio',
    dataIndex: 'price'
}, {
    title: '',
    dataIndex: 'invoiced'
}, {
    title: '',
    dataIndex: 'edited'
}];


class VouchersViewDetails extends Component {
    state = {
        loading: false,
        hotel: {},
        tickets: [],
        creditNotes: [],
        processedTicks: [],
        visibleTicket: false,
        visibleTicketDate: '',
        visibleTicketQuantity: 0,
        visibleTicketAmount: 1,
        visibleTicketConcept: '',
        visibleTicketFolio: '',
        visibleVoucherType: '',
        visibleTicketInvoiced: false,
        visiblePrintAllTickets: false
    }

    idToken = '';


    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    loadData() {
        this.setState({loading: true});

        const hotelId = this.props.match.params.hotelid;
        const startDate = parseInt(this.props.match.params.startdate, 10);
        const endDate = parseInt(this.props.match.params.enddate, 10);

        const tkQueryString = 'hotelId=' + hotelId + '&type=tk&timestamp=' + startDate + '&rangeTimestamp=' + endDate;
        API.get('benackoAPI', '/vouchers?' + tkQueryString, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then(ticketsRes => {
                var preTickets = [];

                if (ticketsRes.Items.length > 0) {
                    const fullTickets = ticketsRes.Items;
                    fullTickets.sort((a, b) => {return a.SK > b.SK});

                    fullTickets.forEach(ticketDate => {
                        const processedTickets = ticketDate.vouchers;

                        if (processedTickets.length > 0) {
                            processedTickets.forEach(tick => {
                                preTickets.push({
                                    key: tick.folio,
                                    date: '' + this.getVoucherDate(ticketDate.SK),
                                    folio: tick.folio,
                                    quantity: tick.quantity,
                                    concept: tick.concept,
                                    price: tick.price,
                                    invoiced: (tick.invoiced) ? <Tag color="geekblue">Facturado</Tag> : '',
                                    edited: (tick.edited) ? <Tag color="red">Editado</Tag> : '',
                                    inv: (tick.invoiced !== true) ? false : true,
                                    type: 'tk'
                                });
                            });
                        }
                    });
                }

                this.setState({tickets: preTickets});
            })
            .catch(errTicks => {
                console.log('Error loading tickets:', errTicks);
            });

        const cnQueryString = 'hotelId=' + hotelId + '&type=nt&timestamp=' + startDate + '&rangeTimestamp=' + endDate;
        API.get('benackoAPI', '/vouchers?' + cnQueryString, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then(notesRes => {
                var preNotes = [];

                if (notesRes.Items.length > 0) {
                    const fullNotes = notesRes.Items;
                    fullNotes.sort((a, b) => {return a.SK > b.SK});

                    fullNotes.forEach(noteDate => {
                        const processedNotes = noteDate.vouchers;

                        if (processedNotes.length > 0) {
                            processedNotes.forEach(note => {
                                preNotes.push({
                                    key: note.folio,
                                    date: '' + this.getVoucherDate(noteDate.SK),
                                    folio: note.folio,
                                    quantity: note.quantity,
                                    concept: note.concept,
                                    price: note.price,
                                    invoiced: (note.invoiced) ? <Tag color="geekblue">Facturado</Tag> : '',
                                    edited: (note.edited) ? <Tag color="red">Editado</Tag> : '',
                                    inv: (note.invoiced !== true) ? false : true,
                                    type: 'nt'
                                });
                            });
                        }
                    });
                }

                this.setState({creditNotes: preNotes});
            })
            .catch(errNotes => {
                console.log('Error loading notes:', errNotes);
            });

        API.get('benackoAPI', '/hotels/' + hotelId, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then(hotelRes => {
                const hotelDetails = hotelRes[0];

                const hotel = {
                    legalName: hotelDetails.legalName,
                    address: hotelDetails.address,
                    rfc: hotelDetails.rfc,
                    city: hotelDetails.city,
                    state: hotelDetails.state,
                    country: hotelDetails.country,
                    zipCode: hotelDetails.zipCode,
                    phoneNumber: hotelDetails.phoneNumber
                };

                this.setState({hotel: hotel, loading: false});
            })
            .catch(errHotel => {
                console.log('Error loading hotel:', errHotel);
            });
    }

    getVoucherDate(vouchersDate) {
        const vouchersYear = vouchersDate.toString().substring(0, 4);
        const vouchersMonth = vouchersDate.toString().substring(4, 6);
        const vouchersDay = vouchersDate.toString().substring(6, 8);
        const vouchersDatum = new Date(vouchersYear, (vouchersMonth - 1), vouchersDay).toDateString();

        return vouchersDatum;
    }

    viewTicket = (ticketDate, ticketQuantity, ticketAmount, ticketConcept, ticketFolio, ticketInvoiced, voucherType) => {
        this.setState({
            visibleTicketDate: ticketDate,
            visibleTicketQuantity: ticketQuantity,
            visibleTicketAmount: ticketAmount,
            visibleTicketConcept: ticketConcept,
            visibleTicketFolio: ticketFolio,
            visibleTicketInvoiced: ticketInvoiced,
            visibleVoucherType: voucherType,
            visibleTicket: true
        });
    }

    closeTicket = () => {
        this.setState({
            visibleTicket: false
        });
    }

    viewAllPrintableTickets = () => {
        const tickets = this.state.tickets;
        //const creditNotes = this.state.creditNotes;

        //const allVouchers = tickets.concat(creditNotes);
        var preProcessedTicks = [];

        tickets.forEach(voucher => {
            preProcessedTicks.push(
                <div key={voucher.folio} style={{ marginBottom: 30 }}>
                    {this.state.hotel.legalName}
                    <br />
                    RFC: {this.state.hotel.rfc}
                    <br />
                    {this.state.hotel.address}
                    <br />
                    {this.state.hotel.city}, {this.state.hotel.state}, {this.state.hotel.country}
                    <br />
                    C.P.: {this.state.hotel.zipCode}
                    <br />
                    Tel: {this.state.hotel.phoneNumber}
                    <hr />
                    {
                        (voucher.type === 'nt') &&
                            <p>NOTA DE CRÉDITO</p>
                    }
                    Fecha: {voucher.date}
                    <br />
                    Cantidad: {
                        voucher.quantity !== 0 &&
                            voucher.quantity
                    }
                    <br />Concepto: {voucher.concept}
                    <br />
                    Monto: ${ 
                        voucher.price !== 0 &&
                            voucher.price
                    }
                    <br />
                    Total: ${voucher.quantity * voucher.price}
                    <hr />
                    Folio {voucher.folio}
                    <br />
                    COMPROBANTE SIMPLIFICADO NO DEDUCIBLE PARA EFECTOS FISCALES
                    < br/><br />
                    ORIGINAL
                    <br /><br />
                    {
                        (voucher.inv === true) &&
                            <p>FACTURADO</p>
                    }
                </div>
            );
        });

        this.setState({
            visiblePrintAllTickets: true,
            processedTicks: preProcessedTicks
        });
    }

    closeAllPrintableTickets = () => {
        this.setState({
            visiblePrintAllTickets: false,
            processedTicks: []
        });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                            <Button type="primary" onClick={this.viewAllPrintableTickets}>
                                <PrinterOutlined />Imprimir comprobantes
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#fff' }}>
                    <Spin spinning={this.state.loading} size="large">
                        <Card loading={false} bordered={false}>
                            <Table
                                title={ ()  => 'Lista de comprobantes' }
                                loading={this.state.loading}
                                columns={ticketsColumns} 
                                dataSource={this.state.tickets} 
                                size="large"
                                bordered={false}
                                locale={{ emptyText: 'No se encontraron comprobantes' }}
                                pagination={false}
                                style={{ marginBottom: 40 }}
                                onRow={ (record) => {
                                    return {
                                        onClick: () => {
                                            this.viewTicket(record.date, record.quantity, record.price,record.concept, record.folio, record.inv);
                                        }
                                    };
                                }}
                            />

                            <Table
                                title={ ()  => 'Lista de notas de crédito' }
                                loading={this.state.loading}
                                columns={ticketsColumns} 
                                dataSource={this.state.creditNotes} 
                                size="large"
                                bordered={false}
                                locale={{ emptyText: 'No se encontraron notas de crédito' }}
                                pagination={false}
                                style={{ marginBottom: 40 }}
                                onRow={ (record) => {
                                    return {
                                        onClick: () => {
                                            this.viewTicket(record.date, record.quantity, record.price,record.concept, record.folio, record.inv, record.type);
                                        }
                                    };
                                }}
                            />
                        </Card>

                        <PrintableTicket 
                            visible={this.state.visibleTicket} 
                            onCancel={this.closeTicket} 
                            hotel={this.state.hotel}
                            rentPeriod={this.state.visibleTicketQuantity}
                            rentRate={this.state.visibleTicketAmount}
                            rentTotalDays={14}
                            rentDate={this.state.visibleTicketDate}
                            ticketConcept={this.state.visibleTicketConcept}
                            ticketFolio={this.state.visibleTicketFolio}
                            rentInvoice={this.state.visibleTicketInvoiced}
                            voucherType={this.state.visibleVoucherType}
                        />

                        <PrintAllTickets
                            visible={this.state.visiblePrintAllTickets}
                            onCancel={this.closeAllPrintableTickets}
                            hotel={this.state.hotel}
                            processedTicks={this.state.processedTicks}
                            tickets={this.state.tickets}
                            creditNotes={this.state.creditNotes}
                        />
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId : state.auth.companyId,
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(VouchersViewDetails);