import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Row, Col, Input, Table, Space, Radio } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import ReloadButton from '../../../components/UI/Buttons/ReloadButton/ReloadButton';
import CreateButton from '../../../components/UI/Buttons/CreateButton/CreateButton';
import { errorNotification } from '../../../components/UI/Notifications/Notifications';

import UserService from '../../../services/users';
import { disableHotelSelector, enableHotelSelector } from '../../../store/actions';
import Roles from '../../../common/roles';


const UsersList = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const cid = useSelector(state => state.auth.companyId ?? 0);
    const hid = useSelector(state => state.ui.selectedHotel ?? 0);

    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [userType, setUserType] = useState('ad');

    const userColumns = [
        {title: 'Nombre de usuario', dataIndex: 'username', defaultSortOrder: 'ascend', sorter: (a,b) => a.username.localeCompare(b.username)},
        {title: 'Rol', dataIndex: 'roleId', render: (r) => Roles[r].name}
    ];


    useEffect(() => {
        dispatch(enableHotelSelector());

        return () => { dispatch(disableHotelSelector()) };
    }, []);

    useEffect(() => {
        if (hid !== 0 && userType != null) {
            getUsers();
        }
    }, [hid, userType]);

    useEffect(() => {
        if (users != null && users.length > 0) {
            clearCurrentUsers();
            setFilteredUsers(users);
        }
    }, [users]);

    useEffect(() => {
        if (inputValue !== null && inputValue !== undefined && inputValue !== '') {console.log(users);
            const newUsers = inputValue ? users.filter(i => (i.username.toLowerCase().indexOf(inputValue) > -1)) : users;
            setFilteredUsers(newUsers);
        } else {
            setFilteredUsers(users);
        }
    }, [inputValue]);


    const getUsers = async () => {
        setLoading(true);
        clearCurrentUsers();

        if (userType === 'op') {
            UserService.getOpUsers(hid)
                .then(res => {
                    const processedRes = res.filter(obj => obj.enabled === 1);
                    setUsers(processedRes);
                    setLoading(false);
                })
                .catch(err => {
                    errorNotification(err);
                    setLoading(false);
                });
        } else {
            const userHotels = await UserService.getUserHotelsByHid(hid)
                .then(res => { return res; })
                .catch(err => {
                    errorNotification(err);
                    setLoading(false);
                });

            UserService.getAdUsers(cid)
                .then(res => {
                    let processedUsers = [];

                    const processedRes = res.filter(obj => obj.roleId > 3 && obj.roleId !== 15 && obj.roleId !== 18 && obj.enabled === 1);
                    processedRes.forEach(user => {
                        const userId = user.id ?? -1;
                        const inHotel = userHotels.find(uh => {return uh.userId === userId});
                        
                        if (inHotel != null) {
                            processedUsers.push(user);
                        }
                    });

                    setUsers(processedUsers);
                    setLoading(false);
                })
                .catch(err => {
                    errorNotification(err);
                    setLoading(false);
                });
        }
    };

    const clearCurrentUsers = () => {
        setFilteredUsers([]);
        setInputValue('');
    };

    const onChangeText = (text) => {
        setInputValue(text);
    };

    const goToCreateUser = () => {
        history.push('/app/users/create?type=' + userType);
    };
    const goToUserDetails = (id) => {
        history.push('/app/users/details/' + id + '?type=' + userType);
    };

    return (
        <Row style={{display: 'flex', flexFlow: 'column', width: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 40}}>
                <Col span={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Input
                        size="middle"
                        prefix={<SearchOutlined />}
                        placeholder={'Buscar'}
                        allowClear={true}
                        value={inputValue}
                        onChange={(ev) => onChangeText(ev.target.value)}
                    />
                </Col>
                <Col offset={2} span={10} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Space>
                        <Radio.Group defaultValue='ad' buttonStyle='solid' onChange={(t => setUserType(t.target.value))}>
                            <Radio.Button value='ad'>Administrativos</Radio.Button>
                            <Radio.Button value='op'>Operativos</Radio.Button>
                        </Radio.Group>
                        <CreateButton onClick={goToCreateUser} loading={loading} />
                        <ReloadButton onClick={getUsers} loading={loading} />
                    </Space>
                </Col>
            </Row>

            <Row style={{width: '100%', height: '100%', flexGrow: 1, marginBottom: 20}}>
                <Col span={24}>
                    <Table
                        style={{cursor: 'pointer'}}
                        size={'small'}
                        bordered={true}
                        loading={loading}
                        columns={userColumns}
                        dataSource={filteredUsers}
                        pagination={false}
                        rowKey={'id'}
                        scroll={{y: '100%'}}
                        locale={{
                            emptyText: 'No se encontraron datos',
                            cancelSort: 'Haz clic para cancelar la ordenación',
                            triggerAsc: 'Haz clic para ordenar ascendentemente',
                            triggerDesc: 'Haz clic para ordenar descendentemente'
                        }}
                        onRow={(row) => ({
                            onClick: () => goToUserDetails(row.id)
                        })}
                    />
                </Col>
            </Row>
        </Row>
    );
};

export default UsersList;