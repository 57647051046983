import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Input, Row, Select, Skeleton } from 'antd';

import { errorNotification, successNotification } from '../../../../components/UI/Notifications/Notifications';
import SectionTitle from '../../../../components/UI/Panels/SectionTitle/SectionTitle';

import MaintenanceService from '../../../../services/maintenance';
import TextArea from 'antd/lib/input/TextArea';
import { disableHotelSelector, enableHotelSelector } from '../../../../store/actions';

const Option = Select.Option;

const AddWorkOrder = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const hid = useSelector(state => state.ui.selectedHotel ?? 0);
    const userId = useSelector(state => state.auth.user?.id ?? 0);
    const username = useSelector(state => state.auth.user?.username ?? '');

    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [newName, setNewName] = useState('');
    const [roomOptions, setRoomOptions] = useState([]);
    const [roomId, setRoomId] = useState(0);
    const [roomName, setRoomName] = useState(null);
    const [notes, setNotes] = useState('');

    useEffect(() => {
        dispatch(enableHotelSelector());

        return () => {
            dispatch(disableHotelSelector());
        };
    }, []);

    useEffect(() => {
        if (hid != null) {
            loadData();
        }
    }, [hid]);

    const create = () => {
        setLoading(true);
        
        if (!submitted) {
            setSubmitted(true);

            if (newName != null && newName.length != 0) {
                MaintenanceService.addWorkOrder(hid, newName, roomId, roomName, notes, userId, username)
                    .then(() => successNotification('La orden de trabajo ha sido creada exitosamente'))
                    .catch(err => errorNotification(err))
                    .finally(() => {
                        setSubmitted(false);
                        history.go(-1);
                    });
            }
        }
    };

    const loadData = async () => {
        setLoading(true);

        await MaintenanceService.getRooms(hid)
            .then(res => {
                res.sort((a, b) => {return parseInt(a.name) - parseInt(b.name);});

                let processedRooms = [];
                processedRooms.push(<Option key={0}>Hotel</Option>);

                res.forEach(room => {
                    processedRooms.push(<Option key={room.id}>{room.name}</Option>);
                });

                setRoomOptions(processedRooms);
            })
            .catch(err => errorNotification(err))
            .finally(() => setLoading(false));
    };

    return (
        <Skeleton loading={loading} paragraph={{rows: 9, cols: 1}} active>
            <SectionTitle title='Crear orden de trabajo'/>

            <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                <Row style={{width: '100%', height: 60}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Nombre</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input type="text" maxLength={200} onChange={(e) => setNewName(e.target.value)} disabled={submitted}/></span>
                    </Col>
                </Row>
                <Row style={{width: '100%', height: 60, marginTop: 20}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Habitación</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Select style={{width: 400}} defaultValue={'Hotel'} onChange={(r,e) => {
                            setRoomId(r ?? 0);
                            setRoomName(e.children ?? null);
                        }} disabled={submitted}>{roomOptions}</Select></span>
                    </Col>
                </Row>
                <Row style={{width: '100%', height: 60, marginTop: 20}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Notas</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <TextArea showCount maxLength={5000} onChange={(e) => setNotes(e.target.value)} disabled={submitted}/></span>
                    </Col>
                </Row>

                <Row style={{width: '100%', height: 60, marginTop: 40}}>
                    <Button type='primary' style={{marginLeft: 40}} onClick={() => create()} disabled={submitted || newName == null || newName.length <= 0}>Crear orden de trabajo</Button>
                </Row>
            </Row>
        </Skeleton>
    );
};

export default AddWorkOrder;