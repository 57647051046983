import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Breadcrumb, Button, Col, Input, Row, Select, Skeleton } from 'antd';

import SectionTitle from '../../../components/UI/Panels/SectionTitle/SectionTitle';
import { errorNotification, successNotification } from '../../../components/UI/Notifications/Notifications';

import HotelService from '../../../services/hotels';
import UserService from '../../../services/users';
import RoleService from '../../../services/roles';


const UsersCreate = () => {
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [hotels, setHotels] = useState([]);

    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [role, setRole] = useState(-1);
    const [userHotels, setUserHotels] = useState([]);
    const [userType, setUserType] = useState('op');

    const [canSubmit, setCanSubmit] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const cid = useSelector(state => state.auth.companyId ?? 0);
    const newCid = useSelector(state => state.base.cid ?? '');//TODO: REMOVE
    const roleLevel = useSelector(state => state.auth.role.level ?? 10000);
    const enabledHotels = useSelector(state => state.auth.hotels ?? []);

    useEffect(() => {
        clearAllFields();
        if (cid != null && roleLevel != 10000) { loadData(); }
    }, [cid, roleLevel]);

    useEffect(() => {
        if ((username != null && username.length >= 3) && (firstName != null && firstName.length >= 2) &&
            (lastName != null && lastName.length >= 2) && (role != null && role > 1) &&
            (userHotels != null)) {
                setCanSubmit(true);
            } else {
                setCanSubmit(false);
            }

    }, [username, firstName, lastName, role, userHotels]);


    const clearAllFields = () => {
        setUsername('');
        setFirstName('');
        setLastName('');
        setRole('');
        setUserHotels([]);
    };

    const loadData = async () => {
        setLoading(true);

        const queryParams = new URLSearchParams(location.search);
        const type = queryParams.get('type') ?? '';
        setUserType(type);

        setRoles([]);
        await RoleService.getRoles(cid, roleLevel, type)
            .then(res => {
                res.forEach(role => {
                    setRoles(arr => [...arr, <Select.Option key={role.id} value={role.id}>{role.name}</Select.Option>]);
                });
            })
            .catch(err => errorNotification(err));

        setHotels([]);
        await HotelService.getHotels(cid)
            .then(res => {
                res.forEach(hotel => {
                    if (enabledHotels[hotel.id] != null) {
                        setHotels(arr => [...arr, <Select.Option key={hotel.id} value={hotel.id}>{hotel.name}</Select.Option>]);
                    }
                });
            })
            .catch(err => errorNotification(err));

        setLoading(false);
    };

    const validateEmail = (email) => {
        if (email == null || email.length <= 0) { return null; }

        return String(email)
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const create = async () => {
        setSubmitted(true);

        if (userType === 'op') {
            // Create op user
            await UserService.createOpUser(username, firstName, lastName, role, userHotels)
                .then(async () => {

                    successNotification('El usuario fue creado exitosamente');
                    history.goBack();
                })
                .catch(err => {
                    errorNotification(err);
                    setSubmitted(false);
                });
        } else {
            // Create admin user
            await UserService.createAdUser(cid, username, firstName, lastName, role, userHotels)
                .then(async () => {
                    successNotification('El usuario fue creado exitosamente');
                    history.goBack();
                })
                .catch(err => {
                    errorNotification(err);
                    setSubmitted(false);
                });
        }
    };

    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 10}}>
                <Col span={23}>
                    <Row style={{marginLeft: 40, width: '100%', height: 40}}>
                        <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <NavLink to={'/app/users'}>{'Usuarios'}</NavLink>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {'Crear usuario'}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <SectionTitle title={'Crear usuario'} />

            <Skeleton loading={loading} paragraph={{rows: 9, cols: 1}} active>
                <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                    <Row style={{width: '100%', height: 60}}>
                        <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Nombre de usuario</span></Col>
                        <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                            <Input type="text" maxLength={200} onChange={(e) => setUsername(e.target.value)} disabled={submitted}/></span>
                        </Col>
                    </Row>
                    <Row style={{width: '100%', height: 60}}>
                        <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Nombre(s)</span></Col>
                        <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                            <Input type="text" maxLength={200} onChange={(e) => {const beautifyValue = e.target.value.trim().toLowerCase();
                                setFirstName(beautifyValue);}} disabled={submitted}/></span>
                        </Col>
                    </Row>
                    <Row style={{width: '100%', height: 60}}>
                        <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Apellido(s)</span></Col>
                        <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                            <Input type="text" maxLength={200} onChange={(e) => {const beautifyValue = e.target.value.trim().toLowerCase();
                                setLastName(beautifyValue);}} disabled={submitted}/></span>
                        </Col>
                    </Row>
                    <Row style={{width: '100%', height: 60}}>
                        <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Rol</span></Col>
                        <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                            <Select style={{marginLeft: 20, width: 400}} onChange={e => setRole(e)} disabled={submitted}>{roles}</Select>
                        </Col>
                    </Row>
                    <Row style={{width: '100%', height: 60}}>
                        <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Hoteles</span></Col>
                        <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                            <Select mode={(userType === 'ad') ? 'multiple' : ''} style={{marginLeft: 20, width: 400}} onChange={e => setUserHotels(e)} disabled={submitted}>{hotels}</Select>
                        </Col>
                    </Row>

                    <Row style={{width: '100%', height: 120}}>
                        <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                            <Button type="primary" style={{marginLeft: 40, width: '50%'}} disabled={!canSubmit || submitted} onClick={create}>Crear</Button>
                        </Col>
                    </Row>
                </Row>
            </Skeleton>
        </Row>
    );
};

export default UsersCreate;
