import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API, Auth } from 'aws-amplify';
import { Layout, Row, Col, Spin, Card, Button, InputNumber, Input, Modal, Alert, Tag } from 'antd';

import PrintAllTickets from '../../../components/UI/Modals/PrintAllTickets/PrintAllTickets';

const { Content } = Layout;
const ButtonGroup = Button.Group;


class CreditNotesAdd extends Component {
    state = {
        loading: false,
        awsPk: '',
        awsSk: '',
        vouchersHotel: '',
        vouchersDate: '',
        ntCanBeAdded: false,
        ntId: 0,
        ntHeader: '',
        ntFolio: 0,
        creditNotes: [],
        creditNotesContents: [],
        folioKeys: 0,
        creditNotesTotalAmount: 0,
        ticketsHaveBeenAdded: false,
        hotel: '',
        visiblePrintAllTickets: false,
        printableTickets: [],
        processedTicks: []
    }

    idToken = '';


    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    loadData() {
        this.setState({loading: true});

        const queryId = this.props.match.params.id;

        const companyId = this.props.companyId;
        const hotelId = queryId.split('h').pop()[0];
        const yyyymmdd = queryId.split('d').pop();
        const type = 'nt';

        const queryString = 'companyId=' + companyId + '&hotelId=' + hotelId + '&timestamp=' + yyyymmdd + '&type=' + type;

        API.get('benackoAPI', '/vouchers?' + queryString, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( vouchersRes => {
                const year = yyyymmdd.substring(0, 4);
                const month = yyyymmdd.substring(4, 6);
                const day = yyyymmdd.substring(6, 8);

                const vouchersDate = new Date(year, (month - 1), day).toLocaleDateString();
                const vouchersHotel = this.props.hotels[hotelId];

                var ntCanBeAdded = false;
                if (vouchersRes.Items[0].vouchers.length <= 0) {
                    ntCanBeAdded = true;
                }

                const awsPk = vouchersRes.Items[0].PK;
                const awsSk = vouchersRes.Items[0].SK;

                this.setState({
                    loading: false,
                    awsPk: awsPk,
                    awsSk: awsSk,
                    ntCanBeAdded: ntCanBeAdded,
                    vouchersHotel: vouchersHotel,
                    vouchersDate: vouchersDate
                });
            })
            .then( () => {
                API.get('benackoAPI', '/system/tickets?hotelId=' + hotelId, {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then( foliosRes => {
                        const activeFolio = foliosRes.find( fr => fr.type === 'frontDeskCreditNoteMG');
                        const ntId = activeFolio.id;
                        const ntHeader = activeFolio.folio_header;
                        const ntFolio = activeFolio.folio_number;

                        this.setState({
                            ntId: ntId,
                            ntHeader: ntHeader,
                            ntFolio: ntFolio
                        });
                    })
                    .catch( errFolios => {
                        console.log('Error loading folios:', errFolios);
                    });
            })
            .then( () => {
                var firstCreditNote = [];
                firstCreditNote.push(
                    <Row key={0} style={{width: '100%', marginTop: 50}}>
                        <Col span={4}>
                            <span>Nota de crédito {1}</span>
                        </Col>
                        <Col span={4}>
                            <strong>Cantidad: </strong>
                            <InputNumber min={1} defaultValue={1} step={1} precision={0} onChange={(value) => this.updateCreditNoteQuantity(value, 0)} />
                        </Col>
                        <Col offset={1} span={9}>
                            <strong>Concepto: </strong>
                            <Input defaultValue="habitación" style={{ width: '50%' }} onChange={(value) => this.updateCreditNoteConcept(value, 0)} />
                        </Col>
                        <Col span={6}>
                            <strong>Precio unitario: </strong>
                            <InputNumber min={0} defaultValue={0} onChange={(value) => this.updateCreditNotePrice(value, 0)} />
                        </Col>
                    </Row>
                );

                var firstCreditNoteContents = [];
                firstCreditNoteContents.push({
                    key: 0,
                    quantity: 1,
                    concept: 'habitación',
                    price: 0
                });

                this.setState({
                    creditNotes: firstCreditNote,
                    creditNotesContents: firstCreditNoteContents
                });
            })
            .catch( errVouchers => {
                console.log('Error loading vouchers:', errVouchers);

                this.setState({loading: false});
            });

        API.get('benackoAPI', '/hotels/' + hotelId, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then(hotelRes => {
                const hotelDetails = hotelRes[0];

                const hotel = {
                    legalName: hotelDetails.legalName,
                    address: hotelDetails.address,
                    rfc: hotelDetails.rfc,
                    city: hotelDetails.city,
                    state: hotelDetails.state,
                    country: hotelDetails.country,
                    zipCode: hotelDetails.zipCode,
                    phoneNumber: hotelDetails.phoneNumber
                };

                this.setState({hotel: hotel, loading: false});
            })
            .catch(errHotel => {
                console.log('Error loading hotel:', errHotel);
            });//FOR LIST
    }

    addCreditNote = () => {
        var folioCounter = this.state.folioKeys;
        folioCounter += 1;

        var creditNotes = this.state.creditNotes;
        creditNotes.push(
            <Row key={folioCounter} style={{width: '100%', marginTop: 10}}>
                <hr />
                <Col span={4}>
                    <span>Nota de crédito {folioCounter + 1}</span>
                </Col>
                <Col span={4}>
                    <strong>Cantidad: </strong>
                    <InputNumber min={1} defaultValue={1} step={1} precision={0} onChange={(value) => this.updateCreditNoteQuantity(value, folioCounter)} />
                </Col>
                <Col offset={1} span={9}>
                    <strong>Concepto: </strong>
                    <Input defaultValue="habitación" style={{ width: '50%' }} onChange={(value) => this.updateCreditNoteConcept(value, folioCounter)} />
                </Col>
                <Col span={6}>
                    <strong>Precio unitario: </strong>
                    <InputNumber min={0} defaultValue={0} onChange={(value) => this.updateCreditNotePrice(value, folioCounter)} />
                </Col>
            </Row>
        );

        var creditNotesContents = this.state.creditNotesContents;
        creditNotesContents.push({
            key: folioCounter,
            quantity: 1,
            concept: 'habitación',
            price: 0
        });

        this.setState({
            folioKeys: folioCounter,
            creditNotes: creditNotes,
            creditNotesContents: creditNotesContents
        });
    }

    updateCreditNoteQuantity = (value, key) => {
        var creditNotesContents = this.state.creditNotesContents;

        creditNotesContents.find( nt => nt.key === key).quantity = value;

        this.setState({creditNotesContents: creditNotesContents});
    }

    updateCreditNoteConcept = (value, key) => {
        var creditNotesContents = this.state.creditNotesContents;
        const beautifiedValue = value.target.value.toLowerCase().trim();

        creditNotesContents.find( nt => nt.key === key).concept = beautifiedValue;

        this.setState({creditNotesContents: creditNotesContents});
    }

    updateCreditNotePrice = (value, key) => {
        var creditNotesContents = this.state.creditNotesContents;

        creditNotesContents.find( nt => nt.key === key).price = value;

        this.setState({creditNotesContents: creditNotesContents}, () => {
            this.calculateTicketsTotalAmount();
        });
    }

    calculateTicketsTotalAmount() {
        var totalAmount = 0;
        this.state.creditNotesContents.forEach(ticket => {
            totalAmount = totalAmount + parseInt(ticket.price, 10);
        });

        this.setState({creditNotesTotalAmount: totalAmount});
    }

    showErrorModal(noteKey) {
        var addedKey = noteKey + 1;
        Modal.error({
            title: 'Error en nota de crédito ' + addedKey,
            content: 'No puede haber campos vacíos en las notas de crédito'
        });
    }

    viewAllPrintableTickets = () => {
        const tickets = this.state.printableTickets;
        var preProcessedTicks = [];

        tickets.forEach(voucher => {
            preProcessedTicks.push(
                <div key={voucher.folio} style={{ marginBottom: 30 }}>
                    {this.state.hotel.legalName}
                    <br />
                    RFC: {this.state.hotel.rfc}
                    <br />
                    {this.state.hotel.address}
                    <br />
                    {this.state.hotel.city}, {this.state.hotel.state}, {this.state.hotel.country}
                    <br />
                    C.P.: {this.state.hotel.zipCode}
                    <br />
                    Tel: {this.state.hotel.phoneNumber}
                    <hr />
                    {
                        (voucher.type === 'nt') &&
                            <p>NOTA DE CRÉDITO</p>
                    }
                    Fecha: {voucher.date}
                    <br />
                    Cantidad: {
                        voucher.quantity !== 0 &&
                            voucher.quantity
                    }
                    <br />Concepto: {voucher.concept}
                    <br />
                    Monto: ${ 
                        voucher.price !== 0 &&
                            voucher.price
                    }
                    <br />
                    Total: ${voucher.quantity * voucher.price}
                    <hr />
                    Folio {voucher.folio}
                    <br />
                    COMPROBANTE SIMPLIFICADO NO DEDUCIBLE PARA EFECTOS FISCALES
                    < br/><br />
                    ORIGINAL
                    <br /><br />
                    {
                        (voucher.inv === true) &&
                            <p>FACTURADO</p>
                    }
                </div>
            );
        });

        this.setState({
            visiblePrintAllTickets: true,
            processedTicks: preProcessedTicks
        });
    }

    closeAllPrintableTickets = () => {
        this.setState({
            visiblePrintAllTickets: false,
            processedTicks: []
        });
    }

    generateCreditNotesHandler = () => {
        const creditNotes = this.state.creditNotesContents;
        const awsPk = this.state.awsPk;
        const awsSk = this.state.awsSk;
        const ntHeader = this.state.ntHeader;
        var ntFolio = this.state.ntFolio;

        var validatedNotes = true;

        creditNotes.forEach( note => {
            if (note.quantity <= 0 || note.quantity === null || note.concept.length <= 0 || note.concept === null || note.price < 0 || note.price === null) {
                validatedNotes = false;
                this.showErrorModal(note.key);
            }
        });

        if (validatedNotes) {
            this.setState({loading: true});
            var preTickets = [];

            // VARIABLES FOR INTEGRATION TO NEW BACKEND
            let newTotal = 0;
            let newTickets = [];

            creditNotes.forEach( note => {
                const newFolio = ntHeader + ntFolio;
                note['folio'] = newFolio;
                note['edited'] = false;
                ntFolio += 1;

                preTickets.push({
                    key: note.folio,
                    date: '' + this.state.vouchersDate,
                    folio: note.folio,
                    quantity: note.quantity,
                    concept: note.concept,
                    price: note.price,
                    invoiced: (note.invoiced) ? <Tag color="geekblue">Facturado</Tag> : '',
                    edited: (note.edited) ? <Tag color="red">Editado</Tag> : '',
                    inv: (note.invoiced !== true) ? false : true,
                    type: 'nt'
                });//FOR LIST

                // VARIABLES FOR INTEGRATION TO NEW BACKEND
                newTotal += parseFloat((parseFloat(note.price, 2) * parseInt(note.quantity)), 2);
                newTickets.push({
                    folio: note.folio,
                    concept: note.concept,
                    quantity: parseInt(note.quantity),
                    price: parseFloat(note.price, 2),
                    type: 'CREDIT_NOTE',
                    invoiced: (note.invoiced !== true) ? false : true
                });
            } );

            API.put('benackoAPI', '/vouchers', {
                    body: {
                        pk: awsPk,
                        sk: awsSk,
                        vouchers: creditNotes
                    },
                    headers: {
                        Authorization: this.idToken
                    }
                })
                .then( () => {
                    API.put( 'benackoAPI', '/system/tickets/' + this.state.ntId, {
                            body: {
                                folioNumber: ntFolio
                            },
                            headers: {
                                Authorization: this.idToken
                            }
                        } )
                        .catch( errFolios => {
                            console.log('Error increasing folios', errFolios);

                            this.setState({loading: false});
                        } );
                } )
                .then( () => {
                    this.setState({loading: false, printableTickets: preTickets, ticketsHaveBeenAdded: true});
                    //this.props.history.push('/app/vouchers');
                } )
                .catch( errNotes => {
                    console.log('Error adding notes:', errNotes);

                    this.setState({loading: false});
                } );
        }
    }

    render() {
        return(
            <Layout>
                <Content style={{width: '100%', margin: 0, padding: 0, background: '#fff', paddingLeft: 20}}>
                    <Spin spinning={this.state.loading} size="large">
                        <Card loading={false} bordered={false} title="Notas de crédito">
                            <p><strong>Hotel: </strong>{this.state.vouchersHotel}</p>
                            <p><strong>Fecha: </strong>{this.state.vouchersDate}</p>
                        </Card>  
                        <Col span={24}>
                            <Row>
                                <ButtonGroup>
                                    <Button type="default" disabled={this.state.ticketsHaveBeenAdded} onClick={this.addCreditNote}>Agregar otra nota de crédito</Button>
                                    <Button type="danger" disabled={this.state.ticketsHaveBeenAdded} onClick={this.generateCreditNotesHandler}>Generar notas de crédito</Button>
                                    <Button type="primary" disabled={!this.state.ticketsHaveBeenAdded} onClick={this.viewAllPrintableTickets}>Imprimir notas de crédito</Button>
                                </ButtonGroup>
                            </Row>
                            <Row>
                                <Alert
                                    type="info" 
                                    message={'Cantidad total: $' + this.state.creditNotesTotalAmount + ' en ' + this.state.creditNotes.length + ((this.state.creditNotes.length === 1) ? ' nota de crédito' : ' notas de crédito')}
                                    style={{ marginTop: 30, marginBottom: 30 }}
                                    showIcon
                                />
                            </Row>
                            <Row style={{width: '100%'}}>
                                {this.state.creditNotes}
                            </Row>
                        </Col>

                        <PrintAllTickets
                            visible={this.state.visiblePrintAllTickets}
                            onCancel={this.closeAllPrintableTickets}
                            hotel={this.state.hotel}
                            processedTicks={this.state.processedTicks}
                        />
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.auth.companyId,
        hotels: state.auth.hotels,
        uid: state.base.uid,
        cid: state.base.cid
    };
};


export default connect(mapStateToProps, null)(CreditNotesAdd);