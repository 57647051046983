import React, { useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Breadcrumb, Col, Row, Tabs } from 'antd';

import FurnishingDetailsTab from './FurnishingDetailsTab/FurnishingDetailsTab';
import FurnishingLogsTab from './FurnishingLogsTab/FurnishingLogsTab';

import { disableHotelSelector, enableHotelSelector } from '../../../../store/actions';


const TabPane = Tabs.TabPane;

const RoomFurnishing = () => {
    const dispatch = useDispatch();
    
    const {roomId, furnishingId} = useParams();

    useEffect(() => {
        dispatch(enableHotelSelector());
        

        return () => {
            dispatch(disableHotelSelector());
        };
    }, []);


    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 10}}>
                <Col span={22}>
                    <Row style={{marginLeft: 40, width: '100%', height: 40}}>
                        <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <NavLink to={'/app/maintenance/rooms'}>{'Habitaciones'}</NavLink>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <NavLink to={'/app/maintenance/rooms/' + roomId}>{'Detalle de habitación'}</NavLink>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {'Detalle de mobiliario'}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Tabs defaultActiveKey={1}>
                <TabPane tab="Información general" key="1">
                    <FurnishingDetailsTab roomId={roomId} furnishingId={furnishingId}/>
                </TabPane>
                <TabPane tab="Eventos" key="2">
                    <FurnishingLogsTab roomId={roomId} furnishingId={furnishingId}/>
                </TabPane>
            </Tabs>
        </Row>
    );
}

export default RoomFurnishing;