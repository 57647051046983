import { Auth, API } from 'aws-amplify';

class RentTypeService {

    static async getRentTypes(hid) {
        return API.get('benackoAPI', '/room-types?hotelId=' + hid)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    const processedResponse = [];

                    response.forEach(rt => {
                        if (rt != null && rt.enabled === 1) {
                            processedResponse.push({
                                Key: rt.id ?? 0,
                                Name: rt.name ?? '',
                                IsTemporary: rt.isTemporary ?? false
                            });
                        }
                    });

                    return processedResponse;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getRentTypeDetails(hid, rid) {
        const rentType = [];

        await API.get('benackoAPI', '/room-types/' + rid)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    if (response[0] != null) {
                        const rt = response[0];

                        rentType.push({
                            Key: rt.id ?? 0,
                            Name: rt.name ?? '',
                            IsTemporary: rt.isTemporary ?? false,
                            Settings: {
                                Price: rt.price ?? 0,
                                RentExtensionHourlyRoomPrice: null,//rt.rentExtensionHourlyPrice ?? 0,
                                RentExtensionHourlyGuestPrice: null//rt.rentExtensionHourlyGuestPrice ?? 0
                            },
                            Modified: rt.updated ?? 0,
                            Created: rt.created ?? 0
                        });
                    }
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});

            // ADD LATER
        // await API.get('benackoAPI', '/v2/rooms/configurations?hotelId=' + hid)
        //     .then(response => {
        //         if (response == null) {
        //             throw Error('Petición incorrecta');
        //         } else {
        //             if (response[0] != null) {
        //                 console.log('rooms', response[0], rid);
        //                 const numRid = parseInt(rid);
        //                 const typeRooms = response.filter(rt => {return rt.RentTypeId === numRid});
        //                 console.log('rooms', typeRooms);
        //             }
        //             return response;
        //         }
        //     })
        //     .catch(err => {throw Error(err ?? 'Petición incorrecta');});
        
        return rentType[0] ?? {};
    }

    static async createRentType(hid, name, isTemporary, price) {

        const body = {
            hotelId: hid,
            name: name,
            isTemporary: isTemporary,
            price: price,
            breakfastNumber: 0
        };

        return API.post('benackoAPI', '/room-types', {body: body})
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }
};

export default RentTypeService;