import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { Layout, Row, Spin, Button, Table, Descriptions } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;

const roomTypeTotalsColumns = [
    {
        title: 'Tipo de habitación',
        dataIndex: 'name'
    }, {
        title: 'Cantidad',
        dataIndex: 'number'
    }
];

const rentColumns = [
    {
        title: 'Fecha',
        dataIndex: 'timestamp'
    },
    {
        title: 'Habitación',
        dataIndex: 'room'
    },
    {
        title: 'Cantidad',
        dataIndex: 'quantity'
    },
    {
        title: 'Adicionales',
        dataIndex: 'guests'
    },
    {
        title: 'Total',
        dataIndex: 'total'
    },
    {
        title: 'MXN',
        dataIndex: 'mxn'
    },
    {
        title: 'USD',
        dataIndex: 'usd'
    },
    {
        title: 'Tarjeta',
        dataIndex: 'card'
    },
    {
        title: 'Depósito',
        dataIndex: 'deposit'
    }
];

const daysAdditionColumns = [
    {
        title: 'Fecha',
        dataIndex: 'timestamp'
    },
    {
        title: 'Habitación',
        dataIndex: 'room'
    },
    {
        title: 'Cantidad',
        dataIndex: 'quantity'
    },
    {
        title: 'Total',
        dataIndex: 'total'
    },
    {
        title: 'MXN',
        dataIndex: 'mxn'
    },
    {
        title: 'USD',
        dataIndex: 'usd'
    },
    {
        title: 'Tarjeta',
        dataIndex: 'card'
    }
];


class View extends Component {
    state = {
        loading: false,
        rooms: [],
        exchangeRate: 1.0,
        rents: [],
        daysAdditions: [],
        mxnTotal: 0,
        usdTotal: 0,
        cardTotal: 0,
        rentTotal: 0,
        daysAdditionTotal: 0,
        roomTypes: []
    }

    hotelId = '';
    timestamp = '';
    idToken = '';


    componentDidMount() {
        Auth.currentSession()
            .then( async response => {
                this.idToken = response.idToken.jwtToken;
                this.hotelId = this.props.match.params.hotel;
                this.timestamp = this.props.match.params.timestamp;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    loadData() {
        this.setState({loading: true});

        const previousDayLimit = new Date();
        previousDayLimit.setTime(this.timestamp);
        previousDayLimit.setDate(previousDayLimit.getDate() - 1);

        const endTimestamp = this.timestamp;
        const startTimestamp = previousDayLimit.getTime();

        API.get('benackoAPI', '/rooms?hotelId=' + this.hotelId, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( roomsRes => {
                var roomsDict = {};

                roomsRes.forEach( room => {
                    roomsDict[room.id] = room.name;
                });

                this.setState({rooms: roomsDict});
            })
            .then( () => {
                const pathString = '/shift-balances?hotelId=' + this.hotelId + '&startTimestamp=' + startTimestamp + '&endTimestamp=' + endTimestamp;
                let ids = API.get('benackoAPI', pathString, {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then( async sbRes => {
                        var ids = [];

                        sbRes.forEach( sb => {
                            ids.push(sb.id);
                        });

                        return ids;
                    })
                    .catch( errSbDate => {
                        console.log('Error getting hours for shift balances', errSbDate);

                        this.setState({lodading: false});
                    });

                return ids;
            })
            .then( async ids => {
                await ids.forEach( async id => {

                    await API.get('benackoAPI', '/reports/shift-balances/headers?companyId=' + this.props.companyId + '&hotelId=' + this.hotelId + '&shiftBalanceId=' + id, {
                            headers: {
                                Authorization: this.idToken
                            }
                        })
                        .then( async headersRes => {
                            const exchangeRate = headersRes.Items[0].exchangeRates[0].rate;
                            var roomTypes = [];
                            const roomTypeHeaders = headersRes.Items[0].roomTypes;
                            roomTypeHeaders.forEach( type => {
                                roomTypes.push({
                                    key: type.id,
                                    name: type.name,
                                    number: 0
                                });
                            });

                            const pathString = '/actions/rooms?companyId=' + this.props.companyId + '&hotelId=' + this.hotelId + '&shiftBalanceId=' + id;
                            await API.get('benackoAPI', pathString, {
                                        headers: {
                                            Authorization: this.idToken
                                        }
                                    })
                                    .then( actionsRes => {
                                        var rents = this.state.rents;
                                        var daysAdditions = this.state.daysAdditions;

                                        var mxnTotal = this.state.mxnTotal;
                                        var usdTotal = this.state.usdTotal;
                                        var cardTotal = this.state.cardTotal;
                                        var rentTotal = this.state.rentTotal;
                                        var daysAdditionTotal = this.state.daysAdditionTotal;

                                        actionsRes.Items.forEach( action => {
                                            const roomId = parseInt(action.SK.split('r').pop().split('a')[0], 10);
                                            const room = (roomId === 0) ? 'Recepción' : this.state.rooms[roomId];

                                            if (action.SK.indexOf('a1u') !== -1 && action.depositInvoice === true) {
                                                if (action.paymentMxn !== 0) {
                                                    mxnTotal = mxnTotal + action.paymentMxn;
                                                    rentTotal = rentTotal + action.paymentMxn;
                                                }
                
                                                if (action.paymentUsd !== 0) {
                                                    usdTotal = usdTotal + action.paymentUsd;
                                                    rentTotal = rentTotal + (action.paymentUsd * exchangeRate);
                                                }
                
                                                if (action.paymentCardAmount != null) {
                                                    cardTotal = cardTotal + action.paymentCardAmount;
                                                    rentTotal = rentTotal + action.paymentCardAmount;
                                                }

                                                const roomTypeId = action.rentRoomType;
                                                const rentPeriod = action.numberOfDays;
                                                roomTypes.find( rt => rt.key === roomTypeId).number += rentPeriod;
                                                
                                                rents.push({
                                                    key: action.SK,
                                                    timestamp: new Date(action.rentTimestamp).toLocaleString(),
                                                    room: room,
                                                    guests: action.numberOfAdditionalGuests,
                                                    quantity: action.numberOfDays,
                                                    total: '$' + (parseFloat(action.rentTotalDays) + parseFloat(action.rentTotalAdditionalGuests)),
                                                    mxn: '$' + action.paymentMxn,
                                                    usd: '$' + action.paymentUsd,
                                                    card: (action.paymentCardAmount != null || action.paymentCardAmount === 0) ? '$' + action.paymentCardAmount : '$0',
                                                    deposit: '-'
                                                });
                                            }

                                            if (action.SK.indexOf('a6u') !== -1 && action.depositInvoice === true) {
                                                if (action.paymentMxn !== 0) {
                                                    mxnTotal = mxnTotal + action.paymentMxn;
                                                    daysAdditionTotal = daysAdditionTotal + action.paymentMxn;
                                                }
                
                                                if (action.paymentUsd !== 0) {
                                                    usdTotal = usdTotal + action.paymentUsd;
                                                    daysAdditionTotal = daysAdditionTotal + (action.paymentUsd * exchangeRate);
                                                }
                
                                                if (action.paymentCardAmount != null) {
                                                    cardTotal = cardTotal + action.paymentCardAmount;
                                                    daysAdditionTotal = daysAdditionTotal + action.paymentCardAmount;
                                                }

                                                const roomTypeId = action.daysAdditionType;
                                                const rentPeriod = action.numberOfDays;
                                                roomTypes.find( rt => rt.key === roomTypeId).number += rentPeriod;

                                                daysAdditions.push({
                                                    key: action.SK,
                                                    timestamp: new Date(action.daysAdditionTimestamp).toLocaleString(),
                                                    room: room,
                                                    quantity: action.numberOfDays,
                                                    total: '$' + parseFloat(action.daysAdditionTotal),
                                                    mxn: '$' + action.paymentMxn,
                                                    usd: '$' + action.paymentUsd,
                                                    card: (action.paymentCardAmount != null || action.paymentCardAmount === 0) ? '$' + action.paymentCardAmount : '$0'
                                                });
                                            }
                                        });

                                        this.setState({
                                            exchangeRate: exchangeRate,
                                            rents: rents,
                                            daysAdditions: daysAdditions,
                                            mxnTotal: mxnTotal,
                                            usdTotal: usdTotal,
                                            cardTotal: cardTotal,
                                            rentTotal: rentTotal,
                                            daysAdditionTotal: daysAdditionTotal,
                                            roomTypes: roomTypes
                                        });
                                    })
                                    .catch( errActions => {
                                        console.log('Error loading actions:', errActions);
                                    });
                        })
                        .catch( sbHeadersErr => {
                            console.log('Error loading header:', sbHeadersErr);
                            this.setState({loading: false});
                        });
                });
            })
            .then( () => {
                this.setState({loading: false});
            })
            .catch( errRooms => {
                console.log('Error loading rooms:', errRooms);
            });
        
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#fff' }}>
                    <Spin spinning={this.state.loading} size="large">
                        <Table
                            title={ ()  => 'Rentas' }
                            columns={rentColumns} 
                            dataSource={this.state.rents} 
                            size="small"
                            bordered={false}
                            locale={{ emptyText: 'No se encontraron rentas' }}
                            pagination={false}
                            style={{ marginBottom: 20 }}
                        />

                        <Table
                            title={ ()  => 'Días adicionales' }
                            columns={daysAdditionColumns} 
                            dataSource={this.state.daysAdditions} 
                            size="small"
                            bordered={false}
                            locale={{ emptyText: 'No se encontraron días adicionales' }}
                            pagination={false}
                            style={{ marginBottom: 80 }}
                        />

                        <Descriptions title="Totales">
                            <Descriptions.Item label="Tipo de cambio">${this.state.exchangeRate}</Descriptions.Item>
                            <Descriptions.Item label="Pesos">${this.state.mxnTotal}</Descriptions.Item>
                            <Descriptions.Item label="Dólares">${this.state.usdTotal}</Descriptions.Item>
                            <Descriptions.Item label="Tarjeta">${this.state.cardTotal}</Descriptions.Item>
                            <Descriptions.Item label="Total">${this.state.mxnTotal + (this.state.usdTotal * this.state.exchangeRate) + this.state.cardTotal}</Descriptions.Item>
                            <Descriptions.Item></Descriptions.Item>
                            <Descriptions.Item label="Total rentas">${this.state.rentTotal}</Descriptions.Item>
                            <Descriptions.Item label="Total adicionales">${this.state.daysAdditionTotal}</Descriptions.Item>
                            <Descriptions.Item label="Total">${this.state.rentTotal + this.state.daysAdditionTotal}</Descriptions.Item>
                        </Descriptions>
                        <Table
                            columns={roomTypeTotalsColumns} 
                            dataSource={this.state.roomTypes} 
                            size="small"
                            bordered={false}
                            pagination={false}
                        />
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.auth.companyId
    };
};


export default connect(mapStateToProps, null)(View);