import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API } from 'aws-amplify';
import { Button, Form, Input, Layout, Row, Col, Spin, Select } from 'antd';
import { LeftOutlined, TableOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;
const Option = Select.Option;


class Create extends Component {
    state = {
        loading: false,
        hotelOptions: [],
        selectedHotel: 0,
        sourceOptions: [],
        selectedSource: null,
        inventoryName: '',
        pcid: null
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setHotelsMenu();
    }

    setHotelsMenu() {
        const enabledHotels = this.props.hotels;
        var hotels = [];

        for (var i in enabledHotels) {
            hotels.push(<Option key={i}>{enabledHotels[i]}</Option>);
        }

        this.setState({hotelOptions: hotels});
        this.setState({pcid: this.props.cid});
    }

    setSourceInventoriesHandler = (optionSelected) => {
        API.get('benackoAPI', '/inventories?hotelId=' + optionSelected)
            .then( response => {
                var options = [];

                response.forEach( inventory => {
                    options.push(<Option key={inventory.id} >{inventory.name}</Option>);
                });

                this.setState({ sourceOptions: options, loading: false });
            })
            .catch( errInventories => {
                console.log('Error loading inventories: ', errInventories);

                this.setState({ loading: false });
            });
    }

    createInventoryHandler = () => {
        this.setState({loading: true});

        const sourceInv = this.state.selectedSource ? this.state.selectedSource : 'nil';
        const beautifiedName = this.state.inventoryName.trim().toLowerCase();

        API.post('benackoAPI', '/inventories', {
                body: {
                    name: beautifiedName,
                    sourceInventoryId: sourceInv,
                    hotelId: this.state.selectedHotel
                }
            })
            .then( () => {
                this.setState({loading: false});
                this.props.history.goBack();
            })
            .catch( error => {
                console.log(error);
                this.setState({loading: false});
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    render() {
        return(
            <Layout>
                <Header style={{width: '100%', background: '#fff', padding: 0, paddingLeft: 20}}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{width: '100%', margin: 0, padding: 0, background: '#ffff', paddingLeft: 20}}>
                    <Spin spinning={this.state.loading} size="large">
                        <Form className="login-form">
                            <Row style={{marginTop: 30}}>
                                <Col span={20} offset={2}>
                                    <p>Hotel:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Select style={{width: '100%'}} onSelect={(e) => this.setState({selectedHotel: e})} onChange={this.setSourceInventoriesHandler}>
                                        {this.state.hotelOptions}
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Inventario fuente:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Select style={{width: '100%'}} onSelect={(e) => this.setState(({selectedSource: e}))}>
                                        {this.state.sourceOptions}
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Nombre:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Input style={{width: '100%'}} onChange={(e) => this.setState({inventoryName: e.target.value})} prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Name" />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={4} offset={2}>
                                    <Button type="primary" 
                                        onClick={this.createInventoryHandler}
                                        disabled={this.state.selectedHotel === 0 || this.state.inventoryName == null || this.state.inventoryName.length <= 1} className="login-form-button"
                                    >
                                        Crear
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        hotels: state.auth.hotels,
        cid: state.base.cid
    };
};


export default connect(mapStateToProps, null)(Create);