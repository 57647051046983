import React, { useEffect, useState } from 'react';
import { Row, Table } from 'antd';

import './RoomsTabContents.css';

const RoomsTabContents = ({columns = [], rooms = {}}) => {
    const [rentTypeColumns, setRentTypeColumns] = useState([]);
    const [shifts, setShifts] = useState([]);


    useEffect(() => {
        if (columns != null) {
            setRentTypeColumns([{title: '', dataIndex: 'Timestamp', width: 100}, ...columns]);
        }

        if (rooms != null) {
            if (rooms.RentTotals != null) { setShifts(rooms.RentTotals ?? []); }
        }
    }, [columns, rooms]);


    return (
        <Row style={{display: 'flex', width: '1500px'}}>
            <Table
                className='rooms-table'
                style={{width: '150%'}}
                size='small'
                scroll={{y: 500}}
                pagination={false}
                columns={rentTypeColumns}
                dataSource={shifts}
                rowKey='Timestamp'
            />
        </Row>
    );
};

export default RoomsTabContents;