import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API, Auth } from 'aws-amplify';
import { Button, Layout, Row, Tabs, Input, InputNumber, Col, Spin, Switch } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group
const TabPane = Tabs.TabPane;
const { TextArea } = Input;


class Edit extends Component {
    state = {
        loadingEdit: false,
        productName: '',
        productInstructions: '',
        recipeId: 0,
        itemNames: new Map(),
        selectedItems: [],
        tableItems: [],
        productItemsQuantity: new Map(),
        productItemsEssential: new Map(),
        disableEditButton: true
    };

    idToken = '';

    loadData() {
        this.setState({loadingEdit: true});

        API.get('benackoAPI', '/items?companyId=' + this.props.companyId, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( itemsRes => {
                var itemNames = new Map();

                itemsRes.forEach( item => {
                    itemNames[item.id] = item.name;
                });

                this.setState({
                    itemNames: itemNames
                });
            })
            .then( async () => {
                let recipeId = await API.get('benackoAPI', '/products/' + this.props.match.params.id , {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then( productRes => {
                        this.setState({
                            productName: productRes[0].name,
                            recipeId: productRes[0].recipeId
                        });

                        return productRes[0].recipeId;
                    })
                    .catch( errProduct => {
                        console.log('Error loading product:', errProduct);

                        this.setState({loadingEdit: false});
                    });

                return recipeId;
            })
            .then( recipeId => {
                API.get('benackoAPI', '/recipes/' + recipeId, {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then( recipeRes => {
                        this.setState({
                            productInstructions: recipeRes[0].instructions
                        });
                    })
                    .catch( errRecipe => {
                        console.log('Error loading recipe:', errRecipe);

                        this.setState({loadingEdit: false});
                    });

                return recipeId;
            })
            .then( recipeId => {
                API.get('benackoAPI', '/recipe-items?recipeId=' + recipeId, {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then( recItemsRes => {
                        var selectedItems = [];
                        var itemsQuantities = new Map();
                        var itemsEssentials = new Map();

                        const items = recItemsRes.map( item => {
                            selectedItems.push(item.itemId);

                            itemsQuantities[item.itemId] = item.quantity;
                            itemsEssentials[item.itemId] = (item.isEssential === 1) ? true : false;

                            return (
                                <Row key={item} style={{width: 600, marginTop: 10}}>
                                    <Col span={6} offset={2}>{this.state.itemNames[item.itemId]}</Col>
                                    <Col span={4}>
                                        <InputNumber
                                            key={item.itemId}
                                            defaultValue={item.quantity}
                                            step={1}
                                            min={1}
                                            onChange={ (quantity) => {
                                                this.setProductQuantity(quantity, item.itemId);
                                            }}
                                        />
                                    </Col>
                                    <Col span={6}>
                                        <Switch size="small" onChange={ (value) => {
                                            this.setEssential(value, item.itemId);
                                        }} defaultChecked />
                                    </Col>
                                </Row>
                            );
                        });
                        
                        this.setState({
                            selectedItems: selectedItems,
                            tableItems: items, 
                            productItemsQuantity: itemsQuantities, 
                            productItemsEssential: itemsEssentials
                        });
                    })
                    .catch( errRecItems => {
                        console.log('Error loading items:', errRecItems);

                        this.setState({loadingEdit: false});
                    });
            })
            .then( () => {
                this.setState({loadingEdit: false});
            })
            .catch( errInventories => {
                console.log('Error loading inventories:', errInventories);
                this.setState({loadingEdit: false});
            });
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    setProductName = (name) => {
        const beautifiedName = name.target.value.trim().toLowerCase();
        this.setState({productName: beautifiedName});
    }

    setProductQuantity = (quantity, itemKey) => {
        var productItems = this.state.productItemsQuantity;
        productItems[itemKey] = quantity;

        this.setState({
            productItemsQuantity: productItems,
            disableEditButton: true
        });
    }

    setEssential = (value, itemKey) => {
        var productItems = this.state.productItemsEssential;
        productItems[itemKey] = value;

        this.setState({
            productItemsEssential: productItems,
            disableEditButton: true
        });
    }

    validateProductItems = () => {
        const productItems = this.state.selectedItems;
        const itemQuantities = this.state.productItemsQuantity;
        const itemEssentials = this.state.productItemsEssential;

        var itemValidation = true;
        var essentialValidation = 0;

        productItems.forEach( item => {
            if (itemQuantities[item] <= 0) {
                itemValidation = false;
            }

            if (itemEssentials[item] === true) {
                essentialValidation++;
            }
        });

        if (essentialValidation <= 0) {
            itemValidation = false;
        }

        this.setState({disableEditButton: !itemValidation});
    }

    editProductDetails = (event) => {
        event.preventDefault();
        
        this.setState({loadingEdit: true});

        API.put('benackoAPI', '/products/' + this.props.match.params.id, {
                body: {
                    name: this.state.productName
                },
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( () => {
                API.put('benackoAPI', '/recipes/' + this.state.recipeId, {
                        body: {
                            instructions: this.state.productInstructions
                        },
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .catch( errRecipe => {
                        console.log('Error editing recipe:', errRecipe);
                    });
            })
            .catch( errProductDetails => {
                console.log('Error editing product details:', errProductDetails);
            });

        this.setState({loadingEdit: false});
        
        this.props.history.goBack();
    }

    editProductItems = (event) => {
        event.preventDefault();
        
        this.setState({loadingEdit: true});

        const items = this.state.selectedItems;
        const quantities = this.state.productItemsQuantity;
        const essentials = this.state.productItemsEssential;
        items.forEach( item => {
            API.put('benackoAPI', '/recipe-items/' + item + '?recipeId=' + this.state.recipeId, {
                    body: {
                        quantity: quantities[item],
                        isEssential: essentials[item]
                    },
                    headers: {
                        Authorization: this.idToken
                    }
                })
                .catch( errRecipeItems => {
                    console.log('Error editing recipe items:', errRecipeItems);
                });
        });

        this.setState({loadingEdit: false});
        
        this.props.history.goBack();
    }

    render() {
        return (
            <Layout>
                <Spin spinning={this.state.loadingEdit} size="large">
                    <Header style={{ background: '#fff', padding: 0 }}>
                        <Row>
                            <ButtonGroup>
                                <Button type="primary" onClick={this.goBackHandler}>
                                    <LeftOutlined />Regresar
                                </Button>
                            </ButtonGroup>
                        </Row>
                    </Header>
                    <Content style={{ margin: 0, padding: 0, background: '#ffff', paddingTop: 15 }}>
                        <Tabs defaultActiveKey="1" tabPosition="right">
                            <TabPane tab="General" key="1">
                                <Row>
                                    <Input onChange={this.setProductName} value={this.state.productName} placeholder="Nombre del producto" />
                                </Row>
                                <Row style={{ marginTop: 20 }}>
                                    <TextArea
                                        rows={4}
                                        value={this.state.productInstructions}
                                        placeholder="Instrucciones de preparación"
                                        onChange={ instructions => {
                                            const beautifiesInstructions = instructions.target.value.trim().toLowerCase();
                                            this.setState({productInstructions: beautifiesInstructions})
                                        }} />
                                </Row>
                                <Row style={{ marginTop: 50 }}>
                                    <Button type="primary" onClick={this.editProductDetails} disabled={(!this.state.productInstructions || !this.state.productName) ? true : false}>Editar</Button>
                                </Row>
                            </TabPane>
                            <TabPane tab="Ingredientes" key="2">
                                <h4>Ingredientes</h4>
                                <Row style={{width: 600, marginTop: 50, marginBottom: 10 }}>
                                    <Col span={6} offset={2}><strong>Artículo</strong></Col>
                                    <Col span={4}><strong>Cantidad</strong></Col>
                                    <Col span={6}><strong>¿Es esencial?</strong></Col>
                                </Row>
                                <Row>
                                    {this.state.tableItems}
                                </Row>
                                <Row style={{ marginTop: 40 }}>
                                    <ButtonGroup>
                                        <Button type="primary" onClick={this.goPreviousStep}>Regresar</Button>
                                        <Button type="primary" onClick={this.validateProductItems}>Validar</Button>
                                        <Button type="default" onClick={this.editProductItems} disabled={this.state.disableEditButton}>Editar</Button>
                                    </ButtonGroup>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </Content>
                </Spin>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.auth.companyId
    };
};


export default connect(mapStateToProps, null)(Edit);