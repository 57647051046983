import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { Layout, Row, Col, Spin, Card, Select, Button, DatePicker } from 'antd';

const { Content } = Layout;
const Option = Select.Option;
const ButtonGroup = Button.Group;


class ItemActionsReport extends Component {
    state = {
        loading: false,
        hotelsOptions: [],
        selectedHotel: null,
        dateOptions: [],
        selectedFromDate: null,
        selectedToDate: null
    }

    idToken = '';


    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    loadData() {
        this.setState({loading: true});

        // HOTELS
        const enabledHotels = this.props.hotels;
        var hotels = [];

        for (var i in enabledHotels) {
            hotels.push(<Option key={i}>{enabledHotels[i]}</Option>);
        }

        this.setState({hotelsOptions: hotels});

        // DAYS
        const today = new Date();
        const upperLimit = this.getUpperDateLimit(today);
        
        var days = [];
        days.push(<Option key={upperLimit}>{today.toDateString()}</Option>);

        var dayDecrement = today;
        for (i = 0; i < 60; i++) {
            dayDecrement.setDate(dayDecrement.getDate() - 1);
            days.push(<Option key={dayDecrement.getTime()}>{dayDecrement.toDateString()}</Option>);
        }
        this.setState({dateOptions: days});

        this.setState({loading: false});
    }

    getUpperDateLimit(date) {
        var tomorrow = date;
        tomorrow.setHours(23);
        tomorrow.setMinutes(59);
        tomorrow.setSeconds(59);
        tomorrow.setMilliseconds(999);
        
        return tomorrow.getTime();
    }

    setSelectedHotel = (selectedHotel) => {
        this.setState({selectedHotel: selectedHotel});
    };

    setSelectedFromDate = (date, dateString) => {
        const beautifiedDate = dateString.split('-').join('');
        const year = parseInt(beautifiedDate.substring(0, 4), 10);
        const month = parseInt(beautifiedDate.substring(4, 6), 10) - 1;
        const day = parseInt(beautifiedDate.substring(6, 8), 10);
        const fromTimestamp = new Date(year, month, day, 0, 0, 0, 0).getTime();

        this.setState({selectedFromDate: fromTimestamp});
    }

    setSelectedToDate = (date, dateString) => {
        const beautifiedDate = dateString.split('-').join('');
        const year = parseInt(beautifiedDate.substring(0, 4), 10);
        const month = parseInt(beautifiedDate.substring(4, 6), 10) - 1;
        const day = parseInt(beautifiedDate.substring(6, 8), 10);
        const toTimestamp = new Date(year, month, day, 23, 59, 59, 999).getTime();

        this.setState({selectedToDate: toTimestamp});
    }

    goToItemActionsReport = () => {
        this.props.history.push('/app/reports/itemactionsreport/' + this.state.selectedHotel + '/' + this.state.selectedFromDate + '/' + this.state.selectedToDate);
    }

    render() {
        return(
            <Layout>
                <Content style={{ margin: 0, padding: 0, background: '#fff' }}>
                    <Spin spinning={this.state.loading} size="large">
                        <Card loading={false} bordered={false} title="Reporte de cantidades - Artículos">
                            <Row style={{marginTop: 30}}>
                                <Col span={4} offset={2}>
                                    <p>Hotel:</p>
                                </Col>
                                <Col span={10} offset={2}>
                                    <Select onChange={this.setSelectedHotel} style={{ width: '100%' }}>
                                        {this.state.hotelsOptions}
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={4} offset={2}>
                                    <p>Fecha inicial:</p>
                                </Col>
                                <Col span={10} offset={2}>
                                    <DatePicker 
                                        onChange={(date, dateString) => this.setSelectedFromDate(date, dateString)} 
                                        disabled={!this.state.selectedHotel} 
                                        placeholder="Selecciona una fecha inicial" 
                                        format="YYYY-MM-DD"
                                        style={{width: '100%'}}
                                    />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={4} offset={2}>
                                    <p>Fecha final:</p>
                                </Col>
                                <Col span={10} offset={2}>
                                    <DatePicker 
                                        onChange={(date, dateString) => this.setSelectedToDate(date, dateString)} 
                                        disabled={!this.state.selectedHotel} 
                                        placeholder="Selecciona una fecha final" 
                                        format="YYYY-MM-DD"
                                        style={{width: '100%'}}
                                    />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 40}}>
                                <Col span={20} offset={2}>
                                    <ButtonGroup>
                                        <Button 
                                            type="primary" 
                                            onClick={this.goToItemActionsReport} 
                                            disabled={!this.state.selectedHotel || !this.state.selectedFromDate || !this.state.selectedToDate || (this.state.selectedFromDate > this.state.selectedToDate)}
                                        >
                                            Ver reporte
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </Card>  
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(ItemActionsReport);