import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth, API } from 'aws-amplify';
import { Layout, Row, Table, Spin, Button, Descriptions, Tabs, Tag } from 'antd';
import { LeftOutlined, PrinterOutlined } from '@ant-design/icons';

import PrintableTicket from '../../../components/UI/Modals/PrintableTicket/PrintableTicket';
import PrintAllTickets from '../../../components/UI/Modals/PrintAllTickets/PrintAllTickets';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;
const { TabPane } = Tabs;

const ticketsColumns = [{
    title: '$',
    dataIndex: 'price'
}, {
    title: 'Comprobantes',
    dataIndex: 'tickets'
}, {
    title: 'Cantidad',
    dataIndex: 'quantity'
}, {
    title: 'Total',
    dataIndex: 'total'
}];

const foliosColumns = [{
    title: 'Fecha',
    dataIndex: 'date'
}, {
    title: 'Folios',
    dataIndex: 'folios'
}];

const vouchersColumns = [{
    title: 'Fecha comprobante',
    dataIndex: 'date'
}, {
    title: 'Folio',
    dataIndex: 'folio'
}, {
    title: 'Concepto',
    dataIndex: 'concept'
}, {
    title: 'Precio',
    dataIndex: 'price'
}, {
    title: 'Cantidad',
    dataIndex: 'quantity'
}, {
    title: 'Total',
    dataIndex: 'total'
}, {
    title: '',
    dataIndex: 'invoiced'
}, {
    title: '',
    dataIndex: 'edited'
}];


class VouchersSummaryDetails extends Component {
    state = {
        loading: false,
        tickets: [],
        creditNotes: [],
        tkTotalsHeader: [],
        tkTotalsTable: [],
        tkFolios: [],
        tkSummaries: [],
        cnTotalsHeader: [],
        cnTotalsTable: [],
        cnFolios: [],
        cnSummaries: [],
        hotel: [],
        visibleTicket: false,
        visibleTicketDate: '',
        visibleTicketQuantity: 0,
        visibleTicketAmount: 1,
        visibleTicketConcept: '',
        visibleTicketFolio: '',
        visibleVoucherType: '',
        visibleTicketInvoiced: false,
        visiblePrintAllTickets: false,
        numberOfTickets: 0
    }

    idToken = '';


    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    loadData() {
        this.setState({loading: true});

        const hotelId = this.props.match.params.hotelid;
        const startDate = parseInt(this.props.match.params.startdate, 10);
        const endDate = parseInt(this.props.match.params.enddate, 10);

        const tkQueryString = 'hotelId=' + hotelId + '&type=tk&timestamp=' + startDate + '&rangeTimestamp=' + endDate;
        API.get('benackoAPI', '/vouchers?' + tkQueryString, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then(ticketsRes => {
                var totalRoomTicks = 0;
                var totalOtherTicks = 0;
                var totalTicksDict = {};
                var totalTicksRooms = {};
                var totalTicksAmounts = {};
                var tkTotal = 0;
                var totalTickets = [];
                var tkFolios = [];
                var tkSummaries = [];
                var preTickets = []; // FOR LIST

                if (ticketsRes.Items.length > 0) {

                    const fullTickets = ticketsRes.Items;
                    fullTickets.sort((a, b) => {return a.SK > b.SK});

                    fullTickets.forEach(ticketDate => {
                        const processedTickets = ticketDate.vouchers;
                        var roomTicks = 0;
                        var otherTicks = 0;
                        var dayTickets = [];
                        var ticksDict = {};
                        var ticksDictRooms = {};
                        var ticksDictAmounts = {};
                        var dayFolios = '';

                        if (processedTickets.length > 0) {
                            processedTickets.forEach(tick => {
                                if (tick.concept === 'habitación') {
                                    roomTicks += tick.quantity;
                                    totalRoomTicks += tick.quantity;
                                } else {
                                    otherTicks += tick.quantity;
                                    totalOtherTicks += tick.quantity;
                                }

                                if (!(tick.price in ticksDict)) {
                                    ticksDict[tick.price] = 1;
                                    ticksDictRooms[tick.price] = tick.quantity;
                                    ticksDictAmounts[tick.price] = (tick.quantity * tick.price);
                                } else {
                                    ticksDict[tick.price] = ticksDict[tick.price] + 1;
                                    ticksDictRooms[tick.price] += tick.quantity;
                                    ticksDictAmounts[tick.price] += (tick.quantity * tick.price);
                                }

                                if (!(tick.price in totalTicksDict)) {
                                    totalTicksDict[tick.price] = 1;
                                    totalTicksRooms[tick.price] = tick.quantity;
                                    totalTicksAmounts[tick.price] = (tick.quantity * tick.price);
                                } else {
                                    totalTicksDict[tick.price] = totalTicksDict[tick.price] + 1;
                                    totalTicksRooms[tick.price] += tick.quantity;
                                    totalTicksAmounts[tick.price] += (tick.quantity * tick.price);
                                }

                                dayFolios = dayFolios + ' ' + tick.folio + ((tick.invoiced === true) ? '(F);' : ';');

                                preTickets.push({
                                    key: tick.folio,
                                    date: '' + this.getVoucherDate(ticketDate.SK),
                                    folio: tick.folio,
                                    quantity: tick.quantity,
                                    concept: tick.concept,
                                    price: tick.price,
                                    total: (tick.quantity * tick.price),
                                    invoiced: (tick.invoiced) ? <Tag color="geekblue">Facturado</Tag> : '',
                                    edited: (tick.edited) ? <Tag color="red">Editado</Tag> : '',
                                    inv: (tick.invoiced !== true) ? false : true,
                                    type: 'tk'
                                });
                            });
                        }

                        var dayTotal = 0;

                        for (var iDict in ticksDict) {
                            const storedValue = ticksDict[iDict];
                            //const storedTotal = ticksDict[iDict] * iDict;
                            const storedRoomsQty = ticksDictRooms[iDict];
                            const storedTotal = ticksDictAmounts[iDict];

                            dayTotal += storedTotal;
                            
                            dayTickets.push({
                                key: iDict,
                                price: '$' + iDict,
                                tickets: storedValue,
                                quantity: storedRoomsQty,
                                total: '$' + storedTotal
                            });
                        }

                        tkTotal += dayTotal;

                        const dayHeader = (
                            <Descriptions title={this.getVoucherDate(ticketDate.SK)} bordered>
                                <Descriptions.Item label="Número de comprobantes">{ticketDate.vouchers.length}</Descriptions.Item>
                                <Descriptions.Item label="Habitación">{roomTicks}</Descriptions.Item>
                                <Descriptions.Item label="Otros">{otherTicks}</Descriptions.Item>
                                <Descriptions.Item label="Total">${dayTotal}</Descriptions.Item>
                            </Descriptions>
                        );
                        
                        const dayTable = (
                            <Table
                                columns={ticketsColumns} 
                                dataSource={dayTickets} 
                                size="small"
                                bordered={true}
                                locale={{ emptyText: 'No se encontraron comprobantes' }}
                                pagination={false}
                                style={{ marginTop: 20, marginBottom: 20 }}
                            />
                        );

                        tkSummaries.push(
                            <Row key={ticketDate.SK} style={{marginBottom: 30}}>
                                {dayHeader}
                                {dayTable}
                                <hr />
                            </Row>
                        );

                        tkFolios.push({
                            key: ticketDate.SK,
                            date: this.getVoucherDate(ticketDate.SK),
                            folios: dayFolios
                        });
                    });
                }

                let numberOfTickets = 0;
                for (var iDict in totalTicksDict) {
                    const storedValue = totalTicksDict[iDict];
                    const storedRoomsQty = totalTicksRooms[iDict];
                    const storedTotal = totalTicksAmounts[iDict];

                    numberOfTickets += storedValue;

                    totalTickets.push({
                        key: iDict,
                        price: '$' + iDict,
                        tickets: storedValue,
                        quantity: storedRoomsQty,
                        total: '$' + storedTotal
                    });
                }

                const totalsHeader = (
                    <Row style={{marginBottom: 20}}>
                        <Descriptions title="Totales - Comprobantes" bordered>
                            <Descriptions.Item label="Número de comprobantes">{numberOfTickets}</Descriptions.Item>
                            <Descriptions.Item label="Habitación">{totalRoomTicks}</Descriptions.Item>
                            <Descriptions.Item label="Otros">{totalOtherTicks}</Descriptions.Item>
                            <Descriptions.Item label="Total">${tkTotal}</Descriptions.Item>
                        </Descriptions>
                    </Row>
                );

                const totalsTable = (
                    <Row style={{marginBottom: 20}}>
                        <Table
                            columns={ticketsColumns} 
                            dataSource={totalTickets} 
                            size="small"
                            bordered={true}
                            locale={{ emptyText: 'No se encontraron comprobantes' }}
                            pagination={false}
                            style={{ marginBottom: 20 }}
                        />
                    </Row>
                );


                this.setState({
                    tkTotalsHeader: totalsHeader,
                    tkTotalsTable: totalsTable,
                    tkFolios: tkFolios,
                    tkSummaries: tkSummaries,
                    tickets: preTickets
                });
            })
            .then(() => {
                const cnQueryString = 'hotelId=' + hotelId + '&type=nt&timestamp=' + startDate + '&rangeTimestamp=' + endDate;
                API.get('benackoAPI', '/vouchers?' + cnQueryString, {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then(notesRes => {
                        var totalRoomTicks = 0;
                        var totalOtherTicks = 0;
                        var totalTicksDict = {};
                        var tkTotal = 0;
                        var totalTickets = [];
                        var tkFolios = [];
                        var tkSummaries = [];
        
                        if (notesRes.Items.length > 0) {
        
                            const fullTickets = notesRes.Items;
                            fullTickets.sort((a, b) => {return a.SK > b.SK});
        
                            fullTickets.forEach(ticketDate => {
                                const processedTickets = ticketDate.vouchers;
                                var roomTicks = 0;
                                var otherTicks = 0;
                                var dayTickets = [];
                                var ticksDict = {};
                                var dayFolios = '';
        
                                if (processedTickets.length > 0) {
                                    processedTickets.forEach(tick => {
                                        (tick.concept === 'habitación') ? roomTicks++ : otherTicks++;
                                        (tick.concept === 'habitación') ? totalRoomTicks++ : totalOtherTicks++;
        
                                        if (!(tick.price in ticksDict)) {
                                            ticksDict[tick.price] = 1;
                                        } else {
                                            ticksDict[tick.price] = ticksDict[tick.price] + 1;
                                        }

                                        if (!(tick.price in totalTicksDict)) {
                                            totalTicksDict[tick.price] = 1;
                                        } else {
                                            totalTicksDict[tick.price] = totalTicksDict[tick.price] + 1;
                                        }
        
                                        dayFolios = dayFolios + ' ' + tick.folio + ((tick.invoiced === true) ? '(F);' : ';');
                                    });
                                }
        
                                var dayTotal = 0;
                                for (var iDict in ticksDict) {
                                    const storedValue = ticksDict[iDict];
                                    const storedTotal = ticksDict[iDict] * iDict;
        
                                    dayTotal += storedTotal;
                                    
                                    dayTickets.push({
                                        key: iDict,
                                        price: '$' + iDict,
                                        quantity: storedValue,
                                        total: '$' + storedTotal
                                    });
                                }

                                tkTotal += dayTotal;
        
                                const dayHeader = (
                                    <Descriptions title={this.getVoucherDate(ticketDate.SK)} bordered>
                                        <Descriptions.Item label="Número de notas de crédito">{ticketDate.vouchers.length}</Descriptions.Item>
                                        <Descriptions.Item label="Habitación">{roomTicks}</Descriptions.Item>
                                        <Descriptions.Item label="Otros">{otherTicks}</Descriptions.Item>
                                        <Descriptions.Item label="Total">${dayTotal}</Descriptions.Item>
                                    </Descriptions>
                                );
                                
                                const dayTable = (
                                    <Table
                                        columns={ticketsColumns} 
                                        dataSource={dayTickets} 
                                        size="small"
                                        bordered={true}
                                        locale={{ emptyText: 'No se encontraron notas de crédito' }}
                                        pagination={false}
                                        style={{ marginTop: 20, marginBottom: 20 }}
                                    />
                                );
        
                                tkSummaries.push(
                                    <Row key={ticketDate.SK} style={{marginBottom: 30}}>
                                        {dayHeader}
                                        {dayTable}
                                        <hr />
                                    </Row>
                                );
        
                                tkFolios.push({
                                    key: ticketDate.SK,
                                    date: this.getVoucherDate(ticketDate.SK),
                                    folios: dayFolios
                                });
                            });
                        }
        
                        for (var iDict in totalTicksDict) {
                            const storedValue = totalTicksDict[iDict];
                            const storedTotal = totalTicksDict[iDict] * iDict;
        
                            totalTickets.push({
                                key: iDict,
                                price: '$' + iDict,
                                quantity: storedValue,
                                total: '$' + storedTotal
                            });
                        }
        
                        const totalsHeader = (
                            <Row style={{marginBottom: 20}}>
                                <Descriptions title="Totales - Notas de crédito" bordered>
                                    <Descriptions.Item label="Número de notas de crédito">{totalRoomTicks + totalOtherTicks}</Descriptions.Item>
                                    <Descriptions.Item label="Habitación">{totalRoomTicks}</Descriptions.Item>
                                    <Descriptions.Item label="Otros">{totalOtherTicks}</Descriptions.Item>
                                    <Descriptions.Item label="Total">${tkTotal}</Descriptions.Item>
                                </Descriptions>
                            </Row>
                        );
        
                        const totalsTable = (
                            <Row style={{marginBottom: 20}}>
                                <Table
                                    columns={ticketsColumns} 
                                    dataSource={totalTickets} 
                                    size="small"
                                    bordered={true}
                                    locale={{ emptyText: 'No se encontraron notas de crédito' }}
                                    pagination={false}
                                    style={{ marginBottom: 20 }}
                                />
                            </Row>
                        );
        
        
                        this.setState({
                            cnTotalsHeader: totalsHeader,
                            cnTotalsTable: totalsTable,
                            cnFolios: tkFolios,
                            cnSummaries: tkSummaries
                        });
                    })
                    .then(() => {
                        this.setState({loading: false});
                    })
                    .catch(errNotes => {
                        console.log('Error loading notes:', errNotes);
                    });
            })
            .catch(errTicks => {
                console.log('Error loading tickets:', errTicks);
            });

        API.get('benackoAPI', '/hotels/' + hotelId, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then(hotelRes => {
                const hotelDetails = hotelRes[0];

                const hotel = {
                    legalName: hotelDetails.legalName,
                    address: hotelDetails.address,
                    rfc: hotelDetails.rfc,
                    city: hotelDetails.city,
                    state: hotelDetails.state,
                    country: hotelDetails.country,
                    zipCode: hotelDetails.zipCode,
                    phoneNumber: hotelDetails.phoneNumber
                };

                this.setState({hotel: hotel, loading: false});
            })
            .catch(errHotel => {
                console.log('Error loading hotel:', errHotel);
            });//FOR LIST
    }

    getVoucherDate(vouchersDate) {
        const vouchersYear = vouchersDate.toString().substring(0, 4);
        const vouchersMonth = vouchersDate.toString().substring(4, 6);
        const vouchersDay = vouchersDate.toString().substring(6, 8);
        const vouchersDatum = new Date(vouchersYear, (vouchersMonth - 1), vouchersDay).toDateString();

        return vouchersDatum;
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    viewTicket = (ticketDate, ticketQuantity, ticketAmount, ticketConcept, ticketFolio, ticketInvoiced, voucherType) => {
        this.setState({
            visibleTicketDate: ticketDate,
            visibleTicketQuantity: ticketQuantity,
            visibleTicketAmount: ticketAmount,
            visibleTicketConcept: ticketConcept,
            visibleTicketFolio: ticketFolio,
            visibleTicketInvoiced: ticketInvoiced,
            visibleVoucherType: voucherType,
            visibleTicket: true
        });
    }

    closeTicket = () => {
        this.setState({
            visibleTicket: false
        });
    }

    viewAllPrintableTickets = () => {
        const tickets = this.state.tickets;
        //const creditNotes = this.state.creditNotes;

        //const allVouchers = tickets.concat(creditNotes);
        var preProcessedTicks = [];

        tickets.forEach(voucher => {
            preProcessedTicks.push(
                <div key={voucher.folio} style={{ marginBottom: 30 }}>
                    {this.state.hotel.legalName}
                    <br />
                    RFC: {this.state.hotel.rfc}
                    <br />
                    {this.state.hotel.address}
                    <br />
                    {this.state.hotel.city}, {this.state.hotel.state}, {this.state.hotel.country}
                    <br />
                    C.P.: {this.state.hotel.zipCode}
                    <br />
                    Tel: {this.state.hotel.phoneNumber}
                    <hr />
                    {
                        (voucher.type === 'nt') &&
                            <p>NOTA DE CRÉDITO</p>
                    }
                    Fecha: {voucher.date}
                    <br />
                    Cantidad: {
                        voucher.quantity !== 0 &&
                            voucher.quantity
                    }
                    <br />Concepto: {voucher.concept}
                    <br />
                    Monto: ${ 
                        voucher.price !== 0 &&
                            voucher.price
                    }
                    <br />
                    Total: ${voucher.quantity * voucher.price}
                    <hr />
                    Folio {voucher.folio}
                    <br />
                    COMPROBANTE SIMPLIFICADO NO DEDUCIBLE PARA EFECTOS FISCALES
                    < br/><br />
                    ORIGINAL
                    <br /><br />
                    {
                        (voucher.inv === true) &&
                            <p>FACTURADO</p>
                    }
                </div>
            );
        });

        this.setState({
            visiblePrintAllTickets: true,
            processedTicks: preProcessedTicks
        });
    }

    closeAllPrintableTickets = () => {
        this.setState({
            visiblePrintAllTickets: false,
            processedTicks: []
        });
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                            <Button type="primary" onClick={this.viewAllPrintableTickets}>
                                <PrinterOutlined />Imprimir comprobantes
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#fff' }}>
                    <Spin spinning={this.state.loading} size="large">
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Resumen - Comprobantes" key="1">
                                {this.state.tkTotalsHeader}
                                {this.state.tkTotalsTable}

                                <Table
                                    title={() => 'Folios de comprobantes'}
                                    columns={foliosColumns} 
                                    dataSource={this.state.tkFolios} 
                                    size="small"
                                    bordered={true}
                                    locale={{ emptyText: 'No se encontraron folios' }}
                                    pagination={false}
                                    style={{ marginBottom: 50 }}
                                />
                            </TabPane>
                            <TabPane tab="Comprobantes" key="2">
                                {this.state.tkSummaries}
                            </TabPane>
                            <TabPane tab="Resumen - Notas de crédito" key="3">
                                {this.state.cnTotalsHeader}
                                {this.state.cnTotalsTable}

                                <Table
                                    title={() => 'Folios de notas de crédito'}
                                    columns={foliosColumns} 
                                    dataSource={this.state.cnFolios} 
                                    size="small"
                                    bordered={true}
                                    locale={{ emptyText: 'No se encontraron folios' }}
                                    pagination={false}
                                    style={{ marginBottom: 20 }}
                                />
                            </TabPane>
                            <TabPane tab="Notas de crédito" key="4">
                                {this.state.cnSummaries}
                            </TabPane>
                            <TabPane tab="Listas" key="5">
                                <Row>
                                    <Table
                                        title={ ()  => 'Comprobantes' }
                                        loading={this.state.loading}
                                        columns={vouchersColumns} 
                                        dataSource={this.state.tickets} 
                                        size="large"
                                        bordered={false}
                                        locale={{ emptyText: 'No se encontraron comprobantes' }}
                                        pagination={false}
                                        style={{ marginBottom: 40 }}
                                        onRow={ (record) => {
                                            return {
                                                onClick: () => {
                                                    this.viewTicket(record.date, record.quantity, record.price,record.concept, record.folio, record.inv);
                                                }
                                            };
                                        }}
                                    />

                                    <Table
                                        title={ ()  => 'Notas de crédito' }
                                        loading={this.state.loading}
                                        columns={vouchersColumns} 
                                        dataSource={this.state.creditNotes} 
                                        size="large"
                                        bordered={false}
                                        locale={{ emptyText: 'No se encontraron notas de crédito' }}
                                        pagination={false}
                                        style={{ marginBottom: 40 }}
                                        onRow={ (record) => {
                                            return {
                                                onClick: () => {
                                                    this.viewTicket(record.date, record.quantity, record.price,record.concept, record.folio, record.inv, record.type);
                                                }
                                            };
                                        }}
                                    />

                                    <PrintableTicket 
                                        visible={this.state.visibleTicket} 
                                        onCancel={this.closeTicket} 
                                        hotel={this.state.hotel}
                                        rentPeriod={this.state.visibleTicketQuantity}
                                        rentRate={this.state.visibleTicketAmount}
                                        rentTotalDays={14}
                                        rentDate={this.state.visibleTicketDate}
                                        ticketConcept={this.state.visibleTicketConcept}
                                        ticketFolio={this.state.visibleTicketFolio}
                                        rentInvoice={this.state.visibleTicketInvoiced}
                                        voucherType={this.state.visibleVoucherType}
                                    />
                                </Row>
                            </TabPane>
                        </Tabs>

                        <PrintAllTickets
                            visible={this.state.visiblePrintAllTickets}
                            onCancel={this.closeAllPrintableTickets}
                            hotel={this.state.hotel}
                            processedTicks={this.state.processedTicks}
                            tickets={this.state.tickets}
                            creditNotes={this.state.creditNotes}
                        />
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId : state.auth.companyId,
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(VouchersSummaryDetails);