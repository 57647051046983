import { Auth, API } from 'aws-amplify';

class RoleService {

    static async getRoles(cid, lvl = 10000, type) {
        const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});
        
        return API.get('benackoAPI', '/roles?companyId=' + cid, {headers: {Authorization: idToken}})
            .then(response => {
                if (response == null) {
                    throw Error(response ?? 'Respuesta no válida del servidor');
                } else {
                    const preProcessedRes = response.filter(r => r.level > lvl);

                    const rType = (type === 'ad') ? 2 : 3;
                    const processedRes = preProcessedRes.filter(r => r.roleTypeId === rType);
                    
                    return processedRes;
                }
            })
            .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    }

    static async getRoleDetails(rid) {
        const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});
        
        return API.get('benackoAPI', '/roles/' + rid, {headers: {Authorization: idToken}})
            .then(response => {
                if (response == null) {
                    throw Error(response ?? 'Respuesta no válida del servidor');
                } else {                    
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    }
};

export default RoleService;
