import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API, Auth } from 'aws-amplify';
import { Button, Layout, Row, Tabs, Card, Table } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import DisableModal from '../../../components/UI/Modals/DisableModal/DisableModal';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group
const TabPane = Tabs.TabPane;

const actionsColumns = [{
    title: 'Fecha',
    dataIndex: 'timestamp'
}, {
    title: 'Acción',
    dataIndex: 'action'
}, {
    title: 'Usuario',
    dataIndex: 'user'
}, {
    title: 'Artículo',
    dataIndex: 'item'
}, {
    title: 'Cantidad',
    dataIndex: 'quantity'
}, {
    title: '',
    dataIndex: 'description'
}];


class ItemDetail extends Component {
    state = {
        loading: true,
        modalVisible: false,
        modalConfirmLoading: false,
        inventoryId: 0,
        inventoryItemId: 0,
        inventoryItemName: "",
        inventoryItemHotel: "",
        inventoryItemLocation: "",
        inventoryItemInventory: "",
        inventoryItemQuantity: 0,
        inventoryItemMinimumQuantity: 0,
        inventoryItemStandardQuantity: 0,
        inventoryItemCreateDate: null,
        inventoryItemEnabled: false,
        loadingActionsTable: true,
        breakfastBeverage: false,
        breakfastMeal: false,
        actions: []
    }

    loadData() {
        this.setState({loading: true});
        const id = this.props.match.params.id;

        API.get('benackoAPI', '/inventory-items/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( async invit => {
                const inventoryId = invit[0].inventoryId;
                const itemId = invit[0].itemId;

                const name = await this.getItemName(invit[0].itemId);
                const inventory = await this.getInventoryName(invit[0].inventoryId);
                const location = await this.getLocationName(invit[0].productLocationId);
                const createDate = new Date(invit[0].created).toLocaleString();
                const quantity = invit[0].quantity;
                const minQuantity = invit[0].minimumQuantity;
                const stdQuantity = invit[0].standardQuantity;
                const enabled = invit[0].enabled;
                const brkBev = invit[0].breakfastBeverage;
                const brkMel = invit[0].breakfastMeal;

                this.setState({
                    loading: false,
                    inventoryId: inventoryId,
                    inventoryItemId: itemId,
                    inventoryItemName: name,
                    inventoryItemLocation: location,
                    inventoryItemInventory: inventory,
                    inventoryItemQuantity: quantity,
                    inventoryItemMinimumQuantity: minQuantity,
                    inventoryItemStandardQuantity: stdQuantity,
                    inventoryItemCreateDate: createDate,
                    inventoryItemEnabled: enabled,
                    breakfastBeverage: brkBev,
                    breakfastMeal: brkMel
                });

                const actionRes = {
                    inventoryId: inventoryId,
                    itemId: itemId
                };

                return actionRes;
            })
            .then(actionRes => {
                const today = new Date();
                const endTimestamp = this.getUpperDateLimit(today);
                var dayDecrement = today;
                dayDecrement.setDate(dayDecrement.getDate() - 60);
                const startTimestamp = this.getUpperDateLimit(dayDecrement);

                API.get('benackoAPI', '/v2/inventory-actions?inventoryId=' + actionRes.inventoryId + '&startTimestamp=' + startTimestamp + '&endTimestamp=' + endTimestamp, {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then(inventoryActionsRes => {
                        var actions = [];

                        inventoryActionsRes.Items.forEach(action => {
                            const itemId = parseInt(actionRes.itemId, 10);
                            if (action.item.id === itemId) {
                                actions.push({
                                    key: action.SK,
                                    timestamp: new Date(action.SK).toLocaleString(),
                                    action: action.action,
                                    user: action.user,
                                    item: action.item.name,
                                    quantity: action.quantity,
                                    description: action.description
                                });
                            }
                        });

                        this.setState({actions: actions, loadingActionsTable: false});
                    })
                    .catch(errInventoryActions => {
                        console.log('Error loading inventory actions:', errInventoryActions);
                        this.setState({loadingActionsTable: false});
                    });
            })
            .catch( errItems => {
                console.log('Error loading items: ', errItems);

                this.setState({loading: false});
            });
    }

    getUpperDateLimit(date) {
        var tomorrow = date;
        tomorrow.setHours(23);
        tomorrow.setMinutes(59);
        tomorrow.setSeconds(59);
        tomorrow.setMilliseconds(999);
        
        return tomorrow.getTime();
    }

    async getInventoryName(inventoryId) {
        if (inventoryId === 0) {
            return 'Ninguno';
        } else {
            let inventory = await API.get('benackoAPI', '/inventories/' + inventoryId);
            let inventoryName = inventory[0].name ?? '';

            return inventoryName;
        }
    }

    async getItemName(itemId) {
        let item = await API.get('benackoAPI', '/items/' + itemId);
        let itemName = item[0].name ?? '';

        return itemName;
    }

    async getLocationName(locationId) {
        let location = await API.get('benackoAPI', '/product-locations/' + locationId);
        let locationName = location[0].name;

        return locationName;
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    goEditHandler = () => {
        this.props.history.push('/app/inventories/details/items/' + this.props.match.params.id + '/edit')
    }

    goActionsHandler = () => {
        this.props.history.push('/app/inventories/details/items/' + this.props.match.params.id + '/actions')
    }

    showDisableModal = () => {
        this.setState({modalVisible: true});
    }

    disableInventoryItem = () => {
        this.setState({modalConfirmLoading: true});
        
        const id = this.props.match.params.id;

        API.del('benackoAPI', '/inventory-items/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( () => {
                const inventoryAction = {
                    inventoryId: parseInt(this.state.inventoryId, 10),
                    action: 'remover artículo',
                    itemId: this.state.inventoryItemId,
                    itemName: this.state.inventoryItemName,
                    quantity: this.state.inventoryItemQuantity,
                    user: this.props.user.username,
                    descr: '-'
                };

                API.post('benackoAPI', '/v2/inventory-actions', {
                        body: inventoryAction
                    })
                    .then(() => {
                        this.setState({
                            modalVisible: false,
                            modalConfirmLoading: false,
                        });
        
                        this.props.history.goBack();
                    })
                    .catch(errInvAction => {
                        console.log('Error loading inventory action:', errInvAction);
                        this.setState({loading: false});
                    });
            })
            .catch( error => {
                console.log(error);

                this.setState({
                    modalVisible: false,
                    modalConfirmLoading: false,
                });
            });
    }

    closeDisableModal = () => {
        this.setState({modalVisible: false});
    }

    render() {

        const actionsTable = (
            <Table
                columns={actionsColumns}
                dataSource={this.state.actions}
                size="middle" 
                pagination={false}
                locale={{
                    emptyText: 'No se encontraron acciones'
                }} 
                loading={{
                    spinning: this.state.loadingActionsTable,
                    delay: 500,
                    size: 'large'
                }}
            />
        );

        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                            <Button type="primary" onClick={this.goEditHandler} disabled={!this.state.inventoryItemEnabled}>
                                Editar
                            </Button>
                            <Button type="danger" onClick={this.showDisableModal} disabled={!this.state.inventoryItemEnabled}>
                                Remover
                            </Button>
                            <Button type="primary" onClick={this.goActionsHandler} disabled={!this.state.inventoryItemEnabled}>
                                Acciones por rango de fecha
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#ffff', paddingTop: 15 }}>
                    <Tabs defaultActiveKey="1" tabPosition="right">
                        <TabPane tab="General" key="1">
                            <Card loading={this.state.loading} bordered={false} title={this.state.inventoryItemName}>
                                <p><strong>Inventario:</strong> {this.state.inventoryItemInventory}</p>
                                <p><strong>Cantidad actual:</strong> {this.state.inventoryItemQuantity}</p>
                                <p><strong>Cantidad mínima:</strong> {this.state.inventoryItemMinimumQuantity}</p>
                                <p><strong>Cantidad estándar:</strong> {this.state.inventoryItemStandardQuantity}</p>
                                <p><strong>Creado:</strong> {this.state.inventoryItemCreateDate}</p>
                                <p><strong>Alimento de desayuno:</strong> {(this.state.breakfastMeal) ? 'Si' : 'No'}</p>
                                <p><strong>Bebida de desayuno:</strong> {(this.state.breakfastBeverage) ? 'Si' : 'No'}</p>
                            </Card>
                        </TabPane>
                        <TabPane key="2" tab="Acciones">
                            {actionsTable}
                        </TabPane>
                    </Tabs>
                </Content>
                <DisableModal 
                    visible={this.state.modalVisible}
                    confirmLoading={this.state.modalConfirmLoading}
                    onCancel={this.closeDisableModal}
                    onOk={this.disableInventoryItem}
                    modalText="¿Deseas remover el artículo del inventario?"
                />
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        cid: state.base.cid
    };
};


export default connect(mapStateToProps, null)(ItemDetail);