import React, { Component } from 'react';
import { Button, Card, Table, Row } from 'antd';

import AddCommentModal from '../Modals/AddCommentModal/AddCommentModal';

const commentsColumns = [
    {
        title: 'Fecha',
        dataIndex: 'timestamp'
    },
    {
        title: 'Usuario',
        dataIndex: 'username'
    },
    {
        title: '',
        dataIndex: 'comment'
    }
];

class CommentsBox extends Component {
    state = {
        newComment: ''
    };

    render() {
        return (
            <Card
                bordered={false}
                loading={this.props.loading}
                style={{ marginTop: 50 }}
            >
                <Button 
                    type="primary" 
                    onClick={ () => { this.addComment.showAddModal(this.props.section) } }
                    style={{ width: '100%' }}
                    disabled={this.props.isClosed}
                >
                    Agregar comentario
                </Button>
                <Row>
                    <Table
                        columns={commentsColumns}
                        dataSource={this.props.comments}
                        bordered={false}
                        size="small"
                        locale={{ emptyText: 'No se encontraron comentarios' }}
                        pagination={{ hideOnSinglePage: true }}
                    />
                </Row>

                <AddCommentModal
                    ref={ instance => { this.addComment = instance } }
                    hotelId={this.props.hotelId}
                    type={this.props.type}
                    startTimestamp={this.props.startTimestamp}
                    section={this.props.section}
                />
            </Card>
        );
    }
}

export default CommentsBox;
