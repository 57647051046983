import React from 'react';
import { I18n } from 'aws-amplify';
import { Tooltip, Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';


const ReloadButton = ({loading = false, disabled = false, onClick = () => console.log('No click action set')}) => {

    return (
        <Tooltip placement="bottom" title={'Recargar'} mouseEnterDelay={0.5} mouseLeaveDelay={0.1}>
            <Button
                type="primary"
                size="middle"
                icon={<ReloadOutlined />}
                loading={loading}
                disabled={disabled}
                onClick={onClick}
            />
        </Tooltip>
    );
}

export default ReloadButton;