import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API } from 'aws-amplify';
import { Layout, Row, Col, Spin, Card, Form, Select, Button, DatePicker } from 'antd';

const { Content } = Layout;
const Option = Select.Option;


class CreditNotesGenerate extends Component {
    state = {
        loading: false,
        hotelsOptions: [],
        selectedHotel: null,
        selectedDate: null,
        generateButton: false
    }


    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState({loading: true});

        // HOTELS
        const enabledHotels = this.props.hotels;
        var hotels = [];

        for (var i in enabledHotels) {
            hotels.push(<Option key={i}>{enabledHotels[i]}</Option>);
        }

        this.setState({hotelsOptions: hotels, loading: false});
    }

    setSelectedHotel = (selectedHotel) => {
        this.setState({selectedHotel: selectedHotel});
    };

    setDateHandler = (date, dateString) => {
        const beautifiedDate = dateString.split('-').join('');

        const companyId = this.props.companyId;
        const hotelId = this.state.selectedHotel;
        const yyyymmdd = beautifiedDate;
        const type = 'nt';

        const queryString = 'companyId=' + companyId + '&hotelId=' + hotelId + '&timestamp=' + yyyymmdd + '&type=' + type;

        API.get('benackoAPI', '/vouchers?' + queryString, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( vouchersRes => {
                var generateButton = false;

                if (vouchersRes.Items.length <= 0 || vouchersRes.Items[0].vouchers.length <= 0) {
                    generateButton = true;
                }

                this.setState({
                    selectedDate: beautifiedDate,
                    generateButton: generateButton
                });
            })
            .catch( errVouchers => {
                console.log('Error loading vouchers:', errVouchers);
            });
    }

    generateCreditNotesHandler = () => {
        this.setState({loading: true});
        
        const companyId = this.props.companyId;
        const hotelId = this.state.selectedHotel;
        const yyyymmdd = this.state.selectedDate;
        const type = 'nt';

        const queryString = 'companyId=' + companyId + '&hotelId=' + hotelId + '&timestamp=' + yyyymmdd + '&type=' + type;

        const nextId = 'h' + hotelId + 'd' + yyyymmdd;

        API.get('benackoAPI', '/vouchers?' + queryString, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( notesRes => {
                
                if (notesRes.Items.length <= 0) {
                    API.post('benackoAPI', '/vouchers', {
                        body: {
                            companyId: companyId,
                            hotelId: hotelId,
                            yyyymmdd: yyyymmdd,
                            type: type
                        }
                    })
                        .then( () => {

                            this.setState({loading: false}, () => {
                                this.props.history.push('/app/vouchers/nt_generate/' + nextId);
                            });
                        })
                        .catch( errVouchers => {
                            console.log('Error creating vouchers:', errVouchers);

                            this.setState({loading: false});
                        });
                } else {

                    this.setState({loading: false}, () => {
                        this.props.history.push('/app/vouchers/nt_generate/' + nextId);
                    });
                }
            })
            .catch(errNotes => {
                console.log('Error generating notes:', errNotes);

                this.setState({loading: false});
            });
    }

    render() {
        return(
            <Layout>
                <Content style={{width: '100%', margin: 0, padding: 0, background: '#fff', paddingLeft: 20}}>
                    <Spin spinning={this.state.loading} size="large">
                        <Card loading={false} bordered={false} title="Notas de crédito">
                            <Form className="login-form">
                                <Row style={{marginTop: 30}}>
                                    <Col span={20} offset={2}>
                                        <p>Hotel:</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={20} offset={2}>
                                        <Select style={{width: '100%'}} onChange={this.setSelectedHotel}>
                                            {this.state.hotelsOptions}
                                        </Select>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: 10}}>
                                    <Col span={20} offset={2}>
                                        <p>Fecha:</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={20} offset={2}>
                                        <DatePicker
                                            style={{width: '100%'}}
                                            onChange={(date, dateString) => this.setDateHandler(date, dateString)} 
                                            disabled={!this.state.selectedHotel} 
                                            placeholder="Selecciona una fecha" 
                                            format="YYYY-MM-DD" 
                                        />
                                    </Col>
                                </Row>
                                <Row style={{marginTop: 50}}>
                                <Col span={4} offset={2}>
                                    <Button type="primary" 
                                        onClick={this.generateCreditNotesHandler}
                                        disabled={!this.state.generateButton} className="login-form-button"
                                    >
                                        Generar notas de crédito
                                    </Button>
                                </Col>
                            </Row>
                            </Form>
                        </Card>  
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.auth.companyId,
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(CreditNotesGenerate);