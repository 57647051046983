import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, List } from 'antd';

import { disableHotelSelector, enableHotelSelector } from '../../../store/actions';

const { Content } = Layout;

const Reports = () => {
    const dispatch = useDispatch();
    const role = useSelector(state => state.auth.role);
    
    const data = [
        {
            title: 'Mobiliario - Hotel',
            path: '/app/maintenance/reports/hotel-furniture',
            description: 'Reporte de mobiliario del hotel',
            permission: 'p_mntn_rep_rfh'
        },
        {
            title: 'Órdenes de trabajo',
            path: '/app/maintenance/reports/work-orders-selector',
            description: 'Reporte de órdenes de trabajo',
            permission: 'p_mntn_rep_rwo'
        },
        {
            title: 'Servicios públicos',
            path: '/app/maintenance/reports/utilities-selector',
            description: 'Reporte de servicios públicos',
            permission: 'p_mntn_rep_ru'
        }
    ];

    useEffect(() => {
        dispatch(enableHotelSelector());

        return () => {
            dispatch(disableHotelSelector());
        };
    }, []);


    return (
        <Layout>
            <Content style={{margin: 0, padding: 0, paddingLeft: 30, background: '#fff'}}>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={item => (
                        (role[item.permission] === 0) ? <div /> : <NavLink to={item.path}>
                            <List.Item>
                                <List.Item.Meta
                                title={item.title}
                                description={item.description}
                                />
                            </List.Item>
                        </NavLink>
                    )}
                />
            </Content>
        </Layout>
    );
}

export default Reports;