import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, Col, Row, Space } from 'antd';

import CreateButton from '../../../../components/UI/Buttons/CreateButton/CreateButton';
import SectionTitle from '../../../../components/UI/Panels/SectionTitle/SectionTitle';
import UtilityEvents from './UtilityEvents/UtilityEvents';

import { disableHotelSelector, enableHotelSelector } from '../../../../store/actions';

const UtilityDetails = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    
    const [utilityType, setUtilityType] = useState(null);
    const [utilityLabel, setUtilityLabel] = useState('');

    useEffect(() => {
        dispatch(enableHotelSelector());

        return () => {
            dispatch(disableHotelSelector());
        };
    }, []);

    useEffect(() => {
        if (location != null) {
            const type = location.search?.substring(6) ?? '';
            setUtilityType(type);

            switch (type) {
                case 'water':
                    setUtilityLabel('Agua')
                    break;
                case 'electricity':
                    setUtilityLabel('Electricidad');
                    break;
                case 'gas':
                    setUtilityLabel('Gas');
                    break;
                default:
                    break;
            }
        }
    }, [location]);

    const goToAdd = () => {
        history.push('/app/maintenance/utilities/create');
    }

    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 10}}>
                <Col span={22}>
                    <Row style={{marginLeft: 40, width: '100%', height: 40}}>
                        <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <NavLink to={'/app/maintenance/utilities'}>{'Servicios'}</NavLink>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {'Detalle de servicio'}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Col>
                <Col span={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Space>
                        <CreateButton onClick={goToAdd}/>
                    </Space>
                </Col>
            </Row>

            <SectionTitle title={utilityLabel ?? ''} />

            <UtilityEvents utility={utilityType} />
        </Row>
    );
}

export default UtilityDetails;