import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { Layout, Row, Col, Spin, Card, Select, Button, DatePicker } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const Option = Select.Option;
const ButtonGroup = Button.Group;


class InventoryItemActions extends Component {
    state = {
        loading: false,
        dateOptions: [],
        selectedFromDate: null,
        selectedToDate: null
    }

    idToken = '';


    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    loadData() {
        this.setState({loading: true});

        // DAYS
        const today = new Date();
        const upperLimit = this.getUpperDateLimit(today);
        
        var days = [];
        days.push(<Option key={upperLimit}>{today.toDateString()}</Option>);

        var dayDecrement = today;
        for (var i = 0; i < 60; i++) {
            dayDecrement.setDate(dayDecrement.getDate() - 1);
            days.push(<Option key={dayDecrement.getTime()}>{dayDecrement.toDateString()}</Option>);
        }
        this.setState({dateOptions: days});

        this.setState({loading: false});
    }

    getUpperDateLimit(date) {
        var tomorrow = date;
        tomorrow.setHours(23);
        tomorrow.setMinutes(59);
        tomorrow.setSeconds(59);
        tomorrow.setMilliseconds(999);
        
        return tomorrow.getTime();
    }

    setSelectedFromDate = (date, dateString) => {
        var beautifiedDate = date.toDate();
        beautifiedDate.setHours(0);
        beautifiedDate.setMinutes(0);
        beautifiedDate.setSeconds(0);
        beautifiedDate.setMilliseconds(0);

        this.setState({selectedFromDate: beautifiedDate.getTime()});
    }

    setSelectedToDate = (date, dateString) => {
        var beautifiedDate = date.toDate();
        beautifiedDate.setHours(23);
        beautifiedDate.setMinutes(59);
        beautifiedDate.setSeconds(59);
        beautifiedDate.setMilliseconds(999);

        this.setState({selectedToDate: beautifiedDate.getTime()});
    }

    goToVouchersDetails = () => {
        this.props.history.push('/app/inventories/details/items/' + this.props.match.params.id + '/actions/' + this.state.selectedFromDate + '/' + this.state.selectedToDate);
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    goToInventoriesHandler = () => {
        this.props.history.push('/app/inventories');
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#fff' }}>
                    <Spin spinning={this.state.loading} size="large">
                        <Card loading={false} bordered={false} title="Acciones de inventario">
                            <Row style={{marginTop: 10}}>
                                <Col span={4} offset={2}>
                                    <p>Fecha inicial:</p>
                                </Col>
                                <Col span={10} offset={2}>
                                    <DatePicker 
                                        onChange={(date, dateString) => this.setSelectedFromDate(date, dateString)} 
                                        placeholder="Selecciona una fecha inicial" 
                                        format="YYYY-MM-DD"
                                        style={{width: '100%'}}
                                    />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={4} offset={2}>
                                    <p>Fecha final:</p>
                                </Col>
                                <Col span={10} offset={2}>
                                    <DatePicker 
                                        onChange={(date, dateString) => this.setSelectedToDate(date, dateString)} 
                                        placeholder="Selecciona una fecha final" 
                                        format="YYYY-MM-DD"
                                        style={{width: '100%'}}
                                    />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 40}}>
                                <Col span={20} offset={2}>
                                    <ButtonGroup>
                                        <Button 
                                            type="primary" 
                                            onClick={this.goToVouchersDetails} 
                                            disabled={!this.state.selectedFromDate || !this.state.selectedToDate || (this.state.selectedFromDate > this.state.selectedToDate)}
                                        >
                                            Ver acciones de inventario
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </Card>  
                    </Spin>
                </Content>
            </Layout>
        );
    }
}


export default InventoryItemActions;