import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { Button, Col, Layout, Row, Select, Steps, Spin, Table } from 'antd';

import PrintableOrderModal from '../../../components/UI/Modals/PrintableOrderModal/PrintableOrderModal';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;
const Option = Select.Option;
const Step = Steps.Step;

const columns = [
    {
        title: 'Artículo',
        dataIndex: 'item',
        key: 'item'
    }, {
        title: 'Ubicación',
        dataIndex: 'location',
        key: 'location'
    }, {
        title: 'Cantidad estándar',
        dataIndex: 'stdqty',
        key: 'stdqty'
    }, {
        title: 'Cantidad',
        dataIndex: 'quantity',
        key: 'quantity'
    }, {
        title: 'Diferencia',
        dataIndex: 'difference',
        key: 'difference'
    }
];


class SupplyOrder extends Component {
    state = {
        loading: false,
        currentStep: 0,
        items: [],
        locations: [],
        hotelsOptions: [],
        selectedHotel: [],
        inventoriesOptions: [],
        selectedInventory: [],
        supplyOrderData: [],
        visibleOrder: false
    }

    idToken = '';
    userHotels = [];


    loadData() {
        this.setState({loading: true});
        this.setHotelsMenu();
        this.loadItems();
        this.setState({loading: false});
    }

    async loadItems() {
        await API.get('benackoAPI', '/items?companyId=' + this.props.companyId, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( itemsRes => {
                var items = [];

                itemsRes.forEach( item => {
                    items[item.id] = item;
                });

                return items;
            })
            .then( items => {
                this.setState({items: items});
            })
            .catch( errItems => {
                console.log('Error loading items:', errItems);
            });
    }

    async loadLocations(hotelId) {
        await API.get('benackoAPI', '/product-locations?hotelId=' + hotelId, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( locationsRes => {
                var locations = [];

                locationsRes.forEach( location => {
                    locations[location.id] = location;
                });

                return locations;
            })
            .then( locations => {
                this.setState({locations: locations});
            })
            .catch( errLocations => {
                console.log('Error loading locations:', errLocations);
            });
    }

    setHotelsMenu() {
        const enabledHotels = this.props.hotels;
        var hotels = [];

        for (var i in enabledHotels) {
            hotels.push(<Option key={i}>{enabledHotels[i]}</Option>);
        }

        this.setState({hotelsOptions: hotels});
    }

    selectHotelHandler = (value) => {
        this.setState({loading: true, selectedHotel: value});

        API.get('benackoAPI', '/inventories?hotelId=' + value, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( response => {
                var inventories = [];

                response.forEach( inventory => {
                    if (inventory.sourceInventoryId !== 0) {
                        inventories.push(<Option key={inventory.id}>{inventory.name}</Option>);
                    }
                });

                this.setState({ inventoriesOptions: inventories, loading: false });
            })
            .then( async () => {
                await this.loadLocations(this.state.selectedHotel);
            })
            .catch( errInventories => {
                console.log('Error loading inventories: ', errInventories);

                this.setState({ loading: false });
            });
    }

    selectInventoryHandler = (value) => {
        this.setState({selectedInventory: value});
    }

    goToSecondStep = async () => {
        this.setState({ loading: true });

        API.get('benackoAPI', '/inventory-items?inventoryId=' + this.state.selectedInventory, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( async response => {
                var supplyOrder = [];

                response.forEach( async item => {
                    if (item.enabled === 1 && item.quantity <= item.standardQuantity) {
                        const name = this.state.items[item.itemId].name;
                        const location = this.state.locations[item.productLocationId].name;
                        const quantity = item.quantity;
                        const stdqty = item.standardQuantity;
                        const difference = stdqty - quantity;

                        supplyOrder.push({
                            key: item.id,
                            item: name,
                            location: location,
                            quantity: quantity,
                            stdqty: stdqty,
                            difference: difference
                        });
                    }
                });
                
                await supplyOrder.sort( (a, b) => a.item.localeCompare(b.item));

                return supplyOrder;                
            })
            .then( supplyOrder => {
                this.setState({ supplyOrderData: supplyOrder, currentStep: 1, loading: false });
            })
            .catch( errInventories => {
                console.log('Error loading inventories: ', errInventories);

                this.setState({ loading: false });
            });
    }

    goPreviousStep = () => {
        const newStep = this.state.currentStep - 1;
        this.setState({
            currentStep: newStep,
            selectedHotel: [],
            inventoriesOptions: [],
            selectedInventory: [],
            locations: []
        });
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    viewPrintableOrder = () => {
        this.setState({ visibleOrder: true });
    }

    closePrintableOrder = () => {
        this.setState({ visibleOrder: false });
    }

    render() {
        const stepOne = (
            <Spin spinning={this.state.loading} size="large">
                <Row style={{width: 600}}>
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Selecciona el hotel"
                        onChange={this.selectHotelHandler}
                    >
                        {this.state.hotelsOptions}
                    </Select>
                </Row>
                <Row style={{width: 600, marginTop: 20}}>
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Selecciona el inventario"
                        onChange={this.selectInventoryHandler}
                    >
                        {this.state.inventoriesOptions}
                    </Select>
                </Row>
                <Row style={{ marginTop: 30 }}>
                    <Button 
                        type="primary" 
                        onClick={this.goToSecondStep} 
                        disabled={(this.state.selectedHotel.length <= 0 || this.state.selectedInventory.length <= 0) ? true : false}
                    >
                        Siguiente
                    </Button>
                </Row>
            </Spin>
        );

        const stepTwo = (
            <Spin spinning={this.state.loading} size="large">
                <Row>
                    <Table 
                        columns={columns} 
                        dataSource={this.state.supplyOrderData}
                        size="middle" 
                        pagination={{
                            pageSize: 8,
                            showTotal: (total) => `Total: ${total} artículos`
                        }}
                        locale={{
                            emptyText: 'No se encontraron artículos por debajo de la cantidad estándar'
                        }} 
                    />
                </Row>
                <Row style={{ marginTop: 40 }}>
                    <ButtonGroup>
                        <Button type="primary" onClick={this.goPreviousStep}>Regresar</Button>
                        <Button type="primary" onClick={this.viewPrintableOrder}>Imprimir</Button>
                    </ButtonGroup>
                </Row>
            </Spin>
        );

        let renderedStep = null;
        if (this.state.currentStep === 0) {
            renderedStep = stepOne;
        }

        if (this.state.currentStep === 1) {
            renderedStep = stepTwo;
        }

        return (
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#fff', paddingTop: 15 }}>
                    <Steps size="small" current={this.state.currentStep}>
                        <Step title="Seleccionar hotel e inventario" />
                        <Step title="Orden de surtido" />
                    </Steps>
                    <Row style={{marginTop: 50}}>
                        <Spin spinning={this.state.loading} size="large">
                        <Col span={20} offset={2}>
                            {renderedStep}
                        </Col>
                        </Spin>
                    </Row>
                </Content>

                <PrintableOrderModal 
                    visible={this.state.visibleOrder} 
                    onCancel={this.closePrintableOrder}
                    title="Orden de surtido"
                    inventory={this.state.selectedInventory}
                    columns={columns}
                    items={this.state.supplyOrderData}
                />
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.auth.companyId,
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(SupplyOrder);