import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Breadcrumb, Button, Col, Popconfirm, Row, Space, Table, Tabs } from 'antd';

import MaintenanceService from '../../../../services/maintenance';

import { errorNotification } from '../../../../components/UI/Notifications/Notifications';
import WorkOrderDetailsTab from './WorkOrderDetailsTab/WorkOrderDetailsTab';

const TabPane = Tabs.TabPane;

const WorkOrderDetails = () => {
    const {workOrderId} = useParams();
    const history = useHistory();

    const hid = useSelector(state => state.ui.selectedHotel ?? 0);
    const userId = useSelector(state => state.auth.user?.id ?? 0);
    const username = useSelector(state => state.auth.user?.username ?? '');
    const isValidator = useSelector(state => state.auth.role?.p_maintenance_validator ?? 0);
    
    const [loading, setLoading] = useState(false);
    const [workOrder, setWorkOrder] = useState({});
    const [supplies, setSupplies] = useState([]);
    const [allowReopening, setAllowReopening] = useState(false);

    const suppliesColumns = [
        {title: 'Nombre', dataIndex: 'name', defaultSortOrder: 'descend', sorter: (a,b) => a.name < b.name},
        {title: 'Cantidad', dataIndex: 'quantity'}
    ];

    useEffect(() => {
        if (workOrderId != null) {
            loadData();
        }
    }, [workOrderId]);

    const loadData = async () => {
        setLoading(true);

        await loadWorkOrder();

        setLoading(false);
    };

    const loadWorkOrder = async () => {
        await MaintenanceService.getWorkOrder(workOrderId)
            .then(res => {
                if (res != null) {
                    setWorkOrder(res);
                    setAllowReopening(isValidator == 1 && res.closeUserId !== '' && res.closeUserId !== '0' && res.validationUserId === '');
                }
            })
            .catch(err => errorNotification(err));

        await MaintenanceService.getWorkOrderSupplies(workOrderId)
            .then(res => {
                if (res != null) {
                    setSupplies(res);
                }
            })
            .catch(err => errorNotification(err));
    };

    const close = async () => {
        setLoading(true);

        await MaintenanceService.editWorkOrder(hid, workOrderId, 'closeUserId', userId, userId, username, 'EDIT-WORK_ORDER')
                                .catch(err => {
                                    errorNotification(err);
                                    return;
                                });
        
        await MaintenanceService.editWorkOrder(hid, workOrderId, 'closeUsername', username, userId, username, 'EDIT-WORK_ORDER')
                                .catch(err => {
                                    errorNotification(err);
                                    return;
                                });

        await MaintenanceService.editWorkOrder(hid, workOrderId, 'closeTimestamp', '-', userId, username, 'EDIT-WORK_ORDER')
                                .catch(err => {
                                    errorNotification(err);
                                    return;
                                })
                                .finally(() => history.go(-1));
    };

    const validate = async () => {
        setLoading(true);

        await MaintenanceService.editWorkOrder(hid, workOrderId, 'validationUserId', userId, userId, username, 'EDIT-WORK_ORDER')
                                .catch(err => {
                                    errorNotification(err);
                                    return;
                                });
        
        await MaintenanceService.editWorkOrder(hid, workOrderId, 'validationUsername', username, userId, username, 'EDIT-WORK_ORDER')
                                .catch(err => {
                                    errorNotification(err);
                                    return;
                                });

        await MaintenanceService.editWorkOrder(hid, workOrderId, 'validationTimestamp', '-', userId, username, 'EDIT-WORK_ORDER')
                                .catch(err => {
                                    errorNotification(err);
                                    return;
                                })
                                .finally(() => history.go(-1));
    };

    const reOpen = async () => {
        setLoading(true);

        await MaintenanceService.editWorkOrder(hid, workOrderId, 'closeUserId', 0, userId, username, 'EDIT-WORK_ORDER')
                                .catch(err => {
                                    errorNotification(err);
                                    return;
                                });
        
        await MaintenanceService.editWorkOrder(hid, workOrderId, 'closeUsername', '', userId, username, 'EDIT-WORK_ORDER')
                                .catch(err => {
                                    errorNotification(err);
                                    return;
                                });

        await MaintenanceService.editWorkOrder(hid, workOrderId, 'closeTimestamp', '-', userId, username, 'EDIT-WORK_ORDER')
                                .catch(err => {
                                    errorNotification(err);
                                    return;
                                })
                                .finally(() => history.go(-1));
    };

    const goToAdd = () => {
        history.push('/app/maintenance/work-orders/' + workOrderId + '/supply-add');
    };


    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 10}}>
                <Col span={22}>
                    <Row style={{marginLeft: 40, width: '100%', height: 40}}>
                        <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <NavLink to={'/app/maintenance/work-orders'}>{'Órdenes de trabajo'}</NavLink>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {'Detalle de orden de trabajo'}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Col>
                <Col span={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Space>
                        <Button type='primary' loading={loading} onClick={() => goToAdd()} disabled={workOrder?.closeUserId != '' && workOrder?.closeUserId != '0'}>Agregar artículos</Button>

                        <Popconfirm title={'¿Deseas cerrar la orden de trabajo?'}  onConfirm={() => close()} cancelText='Cancelar'>
                            <Button type='primary' loading={loading} disabled={workOrder?.closeUserId != '' && workOrder?.closeUserId != '0'}>Cerrar</Button>
                        </Popconfirm>

                        { allowReopening &&
                            <Popconfirm title={'¿Deseas re-abrir la orden de trabajo?'}  onConfirm={() => reOpen()} cancelText='Cancelar'>
                                <Button type='primary' loading={loading}>Re-abrir</Button>
                            </Popconfirm>
                        }

                        <Popconfirm title={'¿Deseas validar la orden de trabajo?'}  onConfirm={() => validate()} cancelText='Cancelar'>
                            <Button type='primary' loading={loading} disabled={isValidator == 0 || workOrder?.closeUserId == '' || workOrder?.closeUserId == '0' || workOrder?.validationUserId != ''}>Validar</Button>
                        </Popconfirm>
                    </Space>
                </Col>
            </Row>

            <Row style={{display: 'flex', alignItems: 'center', width: '100%', height: 60}}>
                <span style={{marginLeft: 0, fontSize: 20}}>{workOrder.name ?? ''}</span>
            </Row>

            <Tabs defaultActiveKey={1}>
                <TabPane tab="Información general" key="1">
                    <WorkOrderDetailsTab/>
                </TabPane>
                <TabPane tab="Artículos" key="2">
                    <Table
                        style={{cursor: 'pointer'}}
                        size="middle"
                        columns={suppliesColumns}
                        rowKey={'id'}
                        dataSource={supplies}
                        scroll={{y: 440}}
                        locale={{
                            emptyText: 'No se encontraron datos',
                            cancelSort: 'Haz clic para cancelar ordenar',
                            triggerAsc: 'Haz clic para ordenar - ascendente',
                            triggerDesc: 'Haz clic para ordenar - descendente'
                        }}
                        pagination={{
                            pageSize: 8,
                            showTotal: (total) => `Total: ${total}`
                        }}
                        loading={{
                            spinning: loading,
                            delay: 500,
                            size: 'large'
                        }}
                    />
                </TabPane>
            </Tabs>
        </Row>
    );
}

export default WorkOrderDetails;