import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Popconfirm, Row, Skeleton, Table } from 'antd';
import { DeleteFilled } from '@ant-design/icons';

import MaintenanceService from '../../../../../services/maintenance';
import { errorNotification, successNotification } from '../../../../../components/UI/Notifications/Notifications';


const PlanActivitiesTab = () => {
    const {planId} = useParams();
    const history = useHistory();

    const hid = useSelector(state => state.ui.selectedHotel ?? 0);
    const userId = useSelector(state => state.auth.user?.id ?? 0);
    const username = useSelector(state => state.auth.user?.username ?? '');

    const [loading, setLoading] = useState(true);
    const [activities, setActivities] = useState([]);

    const activitiesColumns = [
        {title: 'Descripción', dataIndex: 'description', defaultSortOrder: 'descend', sorter: (a,b) => a.name < b.name},
        {title: 'Periodicidad', dataIndex: 'periodicity', render: (a) => renderPeriod(a)},
        {title: 'Siguiente ejecución', dataIndex: 'nextTriggerTimestamp', render: (a) => new Date(a[0], (a[1] - 1), a[2]).toLocaleDateString()},
        {dataIndex: 'id', render: (a) => <Popconfirm title={'¿Deseas borrar la actividad del plan?'}  onConfirm={() => deleteActivity(a)} cancelText='Cancelar'><DeleteFilled /></Popconfirm>}
    ];

    useEffect(() => {
        if (planId != null) {
            loadData();
        }
    }, [planId]);

    const loadData = async () => {
        setLoading(true);

        await MaintenanceService.getPlanActivities(planId)
            .then(res => {
                if (res != null) {
                    setActivities(res);
                }
            })
            .catch(err => errorNotification(err))
            .finally(() => setLoading(false));
    };

    const renderPeriod = (period) => {
        let periodSplit = period.split("_") ?? [];

        switch (periodSplit[0]) {
            case 'D':
                return 'DIARIO';
            case 'W':
                var periodString = 'SEMANAL ' + calculateWeekLabel(periodSplit);
                return periodString;
            case 'M':
                var periodString = 'MENSUAL ' + calculateMonthLabel(periodSplit);
                return periodString;
            case 'Y':
                var periodString = 'ANUAL ' + calculateYearLabel(periodSplit);
                return periodString;
            default:
                return period;
        }
    };

    const calculateWeekLabel = (periodSplit) => {
        var day = calculateDayLabel(periodSplit[1] ?? '');
        return '(' + day + ')';
    };

    const calculateMonthLabel = (periodSplit) => {
        var type = periodSplit[1] ?? '';

        if (type === 'D') {
            var day = periodSplit[2] ?? '';
            return '(DÍA ' + day + ')';
        }

        if (type === 'P') {
            var order = periodSplit[2] ?? '';
            var day = calculateDayLabel(periodSplit[3] ?? '');
            return '(' + order + 'º ' + day + ' DEL MES)';
        }
    };

    const calculateYearLabel = (periodSplit) => {
        var month = periodSplit[1] ?? '';
        var day = periodSplit[2] ?? '';

        return '(' + month + '-' + day + ')';
    };

    const calculateDayLabel = (day) => {
        switch (day) {
            case 'MON':
                return 'LUNES';
            case 'TUE':
                return 'MARTES';
            case 'WED':
                return 'MIÉRCOLES';
            case 'THU':
                return 'JUEVES';
            case 'FRI':
                return 'VIERNES';
            case 'SAT':
                return 'SÁBADO';
            case 'SUN':
                return 'DOMINGO';
            default:
                return '';
        }
    };

    const deleteActivity = async (activityId) => {
        setLoading(true);
        
        await MaintenanceService.deletePlanActivity(hid, planId, activityId, userId, username)
            .then(() => successNotification('La actividad ha sido borrada exitosamente'))
            .catch(err => errorNotification(err))
            .finally(() => {
                setLoading(false);
                history.go(-1);
            });
    };


    return (
        <Skeleton loading={loading} paragraph={{rows: 9, cols: 1}} active>
            <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                <Table
                    style={{cursor: 'pointer'}}
                    size="middle"
                    columns={activitiesColumns}
                    rowKey={'id'}
                    dataSource={activities}
                    scroll={{y: 440}}
                    locale={{
                        emptyText: 'No se encontraron datos',
                        cancelSort: 'Haz clic para cancelar ordenar',
                        triggerAsc: 'Haz clic para ordenar - ascendente',
                        triggerDesc: 'Haz clic para ordenar - descendente'
                    }}
                    pagination={{
                        pageSize: 8,
                        showTotal: (total) => `Total: ${total}`
                    }}
                    loading={{
                        spinning: loading,
                        delay: 500,
                        size: 'large'
                    }}
                />
            </Row>
        </Skeleton>
    );
};

export default PlanActivitiesTab;