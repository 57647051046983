import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { Button, Form, Input, Layout, Row, Col, Spin } from 'antd';
import { LeftOutlined, TableOutlined } from '@ant-design/icons';

import { connect } from 'react-redux';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;


class Edit extends Component {
    state = {
        loading: false,
        locationName: '',
        pcid: null
    }


    loadData() {
        this.setState({loading: true});
        this.setState({pcid: this.props.cid});
        const id = this.props.match.params.id;

        API.get('benackoAPI', '/product-locations/' + id)
            .then(response => {
                const name = response[0].name;
                
                this.setState({locationName: name, loading: false});
            })
            .catch( error => {
                console.log(error);
                this.setState({loading: false});
            });
    }

    componentDidMount() {
        this.loadData();
    }

    editLocationHandler = () => {
        this.setState({loading: true});
        const id = this.props.match.params.id;

        const beautifiedName = this.state.locationName.trim().toLowerCase();

        API.put('benackoAPI', '/product-locations/' + id, {//TODO: REMOVE OLD REFERENCE
                body: { name: beautifiedName }
            })
            .then( () => {
                this.setState({loading: false});
                this.props.history.goBack();
            })
            .catch( error => {
                console.log(error);
                this.setState({loading: false});
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    render() {
        return(
            <Layout>
                <Header style={{width: '100%', background: '#fff', padding: 0, paddingLeft: 20}}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{width: '100%', margin: 0, padding: 0, background: '#ffff', paddingLeft: 20}}>
                    <Spin spinning={this.state.loading} size="large">
                        <Form className="login-form">
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Nombre:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Input style={{width: '100%'}} onChange={(e) => this.setState({locationName: e.target.value})} prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Nombre" />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 20}}>
                                <Col span={4} offset={2}>
                                    <Button type="primary" 
                                        onClick={this.editLocationHandler}
                                        disabled={this.state.locationName == null || this.state.locationName <= 1} className="login-form-button"
                                    >
                                        Editar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        cid: state.base.cid
    };
};

export default connect(mapStateToProps, null)(Edit);