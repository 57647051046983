import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { Layout, Row, Spin, Card, Button } from 'antd';
import { LeftOutlined, ReloadOutlined } from '@ant-design/icons';

import Room from './Room/Room';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;


class RoomStatesDashboardDetail extends Component {
    state = {
        loading: false,
        rooms: [],
        lastSync: '-',
        hotelId: ''
    }

    idToken = '';


    componentDidMount() {
        Auth.currentSession()
            .then(response => {
                this.idToken = response.idToken.jwtToken;
                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    loadData() {
        let hotelId = this.props.match.params.id;
        this.setState({loading: true});

        API.get('benackoAPI', '/v2/dashboards/room-states?hotelId=' + hotelId, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then(res => {
                var lastSync = 0;
                var rooms = [];
                if (res.Items.length > 0) {
                    let report = res.Items[0];

                    for (var entry in report) {
                        if (!isNaN(entry)) {
                            let room = report[entry];
                            rooms.push({
                                key: room.name,
                                name: room.name,
                                status: room.state,
                                checkoutTimestamp: room.checkoutTimestamp,
                                customerPlate: room.licensePlate
                            });
                        }
                    }

                    lastSync = new Date(report.updated).toLocaleString();
                }

                this.setState({
                    rooms: rooms,
                    lastSync: lastSync,
                    loading: false
                });
            })
            .catch(err => console.log('Error loading room states:', err));
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    render() {

        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler} loading={this.state.loading}>
                                <LeftOutlined />Regresar
                            </Button>
                            <Button type="primary" onClick={() => this.loadData()} loading={this.state.loading}>
                                <ReloadOutlined />Actualizar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#fff' }}>
                    <Spin spinning={this.state.loading} size="large">
                        <Card loading={false} bordered={false} title={'Estado de habitaciones'}>
                            <Row style={{display: 'flex'}}>
                                <span><strong>Última actualización:</strong> {this.state.lastSync}</span>
                            </Row>
                            <Row style={{marginTop: 30}}>
                                {
                                    this.state.rooms.length > 0 &&
                                        this.state.rooms.map(room => {
                                            return (
                                                <Room 
                                                    key={room.key}
                                                    name={room.name} 
                                                    status={room.status} 
                                                    checkoutTimestamp={room.checkoutTimestamp} 
                                                    customerPlate={room.customerPlate} 
                                                />
                                            );
                                        })
                                }
                            </Row>
                        </Card>  
                    </Spin>
                </Content>
            </Layout>
        );
    }
}


export default RoomStatesDashboardDetail;