import React from 'react';
import { Row, Col, InputNumber } from 'antd';


const NumberInput = ({label = '', onChange = () => console.log('No action has been set')}) => {
    
    return (
        <Row style={{width: '100%', height: '60px'}}>
            <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                <span style={{marginLeft: 40}}>{label}</span>
            </Col>
            
            <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                <InputNumber style={{marginLeft: 20, width: '400px'}} onChange={onChange} />
            </Col>
        </Row>
    );
}

export default NumberInput;