import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'antd';

import MaintenanceService from '../../../../../services/maintenance';

import { errorNotification } from '../../../../../components/UI/Notifications/Notifications';

const FurnishingLogsTab = ({roomId = null, furnishingId = null}) => {

    const [loading, setLoading] = useState(false);
    const [logs, setLogs] = useState([]);

    const logsColumns = [
        {title: 'Marca de tiempo', dataIndex: 'timestamp', defaultSortOrder: 'descend', sorter: (a,b) => a.timestamp > b.timestamp, render: (a) => new Date(a[0], (a[1] - 1), a[2], a[3], a[4], a[5]).toLocaleString()},
        {title: 'Usuario', dataIndex: 'username'},
        {title: 'Evento', dataIndex: 'message'},
    ];

    useEffect(() => {
        if (roomId != null && furnishingId != null) {
            loadData();
        }
    }, [roomId, furnishingId]);


    const loadData = async () => {
        setLoading(true);

        await loadRoomLogs();

        setLoading(false);
    };

    const loadRoomLogs = async () => {
        await MaintenanceService.getRoomFurnishingLogs(roomId, furnishingId)
            .then(res => {
                if (res != null) {
                    setLogs(res);
                }
            })
            .catch(err => errorNotification(err));
    };


    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%', marginTop: 30}}>
            <Col offset={1} span={22}>
                <Table
                    size="middle"
                    columns={logsColumns}
                    rowKey={'timestamp'}
                    dataSource={logs}
                    scroll={{y: 440}}
                    locale={{
                        emptyText: 'No se encontraron datos',
                        cancelSort: 'Haz clic para cancelar ordenar',
                        triggerAsc: 'Haz clic para ordenar - ascendente',
                        triggerDesc: 'Haz clic para ordenar - descendente'
                    }}
                    pagination={{
                        pageSize: 20,
                        showTotal: (total) => `Total: ${total}`
                    }}
                    loading={{
                        spinning: loading,
                        delay: 500,
                        size: 'large'
                    }}
                />
            </Col>
        </Row>
    );
};

export default FurnishingLogsTab;