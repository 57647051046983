const Roles = {
    1: {"name": "sysadmin"},
    2: {"name": "services"},
    3: {"name": "sysadmin"},
    4: {"name": "gerente general administrativo"},
    5: {"name": "gerente general operativo"},
    6: {"name": "gerente"},
    7: {"name": "auditoría"},
    8: {"name": "contabilidad"},
    9: {"name": "recepcionista"},
    10: {"name": "restaurante"},
    11: {"name": "recamarera"},
    12: {"name": "mantenimiento"},
    15: {"name": "sysadmin"},
    16: {"name": "receptionist"},
    17: {"name": "maid"},
    18: {"name": "vp"},
    19: {"name": "manager"}
};

export default Roles;