import React, { useEffect, useState } from 'react';
import { Descriptions, Divider, Row, Table } from 'antd';

const SummaryTabContents = ({summary = {}}) => {
    const [grossIncome, setGrossIncome] = useState({});
    const [exchangeRates, setExchangeRates] = useState([]);


    useEffect(() => {
        if (summary != null && summary.ExchangeRates != null) { setExchangeRates(summary.ExchangeRates); }
        if (summary != null && summary.Income != null && summary.Income.GrossIncome != null) { setGrossIncome(summary.Income.GrossIncome); }
    }, [summary]);

    const exchangeRateColumns = [
        {title: 'Tasa', dataIndex: 'Rate', render: element => <span>${element}</span>},
        {title: 'Desde', dataIndex: 'From', defaultSortOrder: 'descend', sorter: {compare: (a,b) => a.From.localeCompare(b.From)}},
        {title: 'Hasta', dataIndex: 'To'}
    ];

    return (
        <Row style={{display: 'flex', width: '100%'}}>
            <Row style={{width: '100%'}}>
                <Divider orientation='left' orientationMargin={0}/>
                <Descriptions title='Ingresos'>
                    <Descriptions.Item label='Pesos (MXN)'>${Number(grossIncome?.MxnIncome ?? 0).toLocaleString()}</Descriptions.Item>
                    <Descriptions.Item label='Dólares (USD)'>${Number(grossIncome?.UsdIncome ?? 0).toLocaleString()}</Descriptions.Item>
                    <Descriptions.Item label='Tarjeta (MXN)'>${Number(grossIncome?.CardIncome ?? 0).toLocaleString()}</Descriptions.Item>
                    <Descriptions.Item label='Total (MXN)'>${Number(grossIncome?.TotalIncome ?? 0).toLocaleString()}</Descriptions.Item>
                </Descriptions>
            </Row>
            <Row style={{width: '100%', height: 200, marginTop: 50, marginBottom: 10}}>
                <Divider orientation='left' orientationMargin={0}>Tipo de cambio</Divider>
                <Table
                    style={{width: '100%'}}
                    size='small'
                    scroll={{y: 200}}
                    pagination={false}
                    columns={exchangeRateColumns}
                    dataSource={exchangeRates ?? []}
                    rowKey={'From'}
                />
            </Row>
        </Row>
    );
};

export default SummaryTabContents;