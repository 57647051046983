import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { Button, Layout, Row, Card } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group


class FolioDetails extends Component {
    state = {
        loading: true,
        folioName: '',
        folioHeader: '',
        folioNumber: 0
    }

    idToken = '';

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    loadData() {
        this.setState({loading: true});
        const id = this.props.match.params.id;

        API.get('benackoAPI', '/system/tickets/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( response => {
                this.setState({
                    loading: false,
                    folioName: response[0].name,
                    folioHeader: response[0].folio_header,
                    folioNumber: response[0].folio_number
                });
            })
            .catch( error => {
                console.log(error);
                this.setState({loading: false});
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    goEditHandler = () => {
        this.props.history.push('/app/vouchers/folios/' + this.props.match.params.id + '/edit')
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                            <Button type="primary" onClick={this.goEditHandler}>
                                Editar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#ffff', paddingTop: 15 }}>
                    <Card loading={this.state.loading} bordered={false} title={this.state.folioName}>
                        <p><strong>Encabezado:</strong> {this.state.folioHeader}</p>
                        <p><strong>Serie actual:</strong> {this.state.folioNumber}</p>
                    </Card>
                </Content>
            </Layout>
        );
    }
}

export default FolioDetails;