import React from 'react';


const Room = (props) => {
    return (
        <div 
            style={{ 
                width: 160,
                height: 100,
                float: 'left',
                backgroundColor: 'blue',
                margin: 15,
                cursor: 'pointer'
            }}
            onClick={props.onClick}
        >
            <div style={{ width: '100%', height: 20, color: 'white' }}>
                <div style={{ width: '30%', height: '100%', float: 'left', textAlign: 'center' }}>
                    <span>{props.name}</span>
                </div>
            </div>
        </div>
    );
};

export default Room;
