import React from 'react';
import { Modal, Row, Descriptions } from 'antd'; 

function printPartOfPage (elementId, uniqueIframeId) {
    const content = document.getElementById(elementId)
    let pri
    if (document.getElementById(uniqueIframeId)) {
        pri = document.getElementById(uniqueIframeId).contentWindow
    } else {
        const iframe = document.createElement('iframe')
        iframe.setAttribute('title', uniqueIframeId)
        iframe.setAttribute('id', uniqueIframeId)
        iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
        document.body.appendChild(iframe)
        pri = iframe.contentWindow
    }
    pri.document.open()
    pri.document.write(content.innerHTML)
    pri.document.close()
    pri.focus()
    pri.print()
}


const PrintItemEventReport = props => {
    return (
        <Modal
            id="invoice"
            title={'Eventos - ' + props.addOnTitle}
            cancelText="CERRAR"
            okText="IMPRIMIR"
            onOk={() => {
                printPartOfPage("test", "testInvoice");
            }}
            onCancel={props.onCancel}
            visible={props.visible}
            centered={true}
            width={1000}
        >
            <div id="test">
                <Row style={{marginTop: 10, marginBottom: 30}}>
                    <Descriptions bordered>
                        <Descriptions.Item label="Periodo">{props.reportDate}</Descriptions.Item>
                    </Descriptions>
                </Row>
                {props.itemTable}
            </div>
        </Modal>
    );
};


export default PrintItemEventReport;