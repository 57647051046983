import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth, API } from 'aws-amplify';
import { Layout, Row, Table, Spin, Button, Tabs, Descriptions } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import PrintProductDailyReport from '../../../components/UI/Modals/PrintProductDailyReport/PrintProductDailyReport';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;
const { TabPane } = Tabs;

const productsColumns = [{
    title: 'Producto',
    dataIndex: 'product'
}, {
    title: 'Precio unitario',
    dataIndex: 'price'
}, {
    title: 'Cantidad',
    dataIndex: 'quantity'
}, {
    title: 'Total',
    dataIndex: 'total'
}];


class DailyProductsReportDetails extends Component {
    state = {
        loading: false,
        dailyReports: [],
        products: [],
        startDate: 0,
        endDate: 0,
        absTotal: 0,
        printableTable: [],
        printableTableDate: 0,
        printableTotal: 0,
        printProducts: false
    }

    idToken = '';


    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    loadData() {
        const hotelId = parseInt(this.props.match.params.hotelid, 10);
        const startDate = parseInt(this.props.match.params.startdate, 10);
        const endDate = parseInt(this.props.match.params.enddate, 10);

        const startDateLabel =  new Date(startDate).toDateString();
        const endDateLabel = new Date(endDate).toDateString();

        this.setState({loading: true, startDate: startDateLabel, endDate: endDateLabel});

        const type = 3;

        API.get('benackoAPI', '/product-prices?hotelId=' + hotelId, {
                Authorization: {
                    header: this.idToken
                }
            })
            .then(productPricesRes => {
                return productPricesRes;
            })
            .then(productPrices => {

                const pathString = '/v2/reports/shift-balances?hotelId=' + hotelId + '&startTimestamp=' + startDate + '&endTimestamp=' + endDate + '&type=' + type;
                API.get('benackoAPI', pathString, {
                        headers:{
                            Authorization: this.idToken
                        } 
                    })
                    .then(async sbRes => {
                        const shiftBalances = sbRes.Items;
                        var sbDates = {}

                        shiftBalances.forEach(balance => {
                            const balanceDate = this.getFormattedDateString(balance.SK);

                            if (!sbDates.hasOwnProperty(balanceDate)) {
                                sbDates[balanceDate] = [];
                            }

                            sbDates[balanceDate].push(balance);
                        });

                        var tabsNumber = 1;
                        var entriesTabs = [];
                        let absTotal = 0;

                        for (var key in sbDates) {

                            const startTimestamp = this.getSmallestTimestamp(sbDates[key]);
                            const endTimestamp = this.getLargestTimestamp(sbDates[key]);

                            const entryDate = new Date(startTimestamp);
                            const formattedDate = entryDate.getFullYear() + '-' + (entryDate.getMonth() + 1) + '-' + entryDate.getDate();
                            const date = formattedDate;

                            const orderQueryString = 'hotelId=' + hotelId + '&type='+ type +'&startTimestamp=' + startTimestamp + '&endTimestamp=' + endTimestamp;
                            await API.get('benackoAPI', '/v2/actions/service?' + orderQueryString, {
                                    headers: {
                                        Authorization: this.idToken
                                    }
                                })
                                .then(ordersRes => {
                                    var processedEntries = {};
                                    processedEntries[formattedDate] = [];

                                    if (ordersRes.Items.length > 0) {
                                        ordersRes.Items.forEach(entry => {
                                            entry.products.forEach(product => {
                                                if (processedEntries[formattedDate].find(pe => pe.id === product.productId) === undefined) {
                                                    processedEntries[formattedDate].push({
                                                        id: product.productId,
                                                        name: product.productName,
                                                        quantity: product.quantity,
                                                        price: productPrices.find(pp => pp.productId === product.productId).price,
                                                        amount: productPrices.find(pp => pp.productId === product.productId).price
                                                    });
                                                } else {
                                                    const oldQuantity = processedEntries[formattedDate].find(pe => pe.id === product.productId).quantity;
                                                    const newQuantity = oldQuantity + product.quantity;
                                                    const price = productPrices.find(pp => pp.productId === product.productId).price;
                                                    const newAmount = price * newQuantity;

                                                    processedEntries[formattedDate].find(pe => pe.id === product.productId).quantity = newQuantity;
                                                    processedEntries[formattedDate].find(pe => pe.id === product.productId).amount = newAmount;
                                                }
                                            });
                                        });
                                    }

                                    return processedEntries;
                                })
                                .then(processedEntries => {
                                        var processedProducts = [];
                                        var dailyTotal = 0;

                                        processedEntries[date].forEach(product => {
                                            dailyTotal += parseFloat(product.amount);
                                            
                                            processedProducts.push({
                                                key: product.id,
                                                product: product.name,
                                                price: '$' + product.price,
                                                quantity: product.quantity,
                                                total: '$' + product.amount
                                            });
                                        });

                                        const productTable = (
                                            <Table
                                                columns={productsColumns} 
                                                dataSource={processedProducts} 
                                                size="small"
                                                bordered={true}
                                                locale={{ emptyText: 'No se encontraron productos' }}
                                                pagination={false}
                                                style={{marginTop: 30}}
                                            />
                                        );

                                        const reportTotal = dailyTotal;
                                        absTotal += dailyTotal;

                                        const dateLabelArr = date.split('-');
                                        const dateLabel = new Date(dateLabelArr[0], (dateLabelArr[1]-1), dateLabelArr[2]).toDateString();

                                        entriesTabs.push(
                                            <TabPane tab={dateLabel} key={tabsNumber}>
                                                <Row style={{marginTop: 50}}>
                                                    <ButtonGroup>
                                                        <Button onClick={() => {
                                                            this.setState({printableTable: productTable, printableTableDate: dateLabel, printableTotal: reportTotal});
                                                            this.printProductsHandler();
                                                        }}>Imprimir día</Button>
                                                    </ButtonGroup>
                                                </Row>
                                                <Row style={{marginTop: 20, marginBottom: 30}}>
                                                    <Descriptions title="Totales" bordered>
                                                        <Descriptions.Item label="Total diario">$ {dailyTotal}</Descriptions.Item>
                                                    </Descriptions>
                                                </Row>
                                                <Row>
                                                    {productTable}
                                                </Row>
                                            </TabPane>
                                        );

                                    this.setState({
                                        dailyReports: entriesTabs
                                    });
                                    
                                    tabsNumber++;
                                })
                                .catch(errOrders => {
                                    console.log('Error loading orders:', errOrders);
                                });
                        }

                        return absTotal;
                    })
                    .then(absTotal => {this.setState({loading: false, absTotal: absTotal});})
                    .catch(errSb => {console.log('Error loading shift balance headers:', errSb);
                });
            })
            .catch(errProductPrices => {
                console.log('Error loading product prices:', errProductPrices);
            });
    }

    getSmallestTimestamp(datesArray) {
        if (datesArray.length > 0) {
            var smallestTimestamp = datesArray[0].SK;

            datesArray.forEach(date => {
                if (date.SK < smallestTimestamp) {
                    smallestTimestamp = date.SK;
                }
            });

            return smallestTimestamp;
        } else {
            return 0;
        }
    }

    getLargestTimestamp(datesArray) {
        if (datesArray.length > 0) {
            var largestTimestamp = datesArray[0].endTimestamp;

            datesArray.forEach(date => {
                if (date.endTimestamp > largestTimestamp) {
                    largestTimestamp = date.endTimestamp;
                }
            });

            return largestTimestamp;
        } else {
            return 0;
        }
    }

    sortObject(o) {
        var sorted = {},
        key, a = [];
    
        for (key in o) {
            if (o.hasOwnProperty(key)) {
                a.push(key);
            }
        }
    
        a.sort();
    
        for (key = 0; key < a.length; key++) {
            sorted[a[key]] = o[a[key]];
        }
        return sorted;
    }

    getFormattedDateString(dateNumber) {
        const date = new Date(dateNumber)
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
      
        return [date.getFullYear(),
                (mm>9 ? '' : '0') + mm,
                (dd>9 ? '' : '0') + dd
               ].join('-');
      };

    printProductsHandler = () => {
        this.setState({
            printProducts: true
        });
    }

    closePrintProducts = () => {
        this.setState({
            printProducts: false
        });
    }

    printProductsTable = prods => {
        console.log('prodsTable', prods.target.value);
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    render() {
        return(
            <Layout>
                <Header style={{background: '#fff', padding: 0}}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{width: '100%', margin: 0, padding: 0, paddingLeft: 20, background: '#fff'}}>
                    <Spin spinning={this.state.loading} size="large">
                        <Tabs defaultActiveKey="1" tabPosition="right">
                            <TabPane tab="Total" key={99999}>
                                <Row style={{marginTop: 20, marginBottom: 30}}>
                                    <Descriptions title="Totales" bordered>
                                        <Descriptions.Item label="Inicio del periodo">{this.state.startDate}</Descriptions.Item>
                                        <Descriptions.Item label="Fin del periodo">{this.state.endDate}</Descriptions.Item>
                                        <Descriptions.Item label="Total del periodo seleccionado">$ {this.state.absTotal}</Descriptions.Item>
                                    </Descriptions>
                                </Row>
                            </TabPane>
                            {this.state.dailyReports}
                        </Tabs>
                    </Spin>

                    <PrintProductDailyReport
                        visible={this.state.printProducts}
                        onCancel={this.closePrintProducts}
                        itemTable={this.state.printableTable}
                        reportDate={this.state.printableTableDate}
                        reportTotal={this.state.printableTotal}
                    />
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId : state.auth.companyId,
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(DailyProductsReportDetails);