import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Spin } from 'antd';

import { errorNotification } from '../../../components/UI/Notifications/Notifications';

import MaintenanceService from '../../../services/maintenance';
import { disableHotelSelector, enableHotelSelector } from '../../../store/actions';
import Room from './Room/Room';


const Rooms = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    
    const cid = useSelector(state => state.base.cid ?? '');
    const hid = useSelector(state => state.ui.selectedHotel ?? 0);

    const [rooms, setRooms] = useState([]);


    useEffect(() => {
        dispatch(enableHotelSelector());

        return () => {
            dispatch(disableHotelSelector());
        };
    }, []);

    useEffect(() => {
        if (hid != null && hid > 0) {
            loadData();
        }
    }, [hid]);

    const loadData = async () => {
        setLoading(true);

        await loadRooms();

        setLoading(false);
    };

    const loadRooms = async () => {
        await MaintenanceService.getRooms(hid)
            .then(res => {
                if (res != null) {
                    res.sort((a, b) => parseInt(a.name) - parseInt(b.name));
                    
                    setRooms(res);
                }
            })
            .catch(err => errorNotification(err));
    };


    const goToRoom = (roomId) => {
        history.push('/app/maintenance/rooms/' + roomId);
    }


    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Row style={{display: 'flex', width: '98%', marginTop: 50, marginLeft: 20}}>
                <Spin spinning={loading} size="large">
                    <Row style={{marginTop: 30}}>
                        {
                            rooms.length > 0 &&
                                rooms.map(room => {
                                    return (
                                        <Room
                                            key={room.id}
                                            name={room.name}
                                            onClick={() => goToRoom(room.id)}
                                        />
                                    );
                                })
                        }
                    </Row>
                </Spin>
            </Row>
        </Row>
    );
}

export default Rooms;