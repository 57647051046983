import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { Breadcrumb, Button, Col, Input, Row, Skeleton, Table, Tabs } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import SectionTitle from '../../../../components/UI/Panels/SectionTitle/SectionTitle';
import { errorNotification } from '../../../../components/UI/Notifications/Notifications';

import RentTypeService from '../../../../services/rent-types';
import RoomService from '../../../../services/rooms';

const { TabPane } = Tabs;


const RoomsDetails = () => {
    const {id} = useParams();

    const cid = useSelector(state => state.base.cid ?? '');
    const hid = useSelector(state => state.ui.selectedHotel ?? 0);
    
    const [loading, setLoading] = useState(true);

    const [room, setRoom] = useState(null);
    const [rentTypes, setRentTypes] = useState([]);


    const rentTypeColumns = [
        {title: 'Tipo de renta', dataIndex: 'Name', key: 'Name'},
        {title: '¿Temporal?', dataIndex: 'IsTemporary', key: 'IsTemporary', render: (a) => (a) ? <CheckCircleOutlined/> : <CloseCircleOutlined/>},
        {title: '¿Habilitado?', dataIndex: 'Enabled', key: 'Enabled', render: (a) => (a) ? <CheckCircleOutlined/> : <CloseCircleOutlined/>}
    ];

    useEffect(async () => {
        await loadData();
    }, [cid, hid]);

    const loadData = async () => {
        setLoading(true);

        let preRentTypes = [];
        await RentTypeService.getRentTypes(hid)
            .then(res => {
                res.forEach(type => preRentTypes.push({Key: type.Key, Name: type.Name, IsTemporary: type.IsTemporary}));
            })
            .catch(err => errorNotification(err));

        if (id != null) {
            await RoomService.getRoomDetails(id)
                .then(res => setRoom(res))
                .catch(err => errorNotification(err));

            await RoomService.getRoomConfigurations(id)
                .then(res => {
                    preRentTypes.forEach(type => {
                        const rentType = res.find(rt => rt.RentTypeId === type.Key);
                        type.Enabled = rentType.IsAllowed ?? false;
                    });
                    
                    setRentTypes(preRentTypes);
                })
                .catch(err => errorNotification(err))
                .finally(() => setLoading(false));
        }
    };


    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 10}}>
                <Col span={23}>
                    <Row style={{marginLeft: 40, width: '100%', height: 40}}>
                        <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <NavLink to={'/app/rooms'}>{'Habitaciones'}</NavLink>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {'Detalles de habitación'}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Skeleton loading={loading} paragraph={{rows: 1, cols: 1}} active>
                <SectionTitle title={room?.Name ?? ''} />
            </Skeleton>
            <Tabs defaultActiveKey={1} style={{marginLeft: 40, marginTop: 30}}>
                <TabPane tab={'Información general'} key={1}>
                    <Skeleton loading={loading} paragraph={{rows: 9, cols: 1}} active>
                        <Row style={{display: 'flex', width: '100%', marginTop: 20}}>                            
                            <Row style={{width: '100%', height: 60}}>
                                <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Creado en</span></Col>
                                <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                                    <Input type="text" defaultValue={new Date(room?.Created ?? 0).toLocaleString('es-mx', {timeZone: 'PST'})} disabled/></span>
                                </Col>
                            </Row>
                            <Row style={{width: '100%', height: 60}}>
                                <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Última modificación</span></Col>
                                <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                                    <Input type="text" defaultValue={new Date(room?.Modified ?? 0).toLocaleString('es-mx', {timeZone: 'PST'})} disabled/></span>
                                </Col>
                            </Row>
                            <Row style={{width: '100%', height: 60}}>
                                <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Estado</span></Col>
                                <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                                    <Input type="text" defaultValue={room?.Enabled ? 'Habilitado' : 'Deshabilitado'} disabled/></span>
                                </Col>
                            </Row>
                        </Row>
                    </Skeleton>
                </TabPane>
                <TabPane tab={'Tipos de renta'} key={2}>
                    <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                        <Table
                            style={{cursor: 'pointer'}}
                            size={'small'}
                            bordered={true}
                            loading={loading}
                            columns={rentTypeColumns}
                            dataSource={rentTypes}
                            pagination={false}
                            rowKey={'Key'}
                            scroll={{y: '100%'}}
                            locale={{
                                emptyText: 'No se encontraron datos'
                            }}
                        />
                    </Row>
                </TabPane>
            </Tabs>
        </Row>
    );
};

export default RoomsDetails;