import { amplify } from './amplify';

// GENERAL
import { actions } from './actions';
import { tables } from './tables';
import { toasts } from './toasts';

// MODULES
import { users } from './users';


export const es = {
    ...amplify,
    ...actions,
    ...tables,
    ...toasts,
    ...users
}