import React from 'react';
import { Route, Switch } from 'react-router-dom';

import UsersList from './UsersList/UsersList';
import UsersDetails from './UsersDetails/UsersDetails';
import UsersCreate from './UsersCreate/UsersCreate';


const Users = () => {
    return (
        <Switch>
            <Route path="/app/users"
                render={({ match: { url } }) => (
                    <div>
                        <Route exact path={`${url}`} component={UsersList} />
                        <Route exact path={`${url}/create`} component={UsersCreate} />
                        <Route exact path={`${url}/details/:id`} component={UsersDetails} />
                    </div>
                )}
            />
        </Switch>
    );
}

export default Users;