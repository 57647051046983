import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Input, Row, Skeleton } from 'antd';

import { errorNotification, successNotification } from '../../../../components/UI/Notifications/Notifications';
import SectionTitle from '../../../../components/UI/Panels/SectionTitle/SectionTitle';

import MaintenanceService from '../../../../services/maintenance';

import { disableHotelSelector, enableHotelSelector } from '../../../../store/actions';

const AddPlan = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const hid = useSelector(state => state.ui.selectedHotel ?? 0);

    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [newName, setNewName] = useState('');

    useEffect(() => {
        dispatch(enableHotelSelector());

        return () => {
            dispatch(disableHotelSelector());
        };
    }, []);


    const create = () => {
        setLoading(true);
        
        if (!submitted) {
            setSubmitted(true);

            if (newName != null && newName.length != 0) {
                MaintenanceService.addPlan(hid, newName)
                    .then(() => successNotification('El plan de mantenimiento ha sido creado exitosamente'))
                    .catch(err => errorNotification(err))
                    .finally(() => {
                        setSubmitted(false);
                        history.go(-1);
                    });
            }
        }
    };

    return (
        <Skeleton loading={loading} paragraph={{rows: 9, cols: 1}} active>
            <SectionTitle title='Crear de mantenimiento'/>

            <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                <Row style={{width: '100%', height: 60}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Nombre</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input type="text" maxLength={200} onChange={(e) => setNewName(e.target.value)} disabled={submitted}/></span>
                    </Col>
                </Row>

                <Row style={{width: '100%', height: 60, marginTop: 40}}>
                    <Button type='primary' style={{marginLeft: 40}} onClick={() => create()} disabled={submitted || newName == null || newName.length <= 0}>Crear plan</Button>
                </Row>
            </Row>
        </Skeleton>
    );
};

export default AddPlan;