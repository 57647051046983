import { Auth, API } from 'aws-amplify';

class UserService {

    static async getOpUsers(hid) {
        const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});
        
        return API.get('benackoAPI', '/users/op?hotelId=' + hid, {headers: {Authorization: idToken}})
            .then(response => {
                if (response == null) {
                    throw Error(response ?? 'Respuesta no válida del servidor');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    }

    static async getAdUsers(cid) {
        const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});
        
        return API.get('benackoAPI', '/users/details?companyId=' + cid, {headers: {Authorization: idToken}})
            .then(response => {
                if (response == null) {
                    throw Error(response ?? 'Respuesta no válida del servidor');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    }

    static async getUserDetails(type, uid) {
        const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});

        const url = (type === 'ad') ? '/users/details/' + uid : '/users/op/' + uid;
        
        return API.get('benackoAPI', url, {headers: {Authorization: idToken}})
            .then(response => {
                if (response == null) {
                    throw Error(response ?? 'Respuesta no válida del servidor');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    }

    static async getUserHotels(uid) {
        const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});

        return API.get('benackoAPI', '/users/hotels?userId=' + uid, {headers: {Authorization: idToken}})
            .then(response => {
                if (response == null) {
                    throw Error(response ?? 'Respuesta no válida del servidor');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    }

    static async getUserHotelsByHid(hid) {
        const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});

        return API.get('benackoAPI', '/users/hotels?hotelId=' + hid, {headers: {Authorization: idToken}})
            .then(response => {
                if (response == null) {
                    throw Error(response ?? 'Respuesta no válida del servidor');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    }

    static async createOpUser(username, firstName, lastName, role, hotel) {
        const body = {
            username: username,
            firstname: firstName,
            lastname: lastName,
            roleId: role,
            hotelId: hotel
        };
        
        return API.post('benackoAPI', '/users/op', {body: body})
            .then(response => {
                if (response == null) {
                    throw Error('Respuesta no válida del servidor');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    }

    static async createAdUser(cid, username, firstName, lastName, role, hotels) {
        const body = {
            username: username,
            firstName: firstName,
            lastName: lastName,
            roleId: role,
            companyId: cid
        };
        
        return API.post('benackoAPI', '/users/details', {body: body})
            .then(userDetailsRes => {
                const insertedId = userDetailsRes.insertId;
                const assignedHotels = hotels;

                assignedHotels.forEach(async hotelId => {
                    await API.post('benackoAPI', '/users/hotels', {
                        headers: {Authorization: this.idToken},
                        body: {userId: insertedId, hotelId: hotelId}
                    })
                    .then(() => {/* will need to set loading and go back */})
                    .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
                });
            })
            .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    }

    static async disableAdUser(uid, username) {
        const body = {
            username: username,
            status: false
        };

        return API.del('benackoAPI', '/users/details/' + uid, {body: body})
            .then(response => {
                if (response == null) {
                    throw Error('Respuesta no válida del servidor');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    }

    static async disableOpUser(uid, username) {
        const body = {
            username: username
        };

        return API.del('benackoAPI', '/users/op/' + uid, {body: body})
            .then(response => {
                if (response == null) {
                    throw Error('Respuesta no válida del servidor');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    }
};

export default UserService;