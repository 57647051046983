import XLSX from 'xlsx-js-style';

const getColumnName = (rentTypeColumns, name) => {
    const rentType = rentTypeColumns.find(c => {return c.dataIndex === name});
    return rentType?.title ?? '';
};

export const processRoomsSheet = (rooms, rentTypeColumns) => {
    const sheet = XLSX.utils.aoa_to_sheet([]);

    const processedData = rooms.RentTotals.map(shift => {
        var row = {'': shift.Timestamp};

        for (var rt in shift) {
            if (rt.startsWith('rt')) {
                const name = getColumnName(rentTypeColumns, rt);
                row[name] = shift[rt];
            }
        }

        return row;
    });

    const processedCols = rentTypeColumns.map(col => col.title);
    processedCols.splice(0, 0, '');

    XLSX.utils.sheet_add_json(sheet, processedData, {origin: 'A1', header: processedCols});

    var cols = [{wpx: 120}];
    for (let _ in rentTypeColumns) {
        cols.push({wpx: 25});
    }

    for (let i in sheet) {
        let cell = XLSX.utils.decode_cell(i);

        if (cell.r === 0) {
            sheet[i].s = {alignment: {horizontal: 'center', textRotation: 90, wrapText: true}, font: {sz: 8, bold: true}};
        } else if (cell.r > 0) {
            sheet[i].s = {alignment: {horizontal: 'center', vertical: 'center'}, border: {top: {style: 'thin'}, right: {style: 'thin'}, bottom: {style: 'thin'}, left: {style: 'thin'}}};
        }

        if (cell.c == 0) {
            sheet[i].s = {alignment: {horizontal: 'center', vertical: 'center'}, border: {top: {style: 'thin'}, right: {style: 'thin'}, bottom: {style: 'thin'}, left: {style: 'thin'}}};
        }
    }

    sheet['!cols'] = cols;
    sheet['!rows'] = [{hpt: 150}];

    return sheet;
};