import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Row, Col, Input, Table, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import ReloadButton from '../../../../components/UI/Buttons/ReloadButton/ReloadButton';
import { errorNotification } from '../../../../components/UI/Notifications/Notifications';

import RoomService from '../../../../services/rooms';
import { disableHotelSelector, enableHotelSelector } from '../../../../store/actions';


const RoomsList = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const cid = useSelector(state => state.base.cid ?? '');
    const hid = useSelector(state => state.ui.selectedHotel ?? 0);

    const [loading, setLoading] = useState(true);
    const [rooms, setRooms] = useState([]);
    const [filteredRooms, setFilteredRooms] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const roomColumns = [
        {title: 'Nombre', dataIndex: 'Name', key: 'Name', defaultSortOrder: 'ascend', sorter: {compare: (a,b) => parseInt(a.Name) - parseInt(b.Name)}},
    ];


    useEffect(() => {
        dispatch(enableHotelSelector());

        return () => { dispatch(disableHotelSelector()) };
    }, []);

    useEffect(() => {
        if (hid !== 0) {
            getRooms();
        }
    }, [hid]);

    useEffect(() => {
        if (rooms != null && rooms.length > 0) {
            clearCurrentRooms();
            setFilteredRooms(rooms);
        }
    }, [rooms]);

    useEffect(() => {
        if (inputValue !== null && inputValue !== undefined && inputValue !== '') {
            const newRooms = inputValue ? rooms.filter(i => (i.Name.toLowerCase().indexOf(inputValue) > -1)) : rooms;
            setFilteredRooms(newRooms);
        } else {
            setFilteredRooms(rooms);
        }
    }, [inputValue]);


    const getRooms = () => {
        setLoading(true);

        RoomService.getRooms(hid)
            .then(res => {
                setRooms(res);
                setLoading(false);
            })
            .catch(err => {
                errorNotification(err);
                setLoading(false);
            });
    };

    const clearCurrentRooms = () => {
        setFilteredRooms([]);
        setInputValue('');
    };

    const onChangeText = (text) => {
        setInputValue(text);
    };

    const goToRoomDetails = (id) => {
        history.push('/app/rooms/details/' + id);
    };

    return (
        <Row style={{display: 'flex', flexFlow: 'column', width: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 40}}>
                <Col span={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Input
                        size="middle"
                        prefix={<SearchOutlined />}
                        placeholder={'Buscar'}
                        allowClear={true}
                        value={inputValue}
                        onChange={(ev) => onChangeText(ev.target.value)}
                    />
                </Col>
                <Col offset={10} span={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Space>
                        <ReloadButton onClick={getRooms} loading={loading} />
                    </Space>
                </Col>
            </Row>

            <Row style={{width: '100%', height: '100%', flexGrow: 1, marginBottom: 20}}>
                <Col span={24}>
                    <Table
                        style={{cursor: 'pointer'}}
                        size={'small'}
                        bordered={true}
                        showHeader={false}
                        loading={loading}
                        columns={roomColumns}
                        dataSource={filteredRooms}
                        pagination={false}
                        rowKey={'Key'}
                        scroll={{y: '100%'}}
                        locale={{
                            emptyText: 'No se encontraron datos',
                            cancelSort: 'Haz clic para cancelar la ordenación',
                            triggerAsc: 'Haz clic para ordenar ascendentemente',
                            triggerDesc: 'Haz clic para ordenar descendentemente'
                        }}
                        onRow={(row) => ({
                            onClick: () => goToRoomDetails(row.Key)
                        })}
                    />
                </Col>
            </Row>
        </Row>
    );
};

export default RoomsList;