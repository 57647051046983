import * as actionTypes from '../actions/actionTypes';


const initialState = {
    hotelSelectorEnabled: false,
    selectedHotel: null,
    locale: 'es-mx',
    error: ''
};

const switchHotel = (state, action) => {
    return {
        ...state,
        selectedHotel: action.selectedHotel
    };
};

const enableHotelSelector = (state, _) => {
    return {
        ...state,
        hotelSelectorEnabled: true
    };
};

const disableHotelSelector = (state, _) => {
    return {
        ...state,
        hotelSelectorEnabled: false
    };
};

const clearUiData = () => {
    return {
        hotelSelectorEnabled: false,
        selectedHotel: null,
        locale: null,
        error: ''
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SWITCH_HOTEL: return switchHotel(state, action);
        case actionTypes.ENABLE_HOTEL_SELECTOR: return enableHotelSelector(state, action);
        case actionTypes.DISABLE_HOTEL_SELECTOR: return disableHotelSelector(state, action);
        case actionTypes.CLEAR_UI_DATA: return clearUiData();
        default: return state;
    }
};

export default reducer;