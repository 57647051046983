import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, DatePicker, Row } from 'antd';
import moment from 'moment';

const WorkOrderReportSelector = ({report = ''}) => {
    const history = useHistory();

    const [initialDate, setInitialDate] = useState(false);
    const [finalDate, setFinalDate] = useState(null);
    const [validated, setValidated] = useState(false);

    const specifyRange = (moments) => {
        if (moments != null && moments.length === 2) {
            const current = moment();
            if (moments[0].diff(current, 'months') < -2 || moments[0].diff(current, 'days') > 0 ||
                moments[1].diff(current, 'months') < -2 || moments[1].diff(current, 'days') > 0) {

                setValidated(false);
            } else {
                const initial = moments[0].format('yyyy-MM-DD');
                setInitialDate(initial);
                const final = moments[1].format('yyyy-MM-DD');
                setFinalDate(final);

                setValidated(true);
            }
        } else {
            setInitialDate(null);
            setFinalDate(null);
            setValidated(false);
        }
    };

    return (
        <Row style={{width: '100%', marginTop: 50, marginLeft: 40}}>
            <Row style={{width: '100%'}}>
                <Col span={4} style={{display: 'flex', alignItems: 'center', height: '100%'}}>Rango del reporte</Col>
                <Col span={8}>
                    <DatePicker.RangePicker style={{width: '100%'}} onChange={e => specifyRange(e)}/>
                </Col>
            </Row>
            <Row style={{width: '100%', marginTop: 40}}>
                <Button type='primary' onClick={() => history.push('/app/maintenance/reports/work-orders?initialDate=' + initialDate + '&finalDate=' + finalDate)} disabled={!validated}>Generar reporte</Button>
            </Row>
        </Row>
    );
};

export default WorkOrderReportSelector;