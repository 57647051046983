import React, { useEffect, useState } from 'react';
import { Row, Table } from 'antd';


const ShiftDifferencesTabContents = ({differences = []}) => {
    const [shifts, setShifts] = useState([]);

    const differencesColumns = [
        {title: 'turno', dataIndex: 'timestamp', align: 'center', width: 20},
        {title: 'total', dataIndex: 'cashTotal', align: 'center', width: 20, render: (e) => '$' + Number(e ?? 0).toLocaleString()},
        {title: 'total depositado', dataIndex: 'depositsTotal', align: 'center', width: 20, render: (e) => '$' + Number(e ?? 0).toLocaleString()},
        {title: 'diferencia', dataIndex: 'difference', align: 'center', width: 20, render: (e) => '$' + Number(e ?? 0).toLocaleString()}
    ];

    useEffect(() => {
        if (differences != null) {
            if (differences.Shifts != null) { setShifts(differences.Shifts ?? []); }
        }
    }, [differences]);


    return (
        <Row style={{display: 'flex', width: '100%'}}>
            <Table
                style={{width: '100%'}}
                size='small'
                scroll={{y: 550}}
                pagination={false}
                columns={differencesColumns}
                dataSource={shifts}
                rowKey='timestamp'
            />
        </Row>
    );
};

export default ShiftDifferencesTabContents;