import React, { useEffect, useState } from 'react';
import { Descriptions, Divider, Row, Table } from 'antd';

const AveragesTabContents = ({averages = {}}) => {
    const [incomePerRoom, setIncomePerRoom] = useState(0);
    const [rentTypes, setRentTypes] = useState([]);


    useEffect(() => {console.log(averages);
        if (averages != null && averages.IncomePerRoom != null) { setIncomePerRoom(averages.IncomePerRoom); }
        if (averages != null && averages.RentTypes != null) { setRentTypes(averages.RentTypes); }
    }, [averages]);

    const rentTypeColumns = [
        {title: 'Tipo de renta', dataIndex: 'Name', defaultSortOrder: 'ascend', sorter: {compare: (a,b) => a.Name.localeCompare(b.Name)}},
        {title: 'Total', dataIndex: 'Total'},
        {title: 'Promedio diario', dataIndex: 'DailyAverage'}
    ];

    return (
        <Row style={{display: 'flex', width: '100%'}}>
            <Row style={{width: '100%'}}>
                <Divider orientation='left' orientationMargin={0}/>
                <Descriptions title='Datos por habitación'>
                    <Descriptions.Item label='Ingreso por habitación (MXN)'>${Number(incomePerRoom ?? 0).toLocaleString()}</Descriptions.Item>
                </Descriptions>
            </Row>
            <Row style={{width: '100%', height: 400, marginTop: 50, marginBottom: 10}}>
                <Divider orientation='left' orientationMargin={0}>Promedios por tipo de renta</Divider>
                <Table
                    style={{width: '100%'}}
                    size='small'
                    scroll={{y: 500}}
                    pagination={false}
                    columns={rentTypeColumns}
                    dataSource={rentTypes ?? []}
                    rowKey={'Key'}
                />
            </Row>
        </Row>
    );
};

export default AveragesTabContents;