import React, { useEffect, useState } from 'react';
import { Table, Tabs } from 'antd';


const {TabPane} = Tabs;

const SectionTotals = ({general = [], generalTable = null, roomTypeHeaders = null, section1 = null, section2 = null}) => {
    const [section1Totals, setSection1Totals] = useState([]);
    const [section1Table, setSection1Table] = useState(null);
    const [section2Totals, setSection2Totals] = useState([]);
    const [section2Table, setSection2Table] = useState(null);

    const totalsColumns = [
        {title: '', dataIndex: 'type'},
        {title: 'Monto', dataIndex: 'amount'}
    ];

    useEffect(() => {
        if (roomTypeHeaders != null && section1 != null && section2 != null) {
            calculateSectionTotals(1, section1);
            calculateSectionTotals(2, section2);
        }
    }, [roomTypeHeaders, section1]);

    const calculateSectionTotals = (sectionNumber, totals) => {
        const sbAbsTotal = totals.sbAbsTotal ?? 0;
        const sbTotalMxn = totals.sbTotalMxn ?? 0;
        const sbTotalUsd = totals.sbTotalUsd ?? 0;
        const sbTotalCard = totals.sbTotalCard ?? 0;

        const depositAbsTotal = totals.depositAbsTotal ?? 0;
        const depositTotalMxn = totals.depositTotalMxn ?? 0;
        const depositTotalUsd = totals.depositTotalUsd ?? 0;
        const depositTotalTickets = totals.depositTotalTickets ?? 0;

        const rentTotalMxn = totals.rentTotalMxn ?? 0;
        const rentTotalUsd = totals.rentTotalUsd ?? 0;
        const rentTotalCard = totals.rentTotalCard ?? 0;
        const addDaysTotalMxn = totals.addDaysTotalMxn ?? 0;
        const addDaysTotalUsd = totals.addDaysTotalUsd ?? 0;
        const addDaysTotalCard = totals.addDaysTotalCard ?? 0;
        const addGuestTotalMxn = totals.addGuestTotalMxn ?? 0;
        const addGuestTotalUsd = totals.addGuestTotalUsd ?? 0;
        const addGuestTotalCard = totals.addGuestTotalCard ?? 0;
        const changeTotalMxn = totals.changeTotalMxn ?? 0;
        const changeTotalUsd = totals.changeTotalUsd ?? 0;
        const changeTotalCard = totals.changeTotalCard ?? 0;
        const chargeTotalMxn = totals.chargeTotalMxn ?? 0;
        const chargeTotalUsd = totals.chargeTotalUsd ?? 0;
        const chargeTotalCard = totals.chargeTotalCard ?? 0;
        const reservationsTotalMxn = totals.changeTotalMxn ?? 0;
        const reservationsTotalUsd = totals.changeTotalUsd ?? 0;
        const reservationsTotalCard = totals.changeTotalCard ?? 0;
        const reservationTicketsTotalMxn = totals.reservationTicketsTotalMxn ?? 0;
        const cancelTotalMxn = totals.cancelTotalMxn ?? 0;
        const chargeAbsTotal = totals.chargeAbsTotal ?? 0;
        const numberOfGuestAdditions = totals.numberOfGuestAdditions ?? 0;
        
        const orderAbsTotal = totals.ordersAbsTotal ?? 0;
        const orderTotalMxn = totals.orderTotalMxn ?? 0;
        const orderTotalUsd = totals.orderTotalUsd ?? 0;
        const orderTotalCard = totals.orderTotalCard ?? 0;

        var income = [];
        var roomTypeTotals = [];
        var actions = [];

        // FOR TOTALS TABLE
        const printRoomTypeNames = [];
        const printRoomTypePrices = [];
        const printRoomTypeNumberOfRents = [];
        var printSumActionsMxn = 0;
        var printSumActionsUsd = 0;

        const difference = depositAbsTotal - sbAbsTotal;

        income.push({ key: 1, type: 'Ingresos mxn', amount: '$' + sbTotalMxn });
        income.push({ key: 2, type: 'Ingresos usd', amount: '$' + sbTotalUsd });
        income.push({ key: 3, type: 'Ingresos tarjeta', amount: '$' + sbTotalCard });
        income.push({ key: 4, type: 'TOTAL INGRESOS', amount: <strong>$ {sbAbsTotal}</strong> });
        income.push({ key: 5, type: '', amount: '' });
        income.push({ key: 6, type: 'Depósitos mxn', amount: '$' + depositTotalMxn });
        income.push({ key: 7, type: 'Depósitos usd', amount: '$' + depositTotalUsd });
        income.push({ key: 8, type: 'Depósitos vales', amount: '$' + depositTotalTickets });
        income.push({ key: 9, type: 'TOTAL DEPÓSITOS', amount: <strong>$ {depositAbsTotal}</strong> });
        income.push({ key: 10, type: '', amount: '' });
        income.push({ key: 11, type: 'Diferencia', amount: <strong>$ {difference}</strong> });

        var roomTypeIds = new Set();
        var roomTypeNames = {};
        var roomTypeNumbers = {};
        var roomTypeMonies = {};

        for (var key in totals) {
            if (key.includes('rt')) {
                const roomTypeId = parseInt(key.split('D').pop(), 10);
                const roomType = roomTypeHeaders.find(rt => rt.key === roomTypeId);
                
                roomTypeIds.add(roomTypeId);
                roomTypeNames[roomTypeId] = roomType.name;

                if (key.includes('rtNumber')) {
                    roomTypeNumbers[roomTypeId] = totals[key];

                    //POPULATING PRINT TABLE
                    printRoomTypeNames.push(<th key={key}>{roomType.name}</th>);
                    printRoomTypePrices.push(<td key={key}>{roomType.price}</td>);
                    printRoomTypeNumberOfRents.push(<td key={key}>{totals[key]}</td>);
                }

                if (key.includes('rtTotal')) {
                    roomTypeMonies[roomTypeId] = totals[key];
                }
            }
        }

        roomTypeIds.forEach( rtId => {
            roomTypeTotals.push({ key: rtId, type: roomTypeNames[rtId], numberOfRents: roomTypeNumbers[rtId], amount: '$' + roomTypeMonies[rtId] });
        });

        const sumActionsMxn = orderTotalMxn  + rentTotalMxn 
            + addDaysTotalMxn + addGuestTotalMxn
            + changeTotalMxn + chargeTotalMxn
            + cancelTotalMxn + reservationsTotalMxn
            + reservationTicketsTotalMxn;
        const sumActionsUsd = orderTotalUsd  + rentTotalUsd 
            + addDaysTotalUsd + addGuestTotalUsd
            + changeTotalUsd + chargeTotalUsd
            + reservationsTotalUsd;
        const sumActionsCard = orderTotalCard  + rentTotalCard 
            + addDaysTotalCard + addGuestTotalCard
            + changeTotalCard + chargeTotalCard
            + reservationsTotalCard;
        
        printSumActionsMxn = sumActionsMxn + sumActionsCard;
        printSumActionsUsd = sumActionsUsd;

        // NOW ADD ABS TOTALS TO PRINTED TABLE
        printRoomTypeNames.push(<th key={99995}>Adicionales</th>);
        printRoomTypeNames.push(<th key={99996}>Cargos</th>);
        printRoomTypeNames.push(<th key={99997}>Bar y otros</th>);
        printRoomTypeNames.push(<th key={99998}></th>);
        printRoomTypeNames.push(<th key={99999}></th>);

        printRoomTypePrices.push(<td key={99995}>$150</td>);
        printRoomTypePrices.push(<td key={99996}></td>);
        printRoomTypePrices.push(<th key={99997}></th>);
        printRoomTypePrices.push(<th key={99998}>Pesos</th>);
        printRoomTypePrices.push(<th key={99999}>Dólares</th>);

        printRoomTypeNumberOfRents.push(<td key={99995}>{numberOfGuestAdditions}</td>);
        printRoomTypeNumberOfRents.push(<td key={99996}>{chargeAbsTotal}</td>);
        printRoomTypeNumberOfRents.push(<td key={99997}>{orderAbsTotal}</td>);
        printRoomTypeNumberOfRents.push(<td key={99998}>{printSumActionsMxn}</td>);
        printRoomTypeNumberOfRents.push(<td key={99999}>{printSumActionsUsd}</td>);

        var printRoomTypeTable = (
            <table border="1">
                <tbody>
                    <tr>{printRoomTypeNames}</tr>
                    <tr>{printRoomTypePrices}</tr>
                    <tr>{printRoomTypeNumberOfRents}</tr>
                </tbody>
            </table>
        );

        if (sectionNumber === 1) {
            setSection1Totals(income);
            setSection1Table(printRoomTypeTable);
        } else {
            setSection2Totals(income);
            setSection2Table(printRoomTypeTable);
        }
        // this.setState({
        //     income: income,
        //     roomTypeTotals: roomTypeTotals,
        //     actions: actions,
        //     printRoomTypeTable: printRoomTypeTable
        // });
    };

    return (
        <Tabs>
            <TabPane tab="General" key={1}>
                <Table
                    columns={totalsColumns} 
                    dataSource={general} 
                    size="small"
                    bordered={false}
                    locale={{emptyText: 'No se encontraron totales'}}
                    pagination={false}
                    style={{marginBottom: 20}}
                />

                {generalTable}
            </TabPane>
            {
                section1 != null &&
                    <TabPane tab="Sección 1" key={2}>
                        <Table
                            columns={totalsColumns} 
                            dataSource={section1Totals} 
                            size="small"
                            bordered={false}
                            locale={{emptyText: 'No se encontraron totales'}}
                            pagination={false}
                            style={{marginBottom: 20}}
                        />

                        {section1Table}
                    </TabPane>
            }

            {
                section2 != null &&
                    <TabPane tab="Sección 2" key={3}>
                        <Table
                            columns={totalsColumns} 
                            dataSource={section2Totals} 
                            size="small"
                            bordered={false}
                            locale={{emptyText: 'No se encontraron totales'}}
                            pagination={false}
                            style={{marginBottom: 20}}
                        />

                        {section2Table}
                    </TabPane>
            }
        </Tabs>
    );
};

export default SectionTotals;