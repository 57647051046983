import { notification } from "antd";

const duration = 3;
const placement = 'bottomRight';

export const errorNotification = (err) => {
    notification.error({
        message: 'Error',
        description: err.message,
        duration: duration,
        placement: placement
    });
};

export const successNotification = (msg) => {
    notification.success({
        message: 'Petición completada',
        description: msg,
        duration: duration,
        placement: placement
    });
};

export const infoNotification = (msg) => {
    notification.info({
        message: 'Info',
        description: msg,
        duration: duration,
        placement: placement
    });
};