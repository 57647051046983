const authConfig = {
    region: 'us-west-2',
    userPoolId: 'us-west-2_4gv6Ft4bm',
    userPoolWebClientId: '2r79s0p6ik0gmhvdofcdc23l8',
    mandatorySignIn: true
};

const apiConfig = {
    endpoints: [
        {
            name: 'benackoAPI',
            endpoint: 'https://5wqvl62qab.execute-api.eu-west-1.amazonaws.com/beta'
        },
        {
            name: 'reportsAPI',
            endpoint: 'https://78zcipqzl1.execute-api.us-west-2.amazonaws.com/v1'
        }
    ]
};

export default {
    API: apiConfig,
    Auth: authConfig
};
