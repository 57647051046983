import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Col, Row, Spin, Tabs } from 'antd';

import XLSX from 'sheetjs-style';

import SectionTitle from '../../../components/UI/Panels/SectionTitle/SectionTitle';
import SummaryTabContents from './Tabs/SummaryTabContents/SummaryTabContents';

import ReportsService from '../../../services/reports';
import AveragesTabContents from './Tabs/AveragesTabContents/AveragesTabContents';
import RoomsTabContents from './Tabs/RoomsTabContents/RoomsTabContents';
import DailyRoomsTabContents from './Tabs/DailyRoomsTabContents/DailyRoomsRoomsTabContents';
import DailyIncomeTotalsTabContents from './Tabs/DailyIncomeTotalsTabContents/DailyIncomeTotalsTabContents';
import DailyCardIncomeTabContents from './Tabs/DailyCardIncomeTabContents/DailyCardIncomeTabContents';
import DailyOrderIncomeTabContents from './Tabs/DailyOrderIncomeTabContents/DailyOrderIncomeTabContents';
import ShiftDifferencesTabContents from './Tabs/ShiftDifferencesTabContents/ShiftDifferencesTabContents';

import { processSummarySheet } from './Tabs/Sheets/Summary';
import { processRoomsSheet } from './Tabs/Sheets/Rooms';
import { processDailyRoomsSheet } from './Tabs/Sheets/DailyRooms';
import { processDailyTotalsSheet } from './Tabs/Sheets/DailyTotals';
import { processDailyCardIncomeSheet } from './Tabs/Sheets/DailyCardIncome';
import { processDailyOrderIncomeSheet } from './Tabs/Sheets/DailyOrderIncome';
import { processShiftDifferencesSheet } from './Tabs/Sheets/ShiftDifferences';
import { processAveragesSheet } from './Tabs/Sheets/Averages';


const MonthlyReport = () => {
    const queryString = useLocation();

    const cid = useSelector(state => state.base.cid ?? '');
    const hid = useSelector(state => state.ui.selectedHotel ?? 0);

    const [fileTitle, setFileTitle] = useState('reporte');
    const [loading, setLoading] = useState(true);
    const [summary, setSummary] = useState({});
    const [averages, setAverages] = useState({});
    const [rooms, setRooms] = useState({});
    const [rentTypeColumns, setRentTypeColumns] = useState([]);
    
    const [dailyRooms, setDailyRooms] = useState([]);
    const [dailyTotals, setDailyTotals] = useState([]);
    const [dailyCardIncome, setDailyCardIncome] = useState([]);
    const [dailyOrderIncome, setDailyOrderIncome] = useState([]);
    const [shiftDifferences, setShiftDifferences] = useState([]);

    useEffect(() => {
        if (cid != null && hid != null && queryString != null &&
            queryString.search != null && queryString.search.length > 6) {
            
                const search = queryString.search;
                const month = search.substring(6);

                setFileTitle(month);
                loadData(month);
        }
    }, [cid, hid, queryString]);

    const loadData = (month) => {
        ReportsService.getMonthlyReport(cid, hid, month)
            .then(res => {
                if (res != null && res.Summary != null) { setSummary(res.Summary); }
                if (res != null && res.Averages != null) { setAverages(res.Averages); }
                if (res != null && res.Rooms != null) { setRooms(res.Rooms); }
                if (res != null && res.RentTypeColumns != null) { setRentTypeColumns(res.RentTypeColumns); }
                if (res != null && res.Averages != null) { setAverages(res.Averages); }
                if (res != null && res.DailyRooms) { setDailyRooms(res.DailyRooms); }
                if (res != null && res.DailyIncomeTotals) { setDailyTotals(res.DailyIncomeTotals); }
                if (res != null && res.DailyIncomeCards != null) { setDailyCardIncome(res.DailyIncomeCards); }
                if (res != null && res.DailyIncomeOrders != null) { setDailyOrderIncome(res.DailyIncomeOrders); }
                if (res != null && res.ShiftDifferences != null) { setShiftDifferences(res.ShiftDifferences); }
            })
            .catch(err => console.log('ERR:', err))
            .finally(() => setLoading(false));
    };
    

    const downloadFile = () => {
        setLoading(true);
        const workbook = XLSX.utils.book_new();
        
        const summarySheet = processSummarySheet(summary, hid, fileTitle);
        XLSX.utils.book_append_sheet(workbook, summarySheet, 'Resumen');

        // const averagesSheet = processAveragesSheet(averages);
        // XLSX.utils.book_append_sheet(workbook, averagesSheet, 'Promedios');

        const roomsSheet = processRoomsSheet(rooms, rentTypeColumns);
        XLSX.utils.book_append_sheet(workbook, roomsSheet, 'Habitaciones');

        const dailyRoomsSheet = processDailyRoomsSheet(dailyRooms, rentTypeColumns);
        XLSX.utils.book_append_sheet(workbook, dailyRoomsSheet, 'Concentrado de habitaciones');

        const dailyTotalsSheet = processDailyTotalsSheet(dailyTotals);
        XLSX.utils.book_append_sheet(workbook, dailyTotalsSheet, 'Ingresos');

        const dailyCardIncomeSheet = processDailyCardIncomeSheet(dailyCardIncome);
        XLSX.utils.book_append_sheet(workbook, dailyCardIncomeSheet, 'Concentrado de tarjetas');

        const dailyOrderIncomeSheet = processDailyOrderIncomeSheet(dailyOrderIncome);
        XLSX.utils.book_append_sheet(workbook, dailyOrderIncomeSheet, 'Concentrado de órdenes');

        const shiftDifferencesSheet = processShiftDifferencesSheet(shiftDifferences);
        XLSX.utils.book_append_sheet(workbook, shiftDifferencesSheet, 'Diferencias en turnos');

        XLSX.writeFile(workbook, fileTitle + '.xlsx', {});
        setLoading(false);
    };

    return (
        <Row style={{display: 'flex', flexFlow: 'column', width: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 10}}>
                <Col span={23}>
                    <Row style={{marginLeft: 40, width: '100%', height: 40}}>
                        <Col offset={12} span={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Button type='primary' onClick={downloadFile} disabled={loading}>Descargar</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <SectionTitle title={'Reporte mensual'} />

            <Row style={{width: '95%', marginTop: 50, marginLeft: 40}}>
                <Spin spinning={loading}>
                <Tabs defaultActiveKey={1} style={{width: '100%', height: '100%'}}>
                    <Tabs.TabPane key={1} tab='Resumen'><SummaryTabContents summary={summary}/></Tabs.TabPane>
                    <Tabs.TabPane key={2} tab='Promedios'><AveragesTabContents averages={averages}/></Tabs.TabPane>
                    <Tabs.TabPane key={3} tab='Habitaciones'><RoomsTabContents columns={rentTypeColumns} rooms={rooms}/></Tabs.TabPane>
                    <Tabs.TabPane key={4} tab='Diario - Habitaciones'><DailyRoomsTabContents columns={rentTypeColumns} dailyRooms={dailyRooms}/></Tabs.TabPane>
                    <Tabs.TabPane key={5} tab='Diario - Ingresos'><DailyIncomeTotalsTabContents dailyTotals={dailyTotals}/></Tabs.TabPane>
                    <Tabs.TabPane key={6} tab='Diario - Concentrado de tarjetas'><DailyCardIncomeTabContents dailyIncomeCard={dailyCardIncome}/></Tabs.TabPane>
                    <Tabs.TabPane key={7} tab='Diario - Ingresos por órdenes'><DailyOrderIncomeTabContents dailyIncomeOrder={dailyOrderIncome}/></Tabs.TabPane>
                    <Tabs.TabPane key={8} tab='Turnos - Diferencias'><ShiftDifferencesTabContents differences={shiftDifferences}/></Tabs.TabPane>
                </Tabs>
                </Spin>
            </Row>
        </Row>
    );
};

export default MonthlyReport;