import React from 'react';
import { Tooltip, Button, Popconfirm } from 'antd';


const EnDisableButton = ({type = "disable", disabled = true, buttonEvent = () => console.log('No button event has been set'), onClick = () => console.log('No click action set')}) => {

    return (
        // <Tooltip placement="bottom" title={(type === "disable") ? 'Deshabilitar' : 'Habilitar'}>
            <Popconfirm title="¿Estás seguro de deshabilitar este usuario?" onConfirm={buttonEvent} okText="Si" cancelText="No" placement="bottomLeft">
                <Button
                    type={(type === "disable" ? "danger" : "primary")}
                    size="middle"
                    disabled={disabled}
                    onClick={onClick}
                >
                    {(type === "disable") ? 'Deshabilitar' : 'Habilitar'}
                </Button>
            </Popconfirm>
    );
}

export default EnDisableButton;