import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API } from 'aws-amplify';
import { Layout, Row, Table } from 'antd'


const { Header, Content } = Layout;

const columns = [
    {
        title: 'Hotel',
        dataIndex: 'hotel',
        key: 'hotel'
    },
    {
        title: 'Moneda',
        dataIndex: 'currency',
        key: 'currency'
    },
    {
        title: 'Tasa',
        dataIndex: 'rate',
        key: 'rate'
    },
    {
        title: 'Desde',
        dataIndex: 'updated',
        key: 'updated'
    }
];


class Currencies extends Component {
    state = {
        loading: true,
        data: []
    }

    userHotels = [];

    async loadData() {
        const enabledHotels = this.props.hotels;
        var rates = [];

        for (var hotel in enabledHotels)  {
            this.setState({loading: true});

            await API.get('benackoAPI', '/exchange-rates/' + hotel)
                .then(rate => {
                    
                    rates.push({
                        key: rate[0].id,
                        currency: 'MXN -> ' + rate[0].currency,
                        rate: '$ ' + rate[0].rate.toFixed(2),
                        hotel: enabledHotels[rate[0].hotelId],
                        updated: new Date(rate[0].updated).toLocaleString()
                    });
                })
                .catch( errUserHotels => {
                    console.log('Error loading user hotel data:', errUserHotels);

                    this.setState({ loading: false });
                });
        }

        this.setState({ data: rates, loading: false });
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                    </Row>
                </Header>
                <Content style={{margin: 0, padding: 0, paddingLeft: 20, background: '#fff'}}>
                <Table 
                        columns={columns} 
                        dataSource={this.state.data}
                        onRow={ (record) => {
                            return {
                                onClick: () => {
                                    this.props.history.push('/app/payments/currencies/details/' + record.key);
                                }
                            };
                        }}
                        size="middle" 
                        pagination={{
                            pageSize: 8,
                            showTotal: (total) => `Total: ${total} tipos`
                        }}
                        locale={{
                            emptyText: 'No se encontraron tipos de cambio'
                        }} 
                        loading={{
                            spinning: this.state.loading,
                            delay: 500,
                            size: 'large'
                        }}/>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(Currencies);