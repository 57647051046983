import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API } from 'aws-amplify';
import { Button, Form, Input, Layout, Row, Col, Spin, Select, Radio } from 'antd';
import { LeftOutlined, TableOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;
const Option = Select.Option;


class AddItems extends Component {
    state = {
        loading: false,
        locationOptions: [],
        itemOptions: [],
        itemNames: [],
        itemId: 0,
        productLocationId: 0,
        minimumQuantity: 0,
        standardQuantity: 0,
        selectedBreakfast: 0,
        breakfastMeal: false,
        breakfastBeverage: false
    }

    loadData() {
        const id = this.props.match.params.id;

        API.get('benackoAPI', '/inventories/' + id)
            .then(async response => {
                const hotelId = response[0]?.hotelId ?? 0;

                API.get('benackoAPI', '/product-locations?hotelId=' + hotelId, {
                        headers: {Authorization: this.idToken}
                    })
                    .then(response => {
                        var itemLocations = [];
        
                        response.forEach( type => {
                            itemLocations.push(<Option key={type.id}>{type.name}</Option>);
                        });
        
                        this.setState({locationOptions: itemLocations, loading: false});
                    })
                    .catch( errLocations => {
                        console.log('Error loading item locations: ', errLocations);
        
                        this.setState({loading: false});
                    });
            })
            .catch(errInventories => {
                console.log('Error loading inventories: ', errInventories);

                this.setState({loading: false});
            });

        API.get('benackoAPI', '/items?companyId=' + this.props.companyId)
            .then(response => {
                var items = [];
                var itemNames = [];

                response.sort((a, b) => {return a.name > b.name});

                response.forEach( item => {
                    items.push(<Option key={item.id}>{item.name}</Option>);

                    itemNames.push({
                        id: item.id,
                        name: item.name
                    });
                });

                this.setState({itemOptions: items, itemNames: itemNames, loading: false});
            })
            .catch(errItems => {
                console.log('Error loading items: ', errItems);

                this.setState({loading: false});
            });
    }

    componentDidMount() {
        this.loadData();
    }

    addArticleHandler = () => {
        this.setState({loading: true});

        
        const itemNames = this.state.itemNames;
        const itemId = parseInt(this.state.itemId, 10);
        const itemName = itemNames.find(it => {return it.id === itemId});

        API.post('benackoAPI', '/inventory-items', {
                body: {
                    inventoryId: this.props.match.params.id,
                    itemId: itemId,
                    productLocationId: this.state.productLocationId,
                    minimumQuantity: parseInt(this.state.minimumQuantity, 10),
                    standardQuantity: parseInt(this.state.standardQuantity, 10),
                    breakfastBeverage: this.state.breakfastBeverage,
                    breakfastMeal: this.state.breakfastMeal
                }
            })
            .then(() => {
                const inventoryAction = {
                    inventoryId: parseInt(this.props.match.params.id, 10),
                    action: 'agregar artículo',
                    itemId: itemId,
                    itemName: itemName.name,
                    quantity: 0,
                    user: this.props.user.username,
                    descr: '-'
                };

                API.post('benackoAPI', '/v2/inventory-actions', {
                        body: inventoryAction
                    })
                    .then(() => {
                        this.setState({loading: false});
                        this.props.history.goBack();
                    })
                    .catch(errInvAction => {
                        console.log('Error loading inventory action:', errInvAction);
                        this.setState({loading: false});
                    });
            })
            .catch( errAddItem => {
                console.log('Error adding item: ', errAddItem);
                this.setState({loading: false});
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    render() {
        return(
            <Layout>
                <Header style={{width: '100%', background: '#fff', padding: 0, paddingLeft: 20}}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{width: '100%', margin: 0, padding: 0, background: '#fff', paddingLeft: 20}}>
                    <Spin spinning={this.state.loading} size="large">
                        <Form className="login-form">
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Ubicación:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Select style={{width: '100%'}} onSelect={(e) => this.setState({productLocationId: e})}>
                                        {this.state.locationOptions}
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Artículo:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Select style={{width: '100%'}} onSelect={(e) => this.setState({itemId: e})}>
                                        {this.state.itemOptions}
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Cantidad mínima:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Input style={{width: '100%'}} onChange={(e) => this.setState({minimumQuantity: e.target.value})} prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Minimum quantity" />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Cantidad estándar:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Input style={{width: '100%'}} onChange={(e) => this.setState({standardQuantity: e.target.value})} prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Standard quantity" />
                                </Col>
                            </Row>

                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Artículo de desayuno:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Radio.Group 
                                        options={[{label: 'Ninguno', value: 0},{label: 'Bebida', value: 1},{label: 'Alimento', value: 2}]} 
                                        value={this.state.selectedBreakfast} 
                                        optionType='button' 
                                        buttonStyle='solid' 
                                        onChange={e => {
                                            const selectedVal = e.target.value;
                                            const beverage = (selectedVal === 1);
                                            const meal = (selectedVal === 2);
                                            
                                            this.setState({selectedBreakfast: selectedVal, breakfastBeverage: beverage, breakfastMeal: meal});
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row style={{marginTop: 30}}>
                                <Col span={4} offset={2}>
                                    <Button type="primary" 
                                        onClick={this.addArticleHandler}
                                        disabled={this.state.itemId === 0 || this.state.productLocationId === 0} className="login-form-button"
                                    >
                                        Agregar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.auth.companyId,
        user: state.auth.user,
        cid: state.base.cid
    };
};


export default connect(mapStateToProps, null)(AddItems);