import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API } from 'aws-amplify';
import { Button, Form, Input, Layout, Row, Col, Spin, Select } from 'antd';
import { LeftOutlined, TableOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;
const Option = Select.Option;


class Create extends Component {
    state = {
        loading: false,
        unitOptions: [],
        selectedUnit: null,
        itemName: '',
        pcid: null
    }

    idToken = '';


    loadData() {
        this.setState({loading: true});
        this.setState({pcid: this.props.cid});

        API.get('benackoAPI', '/item-units?companyId=' + this.props.companyId)
            .then(units => {
                var options = [];

                units.forEach(unit => {
                    options.push(<Option key={unit.id}>{unit.name}</Option>);
                })

                this.setState({unitOptions: options});
            })
            .catch( errItemUnits => console.log('Error loading item unit data:', errItemUnits))
            .finally(() => this.setState({loading: false}));
    }

    componentDidMount() {
        this.loadData();
    }

    createItemHandler = () => {
        this.setState({loading: true});

        const beautifiedName = this.state.itemName.trim().toLowerCase();

        API.post('benackoAPI', '/items', {
            body: {
                name: beautifiedName,
                unitId: this.state.selectedUnit,
                companyId: this.props.companyId
            }
        })
        .then( () => {
            this.setState({loading: false});
            this.props.history.goBack();
        })
        .catch( error => {
            console.log(error);
            this.setState({loading: false});
        });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    render() {
        return(
            <Layout>
                <Header style={{background: '#fff', padding: 0, paddingLeft: 20}}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{margin: 0, padding: 0, background: '#ffff', paddingLeft: 20}}>
                    <Spin spinning={this.state.loading} size="large">
                        <Form className="login-form">
                            <Row style={{marginTop: 30}}>
                                <Col span={20} offset={2}>
                                    <p>Unidad:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Select onSelect={(e) => this.setState({selectedUnit: e})} style={{width: '100%'}}>
                                        {this.state.unitOptions}
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Nombre:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Input prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Nombre" onChange={(e) => this.setState({itemName: e.target.value})} />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 50}}>
                                <Col span={4} offset={2}>
                                    <Button type="primary" 
                                        onClick={this.createItemHandler}
                                        disabled={this.state.itemName == null || this.state.itemName.length <= 1} className="login-form-button"
                                    >
                                        Crear
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.auth.companyId,
        cid: state.base.cid
    };
};


export default connect(mapStateToProps, null)(Create);