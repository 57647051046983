import React, { Component } from 'react';


class Dashboard extends Component {

    render() {
        return(
            <div>
                <h1>Tablero</h1>
            </div>
        );
    }
}

export default Dashboard;