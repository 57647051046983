import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, useHistory, useParams } from 'react-router-dom';
import { Breadcrumb, Button, Col, Input, Row, Skeleton, Table, Tabs, Tag } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

import EnDisableButton from '../../../components/UI/Buttons/EnDisableButton/EnDisableButton';
import SectionTitle from '../../../components/UI/Panels/SectionTitle/SectionTitle';
import { errorNotification, successNotification } from '../../../components/UI/Notifications/Notifications';

import HotelService from '../../../services/hotels';
import UserService from '../../../services/users';
import Roles from '../../../common/roles';

const { TabPane } = Tabs;


const UsersDetails = () => {
    const {id} = useParams();
    const location = useLocation();
    const history = useHistory();

    const cid = useSelector(state => state.auth.companyId ?? 0);
    const dbid = useSelector(state => state.base.dbid ?? -1);
    const roleLevel = useSelector(state => state.base.roleLevel ?? 10000);
    
    const [loading, setLoading] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [hotels, setHotels] = useState([]);
    const [user, setUser] = useState(null);

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const userType = searchParams.get("type");

    const [newFirstName, setNewFirstName] = useState(user?.FirstName);
    const [newLastName, setNewLastName] = useState(user?.LastName);

    const hotelColumns = [
        {title: 'Hotel', dataIndex: 'Name'},
        {dataIndex: 'Enabled', render: element => (element) ? <Tag color="#87D068">Activado</Tag> : <Tag color="#FF0500">Desactivado</Tag>}
    ];

    useEffect(() => {
        loadData();
    }, [cid]);

    useEffect(() => {}, [searchParams]);

    const validateFirstName = () => {
        const numId = parseInt(id);
        if (submitted || (user != null && user.RoleLevel < roleLevel) ||
            (user != null && user.RoleLevel == roleLevel && numId !== dbid ) ||
            (user != null && !user.Enabled) ||
            newFirstName == null || newFirstName.length <= 0 || user.FirstName === newFirstName) {
                return true;
        } else {
            return false;
        }
    };

    const validateLastName = () => {
        const numId = parseInt(id);
        if (submitted || (user != null && user.RoleLevel < roleLevel) ||
            (user != null && user.RoleLevel == roleLevel && numId !== dbid) ||
            (user != null && !user.Enabled) ||
            newLastName == null || newLastName.length <= 0 || user.LastName === newLastName) {
                return true;
        } else {
            return false;
        }
    };

    const validateDisable = () => {
        if (loading || submitted ||
            (user != null && user.RoleLevel < roleLevel)) {
            return true;
        } else {
            return false;
        }
    };

    const loadData = async () => {
        setLoading(true);

        const queryParams = new URLSearchParams(location.search);
        const type = queryParams.get('type') ?? '';

        let preHotels = [];
        await HotelService.getHotels(cid)
            .then(res => {
                res.forEach(hotel => preHotels.push({Key: hotel.id, Name: hotel.name, Enabled: false}));
            })
            .catch(err => errorNotification(err));

        if (id != null) {
            UserService.getUserDetails(type, id)
                .then(res => {
                    const preUser = res[0] ?? {};

                    if (type === 'op' && Object.keys(preUser) !== 0) {
                        preUser.first_name = preUser.firstname;
                        delete preUser.firstname;

                        preUser.last_name = preUser.lastname;
                        delete preUser.lastname;

                        preHotels.forEach(hotel => {
                            if (hotel.Key === preUser.hotelId) {
                                hotel.Enabled = true;
                            }
                        });
                    }

                    setHotels(preHotels);
                    setUser(preUser);
                })
                .then(async () => {
                    if (type === 'ad') {
                        await UserService.getUserHotels(id)
                            .then(usht => {
                                preHotels.forEach(hotel => {
                                    const userHotel = usht.find(h => {return h.hotelId === hotel.Key;});
                                    if (userHotel != null && userHotel.enabled === 1) {
                                        hotel.Enabled = true;
                                    }
                                });

                                setHotels(preHotels);
                            })
                            .catch(err => errorNotification(err));
                    }
                })
                .catch(err => errorNotification(err))
                .finally(() => setLoading(false));
        }
    };

    const editUser = (property, currentValue, newValue) => {
        setLoading(true);
        
        if (!submitted) {
            setSubmitted(true);

            if (newValue != null && newValue.length != 0 && newValue != currentValue) {
                UserService.editUserDetails(cid, id, property, newValue)
                    .then(() => successNotification('El parámetro ha sido editado exitosamente'))
                    .catch(err => errorNotification(err))
                    .finally(() => {
                        loadData();
                        setSubmitted(false);
                    });
            }
        }
    };

    const disableUser = () => {
        
        if (userType === 'op') {
            setLoading(true);
            UserService.disableOpUser(id, user.username ?? '')
                .then(() => {
                    successNotification('El usuario ha sido deshabilitado exitosamente');
                })
                .then(() => history.goBack())
                .catch(err => {
                    errorNotification(err);
                    setLoading(false);
                });
        } else if (userType === 'ad') {
            setLoading(true);
            UserService.disableAdUser(id, user.username)
                .then(() => {
                    successNotification('El usuario ha sido deshabilitado exitosamente');
                })
                .then(() => history.goBack())
                .catch(err => {
                    errorNotification(err);
                    setLoading(false);
                });
        }
    };


    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 10}}>
                <Col span={23}>
                    <Row style={{marginLeft: 40, width: '100%', height: 40}}>
                        <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <NavLink to={'/app/users'}>{'Usuarios'}</NavLink>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {'Detalles de usuario'}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        <Col span={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <EnDisableButton type="disable" buttonEvent={disableUser.bind(this)} disabled={validateDisable()} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Skeleton loading={loading} paragraph={{rows: 1, cols: 1}} active>
                <SectionTitle title={user?.username ?? ''} />
            </Skeleton>
            <Tabs defaultActiveKey={1} style={{marginLeft: 40, marginTop: 30}}>
                <TabPane tab={'Información general'} key={1}>
                    <Skeleton loading={loading} paragraph={{rows: 9, cols: 1}} active>
                        <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                            <Row style={{width: '100%', height: 60}}>
                                <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Nombre(s)</span></Col>
                                <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                                    <Input type="text" maxLength={200} defaultValue={user?.first_name ?? ''} onChange={(e) => setNewFirstName(e.target.value)} disabled={true || submitted || (user!= null && user.RoleLevel < roleLevel) || (user != null && !user.Enabled) || (user != null && user.RoleLevel === roleLevel && dbid !== parseInt(id))}/></span>
                                    <span style={{marginLeft: 10, width: 20}}>
                                        <Button type="danger" shape="circle" size="small" style={{marginLeft: 10}} icon={<SaveOutlined />}
                                            onClick={() => editUser('FirstName', user.first_name, newFirstName)} hidden={validateFirstName()} disabled={validateFirstName()}
                                        /></span>
                                </Col>
                            </Row>
                            <Row style={{width: '100%', height: 60}}>
                                <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Apellido(s)</span></Col>
                                <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                                    <Input type="text" maxLength={200} defaultValue={user?.last_name ?? ''} onChange={(e) => setNewLastName(e.target.value)} disabled={true || submitted || (user!= null && user.RoleLevel < roleLevel) || (user != null && !user.Enabled) || (user != null && user.RoleLevel === roleLevel && dbid !== parseInt(id))}/></span>
                                    <span style={{marginLeft: 10, width: 20}}>
                                        <Button type="danger" shape="circle" size="small" style={{marginLeft: 10}} icon={<SaveOutlined />}
                                            onClick={() => editUser('LastName', user.last_name, newLastName)} hidden={validateLastName()} disabled={validateLastName()}
                                        /></span>
                                </Col>
                            </Row>
                            <Row style={{width: '100%', height: 60}}>
                                <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Rol</span></Col>
                                <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                                    <Input type="text" defaultValue={Roles[user?.roleId ?? 0]?.name ?? ''} disabled/></span>
                                </Col>
                            </Row>
                            
                            <Row style={{width: '100%', height: 60}}>
                                <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Creado en</span></Col>
                                <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                                    <Input type="text" defaultValue={new Date(user?.created ?? 0).toLocaleString('es-mx', {timeZone: 'PST'})} disabled/></span>
                                </Col>
                            </Row>
                            <Row style={{width: '100%', height: 60}}>
                                <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Última modificación</span></Col>
                                <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                                    <Input type="text" defaultValue={new Date(user?.updated ?? 0).toLocaleString('es-mx', {timeZone: 'PST'})} disabled/></span>
                                </Col>
                            </Row>
                        </Row>
                    </Skeleton>
                </TabPane>
                <TabPane tab={'Hoteles'} key={2}>
                    <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                        <Table
                            style={{cursor: 'pointer'}}
                            size={'small'}
                            bordered={true}
                            loading={loading}
                            columns={hotelColumns}
                            dataSource={hotels}
                            pagination={false}
                            rowKey={'Key'}
                            scroll={{y: '100%'}}
                            locale={{
                                emptyText: 'No se encontraron datos'
                            }}
                        />
                    </Row>
                </TabPane>
            </Tabs>
        </Row>
    );
};

export default UsersDetails;