import React from 'react';
import { Modal } from 'antd'; 

function printPartOfPage (elementId, uniqueIframeId) {
    const content = document.getElementById(elementId)
    let pri
    if (document.getElementById(uniqueIframeId)) {
        pri = document.getElementById(uniqueIframeId).contentWindow
    } else {
        const iframe = document.createElement('iframe')
        iframe.setAttribute('title', uniqueIframeId)
        iframe.setAttribute('id', uniqueIframeId)
        iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
        document.body.appendChild(iframe)
        pri = iframe.contentWindow
    }
    pri.document.open()
    pri.document.write(content.innerHTML)
    pri.document.close()
    pri.focus()
    pri.print()
}


const PrintableTicket = (props) => {
    const rentPeriod = (<p>Cantidad: {props.rentPeriod}</p>);
    const rentRate = (<p>Importe: ${props.rentRate}</p>);
    const rentTotal = props.rentPeriod * props.rentRate;

    return (
        <Modal
            id="invoice"
            title="Comprobante"
            cancelText="CERRAR"
            okText="IMPRIMIR"
            onOk={() => {
                printPartOfPage("test", "testInvoice");
            }}
            onCancel={props.onCancel}
            visible={props.visible}
            centered={true}
        >
            <div id="test">
                {props.hotel.legalName}
                <br />
                RFC: {props.hotel.rfc}
                <br />
                {props.hotel.address}
                <br />
                {props.hotel.city}, {props.hotel.state}, {props.hotel.country}
                <br />
                C.P.: {props.hotel.zipCode}
                <br />
                Tel: {props.hotel.phoneNumber}
                <hr />
                {
                    (props.voucherType === 'nt') &&
                        <p>NOTA DE CRÉDITO</p>
                }
                <br />
                Fecha: {props.rentDate}
                <br />
                {
                    props.rentPeriod !== 0 &&
                        rentPeriod
                }
                <br />Concepto: {props.ticketConcept}
                { 
                    props.rentRate !== 0 &&
                        rentRate
                }
                <br />
                Total: ${rentTotal}
                <hr />
                Folio {props.ticketFolio}
                <br />
                COMPROBANTE SIMPLIFICADO NO DEDUCIBLE PARA EFECTOS FISCALES
                < br/><br />
                ORIGINAL
                <br /><br />
                {
                    (props.rentInvoice === true) &&
                        <p>FACTURADO</p>
                }
            </div>
        </Modal>
    );
};


export default PrintableTicket;