import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, Button, Col, Input, Row, Select, Space } from 'antd';

import { disableHotelSelector, enableHotelSelector } from '../../../../../store/actions';
import SectionTitle from '../../../../../components/UI/Panels/SectionTitle/SectionTitle';
import MaintenanceService from '../../../../../services/maintenance';
import { errorNotification } from '../../../../../components/UI/Notifications/Notifications';



const CreateRoomFurnishing = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    
    const {roomId} = useParams();

    const hid = useSelector(state => state.ui.selectedHotel ?? 0);

    const [submitted, setSubmitted] = useState(false);
    const [name, setName] = useState('');
    const [model, setModel] = useState('');
    const [description, setDescription] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [type, setType] = useState(1);
    const [category, setCategory] = useState('');
    const [valid, setValid] = useState(false);

    useEffect(() => {
        dispatch(enableHotelSelector());
        

        return () => {
            dispatch(disableHotelSelector());
        };
    }, []);

    useEffect(() => {
        setValid(name != null && name.length > 2 && type != null);
    }, [name, type]);

    const createFurnishing = () => {
        setSubmitted(true);

        MaintenanceService.createRoomFurnishing(hid, roomId, name, model, description, invoiceNumber, serialNumber, type, category)
        .then(() => {
            setSubmitted(false);
            history.go(-1);
        })
        .catch(err => {
            errorNotification(err);
            setSubmitted(false);
        });
    };

    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 10}}>
                <Col span={22}>
                    <Row style={{marginLeft: 40, width: '100%', height: 40}}>
                        <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <NavLink to={'/app/maintenance/rooms'}>{'Habitaciones'}</NavLink>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <NavLink to={'/app/maintenance/rooms/' + roomId}>{'Detalle de habitación'}</NavLink>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {'Crear mobiliario'}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{width: '100%', height: 40, marginBottom: 10}}>
                <Col offset={22} span={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Space/>
                </Col>
            </Row>

            <SectionTitle title='Crear mobiliario'/>

            <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                <Row style={{width: '100%', height: 60}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Nombre</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input type="text" maxLength={200} onChange={(e) => setName(e.target.value)} disabled={submitted}/></span>
                    </Col>
                </Row>
                <Row style={{width: '100%', height: 60}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Categoría</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input type="text" maxLength={200} onChange={(e) => setCategory(e.target.value)} disabled={submitted}/></span>
                    </Col>
                </Row>
                <Row style={{width: '100%', height: 60}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Modelo</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input type="text" maxLength={200} onChange={(e) => setModel(e.target.value)} disabled={submitted}/></span>
                    </Col>
                </Row>
                <Row style={{width: '100%', height: 60}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Descripción</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input type="text" maxLength={200} onChange={(e) => setDescription(e.target.value)} disabled={submitted}/></span>
                    </Col>
                </Row>
                <Row style={{width: '100%', height: 60}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Número de factura</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input type="text" maxLength={200} onChange={(e) => setInvoiceNumber(e.target.value)} disabled={submitted}/></span>
                    </Col>
                </Row>
                <Row style={{width: '100%', height: 60}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Número de serie</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input type="text" maxLength={200} onChange={(e) => setSerialNumber(e.target.value)} disabled={submitted}/></span>
                    </Col>
                </Row>
                <Row style={{width: '100%', height: 60}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Tipo de mobiliario</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Select style={{width: 400}} defaultValue={1} onChange={(e) => setType(e)} disabled={submitted}>
                            <Select.Option value={1}>Mueble</Select.Option>
                            <Select.Option value={2}>Electrodoméstico</Select.Option>
                        </Select></span>
                    </Col>
                </Row>
            </Row>

            <Row style={{width: '100%', height: 40, marginTop: 40, marginBottom: 10}}>
                <Col span={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Button type='primary' disabled={!valid || submitted} onClick={createFurnishing}>Crear</Button>
                </Col>
            </Row>
        </Row>
    );
}

export default CreateRoomFurnishing;