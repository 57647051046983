import { Auth, API } from 'aws-amplify';

class ReportsService {

    // static async getRoomStatusDashboard(cid, hid) {
    //     const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});
        
    //     return API.get('reportsAPI', '/dashboards/room-status?cid=' + cid + '&hid=' + hid, {headers: {Authorization: idToken}})
    //         .then(response => {
    //             if (response == null) {
    //                 throw Error(response ?? 'Respuesta no válida del servidor');
    //             } else {
    //                 return response;
    //             }
    //         })
    //         .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    // }

    // static async getShiftDates(cid, hid, date) {
    //     const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});
        
    //     return API.get('reportsAPI', '/data/shift/dates?cid=' + cid + '&hid=' + hid + '&rd=' + date, {headers: {Authorization: idToken}})
    //         .then(response => {
    //             if (response == null) {
    //                 throw Error(response ?? 'Respuesta no válida del servidor');
    //             } else {
    //                 return response;
    //             }
    //         })
    //         .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    // }

    // static async getDepositsListReport(cid, hid, timestamp) {
    //     const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});
        
    //     return API.get('reportsAPI', '/reports/deposits?cid=' + cid + '&hid=' + hid + '&ts=' + timestamp, {headers: {Authorization: idToken}})
    //         .then(response => {
    //             if (response == null) {
    //                 throw Error(response ?? 'Respuesta no válida del servidor');
    //             } else {
    //                 return response;
    //             }
    //         })
    //         .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    // }

    // static async getShiftHeaderReport(cid, hid, start, end) {
    //     const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});
        
    //     return API.get('reportsAPI', '/reports/shift/headers?cid=' + cid + '&hid=' + hid + '&st=' + start + '&et=' + end, {headers: {Authorization: idToken}})
    //         .then(response => {
    //             if (response == null) {
    //                 throw Error(response ?? 'Respuesta no válida del servidor');
    //             } else {
    //                 return response;
    //             }
    //         })
    //         .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    // }

    // static async getMaidActivityReport(cid, hid, start, end) {
    //     const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});
        
    //     return API.get('reportsAPI', '/reports/maid-activity?cid=' + cid + '&hid=' + hid + '&sd=' + start + '&ed=' + end, {headers: {Authorization: idToken}})
    //         .then(response => {
    //             if (response == null) {
    //                 throw Error(response ?? 'Respuesta no válida del servidor');
    //             } else {
    //                 return response;
    //             }
    //         })
    //         .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    // }

    static async getMonthlyReport(cid, hid, month) {
        const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});
        
        return API.get('reportsAPI', '/reports/monthly-income?cid=' + cid + '&hid=' + hid + '&mon=' + month, {headers: {Authorization: idToken}})
            .then(response => {
                if (response == null) {
                    throw Error(response ?? 'Respuesta no válida del servidor');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    }

    static async getSectionTotals(cid, hid, timestamp) {
        const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});
        
        return API.get('reportsAPI', '/reports/shift/totals/section?cid=' + cid + '&hid=' + hid + '&st=' + timestamp, {headers: {Authorization: idToken}})
            .then(response => {
                if (response == null) {
                    throw Error(response ?? 'Respuesta no válida del servidor');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    }
};

export default ReportsService;