import React from 'react';
import { Row, Col } from 'antd';

import HotelSelector from '../../AppBase/HotelSelector/HotelSelector';
import Logout from '../../UI/Auth/Logout/Logout';


const TopBar = () => {

    return (
        <Row justify="end" style={{padding: 0, width: '100%', height: '60px', backgroundColor: 'white'}}>
            <Col span={10} style={{display: 'flex'}}>
                <Col span={20} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <HotelSelector />
                </Col>
                <Col span={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Logout />
                </Col>
            </Col>
        </Row>
    );
}

export default TopBar;