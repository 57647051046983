import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { Modal, Input } from 'antd';

const { TextArea } = Input;

class AddCommentModal extends Component {
    state = {
        visible: false,
        confirmLoading: false,
        commentSection: '',
        comment: ''
    };

    idToken = '';


    showAddModal = (section) => {
        this.setState({visible: true, commentSection: section});
    }

    closeAddModal = () => {
        this.setState({visible: false, confirmLoading: false});
    }

    addComment = () => {
        const hotelId = this.props.hotelId;
        const type = this.props.type;
        const startTimestamp = this.props.startTimestamp;
        const section = this.state.commentSection;
        const comment = this.state.comment;

        this.setState({confirmLoading: true});

        const beautifiedComment = this.state.comment.trim().toLowerCase();
        if (beautifiedComment.length > 0) {
            Auth.currentSession()
                .then( response => {
                    this.idToken = response.idToken.jwtToken;
                })
                .then( async () => {
                    const user = await Auth.currentAuthenticatedUser();

                    return user.attributes.email;
                })
                .then( username => {
                    API.put('benackoAPI', '/v2/reports/shift-balances/comments', {
                            headers: {
                                Authorization: this.idToken
                            },
                            body: {
                                hotelId: hotelId,
                                type: type,
                                startTimestamp: startTimestamp,
                                username: username,
                                section: section,
                                comment: comment
                            }
                        })
                        .then( () => {
                            this.closeAddModal();
                        })
                        .catch( errComment => {
                            console.log('Error adding comment:', errComment);
                            this.closeAddModal();
                        });
                })
                .catch( errAuth => {
                    console.log('Error authenticating request:', errAuth);
                    this.closeAddModal();
                });
        } else {
            this.closeAddModal();
        }
    }

    updateComment = (comment) => {
        const beautifiedComment = comment.target.value.trim().toLowerCase();
        this.setState({comment: beautifiedComment});
    }

    render() {
        return (
            <Modal
                closable={false}
                visible={this.state.visible}
                okText="Agregar"
                cancelText="Cancelar"
                cancelType="danger"
                confirmLoading={this.state.confirmLoading}
                onCancel={this.closeAddModal}
                onOk={this.addComment}
                title={"Agregar comentario"}
            >
                <TextArea
                    rows={4}
                    style={{ width: '100%' }}
                    onChange={this.updateComment}
                />
            </Modal>
        );
    }
}

export default AddCommentModal;