import React from 'react';


const Room = (props) => {
    let statusColor = computeStatusColor(props.status);
    let statusText = computeStatusText(props.status);

    let customerPlate = '';
    if (props.customerPlate !== null) {
        customerPlate = props.customerPlate;
    }

    var checkoutTimestamp = 0;
    const statusTextLabel = () => {
        var statusLabel = <span><span>{statusText}</span></span>;
        
        if (props.checkoutTimestamp !== null && props.checkoutTimestamp !== 0) {
            checkoutTimestamp = new Date(props.checkoutTimestamp).toLocaleString();
            const currentTimestamp = new Date().getTime();
            const difference = props.checkoutTimestamp - currentTimestamp;

            if (difference < 0) {
                statusLabel = <span><strong style={{ color: 'yellow', backgroundColor: 'black' }}>¡VENCIDA!</strong></span>;
            } else if (difference > 0 && difference <= 1800000) {
                statusLabel = <span><strong style={{ color: 'red', backgroundColor: 'yellow' }}>POR VENCER</strong></span>;
            } else {
                statusLabel = <span><span>{statusText}</span></span>;
            }
        }

        return statusLabel;
    }

    return (
        <div 
            style={{ 
                width: 160,
                height: 100,
                float: 'left',
                backgroundColor: statusColor,
                margin: 20
            }}
        >
            <div style={{ width: '100%', height: 20, color: 'white' }}>
                <div style={{ width: '30%', height: '100%', float: 'left', textAlign: 'center' }}>
                    <span>{props.name}</span>
                </div>
                <div style={{ width: '70%',
                    height: '100%',
                    float: 'right',
                    textAlign: 'right',
                    fontSize: 10,
                    fontWeight: 'lighter',
                    paddingTop: 3,
                    paddingRight: 7
                }}>
                    {statusTextLabel()}
                    <div style={{
                        textAlign: 'left',
                        fontSize: 24,
                        fontWeight: 'bold',
                        paddingTop: 10
                    }}>
                        <span>{customerPlate}</span>
                    </div>
                </div>
            </div>
            <div style={{ width: '100%', height: 60 }}>
            </div>
            <div style={{ width: '100%', height: 20, backgroundColor: 'lightgray', textAlign: 'center'}}>
                <span>{checkoutTimestamp !== 0 ? checkoutTimestamp : ''}</span>
            </div>
        </div>
    );
};

const computeStatusColor = (roomStatusId) => {
    switch(roomStatusId) {
        case 0:
            return 'darkcyan';
        case 1:
            return '#00C853';
        case 2:
            return 'black';
        case 3:
            return 'dimgray';
        case 4:
            return 'red';
        case 5:
            return 'blue';
        case 6:
            return 'white';
        case 7:
            return 'white';
        default:
            return '';
    }
}

const computeStatusText = (roomStatusId) => {
    switch(roomStatusId) {
        case 0:
            return 'recepción';
        case 1:
            return 'disponible';
        case 2:
            return 'bloqueada';
        case 3:
            return 'sucia';
        case 4:
            return 'ocupada';
        case 5:
            return 'limpieza';
        case 6:
            return 'vencida';
        case 7:
            return 'pendiente de pago';
        default:
            return '';
    }
}

export default Room;