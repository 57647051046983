import React, { useEffect, useState } from 'react';
import { Descriptions, Row, Table } from 'antd';

import './DailyIncomeTotalsTabContents.css';

const DailyIncomeTotalsTabContents = ({dailyTotals = []}) => {
    // const [processedTotals, setProcessedTotals] = useState([]);
    const [days, setDays] = useState([]);

    const totalsColumns = [
        {title: 'Día', dataIndex: 'day', align: 'center', width: 20},
        {title: 'Rentas', dataIndex: 'totalRents', align: 'center', width: 20, render: (e) => '$' + Number(e ?? 0).toLocaleString()},
        {title: 'Extensiones de renta', dataIndex: 'totalExtensions', align: 'center', width: 20, render: (e) => '$' + Number(e ?? 0).toLocaleString()},
        {title: 'Huéspedes adicionales', dataIndex: 'totalGuests', align: 'center', width: 20, render: (e) => '$' + Number(e ?? 0).toLocaleString()},
        {title: 'Cambios', dataIndex: 'totalChanges', align: 'center', width: 20, render: (e) => '$' + Number(e ?? 0).toLocaleString()},
        {title: 'Cancelaciones', dataIndex: 'totalCancellations', align: 'center', width: 20, render: (e) => '$' + Number(e ?? 0).toLocaleString()},
        {title: 'Órdenes', dataIndex: 'totalOrders', align: 'center', width: 20, render: (e) => '$' + Number(e ?? 0).toLocaleString()},
        {title: 'Cargos', dataIndex: 'totalCharges', align: 'center', width: 20, render: (e) => '$' + Number(e ?? 0).toLocaleString()},
        {title: 'Reservaciones', dataIndex: 'totalReservations', align: 'center', width: 20, render: (e) => '$' + Number(e ?? 0).toLocaleString()},
        {title: 'Total', dataIndex: 'dayTotal', align: 'center', width: 20, render: (e) => '$' + Number(e ?? 0).toLocaleString()}
    ];

    useEffect(() => {
        if (dailyTotals != null) {
            if (dailyTotals.Totals != null) {
                let processedTotals = dailyTotals.Totals ?? [];

                processedTotals.push({
                    day: 'TOTALES',
                    totalRents: (dailyTotals.RentTotal ?? 0),
                    totalExtensions: (dailyTotals.ExtensionsTotal ?? 0),
                    totalGuests: (dailyTotals.GuestsTotal ?? 0),
                    totalChanges: (dailyTotals.ChangesTotal ?? 0),
                    totalCancellations: (dailyTotals.CancellationsTotal ?? 0),
                    totalOrders: (dailyTotals.OrdersTotal ?? 0),
                    totalCharges: (dailyTotals.ChargesTotal ?? 0),
                    totalReservations: (dailyTotals.ReservationsTotal ?? 0),
                    dayTotal: (dailyTotals.MonthlyTotal ?? 0)
                });

                setDays(processedTotals);
            }
        }
    }, [dailyTotals]);


    return (
        <Row style={{display: 'flex', width: '100%'}}>
            <Table
                size='small'
                scroll={{y: 500}}
                pagination={false}
                columns={totalsColumns}
                dataSource={days}
                rowKey='day'
            />
        </Row>
    );
};

export default DailyIncomeTotalsTabContents;