// AUTHENTICATION
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_LOAD = 'AUTH_LOAD';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

// SHIFT BALANCE
export const SHIFT_BALANCE_START = 'SHIFT_BALANCE_START';
export const SHIFT_BALANCE_LOAD = 'SHIFT_BALANCE_LOAD';
export const SHIFT_BALANCE_FAIL = 'SHIFT_BALANCE_FAIL';
export const SHIFT_BALANCE_UNLOAD = 'SHIFT_BALANCE_UNLOAD';

// MAINTENANCE
export const GET_CURRENT_UTILITY = 'GET_CURRENT_UTILITY';
export const GET_CURRENT_UTILITY_FAIL = 'GET_CURRENT_UTILITY_FAIL';
export const CREATE_UTILITY_EVENT = 'CREATE_UTILITY_EVENT';
export const CREATE_UTILITY_EVENT_FAIL = 'CREATE_UTILITY_EVENT_FAIL';
export const GET_UTILITY_EVENTS = 'GET_UTILITY_EVENTS';
export const GET_UTILITY_EVENTS_FAIL = 'GET_UTILITY_EVENTS_FAIL';
export const CLEAR_MAINTENANCE_DATA = 'CLEAR_MAINTENANCE_DATA';


// AUTH USER
export const GET_AUTH_USER_DATA = 'GET_AUTH_USER_DATA';
export const GET_AUTH_USER_DATA_FAIL = 'GET_AUTH_USER_DATA_FAIL';
export const CLEAR_AUTH_USER_DATA = 'CLEAR_AUTH_USER_DATA';

// UI
export const SWITCH_HOTEL = 'SWITCH_HOTEL';
export const ENABLE_HOTEL_SELECTOR = 'ENABLE_HOTEL_SELECTOR';
export const DISABLE_HOTEL_SELECTOR = 'DISABLE_HOTEL_SELECTOR';
export const CLEAR_UI_DATA = 'CLEAR_UI_DATA';
